import React from "react";
import {withStyles} from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import clsx from "clsx";
// import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import MobileStepper from "@material-ui/core/MobileStepper";
// import SwipeableViews from "react-swipeable-views";
// import { autoPlay } from "react-swipeable-views-utils";

import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

import ReactImageMagnify from "react-image-magnify";
// const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const styles = theme => (
{ 
    root: {
        maxWidth: "100%",
        flexGrow: 1,
        // [theme.breakpoints.down("sm")]: {
        // },
        // [theme.breakpoints.up("md")]: {          
        // },
        // [theme.breakpoints.up("lg")]: {
        //     minHeight: 576,
        // },
        
      },
      header: {
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        padding: theme.spacing(2),

      },      
      mobileStepper:{
          backgroundColor: theme.palette.background.paper,
      },
      img: {
        // height: "100%",
        display: "block",
        // maxWidth: 400,
        overflow: "hidden",
        width: "100%",
      },
      imgCont: {
        border: "solid 1px #fff !important",        
        backgroundColor: theme.palette.background.paper,
        boxShadow: "0 28px 28px rgba(0,0,0,0.05), 0 10px 10px rgba(0,0,0,0.05)",
        borderRadius: "12px",
        zIndex: 10000,
        opacity: 1
      },
      imageClassName: {                
        maxWidth: "100%",
      },
      grayscale: {        
        webkitFilter: "opacity(30%) grayscale(100%)",
        filter: "opacity(30%) grayscale(100%)",
        "&:hover": {
            webkitFilter: "none",
            filter: "none",
          },
    },

});

class ProductSlider extends React.Component {    
    constructor(props) {
        super(props);

        this.state = {
            // items: (props.items &&  props.items.length > 0 ? [props.items[0]] : []),
            slider: {},
            activeStep: 0,
            maxSteps: props.items.length,
            interval: 20000,
            // eslint-disable-next-line no-undef
            cdn: `${process.env.REACT_APP_CDN}Pictures`,
            imgLgSize: "392x392",
            imgMdSize: "183x183",
        };        
    }

    setActiveStep = (step) =>{
        this.setState({ activeStep: step });            
    }

    handleNext = () => {        
        console.log("Next");
        if(this.state.maxSteps > this.state.activeStep + 1){
            this.setActiveStep(this.state.activeStep + 1);
        }
      }
    
    handleBack = () => {   
        console.log("Back");     
        if(0 <= this.state.activeStep - 1){
            this.setActiveStep(this.state.activeStep - 1);
        }        
      }
    
    handleStepChange = (step) => {        
        this.setActiveStep(step);
      }
    
    render() {
        const { classes, items, available } = this.props;         
        //const { classes, rsProps, items } = this.props;         
        // const {items} = this.state;
        const { activeStep, cdn } = this.state; 
        return (
            <div className={classes.root}>
                
                { items && items.length > 0 && 
                    <React.Fragment>           
                        {/* <AutoPlaySwipeableViews                            
                            index={this.state.activeStep}
                            onChangeIndex={this.handleStepChange}
                            enableMouseEvents
                            interval={this.state.interval}
                        >
                        {items.map((step, index) => (                            
                            <img key={index} className={classes.img} src={`${cdn}/${this.state.imgLgSize}/${step.FileName}${step.Extention}`} alt={step.Name} />             
                        ))}                        
                        </AutoPlaySwipeableViews> */}
                        
                        {/* <img className={classes.img} src={`${cdn}/${this.state.imgLgSize}/${items[activeStep].FileName}${items[activeStep].Extention}`} alt={items[activeStep].Name} />  */}
                        
                        <ReactImageMagnify
                        {...{
                            rimProps: {
                                isHintEnabled: true,
                                shouldHideHintAfterFirstActivation: false,
                                enlargedImagePosition: "over"
                            },
                            smallImage: {
                                alt: items[activeStep].Name,
                                isFluidWidth: true,
                                src: `${cdn}/${this.state.imgLgSize}/${items[activeStep].FileName}${items[activeStep].Extention}`,
                                srcSet:  `${cdn}/${this.state.imgLgSize}/${items[activeStep].FileName}${items[activeStep].Extention}`,
                                // srcSet: src.srcSet,
                                // sizes: "(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px"
                            },
                            largeImage: {
                                src: `${cdn}/${items[activeStep].FileName}${items[activeStep].Extention}`,
                                width: 1788,
                                height: 1788 
                            },
                            enlargedImageContainerDimensions: {
                                width: "150%",
                                height: "108%"
                            },
                            // lensStyle: { backgroundColor: "rgba(0,0,0,.4)" },
                            shouldUsePositiveSpaceLens: true, 
                            imageClassName: clsx(classes.imageClassName, (available ? "": classes.grayscale)),
                            enlargedImageContainerClassName: clsx(classes.imgCont)
                        }}                                    
                        />                                    

                         <MobileStepper
                            steps={this.state.maxSteps}
                            position="static"
                            variant="text"
                            activeStep={activeStep}
                            className={classes.mobileStepper}
                            nextButton={
                            <Button size="small" onClick={this.handleNext} disabled={activeStep === this.state.maxSteps - 1}>                                
                                <KeyboardArrowRight />
                            </Button>
                            }
                            backButton={
                            <Button size="small" onClick={this.handleBack} disabled={activeStep === 0}>
                               <KeyboardArrowLeft />
                            </Button>
                            }
                        />
                        
                        
                            {/* {items.map((item, index) => (                            
                                <div key={index}>
                                    
                                    <ReactImageMagnify
                                    {...{
                                        rimProps: {
                                            isHintEnabled: true,
                                            shouldHideHintAfterFirstActivation: false,
                                            enlargedImagePosition: "over"
                                        },
                                        smallImage: {
                                            alt: item.Name,
                                            isFluidWidth: true,
                                            src: `${cdn}/${this.state.imgLgSize}/${item.FileName}${item.Extention}`,
                                            srcSet:  `${cdn}/${this.state.imgLgSize}/${item.FileName}${item.Extention}`,
                                            // srcSet: src.srcSet,
                                            sizes: "(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px"
                                        },
                                        largeImage: {
                                            src: `${cdn}/${item.FileName}${item.Extention}`,
                                            width: 1426,
                                            height: 2000
                                        },
                                        enlargedImageContainerDimensions: {
                                            width: "160%",
                                            height: "100%"
                                        },
                                        // lensStyle: { backgroundColor: "rgba(0,0,0,.4)" },
                                        shouldUsePositiveSpaceLens: true, 
                                        enlargedImageContainerClassName: classes.imgCont
                                    }}                                    
                                    />                                    
                             </div>
                        ))} */}

                                                                                                                                    
                    </React.Fragment>
                }
            </div>
        );
    }
}

export default  withWidth()(withStyles(styles)(ProductSlider));