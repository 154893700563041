import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import withWidth from "@material-ui/core/withWidth";
import { actionOrdersCreators } from "../../store/Orders";
import { actionOrderCreators } from "../../store/Order";

import LinearProgress from "@material-ui/core/LinearProgress";
// import Backdrop from "@material-ui/core/Backdrop";
// import CircularProgress from "@material-ui/core/CircularProgress";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Snackbar from "@material-ui/core/Snackbar";
// import MuiAlert from "@material-ui/lab/Alert";
// import Chip from "@material-ui/core/Chip";
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/uk";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import red from "@material-ui/core/colors/red";
import TablePagination from "@material-ui/core/TablePagination";
import CreateIcon from "@material-ui/icons/Create";
import UpdateIcon from "@material-ui/icons/Update";
import { withTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
// import MenuIcon from "@material-ui/icons/Menu";

import Chip from "@material-ui/core/Chip";
// import BlockIcon from "@material-ui/icons/Block";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import ClearIcon from "@material-ui/icons/Clear";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Divider } from "@material-ui/core";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import SyncIcon from "@material-ui/icons/Sync";
import LoopIcon from "@material-ui/icons/Loop";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import ReplyIcon from "@material-ui/icons/Reply";

const styles = theme => ({ 
    root: {
        flexGrow: 1,      
        padding: theme.spacing(1),
    },
    progress: {
        height: 2,
        // position: "absolute",
        top: 0,
        left: 0,
        right:0,
      },  
    linearColorPrimary: {
        backgroundColor: "orange",
    },
    linearBarColorPrimary: {
        backgroundColor: "#d20000",
    },
    button: {
        padding: theme.spacing(1)
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
      },
    paper:{
        // padding: theme.spacing(1),
        // marginBottom: theme.spacing(1)
    },
    title: {
        flexGrow: 1,
    },
    toolbar: {
        marginBottom: theme.spacing(1)
    },
    paperrow:{
        fontSize: ".9em",
        padding: theme.spacing(1,1),
        marginBottom: theme.spacing(1)
    },
    row: {
        fontSize: "1em",        
        // paddingTop: theme.spacing(1),
        // paddingBottom: theme.spacing(1)
    }, 
    rowhead: {
        fontSize: ".8em",        
        paddingBottom: theme.spacing(0), 
    }, 
    rowactbtn: {
        margin: theme.spacing(0, 1),
    },  
    header: {
        textTransform: "uppercase"        
    },
    prices: {
        textAlign: "right",
    },
    center: {
        textAlign: "center",
    },
    payed: {
        color: theme.palette.success.main,
    },
    stateLbl: {
        textTransform: "uppercase",
        fontSize: ".9em",
        fontWeight: 500,
    },
    state: {
        borderLeft: "6px solid #c3c3c3",        
    },

    st10: {
        borderLeft: "6px solid #c3c3c3",
    },
    stClrr10: {        
    },
    st30: {
        borderLeft: "6px solid #f44336",
    },
    stClr30: {
        color: "#f44336",
    },
    st50: {
        borderLeft: "6px solid #ff9800",
    },
    st50T: {
        borderLeft: "6px solid #ff9800",
        borderRight: "1px solid #ff9800",
        borderTop: "1px solid #ff9800",
        borderBottom: "1px solid #ff9800",

    },
    stClr50: {
        color: "#ff9800",
    },
    st70: {
        borderLeft: "6px solid #2196f3",
    },
    stClr70: {
        color: "#2196f3",
    },
    st150: {
        borderLeft: "6px solid #4caf50",
    },
    stClr150: {
        color: "#388e3c",
        fontSize: ".9em",
        fontWeight: 500,
    },
    discount: {
        color: red[800],
    },
    menuicon: {
        marginRight: theme.spacing(2)
    },
    detailCont: {
        margin: theme.spacing(0),
        padding: theme.spacing(1, 0),
    },
    detailBox: {
        paddingTop: theme.spacing(2),        
        // backgroundColor: "#f9f9f9",
        fontSize: "0.9em",
    },
    detailsrow: {
        padding: theme.spacing(2, 0)
        // marginTop: theme.spacing(2),
    },
    bp: {
        border: "none"
    },
    colorP: {
        color: theme.palette.primary.main,
    },
    bdrbtn: {        
        marginBottom: theme.spacing(1),
    },
    detail: {
        margin: theme.spacing(0,2,2,2),
        borderBottom: "1px solid #e0e0e0"
    },
    detailSum: {        
        borderLeft: "1px solid #e0e0e0",
        paddingLeft: theme.spacing(2),
        [theme.breakpoints.down("xs")]: {
            borderLeft: "none",            
        },        
    }
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-prevent-tabpanel-${index}`}
        aria-labelledby={`scrollable-prevent-tab-${index}`}
        {...other}
      >
        {value === index && <div style={{paddingTop: 10, minHeight: 250}}>{children}</div>}
      </Typography>
    );
}

const QuestionType = {
    CommonConfirmed: { value: 0, text: "", title: ""},
    EditConfirmed: { value: 1, text: "Edit order?", title: "EditOrderTitle"},
    CancelConfirmed: { value: 2, text: "Cancel order?", title: "CancelOrderTitle"},
};

class Orders extends React.Component { 
    constructor(props) {        
        super(props);

        const tabName =  this.props.match.params.t || "draft";

        this.state = {
            open: false,
            detailIdx: -1,
            detail: false,
            detailOrderIdx: -1,
            detailOrder: false,
            openMenu: false,
            openMenuOrder: false,            
            anchorEl: null,
            anchorElOrder: null,  
            tabIndex: tabName === "draft" ? 0 : 1,
            rowIdx: null,
            rowOdrIdx: null,
            openDlg: false,
            selRowDraft: null,
            anchorPayMenuEl: null,
            orderId: null,
            allowCancelOrder: false, 
            allowPay: false,
            copied: null,
            copiedT: null,
            time: 0,
            isOn: false,
            start: 0,
            openQuestion: false,
            questionType: QuestionType.CommonConfirmed,
            orderNumber: "",

        };        
        moment.locale("ru");
        const pi = parseInt(this.props.match.params.pi, 10) || 0;
        const ps = parseInt(this.props.match.params.ps, 10) || 10;

        this.props.actions.requestDrafts(pi, ps);
        this.props.actions.requestOrders(pi, ps);
    }

    handleCloseSnack = () => {        
        this.props.actions.requestCloseMessage();
    };
    handleErrorOrderMessage = () => {
        this.props.actions.closeErrorOrderMessage();
    };

    // setAnchorMenuEl = (el) => {
    //     this.setState({anchorEl: el});
    // };

    handleClickMenu = (event, idx) => {       
        console.log("Click by draw row idx", idx);   
        
                
        this.setState({openMenu: true, anchorEl: event.currentTarget, rowIdx: idx});
    };
    
    validatePay = (row) => {
        if(row.StateCode === 30 || row.StateCode === 140 || row.AmountPayment <= 0 || row.TypePaymentAbr ==="COD") {
            return false;
        }
        return true;
    };

    // Order menu
    handleClickMenuOrder = (event, idx) => {           
        const {Orders} = this.props;
        const order = Orders[idx];
        const allowCancelOrder = order && order.StateCode === 50;        
        this.setState({openMenuOrder: true, anchorElOrder: event.currentTarget, orderId: order.Id,  allowCancelOrder: allowCancelOrder, rowOdrIdx: idx, allowPay: this.validatePay(order)});
    };

    detailDraftClick = (idx) => {
        const {detail, detailIdx } = this.state;
        const {Drafts} = this.props;

        this.setState({detail: detailIdx === idx ? !detail : true, detailIdx: idx});
        if(detailIdx === idx ? !detail : true){
            this.loadDraft(Drafts[idx].Id);
        }        
    };

    detailOrderClick = (idx) => {
        const {detailOrder, detailOrderIdx } = this.state;
        const {Orders} = this.props;
                
        this.setState({detailOrder: detailOrderIdx === idx ? !detailOrder : true, detailOrderIdx: idx});
        if(detailOrderIdx === idx ? !detailOrder : true){            
            this.loadOrder(Orders[idx].Id);
        }
    };

    openDetail = (idx) => {
        const {detail, detailIdx} = this.state;
        return (detail &&  detailIdx === idx);       
    };
    
    openOrderDetail = (idx) => {
        const {detailOrder, detailOrderIdx} = this.state;        
        return (detailOrder &&  detailOrderIdx === idx);       
    };

    callbackCopyOrder = (order) => {
        console.log("COPY ORDER:", order);
        if(order){
            window.scrollTo({top: 0, behavior: "smooth"});
            this.props.actions.refreshJournal(0);
            this.setState({tabIndex: 0});
            this.lightRow(order.Id);
        }
    }

    callbackEditOrder = (order) => {
        console.log("EDIT ORDER:", order);
        if(order){
            this.props.history.push(`/order/${order.Id}`);
        }
    }

    callbackCancelOrder = (order) => {
        console.log(order);
    }

    testDraft = () => {
        const {rowIdx} = this.state;        
        const draft = this.props.Drafts[rowIdx];
        this.lightRow(draft.Id);
    };

    lightRow = (id) => {
        const copied = this.state.copied;
        this.setState({copied: copied === id ? null : id, copiedT: id, isOn: true, time: 0, start: 0, anchorEl: null, openMenu: false});                 
        this.timer = setInterval(() => this.tick(), 500);
    };

    tick() {
        // const repeat = this.state.repeat + 1;
        // const copiedT = this.state.repeat;

        const time = this.state.time +1; 

        if(time > 7){
            this.setState({isOn: false, time: 0, copied: null, copiedT: null});
            clearInterval(this.timer);
            return;
        }
        const copiedT = this.state.copiedT;
        this.setState({
            time: time, copied: (time % 2 === 0 ? copiedT : null )
        });
        // console.log("Time:", time);
        
        // if(repeat > 10){
        //     this.setState({ repeat: 0,  copied: null});
        //     clearInterval(this.timerID);
        // }
        // else
        // {
        //     this.setState({ repeat: repeat});
        //     console.log("Repeat:", repeat);
        //     // this.setState({ repeat: repeat, copied: repeat % 2 === 0 ? copiedT : null   });
        // }
        
        
    }

    editDraft = () => {
        const {rowIdx} = this.state;
        const {Drafts} = this.props;        
        const draft = Drafts[rowIdx];
        // console.log("Edit draft", draft);
        this.props.history.push(`/order/${draft.Id}`);
    };

    editOrder = () => {
        const {rowOdrIdx} = this.state;
        const {Orders} = this.props;
        const order = Orders[rowOdrIdx];                
        this.setState({openMenuOrder: false, anchorElOrder: null, openQuestion: true, questionType: QuestionType.EditConfirmed, orderNumber: order.DocNumber });
        // this.props.actions.editOrder(order.Id, this.callbackEditOrder);
        
    };

    copyOrder = () => {
        const {rowOdrIdx} = this.state;
        const {Orders} = this.props;
        const order = Orders[rowOdrIdx];
        this.setState({openMenuOrder: false, anchorElOrder: null});
        this.props.actions.copyOrder(order.Id, this.callbackCopyOrder);
        // console.log("Copy order", order);
    };

    cancelOrder = () => {
        const {rowOdrIdx} = this.state;
        const {Orders} = this.props;
        const order = Orders[rowOdrIdx];
        this.setState({openMenuOrder: false, anchorElOrder: null});
        this.setState({openMenuOrder: false, anchorElOrder: null, openQuestion: true, questionType: QuestionType.CancelConfirmed, orderNumber: order.DocNumber });
        // this.props.actions.cancelOrder(order.Id, this.callbackCancelOrder);
        
    };

    handleClickOpenDlg = () => {        
        const {rowIdx} = this.state;
        const {Drafts} = this.props;
        
        this.setState({openDlg: true, openMenu: false, selRowDraft: Drafts[rowIdx]});
        
        console.log(Drafts[rowIdx]);
    };
    
    loadDraft = (id) => {        
        this.props.actions.requestDraft(id);
    };
    
    loadOrder = (id) => {                
        this.props.actions.requestConfirmedOrder(id);
    };

    handleCloseDlg = () => {
        this.setState({openDlg: false, selRowDraft: null });
    };

    delDraft = (id) => {    
        this.setState({openDlg: false, selRowDraft: null });
        // console.log(`Delete draft ${id}`);
        this.props.actions.deleteDraft(id);
    };

    deleteClickDraft = () => {
        const {rowIdx} = this.state;
        const {Drafts} = this.props;        
        this.delDraft(Drafts[rowIdx].Id);        
    };

    handleChangePageDrafts = (e, newPage) => {        
       this.props.actions.requestDrafts(newPage, this.props.o_ps);
    };
    
    handleChangeRowsPerPageDrafts = (e) => {
        let ps = +e.target.value; 
        this.props.actions.requestDrafts(0, ps);
    };

    handleChangePage = (e, newPage) => {        
        this.props.actions.requestOrders(newPage, this.props.o_ps);
    };

    handleChangeRowsPerPage = (e) => {
        let ps = +e.target.value; 
        this.props.actions.requestOrders(0, ps);
    };

    handleChangeTabIndex = (event, value) => {
        this.setState({tabIndex: value});
    };

    GetClassState = (state) =>{    
        const { classes} = this.props;            
        switch (state) {
            case 10:
                return classes.st10;
            case 30:
            case 130:
            case 140:
                return classes.st30;
            case 50:
            case 60:    
                return classes.st50;
            case 70:
            case 80:    
                return classes.st70;    
            case 150:
                return classes.st150;
            default:
                return classes.state;
        }
    }
    
    GetClrState = (state) =>{    
        const { classes} = this.props;            
        switch (state) {
            case 10:
                return classes.stClr10;
            case 30:
            case 130:
            case 140:
                return classes.stClr30;
            case 50:
            case 60:    
                return classes.stClr50;    
            case 70:
            case 80:    
                return classes.stClr70;
            case 150:
                return classes.stClr150;
            default:
                return null;
        }
    }

    handleClose = () => {        
        this.setState({ openMenu: false, anchorEl: null, rowIdx: null });        
    };
    
    closeMenuOrder = () => {
        // this.setState({openMenuOrder: false, , orderId: null});
        this.setState({ openMenuOrder: false, anchorElOrder: null, rowOdrIdx: null, orderId: null });
    };

    rowPrice = (row, useBusinessPack) => {
        if(row.OrderRowDetails.length > 1) {
          return(
            <span>
                {parseFloat((row.Amount - (!useBusinessPack ? row.DiscountCdc : 0)) / row.Qty).toFixed(2)}
            </span>
          )
        } else if(row.OrderRowDetails.length === 1){
          if(row.OrderRowDetails[0].OrderRowPromotions.length > 1){
            return(
                <span>
                    {parseFloat((row.Amount - (!useBusinessPack ? row.DiscountCdc : 0)) / row.Qty).toFixed(2)}            
                </span>
              )
          }        
          return(      
            parseFloat((row.Amount - (!useBusinessPack ? row.DiscountCdc : 0)) / row.Qty).toFixed(2)
          );
        }
        return(
          parseFloat((row.Amount - (!useBusinessPack ? row.DiscountCdc : 0)) / row.Qty).toFixed(2)
           // parseFloat(row.Price).toFixed(2) 
        );
    };
    
    openPayMenu = (e, orderId) => {
        console.log("orderId:", orderId);
        this.setState({anchorPayMenuEl: e.currentTarget, orderId: orderId});
    };
    
    closePayMenu = () => {
        this.setState({anchorPayMenuEl: null, orderId: null});
    };

    payOrder = () => {
        if(this.state.orderId){
            this.props.history.push(`/pay/${this.state.orderId}`);
        }
    };
    
    payOrderFromBalance = () => {
        if(this.state.orderId){
            this.setState({anchorPayMenuEl: null, orderId: null});
            this.props.actions.requestPayOrderFromBalance(this.state.orderId);            
        }
    };

    getPayColumn = (row) => {
        const {t} = this.props;
        
        if(row.StateCode === 30 || row.StateCode === 140 || row.AmountPayment <= 0 || row.TypePaymentAbr ==="COD") {
            return null;
        }
        
        //TODO: Not finish yet, payement type?
        return row.Paid ? 
            <Typography className={clsx(this.GetClrState(row.StateCode))} variant="button" display="block" gutterBottom>
                {t("orders.Payed")}
            </Typography>
        :
            <Button size="small" color="primary" aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => this.openPayMenu(e, row.Id)} >                
              {t("orders.Pay")}
            </Button>        
    };
    
    Refresh = () => {
        this.props.actions.refreshJournal(this.state.tabIndex);
        // console.log("tabIndex", this.state.tabIndex)
    };

    handleCloseQuestion = () => {
        this.setState({openQuestion: false, questionType: QuestionType.CommonConfirmed, orderNumber: "" });
    };

    handleYesQuestion = () => {
        const { questionType, rowOdrIdx } = this.state;    
        
        const {Orders} = this.props;
        const order = Orders[rowOdrIdx];

        this.setState({openQuestion: false, questionType: QuestionType.CommonConfirmed, orderNumber: "" });
        
        if(questionType === QuestionType.EditConfirmed){
            // console.log("EDIT:", order.DocNumber);
            this.props.actions.editOrder(order.Id, this.callbackEditOrder);
        } else if(questionType === QuestionType.CancelConfirmed){
            this.props.actions.cancelOrder(order.Id, this.callbackCancelOrder);
            // console.log("CANCEL:", order.DocNumber);
        }
    };

    render() {
        const { classes, isLoading, Orders, Order, Drafts, Draft, orderPayedResult, orderError, orderErrorMessage,
                rowsPerPageOptions, o_ps, o_pi, o_count, d_ps, d_pi, d_count, t, width } = this.props;
        const {tabIndex, anchorEl, openDlg, openQuestion, selRowDraft, allowCancelOrder, 
                questionType, orderNumber, allowPay } = this.state;
        return (
            <div className={classes.root}>                                
                <AppBar className={classes.toolbar} position="static" color="default" elevation={1}>
                    <Toolbar variant="dense" color="default">                        
                        <Typography className={classes.title} variant="h6" color="inherit">
                            {t("orders.JournalOrders")}
                        </Typography>
                        <Button onClick={this.Refresh}
                            variant="outlined"
                            color="primary"
                            className={classes.button}
                            startIcon={<SyncIcon />}
                        >
                            {t("buttons.Refresh")}
                        </Button>                        
                    </Toolbar>                    
                </AppBar>
                { isLoading &&                                                                 
                    // <Backdrop className={classes.backdrop} open={isLoading} >
                    //     <CircularProgress color="inherit" />
                    // </Backdrop>
                    <LinearProgress className={classes.progress} 
                        classes={{
                            colorPrimary: classes.linearColorPrimary,
                            barColorPrimary: classes.linearBarColorPrimary,
                        }}
                    />
                }
                
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={1}>                             
                            <Tabs
                                value={tabIndex} 
                                onChange={this.handleChangeTabIndex} 
                                variant="fullWidth" centered
                                aria-label="order-tabs"
                                indicatorColor="primary"
                                textColor="primary"
                                >                                
                                <Tab icon={<UpdateIcon />} label={t("orders.tabHistory")} />
                                <Tab icon={<CreateIcon />} label={t("orders.tabDrafts")} />
                            </Tabs>
                        </Paper>

                        {/* Drafts */}
                        <TabPanel value={tabIndex} index={1}>
                            {
                                Drafts.map((row, idx) => (
                                    <Paper key={row.Id} className={clsx(classes.paperrow, classes.state, (row.Id === this.state.copied ? classes.st50T : null ))} elevation={1} >
                                    {
                                        width === "xs" ? null :
                                        <Grid className={classes.rowhead} container spacing={1} direction="row" justify="flex-start"  >
                                            <Grid item xs={2} sm={1}></Grid>                                                                        
                                            <Grid item xs={2}>{t("orders.DocNum")}</Grid>
                                            <Grid item xs={2}>{t("orders.Date")}</Grid>
                                            <Grid item align="right" xs={4} sm={2}>{t("orders.AmountWithDiscount")}</Grid>
                                            <Grid item align="right" xs={4} sm={2}>{t("orders.AmountWithoutDiscount")}</Grid>
                                            <Grid item align="right" xs={3}></Grid>                                                                        
                                        </Grid> 
                                    }    
                                    <Grid className={classes.row} container spacing={0} direction="row" justify="flex-start" alignItems="center">
                                    {
                                            width === "xs" ? (
                                                <React.Fragment>
                                                    <Grid item xs={2} sm={1}>
                                                        <IconButton aria-label="expand row" onClick={() => this.detailDraftClick(idx)}>
                                                            {this.openDetail(idx) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                        </IconButton>                        
                                                    </Grid>                                   
                                                    <Grid item xs={3}>
                                                        {row.DocNumber}<br />
                                                        <small>{moment(row.CreatedOn).format("DD-MM-YYYY")}</small>
                                                    </Grid>
                                                    <Grid item align="right" xs={4}>
                                                        { row.AmountCatalogPrice !== 0 ? parseFloat(row.AmountCatalogPrice - row.DiscountCdc).toFixed(2): null } 
                                                        <div><span className="text-gray price--line-through">{parseFloat(row.AmountCatalogPrice).toFixed(2)}</span></div>
                                                    </Grid>                                                    
                                                    <Grid item align="right" xs={3}>
                                                        <IconButton aria-label="draft more" onClick={(e)=> this.handleClickMenu(e, idx)}>
                                                            <MoreVertIcon />
                                                        </IconButton>                                                                
                                                    </Grid>                                        
                                                    <Grid item xs={12} style={{padding: "0"}} >
                                                        <Collapse className={classes.detailCont} in={this.openDetail(idx)} timeout="auto" unmountOnExit>
                                                            <Box className={classes.detailBox}>                                                            
                                                            {
                                                                    Draft ?
                                                                    <React.Fragment>
                                                                    { 
                                                                        Draft.Rows.map( (pos) => (
                                                                            <React.Fragment key={pos.Id}>
                                                                                <Grid  container spacing={1} direction="row" justify="flex-start" alignItems="center">
                                                                                    <Grid item align="right" xs={2}>
                                                                                        {pos.Code}
                                                                                    </Grid>
                                                                                    <Grid item xs={10}>
                                                                                        {pos.Name}
                                                                                    </Grid>
                                                                                </Grid>
                                                                                
                                                                                <Grid  container spacing={1} direction="row" justify="flex-start" alignItems="center">
                                                                                    <Grid item align="right" xs={1}></Grid>
                                                                                    <Grid item align="right" xs={3}>{pos.Qty} шт</Grid>
                                                                                    <Grid item align="right" xs={3}>{this.rowPrice(pos, Draft.UseBusinessPack)}</Grid>
                                                                                    <Grid item align="right" xs={3}>{ parseFloat(pos.Amount - (!Draft.Draft ? pos.DiscountCdc : 0)).toFixed(2)}</Grid>                                                                                    
                                                                                </Grid>                                                                                
                                                                            </React.Fragment>
                                                                        ))
                                                                    }
                                                                    </React.Fragment>
                                                                    :  null
                                                            }
                                                            </Box>
                                                        </Collapse>    
                                                    </Grid>
                                                </React.Fragment>
                                            ) 
                                            : (
                                                <React.Fragment>
                                                    <Grid item xs={2} sm={1}>
                                                        <IconButton aria-label="expand row" onClick={() => this.detailDraftClick(idx)}>
                                                            {this.openDetail(idx) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                        </IconButton>                        
                                                    </Grid>                                   
                                                    <Grid item xs={2}>
                                                        {row.DocNumber}
                                                    </Grid>
                                                    <Grid item xs={2}>                                                        
                                                        {moment(row.CreatedOn).format("DD-MM-YYYY")}
                                                    </Grid>
                                                    <Grid item align="right" xs={4} sm={2}>
                                                        { row.AmountCatalogPrice !== 0 ? parseFloat(row.AmountCatalogPrice - row.DiscountCdc).toFixed(2): null }                                                         
                                                    </Grid>
                                                    <Grid item align="right" xs={4} sm={2}>
                                                        <span className="text-gray price--line-through">{ row.AmountCatalogPrice !== 0 ? parseFloat(row.AmountCatalogPrice).toFixed(2): null}</span>
                                                    </Grid>
                                                    <Grid item align="right" xs={3}>
                                                        <IconButton aria-label="order more" onClick={(e)=> this.handleClickMenu(e, idx)}>
                                                            <MoreVertIcon />
                                                        </IconButton>                                                                
                                                    </Grid>                                        
                                                    <Grid item xs={12}>
                                                        <Collapse className={classes.detailCont} in={this.openDetail(idx)} timeout="auto" unmountOnExit>
                                                                <Box>
                                                                {
                                                                        Draft &&
                                                                        <React.Fragment>
                                                                            <Grid className={classes.rowhead}  container spacing={1} direction="row" justify="flex-start" alignItems="center">
                                                                                <Grid item align="center" xs={1}><b>{t("product.Article")}</b></Grid>
                                                                                <Grid item xs={2}><b>{t("order.Qty")}</b></Grid>
                                                                                <Grid item align="right" xs={2}><b>{t("order.Qty")}</b></Grid>
                                                                                <Grid item align="right" xs={2}><b>{t("order.Price")}</b></Grid>
                                                                                <Grid item align="right" xs={2}><b>{t("orders.AmountWithDiscount")}</b></Grid>
                                                                            </Grid>
                                                                            <Divider />
                                                                        { 
                                                                            Draft.Rows.map( (pos) => (
                                                                                <React.Fragment key={pos.Id}>
                                                                                    <Grid  container spacing={1} direction="row" justify="flex-start" alignItems="center">
                                                                                        <Grid item align="center" sm={2} md={1}>
                                                                                            {pos.Code}
                                                                                        </Grid>
                                                                                        <Grid item xs={10}>
                                                                                            {pos.Name}
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    
                                                                                    <Grid  container spacing={1} direction="row" justify="flex-start" alignItems="center">
                                                                                        <Grid item align="right" xs={4}></Grid>
                                                                                        <Grid item align="right" xs={1}>{pos.Qty} шт</Grid>
                                                                                        <Grid item align="right" xs={2}>{this.rowPrice(pos, Draft.UseBusinessPack)}</Grid>
                                                                                        <Grid item align="right" xs={2}>{ parseFloat(pos.Amount - (!Draft.Draft ? pos.DiscountCdc : 0)).toFixed(2)}</Grid>                                                                                    
                                                                                    </Grid>                                                                                
                                                                                </React.Fragment>
                                                                            ))
                                                                        }
                                                                        </React.Fragment>
                                                                        
                                                                }                                                                
                                                                </Box>
                                                            </Collapse>    
                                                    </Grid>
                                                </React.Fragment>
                                            )
                                        }
                                        </Grid>
                                    </Paper>
                                ))
                            }
                                <Paper className={classes.paper} elevation={1}>
                                    <Grid container spacing={1} direction="row" justify="flex-start" > 
                                        <Grid item xs={12}>
                                        <TablePagination
                                            rowsPerPageOptions={ rowsPerPageOptions }
                                            component="div"
                                            count={d_count}
                                            rowsPerPage={ d_ps }
                                            page={ d_pi < 0 ? 0 : d_pi }
                                            labelRowsPerPage={t("orders.Rows")}
                                            backIconButtonProps={{
                                            "aria-label": "previous page",
                                            }}
                                            nextIconButtonProps={{
                                            "aria-label": "next page",
                                            }}
                                            onChangePage={ this.handleChangePageDrafts }
                                            onChangeRowsPerPage={ this.handleChangeRowsPerPageDrafts }
                                        />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            
                            </TabPanel>
                            
                        {/* Orders */}
                        <TabPanel value={tabIndex} index={0}>
                            {                                 
                                Orders.map((row, idx) => ( 
                                    <Paper key={row.Id} className={clsx(classes.paperrow, this.GetClassState(row.StateCode))} elevation={1} >
                                        {
                                            width === "xs" ? null :
                                            <Grid className={classes.rowhead} container spacing={1} direction="row" justify="flex-start" alignItems="center"  >
                                                <Grid item xs={1} sm={1}></Grid>
                                                <Grid item xs={1} sm={1}>{t("orders.State")}</Grid>                                                                        
                                                <Grid item xs={4} sm={2}>{t("orders.DocNum")}</Grid>
                                                <Grid item xs={4} sm={2}>{t("orders.BarCode")}</Grid>
                                                <Grid item xs={2} sm={1} align="right">{t("orders.Date")}</Grid>
                                                <Grid item align="right" xs={4} sm={2} md={1}>{t("orders.Amount")}</Grid>
                                                <Grid item align="right" xs={4} sm={2} md={1}>{t("orders.AmountOB")}</Grid>
                                                <Grid item align="right" xs={4} sm={2} md={1}></Grid>                      
                                                {/* <Grid item align="right" xs={4} sm={2}>{t("orders.AmountWithoutDiscount")}</Grid> */}
                                                
                                            </Grid> 
                                        }                                    
                                        <Grid className={classes.row} container spacing={0} direction="row" justify="flex-start" alignItems="center">
                                        {                                            
                                            <React.Fragment>
                                                    {/* First row */}
                                                    <Grid item xs={2} sm={1}>
                                                        <IconButton className={classes.button} aria-label="expand row" onClick={() => this.detailOrderClick(idx)}>
                                                            {this.openOrderDetail(idx) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                        </IconButton>                        
                                                    </Grid> 
                                                    <Grid item xs={3} sm={1} align={width === "xs" ? "right": "left" }>
                                                        {/* <Button>{`${row.StateName}`}</Button> */}
                                                        <Typography className={clsx( classes.stateLbl, this.GetClrState(row.StateCode))} display="block">
                                                                {t(`order.State.${row.StateName}`)}
                                                            {/* {   `order.State.${row.StateName}`    } */}
                                                        </Typography>
                                                    </Grid> 
                                                    <Grid item xs={5} sm={2} align={width === "xs" ? "right": "left" }>
                                                        {row.DocNumber}                                                        
                                                    </Grid>
                                                    {width === "xs" ? 
                                                        <Grid item align="right" xs={2}>
                                                            <IconButton className={classes.button} aria-label="order more" onClick={(e)=> this.handleClickMenuOrder(e, idx)}>
                                                            <MoreVertIcon />
                                                            </IconButton> 
                                                        </Grid> : null 
                                                    }                                                                                                        
                                                    {/* Second row */}
                                                    <Grid item xs={5} sm={2} align={width === "xs" ? "right": "left" }>
                                                        {row.BarCode} 
                                                    </Grid>
                                                    <Grid item align="right" xs={5} sm={1}>
                                                        {moment(row.CreatedOn).format("DD-MM-YYYY")}
                                                    </Grid>                                                                                                        
                                                    {/* Third row */}
                                                    <Grid item align={width === "xs" ? "right": "right"} xs={5} sm={2} md={1}>
                                                        <Typography variant="button" display="block">
                                                            {parseFloat(row.Amount).toFixed(2)}
                                                        </Typography>                                                        
                                                    </Grid>
                                                    <Grid item align={width === "xs" ? "right": "right"} xs={5} sm={2} md={1}>
                                                        <Typography color="primary" variant= {row.AmountOB > 0 ? "button": "body2"} display="block">
                                                            {parseFloat(row.AmountOB).toFixed(2)}
                                                        </Typography>                                                        
                                                    </Grid>                                                                                                                                                            
                                                    <Grid item align="right" xs={5} sm={2} md={1} >
                                                        {/* {this.getPayColumn(row)} */}
                                                    </Grid>
                                                    <Grid item align="right" xs={5} sm={2} md={1}>
                                                        {row.UseReward ?
                                                            <Chip className={classes.bp} color="secondary" variant="outlined" size="small" label={t("order.BP")} icon={<DoneAllIcon />} />                                                        
                                                            : null 
                                                        }                                                        
                                                    </Grid>
                                                    {width !== "xs" ? 
                                                        <Grid item align="right" xs={2} sm={2} md={1}>
                                                            <IconButton aria-label="order more" onClick={(e)=> this.handleClickMenuOrder(e, idx)}>
                                                            <MoreVertIcon />
                                                            </IconButton> 
                                                        </Grid> : null 
                                                    }

                                                    {/* More row */}
                                                    <Grid item xs={12}>
                                                        <Collapse className={classes.detailCont} in={this.openOrderDetail(idx)} timeout="auto" unmountOnExit>
                                                            <Box>                                                              
                                                            {
                                                                Order && 
                                                                <React.Fragment>                                                                    
                                                                    <Divider />
                                                                    <Grid className={classes.detailsrow}  container spacing={1} direction="row" justify="flex-start" alignItems="stretch">
                                                                        <Grid item xs={12} sm={7}>                                                                                                                                                
                                                                        {
                                                                            Order.Rows.map( (pos) => (
                                                                            <div className={classes.detail} key={pos.LineNumber}>                                                                                
                                                                                <Grid  container spacing={1} direction="row" justify="flex-start" alignItems="flex-start">
                                                                                    <Grid item xs={2}>
                                                                                        {pos.ProductCode}
                                                                                    </Grid>
                                                                                    <Grid item xs={10}>
                                                                                        {pos.ProductName}
                                                                                    </Grid>
                                                                                </Grid>
                                                                                    
                                                                                <Grid className={classes.bdrbtn}  container spacing={1} direction="row" justify="flex-start" alignItems="center">                                                                                    
                                                                                    <Grid item xs={2} >
                                                                                        <Typography variant="body2" display="block">{pos.Qty} <small>шт</small></Typography>                                                                                        
                                                                                    </Grid>
                                                                                    <Grid item  xs={4} sm={2} >
                                                                                        <Typography variant="button" display="block">{parseFloat(pos.Price).toFixed(2)}</Typography>
                                                                                    </Grid>
                                                                                    <Grid item align="right" xs={4} sm={3} >
                                                                                        <Typography variant="button" display="block">{parseFloat(pos.Amount).toFixed(2)}</Typography>                                                                                        
                                                                                    </Grid> 
                                                                                </Grid>   
                                                                            </div>
                                                                            ))
                                                                        }                                                                        
                                                                    </Grid>
                                                                    
                                                                    <Grid item xs={12} sm={5} >
                                                                        <div className={classes.detailSum}>
                                                                                <Grid container spacing={1} direction="row" >
                                                                                    <Grid item>
                                                                                        <Typography variant="body1">{t("order.AmountPaid")}:</Typography>
                                                                                    </Grid>
                                                                                    <Grid item>
                                                                                        <Typography variant="body1"><b>{parseFloat(row.Amount).toFixed(2)}</b></Typography>
                                                                                    </Grid>
                                                                                </Grid>                                                                        
                                                                                <Grid container spacing={1} direction="row" >
                                                                                    <Grid item>
                                                                                    { t("order.AmountCOD") }:
                                                                                    </Grid>
                                                                                    <Grid item>
                                                                                        <b>{parseFloat(Order.AmountCOD).toFixed(2)}</b>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid container spacing={1} direction="row" >
                                                                                    <Grid item>
                                                                                    { t("order.AmountDelivery") }:
                                                                                    </Grid>
                                                                                    <Grid item>
                                                                                    <b>{parseFloat(Order.AmountDelivery).toFixed(2)}</b>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid container spacing={1} direction="row" >
                                                                                    <Grid item>
                                                                                    { t("order.AmountService") }:
                                                                                    </Grid>
                                                                                    <Grid item>
                                                                                        <b>{parseFloat(Order.AmountService).toFixed(2)}</b>
                                                                                    </Grid>
                                                                                </Grid>                                                                        
                                                                                {
                                                                                row.AmountOB > 0 ?                                                                        
                                                                                <Grid container spacing={1} direction="row" >
                                                                                    <Grid item>
                                                                                    { t("order.AmountLO") }:
                                                                                    </Grid>
                                                                                    <Grid item>
                                                                                        <b className={classes.colorP}>{parseFloat(row.AmountOB).toFixed(2)}</b>
                                                                                    </Grid>
                                                                                </Grid> : null
                                                                                }
                                                                                <Grid container spacing={1} direction="row" style={{marginTop: "16px"}} >
                                                                                    <Grid item>
                                                                                        { t("order.BusinessPack") }:
                                                                                    </Grid>
                                                                                    <Grid item>
                                                                                        {Order.UseBusinessPack ? <DoneAllIcon color="secondary" fontSize="small" />: null}
                                                                                    </Grid>
                                                                                </Grid>                                                                        
                                                                                <Grid container spacing={1} direction="row" >
                                                                                    <Grid item>                                                                            
                                                                                    {t("order.PaymentMethod")}:
                                                                                    </Grid>
                                                                                    <Grid item>
                                                                                        <b>{t(`order.payments.${row.TypePaymentName}`)}</b>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid container spacing={1} style={{marginTop: "16px"}} >
                                                                                    <Grid item>
                                                                                    { t("order.RecipientFirstName") }:
                                                                                    </Grid>
                                                                                    <Grid item>
                                                                                        <b>{Order.RecipientFirstName}</b>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid container spacing={1} direction="row" >
                                                                                    <Grid item>
                                                                                    { t("order.RecipientLastName") }:
                                                                                    </Grid>
                                                                                    <Grid item>
                                                                                        <b>{Order.RecipientLastName}</b>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid container spacing={1} direction="row" >
                                                                                    <Grid item>
                                                                                    { t("order.RecipientMiddleName") }:
                                                                                    </Grid>
                                                                                    <Grid item>
                                                                                        <b>{Order.RecipientMiddleName}</b>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid container spacing={1} direction="row" >
                                                                                    <Grid item>
                                                                                        { t("order.RecipientPhone") }:
                                                                                    </Grid>
                                                                                    <Grid item>
                                                                                        <b>{Order.RecipientPhone}</b>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                { Order.PostOffice &&
                                                                                <>
                                                                                <Grid container spacing={1} style={{marginTop: "16px"}} >
                                                                                    <Grid item>
                                                                                    {t("order.Carrier")}:
                                                                                    </Grid>
                                                                                    <Grid item>
                                                                                        <b>{Order.PostOffice.Carrier}</b>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid container spacing={1} >
                                                                                    <Grid item>
                                                                                    {t("order.DevileryAddress")}:
                                                                                    </Grid>
                                                                                    <Grid item>
                                                                                        <b>{Order.PostOffice.LongName}</b>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                </>
                                                                                }
                                                                        </div>    
                                                                    </Grid>                                                                                                                                        
                                                                    </Grid>                                                                    
                                                                    
                                                                </React.Fragment>
                                                            }
                                                            </Box>
                                                        </Collapse>
                                                    </Grid>
                                            </React.Fragment>                                            
                                        }
                                        </Grid>                                                                        
                                    </Paper>                                                                                                       
                                ))
                            }
                            <Paper className={classes.paper} elevation={1}>
                                <Grid container spacing={1} direction="row" justify="flex-start" > 
                                    <Grid item xs={12}>
                                        <TablePagination
                                            rowsPerPageOptions={ rowsPerPageOptions }
                                            component="div"
                                            count={o_count}
                                            rowsPerPage={ o_ps }
                                            page={ o_pi < 0 ? 0 : o_pi }
                                            labelRowsPerPage={t("orders.Rows")}
                                            backIconButtonProps={{
                                            "aria-label": "previous page",
                                            }}
                                            nextIconButtonProps={{
                                            "aria-label": "next page",
                                            }}
                                            onChangePage={ this.handleChangePage }
                                            onChangeRowsPerPage={ this.handleChangeRowsPerPage }
                                        />
                                    </Grid>
                                </Grid>    
                            </Paper>
                        </TabPanel>
                    </Grid>
                </Grid>                                    
            
                {/* Draft menu */}
                <Menu id="draft-menu"
                    keepMounted
                    open={this.state.openMenu}
                    onClose={this.handleClose}
                    anchorEl={anchorEl}                    
                    >
                    <MenuItem onClick={this.editDraft}><CreateIcon className={classes.menuicon} /> {t("orders.Edit")}</MenuItem>
                    <MenuItem onClick={this.handleClickOpenDlg}><ClearIcon className={classes.menuicon} /> {t("orders.Delete")}</MenuItem>
                    
                    {/* <MenuItem onClick={this.testDraft}><ClearIcon className={classes.menuicon} /> TEST</MenuItem> */}
                    
                </Menu>
                
                {/* Pay menu */}
                <Menu id="order-pay-menu"                                   
                    anchorEl={this.state.anchorPayMenuEl}                    
                    open={Boolean(this.state.anchorPayMenuEl)}
                    onClose={this.closePayMenu}
                >
                    <MenuItem onClick={this.payOrder} ><CreditCardIcon className={classes.menuicon} />{t("orders.PayOrder")}</MenuItem>
                    <MenuItem onClick={this.payOrderFromBalance}><LoopIcon className={classes.menuicon} />{t("orders.PayOrderFromBalance")}</MenuItem>                    
                </Menu>

                {/* Order menu */}
                <Menu id="order-act-menu"                   
                    anchorEl={this.state.anchorElOrder}
                    open={this.state.openMenuOrder}
                    // keepMounted                    
                    onClose={this.closeMenuOrder}
                >
                    <MenuItem onClick={this.editOrder} disabled={!allowCancelOrder} ><CreateIcon className={classes.menuicon} />{t("orders.Edit")}</MenuItem>
                    <MenuItem onClick={this.copyOrder} ><FilterNoneIcon className={classes.menuicon} />{t("orders.CopyOrder")}</MenuItem>
                    <MenuItem onClick={this.cancelOrder} disabled={!allowCancelOrder} ><ReplyIcon className={classes.menuicon} />{t("orders.CancelOrder")}</MenuItem>
                    <Divider />
                    <MenuItem onClick={this.payOrder} disabled={!allowPay} ><CreditCardIcon className={classes.menuicon} />{t("orders.PayOrder")}</MenuItem>
                    <MenuItem onClick={this.payOrderFromBalance} disabled={!allowPay}><LoopIcon className={classes.menuicon} />{t("orders.PayOrderFromBalance")}</MenuItem>
                </Menu>
               
                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={openDlg}
                    onClose={this.handleCloseDlg}
                    aria-labelledby="alert-dialogdel-title"
                    aria-describedby="alert-dialogdel-description"
                >
                    <DialogTitle id="alert-dialogdel-title">{selRowDraft ? `${t("orders.Draft")}: ${selRowDraft.DocNumber}` : null}</DialogTitle>
                    <DialogContent>                    
                        {
                         selRowDraft && (
                             <React.Fragment>
                                 <Alert variant="outlined" severity="warning">  
                                    {t("orders.DeleteDraft", {number: selRowDraft.DocNumber})}
                                 </Alert>
                             </React.Fragment>                                                             
                         )   
                        }                    
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={this.deleteClickDraft} color="primary">
                        {t("buttons.Delete")}
                    </Button>
                    <Button onClick={this.handleCloseDlg} autoFocus>
                        {t("buttons.Cancel")}
                    </Button>
                    </DialogActions>
                </Dialog>                
                
                { orderPayedResult &&                 
                <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={this.handleCloseSnack}
                    open={Boolean(orderPayedResult)} autoHideDuration={orderPayedResult.Succes ? 6000 : 60000} >
                    <Alert variant="filled" severity={orderPayedResult.Succes ? "success": "warning"} onClose={this.handleCloseSnack} >
                        {orderPayedResult.Succes ? t("order.PaymentSuccess") : orderPayedResult.ErrorDescription}
                    </Alert>
                </Snackbar>
                }

                { orderError && 
                    <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={this.handleErrorOrderMessage}
                        open={Boolean(orderError)} autoHideDuration={30000} >
                    <Alert variant="filled" severity="error" onClose={this.handleErrorOrderMessage} >
                        {orderErrorMessage}
                    </Alert>
                </Snackbar>                

                }
                

                <Dialog
                    fullWidth={true}  maxWidth={"xs"}
                    open={openQuestion}
                    onClose={this.handleCloseQuestion}
                    aria-labelledby="alert-dialog-order"
                    aria-describedby="alert-dialog-order-description"
                >
                    {/* <DialogTitle id="alert-dialog-order">
                        {t(`orders.${questionType.title}`, {number: orderNumber})}
                    </DialogTitle> */}

                    <DialogContent>
                        <DialogContentText id="alert-dialog-order-description">
                            {t(`orders.${questionType.title}`, {number: orderNumber})}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseQuestion} color="default" autoFocus>
                            {t("buttons.No")}
                        </Button>
                    <Button onClick={this.handleYesQuestion} color="primary">
                        {t("buttons.Yes")}
                    </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default  withTranslation()(withWidth()(withStyles(styles)(
    connect(    
        state => state.ordersRequest,
        dispatch => {
            return {
               actions: bindActionCreators(Object.assign({}, actionOrdersCreators, actionOrderCreators ), dispatch)
        }    }
    )(Orders)
)));
