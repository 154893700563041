import React, { useEffect, useState, useCallback  } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Tooltip, Select, MenuItem, IconButton, Paper } from "@material-ui/core";
import { fade } from "@material-ui/core/styles";
import CachedIcon from "@material-ui/icons/Cached";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import "moment/locale/uk";
import { OidcUserManager } from "../../helpers/OidcSettings";


const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,      
    },
    
    paper: {
      margin: theme.spacing(1),
      padding: theme.spacing(1)
    },

    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,    
      border: "1px solid #e8e8e8",
      backgroundColor: fade(theme.palette.common.white, 0.75),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 1),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      // padding: theme.spacing(1, 1, 1, 0),    
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
    select: {
      minWidth: 120,
      backgroundColor: fade(theme.palette.common.white, 0.75),      
      "&:before": {
        borderBottom: "none",
      },
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 1),
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    iconButton: {
      margin: theme.spacing(1),
    },

  })
);

export const LogSms = () => {
  const classes = useStyles();
  const [phone, setPhone] = React.useState("");
  const [provider, setProvider] = React.useState(" ");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [data, setData] = React.useState(null);

  const handleChangeProvider = (event) => {
    setProvider(event.target.value);
  };

  const fetchData = useCallback( async () => {
    const userManager = await OidcUserManager();
    userManager.getUser().then(user => {
      let token = null;
      if (user != null) {
        token =  `Bearer ${user.access_token}`;
      }
      
      const url = `/api/v1.0/LogSms/GetLogSms/${(phone.length === 0 ? " " : phone)}/${(provider.length === 0 ? " " : provider)}/${page+1}/${pageSize}`;      
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Accept", "application/json");
      headers.append("Authorization", token);
   
    fetch(url, { method: "GET", headers: headers })      
      .then(response => response.json())
      .then(data => {      
        // console.log(data);
        setData(data)                        
      });
    });

  }, [phone, provider, page, pageSize]);

  const handleClickSearch = () => {
    fetchData();
  };
  

  const columns = [
    {
      name: "Id",
      label: "ID",
      options: {
        filter: false,
      }
    },
    {
      name: "CreatedOn",
      label: "Date",
      options: {
        filter: false,
        customBodyRender: (value) => {
          return (
            moment(value).format("DD-MM-YY HH:mm:ss")
            // `${moment(value).format("DD-MM-YY")} ${moment(value).format("HH:mm:ss")}`
          )
        }
      }
    },
    {
      name: "Phone",
      label: "Phone",
      options: {
        filter: false,
      }
    },
    {
      name: "Message",
      label: "Message",
      options: {
        filter: false,
      }
    },
    {
      name: "Provider",
      label: "Provider",
      options: {
        filter: false,
      }
    },
    {
      name: "MessageId",
      label: "Message ID",
      options: {
        filter: false,
      }
    },
  ];
  
  const options = {
    download: false,
    print: false,
    filter: false,
    page: page,
    rowsPerPage: pageSize,
    rowsPerPageOptions: [10, 50, 100],
    serverSide: true,
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: "none",
    searchText: phone,
    count: data ? data.TotalCount: 0, 
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":          
          setPage(tableState.page);
          break;
        case "changeRowsPerPage":
          setPage(0);
          setPageSize(tableState.rowsPerPage);          
          break;
          case "search":
            {
              const phone = tableState.searchText ? tableState.searchText: "";
              if(phone.length === 0 || phone.length > 11 ) {
                setPage(0);
                setPhone(phone);            
              }
              break;
            }
        default:
          break;
      }
    },
    // eslint-disable-next-line react/display-name
    customToolbar: () => {
      return (
          <>
          <Tooltip title={"Load data"}>
            <IconButton color="primary" className={classes.iconButton} onClick={handleClickSearch}>
              <CachedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={"SMS Provider"}>
                                   
        <Select className={classes.select}
          labelId="provider-select-label"
          id="provider-select"
          value={provider}
          onChange={handleChangeProvider}
        >
          <MenuItem value=" ">All</MenuItem>
          <MenuItem value="ESputnik">ESputnik</MenuItem>
          <MenuItem value={"Kyivstar"}>Kyivstar</MenuItem>
        </Select>
      
      </Tooltip>
        </>
      )
    },

  };

  useEffect( () => {
    fetchData();
  }, [fetchData]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} > 
        <MUIDataTable 
          responsive="standard"
          title="SMS log"
          data={data? data.Data : []}                            
          columns={columns} 
          options={options} 
          // components={components} 
        />    
      </Paper>
      
    </div>
  );
}
