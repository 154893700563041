import axios from "axios";
// import userManager from "../helpers/userManager";
import { OidcUserManager } from "../helpers/OidcSettings";

const requestNavItemsType = "REQUEST_NAVITEMS";
const receiveNavItemsType = "RECEIVE_NAVITEMS";
const requestCustomerType = "REQUEST_CUSTOMER";
const receiveCustomerType = "RECEIVE_CUSTOMER";

const requestFooterNavItemsType = "REQUEST_FOOTERNAVITEMS";
const receiveFooterNavItemsType = "RECEIVE_FOOTERNAVITEMS";
const requestApplayPromoCodeType = "REQUEST_APPLAYPROMOCODE";
const receiveApplayPromoCodeType = "RECEIVE_APPLAYPROMOCODE";
const showSnakBarFromNavBarType = "SHOW_SNACKBAR_NAVBAR";
const hideSnakBarFromNavBarType = "HIDE_SNACKBAR_NAVBAR";
const showDlgGiftCardType = "DIALOG_GIFT"; 


const initialState = {
  AppSettings: {
    AppName: "",  
    Chats: [],
  },   
  LeftNavItems: [],
  GroupNavItems: [],
  Langs: [],
  FooterNavItems: [],  
  isLoading: false,
  promocode: null,
  Adviser: null,
  snackbarTxt: "",
  variant: "success",
  snackbarShow: false, 
  showDlgGiftCard: false,
};

export const actionCreators = {
  getActiveLang: () => async (dispatch, getState) => {
    const langs = getState().appLayout.Langs;
    for (let i = 0; i < langs.length; i++) {
      const element = langs[i];
      if (element.Active) {
        return element.Name;
      }
    }
    return "";
  },
  requestFooterNavItems: () => async (dispatch) => {
    
    const userManager = await OidcUserManager();

    userManager.getUser().then(function(user) {
      let token = null;

      if (user != null) {
        token = `Bearer ${user.access_token}`;        
      }

      dispatch({ type: requestFooterNavItemsType });

      const URL = "/api/applayout/footernav";
      axios
        .get(URL, { headers: { Authorization: token } })
        .then(response => {          
          dispatch({ type: receiveFooterNavItemsType, footerNavItems: response.data });
        })
        .catch(error => {
          console.log("error " + error);
        });
    });
  },
  requestNavItems: () => async (dispatch) => {    
    const userManager = await OidcUserManager();
    userManager.getUser().then(function(user) {
      let token = null;
      if (user != null) {
        token = `Bearer ${user.access_token}`;       
      }
      dispatch({ type: requestNavItemsType });
      const URL = "/api/applayout/layout";
      axios
        .get(URL, { headers: { Authorization: token } })
        .then(response => {
          const applayout = response.data;
          
          //console.log("applayout", applayout);
          dispatch({ type: receiveNavItemsType, applayout });
        })
        .catch(error => {
          console.log("error " + error);
        });
    });
  },
  applayPromoCode: (code) => async (dispatch) => {
    console.log("Promo code", code);
    const userManager = await OidcUserManager();

    userManager.getUser().then(function(user) {
      let token = null;

      if (user != null) {
        token = `Bearer ${user.access_token}`;        
      }

      dispatch({ type: requestApplayPromoCodeType });

      const URL = `/api/v1.0/Adviser/Promocode/${code}`;
      axios
        .get(URL, { headers: { Authorization: token } })
        .then(response => {          
          // dispatch({ type: receiveApplayPromoCodeType, promocode: response.data });
          dispatch({
            type: showSnakBarFromNavBarType,
            variant: "success",
            snackbarTxt: response.data,
            showDlgGiftCard: false,
            flag: false,
            //snackbarTxt: `${error.response.statusText}. ${error.response.data}`,
          });
        })
        .catch(error => {                  
          dispatch({
            type: showSnakBarFromNavBarType,
            variant: "error",
            snackbarTxt: error.response.data,            
            showDlgGiftCard: true,
            flag: true,
            //snackbarTxt: `${error.response.statusText}. ${error.response.data}`,
          });                    
        });
    });
    
  },

  hideSnackBar: () => (dispatch) => {
    dispatch({ type: hideSnakBarFromNavBarType });
  },
  
  showDlgGift: (flag) => (dispatch) =>{
    dispatch({ type: showDlgGiftCardType, flag });
  },

  getCustomer : () => async (dispatch) => {
    
    const userManager = await OidcUserManager();
    userManager.getUser().then(function(user) {
      let token = null;
      if (user != null) {
        token = `Bearer ${user.access_token}`
      }
      dispatch({ type: requestCustomerType });
      const URL = "/api/v1.0/adviser/getadviserbycontext";
      axios
        .get(URL, { headers: { Authorization: token } })
        .then(response => {          
          dispatch({ type: receiveCustomerType, Adviser: response.data});
        })
        .catch(error => {
          console.log("error " + error);
        });
    });    
  },
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {    
    case requestNavItemsType:
    case requestApplayPromoCodeType: 
    case requestCustomerType:
      return {
        ...state,
        isLoading: true
      };
    case receiveNavItemsType:
      return {
        ...state,
        ...action.applayout,
        isLoading: false
      };
    case receiveFooterNavItemsType:
      return {
        ...state,
        FooterNavItems: action.footerNavItems,
        isLoading: false
      }; 
    case receiveApplayPromoCodeType:
        return {
          ...state,
          promocode: action.promocode,
          isLoading: false
        }; 
    case receiveCustomerType:
        return {
          ...state,
          Adviser: action.Adviser,
          isLoading: false
        }; 
    case showSnakBarFromNavBarType: 
        return {
          ...state,
          snackbarTxt: action.snackbarTxt,
          variant: action.variant,
          snackbarShow: true,
          showDlgGiftCard: action.flag,
          isLoading: false,          
        };
    case hideSnakBarFromNavBarType:
          return {
            ...state,
            snackbarTxt: "",
            snackbarShow: false,
          }; 
    case showDlgGiftCardType: 
        return {
          ...state,
          showDlgGiftCard: action.flag
        } ;      
    default:
      return state;
  }
};
