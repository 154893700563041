import React, { Component } from "react";
// import {withRouter} from "react-router-dom";
// import { bindActionCreators } from "redux";
// import { actionOrderCreators } from "../../store/Order";
import {withStyles} from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
// import { connect } from "react-redux";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import { withTranslation } from "react-i18next";

const styles = theme => ({
    card: {    
        height: "auto",        
        // height: 512,
        // [theme.breakpoints.down("sm")]: {
        //     height: "auto",
        // },
    }, 
    media: {
        height: 180,
        [theme.breakpoints.up("sm")]: {
            height: 360,
        },
        
    },
    content: {
        height: 140,
        [theme.breakpoints.down("sm")]: {
            height: "auto",
        },
    },
    chip: {
        marginRight: theme.spacing(2),
    },
});

class PromotionsThumb extends Component {
    constructor(props) {
        super(props);
        this.state = {     
        };
    }    
    clickByPromotions = () => {
        this.props.clickByPromotions(this.props.item);
    };

    render() {
        const {item, classes, t } = this.props;
        return (
            <Card className={classes.card} >
                <CardActionArea onClick={this.clickByPromotions}>
                    { item.Banners.length > 0 ?
                        <CardMedia
                            className={classes.media}
                            image={item.Banners[0].FileName}
                            title={item.Name}
                        /> : <CardMedia
                                image="assets/files/images/promotions/default.png"
                                className={classes.media}                                    
                                title={item.Name}
                            />
                    }
                    <CardContent className={classes.content}>
                        <Typography gutterBottom variant="h5" component="h2">
                            {
                            parseInt(item.AvailableQuantity) > 0 ? <Chip className={classes.chip}  variant="outlined"  size="small" label={`${item.AvailableQuantity}`} /> 
                                                        : null
                            }
                            {item.Name}                            
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            {item.Description}
                        </Typography>
                    </CardContent>
                </CardActionArea>                
                <CardActions>
                    <Button size="small" color="primary" onClick={this.clickByPromotions}>
                        {
                            item.PromotionType === 0 ? t("promotions.DetailsAboutPromotions") :
                            t("promotions.DetailsAboutChest")
                        }
                    </Button>
                </CardActions>
            </Card>
        );
    }
}

export default  withTranslation()(withWidth() (withStyles(styles)((PromotionsThumb))));

// export default withTranslation()(withWidth() (withRouter( withStyles(styles)(
//     connect(    
//         state => state.orderRequest,
//         dispatch => {
//             return {
//                actions: bindActionCreators(Object.assign({}, actionOrderCreators), dispatch)
//         }}
//     )(PromotionsThumb)
// ))));