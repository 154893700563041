import axios from "axios";
// import userManager from "../helpers/userManager";
import { OidcUserManager } from "../helpers/OidcSettings";
import { EntityState, Order, OrderRow, OrderRowDetail, OrderRowType, PromotionType } from "../definitions/order";

const startRequestType = "START_REQUEST";
const requestNewOrderType = "REQUEST_NEW_ORDER";
const reciveNewOrderType = "RECIVE_NEW_ORDER";
const confirmOrderType = "CONFIRM_CORDER";
const errorconfirmOrderType = "ERROR_CONFIRM_CORDER";

const addRowToOrder = "ADD_ROW_TO_ORDER";
const saveOrder = "SAVE_ORDER";
const changeOrderField = "ORDER_ORDER_FIELD";
const delRowFromOrder = "DEL_ROW_FROM_ORDER";
const updRow = "UPD_ROW";
const getSuggestionsProducts = "GET_SUGGESTIONS_PRODUCTS";
const clearSuggestionsProducts = "CLEAR_SUGGESTIONS_PRODUCTS";
const hideSnakBarFromOrder = "HIDE_SNACKBAR_ORDER";
const showSnakBarFromOrder = "SHOW_SNACKBAR_ORDER";

const requestRecommendedType = "REQUEST_RECOMMENDED";
const requestPromotionsType = "REQUEST_PROMOTIONS";
const requestChestType = "REQUEST_CHEST";
const requestDeliveriesType = "REQUEST_DELIVERIES";
const requestScriptType = "REQUEST_SCRIPTS";
const getAppSettings = "GET_APPSETTINGS";
const beginOrderChg = "BEGIN_ORDER_CHANGE";

const requestCatalogItemType = "REQUEST_CATALOG_ITEM";
const reciveCatalogItemType = "RECIVE_CATALOG_ITEM";

// const saveOrder = "SAVE_ORDER";

const emptyGuid = "00000000-0000-0000-0000-000000000000";

const NewOrder = () => {
  return new Order({
    ExtOrderId: emptyGuid,
    DocNumber: "",
    AppSsid: "",
    Email: "",
    ExtUserId: "",
    Amount: 0.0,
    ApplayDiscountAmount: 0.0,
    DiscountAmount: 0.0,
    AmountOrder: 0.0,
    CreatedOn: new Date(),
    UpdatedOn: new Date(),
    Id: 0,
    Rows: [],
    UseRepayment: false,
    UseBusinessPack: false,    
    ShowedRecomProducts: false,
    State: EntityState.Added,
    UsePaymentOB: false,
    AmountOB: 0.0,
    AmountCOD: 0.0,
    AmountDelivery: 0.0,
    DeliveryProviderId: emptyGuid,
    PaymentTypeId: emptyGuid,
    RefId: "",

    RecipientFirstName: "",    
    RecipientLastName: "",        
    RecipientMiddleName: "",        
    RecipientPhone: "",        
    RecipientMail: "",
    
  });
};


const accessBusiness = (user) => {  
  if(user){  
    //console.log("accessBusiness", user);
    if(user.profile == null || user.profile.role == null){
      return false;
    }
      const administrator = "Administrator";
      const consultant = "Consultant";
      
      const isAdministrator = user.profile.role.includes(administrator);
      const isConsultant = user.profile.role.includes(consultant);
      // console.log("isAdministrator", isAdministrator, "isConsultant", isConsultant);      
      return isAdministrator || isConsultant;
    }      
    
    return false;
}

const accessUsePaymentOB = (user) => {  
  if(user){  
    return user.profile != null;
      // const administrator = "Administrator";
      // const consultant = "Consultant";
      // const customer= "Customer";
      
      // const isAdministrator = user.profile.role.includes(administrator);
      // const isConsultant = user.profile.role.includes(consultant);
      // console.log("isAdministrator", isAdministrator, "isConsultant", isConsultant);      
      // return isAdministrator || isConsultant;
    }      
    
    return false;
}

const initialStorageState =  () => {    
    return  {
      Order: NewOrder(),
      reqOrderId: "",
      qtyrows: 0,
      isLoading: false,
      snackbarTxt: "",
      snackbarShow: false,
      suggestions: { CatalogItems: [] },
      promotions: [],
      chests: [],
      recommended: [],
      scripts: [],
      deliveries: [],
      selectedDelivery: null,
      variant: "success",
      redirect: false,
      user: null,
      accessBusinessAttr: false,
      accessUsePaymentOBAttr: false,
      disabled: false,
      product: null,
      code: "",      
    };
}

const initialState = initialStorageState();

const SelectedDeliveryById = (deliveries, id ) => {  
  if(!deliveries){
    return null;
  }

  for (let i = 0; i < deliveries.length; i++) {
    const delivery = deliveries[i];
    if(delivery.Id === id){
      return delivery;          
    }
  }    
  return null;
}

const SelectedPaymentTypeById = (paymenttypes, id ) => {  
  if(!paymenttypes){
    return null;
  }

  for (let i = 0; i < paymenttypes.length; i++) {
    const payment = paymenttypes[i];
    if(payment && payment.Id === id){
      return payment;
    }
  }
  
  return null;
}

async function Save(order, token) {
  // console.log("Order before save", order);
  const url = "/api/v1/order/save";
  return await axios.post(url, order, { headers: { Authorization: token } });
}

const CreateOrderRowPromotion = (item) => {
  // console.log("Create Order Row Promotion", item);

  if(item.orderrowtype === OrderRowType.Default)
    return null;

  if(!item.pos.Source){
    return null;
  }

  const qty = parseInt(item.qty);
  const price = item.pos.Price;
  const rate = item.pos.Rate;

  const orderRowPromotion = {
    Id: 0,
    Qty: qty,
    Price: price,
    Rate: rate,    
    CatalogPrice: item.pos.CatalogPrice,
    CatalogPricePv: item.pos.CatalogPricePv,
    PersonalVolume: item.pos.UseLO ? qty * price : 0,
    PersonalVolumePv: item.pos.UseLO ? (qty * price) / rate : 0,
    AmountCdc: item.pos.DiscountForConsultant ? qty * price : 0,
    AmountCdcPv: item.pos.DiscountForConsultant ? (qty * price) / rate : 0,
    PromotionsId: item.pos.Source.Id,
    PromotionsName: item.pos.Source.Name,
    PromotionsDescr: item.pos.Source.Descr,
    DiscountForConsultant: item.pos.DiscountForConsultant,
    UseLO: item.pos.UseLO,
    State: EntityState.Added,
  };
  
  // console.log("CreateOrderRowPromotion orderRowPromotion", orderRowPromotion);
  return orderRowPromotion;
};

const GetChestById = (id, chests) => {
  if(chests){
    for (let i = 0; i < chests.length; i++) {
      const chest = chests[i];
      if(chest.Id === id){
        return chest;
      }
    }
  }
  return null;
}

const ValidateChest = (rows, item, chest) => {  
    
    // console.log("CHEST", chest);
    // console.log("ITEM", item);      
  
    const ApplyToQty = chest.ActionByCondition.ApplyToQty;
    const ApplyToPos = chest.ActionByCondition.ApplyToPos;
    let result = false;
    
    let pos = 0;
    for (let r = 0; r < rows.length; r++) {
      const row = rows[r];
      let qty = 0;
      
        for (let d = 0; d < row.OrderRowDetails.length; d++) {
          const detail = row.OrderRowDetails[d];
          for (let p = 0; p < detail.OrderRowPromotions.length; p++) {
            const promo = detail.OrderRowPromotions[p];            
            if(promo.PromotionsId === chest.Id){
              pos++;
              console.log(`pos=${pos}; ApplyToPos=${ApplyToPos}`)
              if(pos + 1 > ApplyToPos){
                result = true;
                break;
                // return true;
              }

              qty += promo.Qty;              
              
              if(qty + item.qty > ApplyToQty){
                result = true;
                break;
                // return true;
              }
            }
          }          
        }
      
    }  
  return result;
}

const PrepareOrderRows = function (order, items) {
  let txt = "";
  // The loop on added items
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    let exists = false;
    txt += item.pos.LocalName + "; \n";
    // console.log("Add to order item", item);
    
    const qty = parseInt(item.qty);    
    const { Price, CatalogPrice, CatalogPricePv, Rate } = item.pos;
    
    order.Amount += parseFloat(qty * Price);
    order.AmountCatalogPrice += parseFloat(qty * CatalogPrice);

    // Level 0. The loop on exists order items
    for (let r = 0; r < order.Rows.length; r++) {
      const row = order.Rows[r];      
      if (row.ProductId === item.pos.ProductId) {                        
        
        row.State = EntityState.Modified;
        row.Qty += qty;      
        row.Amount += parseFloat(qty * Price);
        row.AmountPv +=  parseFloat(qty * Price / Rate);
        row.Price = parseFloat(row.Amount / row.Qty);
  
        let existsDet = false;       
        // Level 1. The loop on exists order item details
        for (let d = 0; d < row.OrderRowDetails.length; d++) {          
          const detail = row.OrderRowDetails[d];                    
          if (detail.OrderRowType === item.orderrowtype) {
            detail.State = EntityState.Modified;            
            detail.Qty += qty;
            detail.Amount +=  parseFloat(qty * Price);
            detail.AmountPv +=  parseFloat(qty * Price / Rate);            
            // detail.AmountCOD = detail.Amount;
            // detail.AmountCODPv = detail.AmountPv;
            detail.Price = detail.Amount / detail.Qty;

            // Level 2. The loop on details of promotions            
            let existsPromo = false;            
            if(detail.OrderRowPromotions){
              for(let p = 0; p < detail.OrderRowPromotions.length; p++){
                const promo = detail.OrderRowPromotions[p];
                if(!promo){
                  continue;
                }
                if(!item.pos.Source){
                  continue;
                }

                if(promo.PromotionsId === item.pos.Source.Id){
                  promo.Qty += item.qty;
                  existsPromo = true;
                }               
              }
            }

            if(!existsPromo){              
              if(item.pos.Source){
                const promoDetail = CreateOrderRowPromotion(item);
                if(promoDetail)
                  detail.OrderRowPromotions.push(promoDetail);                                              
              }              
            }
            existsDet = true;
            break;
          }
        }

        if (!existsDet) {
          const rowPromo = CreateOrderRowPromotion(item);
          row.OrderRowDetails.push(
            new OrderRowDetail({
              Id: 0,
              OrderRowType: item.orderrowtype,
              Qty: qty,
              Price: Price,
              Rate: Rate,        
              CatalogPrice: CatalogPrice,
              CatalogPricePv: CatalogPricePv,
              Amount: qty * Price,
              AmountPv: qty * Price / Rate,   
              AmountCdc: ( item.pos.UseLO && !item.pos.OutsideCatalog ? parseFloat(qty * Price).toFixed(2) : 0),
              AmountCdcPv: ( item.pos.UseLO && !item.pos.OutsideCatalog ? parseFloat(qty * Price / Rate).toFixed(2) : 0),
              State: EntityState.Added,
              OrderRowPromotions: rowPromo ? [rowPromo] : [],
              OutsideCatalog: item.pos.OutsideCatalog,
              UseLO: item.pos.UseLO
            })
          );
        }        
        exists = true;        
        break;
      }
    }

    // add if not exists product in order
    if (!exists) {

      const orderRow = new OrderRow({
        Id: 0,
        Price: Price,
        Rate: Rate,
        Amount: parseFloat(Price * qty),
        AmountPv:  parseFloat(Price * qty / Rate),
        CatalogPrice: CatalogPrice,
        CatalogPricePv: CatalogPricePv,        
        PriceCdc: 0,
        PriceCdcPv: 0,
        Discount: 0,
        DiscountPv: 0,        
        FastUpBonus: 0,
        PersonalVolume: 0,
        AnonymousDiscount: 0,
        DeferredDiscount: 0,
        PromoDiscount: 0,
        Qty: qty,
        ProductId: item.pos.ProductId,
        Code: item.pos.Code,
        Name: item.pos.Name,
        CatalogId: "",
        LocalName: item.pos.LocalName,
        Image: item.pos.Image,
        OrderId: order.Id,
        CreatedOn: new Date(),
        UpdatedOn: new Date(),
        State: 4,
        OutsideCatalog: item.pos.OutsideCatalog,
        UseLO: item.pos.UseLO,
        OrderRowDetails: [
          new OrderRowDetail({
            Id: 0,
            OrderRowType: item.orderrowtype,
            Qty: qty,
            Price: Price,
            Rate: Rate,        
            CatalogPrice: CatalogPrice,
            CatalogPricePv: CatalogPricePv,
            Amount: qty * Price,
            AmountPv: qty * Price / Rate,
            AmountCdc: ( item.pos.UseLO && !item.pos.OutsideCatalog ? parseFloat(qty * Price) : 0),
            AmountCdcPv: ( item.pos.UseLO && !item.pos.OutsideCatalog ? parseFloat(qty * Price / Rate) : 0),
            State: EntityState.Added,            
            OutsideCatalog: item.pos.OutsideCatalog,
            UseLO: item.pos.UseLO
          }),
        ],
      });

      if(item.pos.Source){
        const rowPromo = CreateOrderRowPromotion(item);
        if(rowPromo){
          orderRow.OrderRowDetails[0].OrderRowPromotions.push(rowPromo);
        }        
      }

      order.Rows.push(orderRow);
    }

    if (order.Id > 0) {
      order.State = EntityState.Modified;
    }
  }

  return txt;
};

export const actionOrderCreators = {
  getAppSettings: () => async (dispatch, getState) => {
    const appLayout = getState().appLayout;
    const appSettings = appLayout.AppSettings;//  getState().appLayout.Langs; //
    // console.log(appLayout.user);
    dispatch({ type: getAppSettings, appSettings, user: appLayout.user });
  },

  requestNewOrder: (refId) => async (dispatch) => {
    dispatch({ type: startRequestType });
    const userManager = await OidcUserManager();
    userManager.getUser().then(async (user) => {
      let token = null;
      if (user) {
        token = `Bearer ${user.access_token}`;
      }
      const accessBusinessAttr = accessBusiness(user);
      const accessUsePaymentOBAttr = accessUsePaymentOB(user);
      const url = "api/v1/order/new";
      if(typeof refId === "undefined") {
        refId = "";
      } else if(refId === null){
        refId = "";
      }
      
      await axios
        .get(url, { headers: { Authorization: token } })
        .then((response) => {
          
          console.log("New order request", response);
          console.log("refId", refId );

          response.data.RefId = refId;
          dispatch({
            type: reciveNewOrderType,
                reqOrderId: "undefined",
                order: response.data,
                qtyrows: response.data.Rows.length,
                Amount: response.data.Amount,
                user: user,
                accessBusinessAttr: accessBusinessAttr,
                accessUsePaymentOBAttr: accessUsePaymentOBAttr
          });
          // dispatch({ type: requestPromotionsType, promotions: response.data });
        });
    });
  },

  requestOrder: (id, refId) => async (dispatch, getState) => {
    
    const req = getState().orderRequest;

    // if(typeof id === "undefined"  && typeof refId === "undefined"){
    //   return;
    // }

    if(id === "new"){
      id = 0;
    }


    // console.log("requestOrder ID", id);

    if (id === req.reqOrderId) {
      // Don't issue a duplicate request (we already have or are loading the requested data)
      return;
    }
    
    if(typeof refId === "undefined") {
      refId = "";
    } else if(refId === null){
      refId = "";
    }

    dispatch({ type: requestNewOrderType, reqOrderId: id });

    const userManager = await OidcUserManager();
    let accessBusinessAttr = false;
    let accessUsePaymentOBAttr = false;
    
    userManager.getUser().then(async (user) => {      
      let token = null;      
      if (user) {        
        token = `Bearer ${user.access_token}`;
        accessBusinessAttr = accessBusiness(user);
        accessUsePaymentOBAttr = accessUsePaymentOB(user);
      }
          
      const url = typeof id === "undefined" ? "api/v1/order/get" : `api/v1/order/get/${id}`;
      
      axios
        .all([
          axios.get(url, { headers: { Authorization: token } }),
          // axios.get(urlPromo, {headers:{Authorization: token }}),
          // axios.get(urlChast, {headers:{Authorization: token }}),
        ])        
        .then(
          axios.spread((order) => {            
            // console.log("requestOrder", order.data);
            if (order.data !== "") {
              const newOrder = order.data;                            
              newOrder.RefId = id;
              dispatch({
                type: reciveNewOrderType,
                reqOrderId: id,
                order: newOrder,
                qtyrows: order.data.Rows.length,
                Amount: order.data.Amount,
                user: user,
                accessBusinessAttr: accessBusinessAttr,
                accessUsePaymentOBAttr: accessUsePaymentOBAttr,
                // promotions: promotions.data,
                // chests: chests.data,
              });
            } else {
              const newOrder = getState().orderRequest.Order;
              newOrder.RefId = refId;

              dispatch({
                type: reciveNewOrderType,
                reqOrderId: id,
                order: newOrder, //getState().orderRequest.Order,
                qtyrows: 0,
                user: user,
                accessBusinessAttr: accessBusinessAttr,
                accessUsePaymentOBAttr: accessUsePaymentOBAttr
                // promotions: promotions.data,
                // chests: chests.data,
              });
            }
          })
        )
        .catch((error) => {
          dispatch({
            type: showSnakBarFromOrder,
            variant: "error",
            snackbarTxt: `${error.response.statusText}. ${error.response.data}`,
          });
        });
    });
  },

  requestPromotions: () => async (dispatch, getState) => {
    const req = getState().orderRequest;
    if (req.promotions.length > 0) {
      // Don't issue a duplicate request (we already have or are loading the requested data)
      return;
    }
    const userManager = await OidcUserManager();
    userManager.getUser().then(async (user) => {
      let token = null;
      if (user) {
        token = `Bearer ${user.access_token}`;
      }
      const url = "api/v1/promotions/getactivepromotions";
      await axios
        .get(url, { headers: { Authorization: token } })
        .then((response) => {          
          dispatch({
            type: requestPromotionsType,
            promotions: response.data,
          });
        });
    });
  },

  requestDeliveries: () => async (dispatch, getState) => {
    const req = getState().orderRequest;
    if (req.deliveries.length > 0) {
      // Don't issue a duplicate request (we already have or are loading the requested data)
      return;
    }

    const userManager = await OidcUserManager();
    userManager.getUser().then(async (user) => {
      let token = null;
      if (user) {
        token = `Bearer ${user.access_token}`;
      }

      const url = "api/v1/deliveryprovider";
      await axios
        .get(url, { headers: { Authorization: token } })
        .then((response) => {
          dispatch({ type: requestDeliveriesType, deliveries: response.data });
        });
    });
  },

  requestChest: () => async (dispatch, getState) => {
    const req = getState().orderRequest;
    if (req.chests.length > 0) {
      // Don't issue a duplicate request (we already have or are loading the requested data)
      return;
    }
    const userManager = await OidcUserManager();
    userManager.getUser().then(async (user) => {
      let token = null;
      
      if (user) {        
        token = `Bearer ${user.access_token}`;
      }

      const accessBusinessAttr = accessBusiness(user);
      if(!accessBusinessAttr){
        return;
      }

      const url = "api/v1/promotions/getactivechestpromotions";
      await axios
        .get(url, { headers: { Authorization: token } })
        .then((response) => {
          dispatch({ type: requestChestType, chests: response.data });
        });
    });
  },

  requestRecommended: () => async (dispatch, getState) => {
    const req = getState().orderRequest;
    if (req.recommended.length > 0) {
      // Don't issue a duplicate request (we already have or are loading the requested data)
      return;
    }
    const userManager = await OidcUserManager();
    userManager.getUser().then(async (user) => {
      let token = null;
      if (user) {
        token = `Bearer ${user.access_token}`;
      }

      const url = "api/v1/CatalogItem/GetRecommended";
      await axios
        .get(url, { headers: { Authorization: token } })
        .then((response) => {
          dispatch({
            type: requestRecommendedType,
            recommended: response.data.CatalogItems,
          });
        });
    });
  },

  requestScripts: (id, callBack) => async (dispatch) => {
    dispatch({ type: startRequestType });
    const userManager = await OidcUserManager();
    userManager.getUser().then(async (user) => {
      let token = null;
      if (user) {
        token = `Bearer ${user.access_token}`;
      }
      const url = `api/v1/order/getactivescriptsbyorder?id=${id}`;
      await axios
        .get(url, { headers: { Authorization: token } })
        .then((response) => {                    
          if(callBack){
            callBack(response.data.length > 0); 
          }
          // console.log("getactivescriptsbyorder", response.data);
          dispatch({ type: requestScriptType, scripts: response.data });          
        })
        .catch((error) => {
          dispatch({
            type: showSnakBarFromOrder,
            variant: "error",
            snackbarTxt: `${error.response.statusText}. ${error.response.data}`,
          });
        });
    });
  },

  /**
   * Save order to DB
   */
  saveOrder: (options) => async (dispatch, getState) => {    
    const {Order} = getState().orderRequest;
    console.log(options.message, Order);      
  },

  handleChangeFieldValue: (e) => async (dispatch, getState) => {
    // console.log("Update");
    const orderRequest = getState().orderRequest;
    
    if(e.target.type === "checkbox") {      
      orderRequest.Order[e.target.name] = e.target.checked;
    }
    else {
      // const fieldError = `error${e.target.name}`;      
      // console.log("fieldError", fieldError);
      // orderRequest.Order[fieldError] = e.target.value.length === 0; 

      orderRequest.Order[e.target.name] = e.target.value;


      if(e.target.name === "DeliveryProviderId"){
        // orderRequest.Order.PaymentTypeId = null;
        const delivery = SelectedDeliveryById(orderRequest.deliveries, e.target.value);
        orderRequest.Order.PostOfficeId = "";
        orderRequest.Order.PostOffice = null;        
        orderRequest.selectedDelivery = delivery;
        if(delivery.DeliveryMethods && delivery.DeliveryMethods.length > 0){
          const method = delivery.DeliveryMethods[0];
          orderRequest.Order.AmountDelivery = parseFloat(method.CostDelivery);
          orderRequest.Order.DeliveryMethodId = method.Id;
          orderRequest.Order.DeliveryMethodOrder = method.DeliveryMethodOrder;

          // console.log("Cost Delivery",method.CostDelivery);
        }
        if(delivery.PaymentTypes && delivery.PaymentTypes.length > 0){
          const payment = delivery.PaymentTypes[0];
          console.log("payment", payment);
          orderRequest.Order.PaymentTypeId = payment.Id;

          if(payment){
            orderRequest.Order.AmountCOD = payment.AmountCOD;
          }
          else{
            orderRequest.Order.AmountCOD = 0;
          }
        }
        // console.log("delivery", delivery);
      }

      if(e.target.name === "PaymentTypeId"){
        const delivery = SelectedDeliveryById(orderRequest.deliveries, orderRequest.Order.DeliveryProviderId);
        if(delivery){
          const payment = SelectedPaymentTypeById(delivery.PaymentTypes, e.target.value);
          orderRequest.Order.PaymentTypeId = payment.Id;
          if(payment){
            orderRequest.Order.AmountCOD = payment.AmountCOD;
          }
          else{
            orderRequest.Order.AmountCOD = 0;
          }
          // console.log("payment", payment);
        }
        // SelectedDeliveryById
        // console.log(e.target);
      }
    }        
    dispatch({ type: changeOrderField, orderRequest: orderRequest });
    // this.setState({ [e.target.name]: e.target.checked });
  },

  selectPostOffice: (item) => async (dispatch, getState) => {
    const orderRequest = getState().orderRequest;
    if(item){
      orderRequest.Order.PostOfficeId = item.Id;
      orderRequest.Order.PostOffice = item;
    } else {
      orderRequest.Order.PostOfficeId = "";
      orderRequest.Order.PostOffice = null;
    }

    
    dispatch({ type: changeOrderField, orderRequest: orderRequest });
  },

  // Add array to order
  addToOrder: (items, callBackFn) => async (dispatch, getState) => {
    if (items === undefined) {
      return;
    }

    // console.log("STORAGE addToOrder");

    dispatch({ type: beginOrderChg });
    dispatch({ type: requestNewOrderType });

    const orderRequest = getState().orderRequest;
    const order = orderRequest.Order;
    const chests = orderRequest.chests;

    if(items.length === 1){
      const item = items[0];
      if(item.orderrowtype === OrderRowType.Chest){
        const chest = GetChestById(item.pos.Source.Id, chests);
        if(chest){
          if(ValidateChest(order.Rows, item, chest)){
            dispatch({
              type: showSnakBarFromOrder,
              variant: "warning",
              snackbarTxt: `Скриня уже використана! \r\n ${chest.Name}.`,
            });
            return;
          }
        }
      }
    }

    const txt = PrepareOrderRows(order, items, chests);

    const userManager = await OidcUserManager();
    userManager.getUser().then(async (user) => {
      let token = null;
      let accessBusinessAttr = order.accessBusinessAttr;
      let accessUsePaymentOBAttr = order.accessUsePaymentOBAttr;

      if (user) {        
          token = `Bearer ${user.access_token}`; 
          accessBusinessAttr = accessBusiness(user);
          accessUsePaymentOBAttr = accessUsePaymentOB(user);
      }
      order.accessBusinessAttr = accessBusinessAttr;
      order.accessUsePaymentOBAttr = accessUsePaymentOBAttr;
      
      const neworder = order.Id === 0;            
      // await Save(order, token)
      const url = "/api/v1.0/order/save";         
      // console.log("addToOrder", order);
      axios.post(url, order, { headers: { Authorization: token } })      
        .then((response) => {        
          // console.log(response.data);
          dispatch({ type: addRowToOrder, order: response.data, snackbarTxt: txt, snackbarShow: true, neworder: neworder, accessBusinessAttr: accessBusinessAttr, accessUsePaymentOBAttr: accessUsePaymentOBAttr });          
          // if(order.Id === 0 && response.data.Id > 0){
            
          //   const days = 10;
          //   let date = new Date(Date.now() + days * 24 * 60 * 60 * 1000);
            
          //   document.cookie = `orderId=${response.data.Id}; path=/; samesite=strict; expires=${date.toUTCString()}`;
          // }

          if(callBackFn){
            if(order.Id === 0 && response.data.Id > 0){
              callBackFn(response.data.Id);
            }
          }

        })
        .catch((error) => {
          dispatch({
            type: showSnakBarFromOrder,
            variant: "error",
            snackbarTxt: `${error.response.statusText}. ${error.response.data}`,
          });
        });
    });
  },

  updRow: (ri, di, qty) => async (dispatch, getState) => {
    const order = getState().orderRequest.Order;
    const row = order.Rows[ri];
    row.Qty = 0;
    for (let i = 0; i < row.OrderRowDetails.length; i++) {
      const detail = row.OrderRowDetails[i];
      if(i === di){
        detail.Qty = qty;  
      }
      row.Qty += detail.Qty;
    }
    dispatch({ type: updRow, order: {...order} }); 
  },

  updFromScripts: (sIndx, pIndx, qty) => async (dispatch, getState) => {        
    const orderRequest = getState().orderRequest;
    const scripts = orderRequest.scripts.slice();    
    // const products = scripts[sIndx].ActionByCondition.Products;
    const product = scripts[sIndx].ActionByCondition.Products[pIndx];
    if(product){
      product.Qty = parseInt(qty);
    }
    // products.splice(pIndx, 1);
    // scripts[sIndx].ActionByCondition.Products = products;                
    dispatch({ type: requestScriptType, scripts: scripts });    
  },

  updToOrder: (ri, di, qty) => async (dispatch, getState) => {
    // dispatch({ type: requestNewOrderType });

    dispatch({ type: beginOrderChg });
    // const orderRequest = getState().orderRequest;    
    const order = getState().orderRequest.Order;
    const row = order.Rows[ri];
    row.Qty = 0;
    for (let i = 0; i < row.OrderRowDetails.length; i++) {
      const detail = row.OrderRowDetails[i];
      if(i === di){
        detail.Qty = qty;  
      }
      row.Qty += detail.Qty;
    }
    
    let txt = row.Name;
    const userManager = await OidcUserManager();
    userManager.getUser().then(async (user) => {
      let token = null;
      let accessBusinessAttr = order.accessBusinessAttr;
      let accessUsePaymentOBAttr = order.accessUsePaymentOBAttr;

      if (user) {        
          token = `Bearer ${user.access_token}`; 
          accessBusinessAttr = accessBusiness(user);
          accessUsePaymentOBAttr = accessUsePaymentOB(user);
      }
      order.accessBusinessAttr = accessBusinessAttr;
      order.accessUsePaymentOBAttr = accessUsePaymentOBAttr;      
      // console.log("Update order row", row);   
      // dispatch({ type: addRowToOrder, order: order, snackbarTxt: "", snackbarShow: false, neworder: false });      
      const url = "/api/v1.0/order/updrows";      
      axios.post(url, [row], { headers: { Authorization: token } })      
        .then((response) => {                  
          dispatch({ type: addRowToOrder, order: response.data, snackbarTxt: txt, snackbarShow: true, neworder: false, accessBusinessAttr: accessBusinessAttr, accessUsePaymentOBAttr: accessUsePaymentOBAttr });
        })
        .catch((error) => {          
          dispatch({
            type: showSnakBarFromOrder,
            variant: "error",
            snackbarTxt: `${error.response.data}`,
          });

        });        
      
      
      // await Save(order, token)
      //   .then((response) => {          
      //     dispatch({ type: addRowToOrder, order: response.data, snackbarTxt: txt, snackbarShow: false, neworder: false });
      //   })
      //   .catch((error) => {
      //     dispatch({
      //       type: showSnakBarFromOrder,
      //       variant: "error",
      //       snackbarTxt: `${error.response.statusText}. ${error.response.data}`,
      //     });
      //   });


    });
  },

  delFromOrder: (e, idx, msg) => async (dispatch, getState) => {
    dispatch({ type: requestNewOrderType });

    e.stopPropagation();
    const orderRequest = getState().orderRequest;
    const id = orderRequest.Order.Rows[idx].Id;

    const userManager = await OidcUserManager();
    userManager.getUser().then(async (user) => {
      let token = null;
      if (user != null) {
        token = `Bearer ${user.access_token}`;
      }
      orderRequest.snackbarTxt =  msg;// "Deleted row from order";

      const url = `api/v1/order/delrow/${id}`;

      await axios
        .delete(url, { headers: { Authorization: token } })
        .then((response) => {
          orderRequest.Order = response.data;
          orderRequest.Amount = response.data.Amount;
          orderRequest.qtyrows = response.data.Rows.length; // orderRequest.Order.Rows.length;
          dispatch({ type: delRowFromOrder, orderRequest: orderRequest });
        });
    });
  },

  delFromDetail: (rowId, detailId) => async (dispatch, getState) => {
    dispatch({ type: requestNewOrderType });

    const orderRequest = getState().orderRequest;

    const userManager = await OidcUserManager();
    userManager.getUser().then(async (user) => {
      let token = null;
      if (user != null) {
        token = `Bearer ${user.access_token}`;
      }
      orderRequest.snackbarTxt = "Deleted row from order";
      const url = `api/v1/order/delrowdetail/${rowId}/${detailId}`;
      await axios
        .delete(url, { headers: { Authorization: token } })
        .then((response) => {
          orderRequest.Order = response.data;
          orderRequest.Amount = response.data.Amount;
          orderRequest.qtyrows = response.data.Rows.length; // orderRequest.Order.Rows.length;
          dispatch({ type: delRowFromOrder, orderRequest: orderRequest });
        });
    });
  },

  delFromScripts: (sIndx, pIndx) => async (dispatch, getState) => {        
    const orderRequest = getState().orderRequest;
    const scripts = orderRequest.scripts.slice();    
    const products = scripts[sIndx].ActionByCondition.Products;
    products.splice(pIndx, 1);
    scripts[sIndx].ActionByCondition.Products = products;                
    dispatch({ type: requestScriptType, scripts: scripts });    
  },

  delAllFromScripts: () => async (dispatch, getState) => {
    const orderRequest = getState().orderRequest;
    const scripts = orderRequest.scripts.slice();    
    for (let i = 0; i < scripts.length; i++) {
      const script = scripts[i];
      const products = script.ActionByCondition.Products;
      const left = [];
      
      for (let y = 0; y < products.length; y++) {
        const product = products[y];
        if(product.ForceAdd){
          left.push(product);
        }        
        // console.log(product);
      }

      script.ActionByCondition.Products = left;
    }
    dispatch({ type: requestScriptType, scripts: scripts });
  },

  hideSnackBar: () => (dispatch) => {
    dispatch({ type: hideSnakBarFromOrder });
  },

  clearProducts: () => async (dispatch) => {
    dispatch({
      type: getSuggestionsProducts,
      suggestions: { CatalogItems: [] },
      promotions: [],
      chests: [],
    });
  },

  getProducts: (value) => async (dispatch) => {
    const inputLength = value.length;
    
    if (inputLength > 2) {
      let token = null;
      const userManager = await OidcUserManager();
      userManager.getUser().then(function (user) {
        if (user != null) {
          token = `Bearer ${user.access_token}`;
        }
        const page = 1;
        const size = 20;
        const url = `/api/v1/CatalogItem/Search/${value}/true/${page}/${size}`;
        axios
          .get(url, {
            headers: { Authorization: token },
          })
          .then(function (response) {
            //console.log(response.data);
            dispatch({
              type: getSuggestionsProducts,
              suggestions: response.data,
            });
          })
          .catch((error) => {
            console.log(error);
            dispatch({
              type: getSuggestionsProducts,
              suggestions: { CatalogItems: [] },
            });
          });
      });

      // const response = await fetch(url);
      // const items = await response.json();
      // const result = inputLength === 0 ? [] : items;
      // dispatch({ type: getSuggestionsProducts, suggestions: result });
    } else {
      dispatch({ type: clearSuggestionsProducts });
    }
  },

  confirmOrder: (handleConfirmedOrder) => async (dispatch, getState) => {   
    dispatch({ type: beginOrderChg });
    
    const orderRequest = getState().orderRequest;
    const order = { ...orderRequest.Order };
    const scripts = orderRequest.scripts;
    const items = [];

    for (let i = 0; i < scripts.length; i++) {
      const script = scripts[i];
      // console.log("Add from Script", script);

      for (let y = 0; y < script.ActionByCondition.Products.length; y++) {        
        const data = script.ActionByCondition.Products[y]; 
        // console.log("Data by script", data);
        const pos = {
          ParentIdrref: data.Product.Idrref,
          ProductId: data.Product.Id,
          orderrowtype: OrderRowType.Script,
          Name: data.Product.Name,
          LocalName: data.Product.Name,
          Price: data.Prices[0],
          CatalogPrice: data.Product.CatalogPrice,
          CatalogPricePv: data.Product.CatalogPricePv,
          Code: data.Product.Code,
          Image: data.Product.Image,
          Rate: data.Rate,
          UseLO: script.ActionByCondition.UseLO,
          DiscountForConsultant: script.ActionByCondition.DiscountForConsultant,
          Source: { 
            Id: script.Id,
            Idrref: script.Idrref,
            Name: script.Name,
            Description: script.Description
        }
        };
        items.push({ pos: pos, qty: parseInt(data.Qty), orderrowtype: OrderRowType.Script, promotiontype: PromotionType.Script, } );
      }
    }
    
    PrepareOrderRows(order, items);

    const userManager = await OidcUserManager();
    userManager.getUser().then(async (user) => {
      let token = null;
      if (user != null) {
        token = `Bearer ${user.access_token}`;
      }
      
      await Save(order, token)
        .then((response) => {
          // console.log("After Save before confirm", response.data);          
          
          const confirmUrl = `api/v1/order/confirm/${response.data.Id}`;          
          axios.put(confirmUrl, null, { headers: { Authorization: token } })
          .then((response) => {            
            // console.log("Get confimed order from 1c", response.data);
            const newOrder = NewOrder();
            newOrder.ExtOrderId = response.data.ExtOrderId;
            
            dispatch({ type: confirmOrderType, order: newOrder, snackbarTxt: "Save & confirm order", snackbarShow: true, redirect: true });
            dispatch({
              type: reciveNewOrderType,
                  reqOrderId: "undefined",
                  order: newOrder,
                  qtyrows: 0,
                  Amount: 0,
                  user: user,                                
            });
            
            handleConfirmedOrder(response.data);                      
          })
          .catch((error) => {
            console.log("Error 1" ,error);
            if(error.response.data.code === 409){
              let errorTxt = `${error.response.data.info}\r\n${error.response.data.description}\r\n`;
              for (let i = 0; i < error.response.data.rows.length; i++) {
                const pos = error.response.data.rows[i];
                errorTxt += `${pos.code} - ${pos.productname}; Не вистачає: ${pos.missed} шт.\r\n`
              }
              
              dispatch({
                type: errorconfirmOrderType,
                variant: "error",
                snackbarTxt: errorTxt,
                order: error.response.data.Order,
              });
            }else{                                          
              dispatch({
                type: showSnakBarFromOrder,
                variant: "error",
                snackbarTxt: `${error.message}. ${error.response.data.description}`,
                //snackbarTxt: `${error.response.statusText}. ${error.response.data}`,
              });
            }            
          });

        })
        .catch((error) => {
          console.log("Error 2", error);
          dispatch({
            type: showSnakBarFromOrder,
            variant: "error",
            snackbarTxt: `${error.response.statusText}. ${error.response.data}`,
          });
        });

    });
  },

  showMessage: ( text, variant) => async (dispatch) => {
    dispatch({
      type: showSnakBarFromOrder,
      variant: variant,
      snackbarTxt: text,
    });
  },

  requestCatalogItem: code => async (dispatch, getState) => {

    const req = getState().orderRequest;

    if (code === req.code) {
      // Don't issue a duplicate request (we already have or are loading the requested data)
      return;
    }

    dispatch({ type: requestCatalogItemType, code: code });

    let token = null;
    const userManager = await OidcUserManager();
    userManager.getUser().then(function(user) {
      if (user != null) {
        token = `Bearer ${user.access_token}`;
      }
      const url = `/api/v1/CatalogItem/ByCode?code=${code}`;
      axios
        .get(url, {
          headers: { Authorization: token }
        })
        .then(function(response) {          
          dispatch({ type: reciveCatalogItemType, code, product: response.data });
        })
        .catch(error => {
          console.log(error);
          dispatch({ type: reciveCatalogItemType, code, product: null });
        });
    });
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case beginOrderChg:
     return {
       ...state,
       disabled: true,
       isLoading: true,
     };
    case startRequestType:
      return {
        ...state,
        isLoading: true,
      };
    case requestScriptType:      
      return {
        ...state,
        scripts: action.scripts,
        isLoading: false,
      };      
    case requestDeliveriesType  :
        return {
          ...state,
          deliveries: action.deliveries,
          isLoading: false,
        };  
    case requestChestType:
      return {
        ...state,
        chests: action.chests,
        isLoading: false,
      };
    case requestPromotionsType:
      return {
        ...state,
        promotions: action.promotions,
        isLoading: false,
      };
    case requestRecommendedType:
      return {
        ...state,
        recommended: (action.recommended ? action.recommended: []),
        isLoading: false,
      };
    case getAppSettings:
      return {
        ...state,
        appSettings: action.appSettings,
        user: action.user,
      };
    case requestNewOrderType:
      return {
        ...state,
        isLoading: true,        
        reqOrderId: action.reqOrderId,
      };
    case changeOrderField:
      // console.log(action.orderRequest.Order);
      return {
        ...state,
        Order: { ...action.orderRequest.Order },
      };
    case reciveNewOrderType:
      return {
        ...state,
        isLoading: false,
        disabled: false,
        reqOrderId: action.reqOrderId,
        qtyrows: action.qtyrows,
        Amount: action.Amount,
        Order: { ...action.order },
        user: action.user,
        accessBusinessAttr: action.accessBusinessAttr,
        accessUsePaymentOBAttr: action.accessUsePaymentOBAttr,
      };
    case saveOrder:
      return {
        ...state,
        isLoading: false,
        qtyrows: action.orderRequest.qtyrows,
        Amount: action.orderRequest.Amount,
        snackbarTxt: action.snackbarTxt,
        variant: "success",
        snackbarShow: true,
        disabled: false,
        Order: { ...action.orderRequest.Order },
      };
    case confirmOrderType:
      return {
        ...state,        
        Order: { ...action.order },        
        qtyrows: action.order.Rows.length,
        Amount: action.order.Amount,
        snackbarTxt: action.snackbarTxt,
        variant: "success",
        scripts: [],
        snackbarShow: action.snackbarShow,
        neworder: action.neworder,
        redirect: action.redirect,
      };
    case errorconfirmOrderType:
      return {
        ...state,
        snackbarTxt: action.snackbarTxt,
        variant: action.variant,
        snackbarShow: true,
        disabled: false,
        isLoading: false,
        Order: { ...action.order },
      };  
    case addRowToOrder:
      return {
        ...state,
        isLoading: false,
        Order: { ...action.order },
        qtyrows: action.order.Rows.length,
        Amount: action.order.Amount,
        snackbarTxt: action.snackbarTxt,
        variant: "success",
        snackbarShow: action.snackbarShow,
        neworder: action.neworder,
        disabled: false,
        accessBusinessAttr: action.accessBusinessAttr,
        accessUsePaymentOBAttr: action.accessUsePaymentOBAttr
      };
    case updRow:
      return {
        ...state,
        // isLoading: false,
        // snackbarTxt: "",// action.snackbarTxt,
        // variant: "success",
        // snackbarShow: false,
        // qtyrows: action.order.Rows.length,
        // Amount: action.order.Amount,
        Order: action.order,
        disabled: false,
      };
    case delRowFromOrder:
      return {
        ...state,
        isLoading: false,
        snackbarTxt: action.orderRequest.snackbarTxt,
        variant: "warning",
        snackbarShow: true,
        qtyrows: action.orderRequest.qtyrows,
        Amount: action.orderRequest.Amount,
        Order: { ...action.orderRequest.Order },
      };
    case hideSnakBarFromOrder:
      return {
        ...state,
        snackbarTxt: "",
        snackbarShow: false,
      };
    case showSnakBarFromOrder:
      return {
        ...state,
        snackbarTxt: action.snackbarTxt,
        variant: action.variant,
        snackbarShow: true,
        disabled: false,
        isLoading: false,
      };
    case getSuggestionsProducts:
      return {
        ...state,
        suggestions: action.suggestions,
        isLoading: false,
      };
    case clearSuggestionsProducts:
      return {
        ...state,
        suggestions: { CatalogItems: [] },
        isLoading: false,
      };
    case reciveCatalogItemType:
      return {
        ...state,
        code: action.code,
        product: action.product,
        isLoading: false
      };
    case requestCatalogItemType:
      return {
        ...state,
        code: action.code,
        isLoading: true
      };
    default:
      return state;
  }
};
