
import "./styles/index.css";
import React from "react";
import ReactDOM from "react-dom";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import configureStore from "./store/configureStore";
import { CookiesProvider } from "react-cookie";
import { AuthProvider } from "./providers/authProvider";

import App from "./App";
import { SnackbarProvider } from "notistack";
import { AppTheme } from "./AppTheme";
import "./i18n";
import * as serviceWorker from "./serviceWorker";
// import ReactGA from "react-ga";
import TagManager from "react-gtm-module"
//TODO: Enable google tag manager
TagManager.initialize({ gtmId: "GTM-P9WB4Q" })

const SW_INIT = "SW_INIT";
const SW_UPDATE = "SW_UPDATE";

window.devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

// Create browser history to use in the Redux store
const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const history = createBrowserHistory({ basename: baseUrl });
const initialState = window.initialReduxState;
const store = configureStore(history, initialState);
const rootElement = document.getElementById("root");

function Root() {
  const [theme, setTheme] = React.useState(AppTheme.LIGHT);  
  const toggleTheme = () => { setTheme(theme === AppTheme.LIGHT ? AppTheme.DARK: AppTheme.LIGHT) }  
  
  return (
    <AuthProvider>
      <CookiesProvider>
        <Provider store={store}>
            <ConnectedRouter history={history}>
              <SnackbarProvider maxSnack={10}>              
                  <App toggleTheme={toggleTheme} theme={theme} />
              </SnackbarProvider>
            </ConnectedRouter>
        </Provider>
      </CookiesProvider>
    </AuthProvider>
  );
}

ReactDOM.render(<Root />, rootElement );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.register({
  onSuccess: () => store.dispatch({ type: SW_INIT }),
  onUpdate: registration =>  store.dispatch({ type: SW_UPDATE, payload: registration }),
});

// serviceWorker.register();