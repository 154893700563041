import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withTranslation } from "react-i18next";
import { actionOrderCreators } from "../../store/Order";
import withWidth from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { OidcUserManager } from "../../helpers/OidcSettings";
import PromotionsThumb from "../Order/PromotionsThumb";
// import Icon from "@material-ui/core/Icon";
import gray from "@material-ui/core/colors/grey";
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/uk";

import axios from "axios";

const styles = theme => ({
    root: {
        flexGrow: 1,        
        padding: theme.spacing(1),
    },
    progress: {
        height: 2,
        position: "absolute",
        top: 0,
        left: 0,
        right:0,
      },  
      linearColorPrimary: {
        backgroundColor: "#abdcda",
      },
      linearBarColorPrimary: {
        backgroundColor: "#d20000",
      },
    
    paper: {
        padding: theme.spacing(2),
    },
    info: {
        padding: theme.spacing(1),
        height: "auto",
        [theme.breakpoints.down("sm")]: {
            height: 100,
          },
    },
    divider: {        
        marginBottom:theme.spacing(2),
    },
    customer: {
        textTransform: "capitalize",
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    media: {
        height: 183,
        // backgroundSize: "contain",
    },
    value: {
        fontWeight: "bold",
    },
    table: {
        minWidth: 500,
    },
    bold: {
        fontWeight: "500",
    },
    total: {
        fontWeight: "500",
        backgroundColor: gray[200],
    },
    step: {
        marginRight: theme.spacing(1),        
      },  
    fixcell: {
        backgroundColor: gray[100],
    },
});


class Promo extends Component {
    constructor(props) {
        super(props);
        this.state = {            
            isLoading: false,
        };
        
        this.props.requestPromotions();

        moment.locale("uk");
    }

    componentDidMount() {
        window.scrollTo({top: -200, behavior: "smooth"});
        // this.loadPromotions();        
    }

    // componentWillUnmount() {        
    // }
    
    handleClickPromotions = (item) => {
        // this.setState({selectedPromotions: item, openPromotions: true});
        console.log("Click by promotion: ", item);
        this.props.history.push(`/promotion/${item.Id}`)
      };

    loadPromotions = async () => {
        const self = this;
        const userManager = await OidcUserManager();

        userManager.getUser().then(user => {
            let token = null;

            if (user != null) {
                token =  `Bearer ${user.access_token}`;
            }
        
            token =  `Bearer ${user.access_token}`;                
            this.setState({isLoading: true});
            const URL = "api/v1/promotions/getactivepromotions";
            

            axios.get(URL,  
                { headers: { Authorization: token } }
            )                
            .then(function(response){
                const keys = [];
                                    
                self.setState({ data: response.data, isLoading: false, keys: keys });
                
                console.log("GET ACTIVE PROMOTIONS", response.data); 
            })
            .catch((error) => {        
                this.setState({isLoading: false});
                console.log(error);
            });
        });
    };

    render() {
        const { classes, promotions, t } = this.props;
        
        const { isLoading } = this.state;

        return (
            <div className={classes.root}>
                { isLoading && 
                    <LinearProgress className={classes.progress} 
                    classes={{
                        colorPrimary: classes.linearColorPrimary,
                        barColorPrimary: classes.linearBarColorPrimary,
                    }}
                />
                }

                <Grid container direction="row" spacing={2} justify="flex-start" alignItems="center" >
                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={1}>
                            <Typography variant="h6">
                                {t("promotions.title")}
                            </Typography>                            
                        </Paper>
                    </Grid>
                </Grid>
                
                {
                promotions && 
                <Grid container spacing={2} direction="row" > 
                { promotions.map((item) => (
                    <Grid item key={item.Id} xs={12} sm={12} md={6} >
                        <PromotionsThumb item={item} clickByPromotions={this.handleClickPromotions} ></PromotionsThumb>
                    </Grid>
                ))
                }  
                </Grid>
                }                                            
            </div>
        );
    }
}

// export default  withTranslation()(withWidth() (withStyles(styles)((Promo))));

export default withTranslation()(withWidth()(
    withRouter(
      withStyles(styles, { withTheme: true })(
        connect(
            state => state.orderRequest,
            dispatch => bindActionCreators(actionOrderCreators, dispatch)
        )(Promo)
      )
    )
  ));