import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { PrivateRoute } from "./privateRoute";

import Index from "./components/Index/Index";
import Counter from "./components/Counter";
import FetchData from "./components/FetchData";
import Auth from "./components/Auth";
import MenuBuilder from "./components/MenuBuilder/MenuBuilder";
import Carousels from "./components/Carousels";
import Files from "./components/Files/Files";
import Calendar from "./components/Calendar/Calendar";
import ListContent from "./components/ListContent/ListContent";
import ContentEditor from "./components/ContentEditor/ContentEditor";
// import SilentRenew from "./components/SilentRenew/SilentRenew";

// Register users
import Register from "./components/Register/Register";
import RegisterConsultant from "./components/Register/RegisterConsultant";
import RegisterConsultantMFA from "./components/Register/RegisterConsultantMFA";
import RegisterConsultantSuccess from "./components/Register/RegisterConsultantSuccess";
import RegisterClient from "./components/Register/RegisterClient";
import RegisterClientSuccess from "./components/Register/RegisterClientSuccess";


import Catalog from "./components/Catalog/Catalog";
import Search from "./components/Search/Search";
import Product from "./components/Product";

import Profile from "./components/Profile";
import Introduce from "./components/Profile/Introduce";

import Order from "./components/Order";
import Orders from "./components/Orders";
import Confirm from "./components/Order/Confirm";
import Thanks from "./components/Order/Tanks";

import AllOrders from "./components/AllOrders/AllOrders";
import Pay from "./components/Pay";
import Recharge from "./components/Recharge";

import We from "./components/We/We";
import Promotions from "./components/Promotions/Promotions";
import Goods from "./components/Goods/Goods";
import Page from "./components/Page/Page";
import NotFound from "./components/NotFound";
import Landing from "./components/Landing/Landing";
import LandingPage from "./components/LandingPage/LandingPage";
import LandingEditor from "./components/LandingEditor/LandingEditor";
import BearerToken from "./components/BearerToken/BearerToken";
import News from "./components/News/News";
import Programs from "./components/Programs/Programs";
import Promo from "./components/Promo";
import Promotion from "./components/Order/PromotionPage.jsx";

//
import { Callback } from "./components/auth/callback";
import { SilentRenew } from "./components/auth/silentRenew";
import { Signin } from "./components/auth/signin";
import { SigninPopup } from "./components/auth/signinPopup";


// Manage 
import Slides from "./components/Slides";
import Reviews from "./components/Reviews";
import Drafts from "./components/Drafts";
import {LogSms} from "./components/Logs/LogSms";

// Reports
import Dashboard from "./components/Reports/Dashboard";
import PersonalIndicators from "./components/Reports/PersonalIndicators";
import CurrentPerion from "./components/Reports/CurrentPeriod";
import Terminated from "./components/Reports/Terminated";
import NetworkStructure from "./components/Reports/NetworkStructure";
import Activity from "./components/Reports/Activity";
import OrderFin from "./components/Order/OrderFin";

const reload = () => window.location.reload();
export const Routes = (
  <Switch>
    <Route exact path='/'>
      <Index />
    </Route>
    
    <Route exact path='/content/:contenttype/:indexPage?/:query?/' component={ListContent} />
    <Route exact path='/contenteditor/:contenttype/:id?' component={ContentEditor} />
    
    <Route exact path='/signin' component={Signin} />
    <Route exact path='/signinpopup' component={SigninPopup} />        
    <Route exact={true} path="/callback" component={Callback} />
    <Route exact path='/silentrenew' component={SilentRenew} />

    <Route exact path='/auth' component={Auth} />        
    <Route exact path='/register/:ref?' component={Register} />
    <Route exact path='/register_consultant/:ref?' component={RegisterConsultant} />
    <Route exact path='/register_consultant_verify' component={RegisterConsultantMFA} />
    <Route exact path='/register_consultant_success' component={RegisterConsultantSuccess} />        
    <Route exact path='/register_client/:ref?' component={RegisterClient} />
    <Route exact path='/register_client_success' component={RegisterClientSuccess} />

    <Route exact path='/page/:key' component={Page} />
    <Route exact path='/catalog/:grp?/:pi?' component={Catalog} />
    <Route exact path='/search/:q/:page?/:size?' component={Search} />
    <Route exact path='/product/:code' component={Product} />
    <Route exact path='/order/:id?' component={Order} />
    <Route exact path='/order/thanks/:id?' component={Thanks} />
    {/* /orderfin/reg/pp; /orderfin/noreg/pp;     /orderfin/reg/cod; /orderfin/noreg/cod; */}
    <Route exact path='/orderfin/:auth/:paymethod/:id' component={OrderFin} />
    <Route exact path='/pay/:id' component={Pay} />
    <Route exact path='/recharge' component={Recharge} />

    {/* Manage */}
    <Route exact path='/slides' component={Slides} />    
    <Route exact path='/confirm/:id?' component={Confirm} />

    <Route exact path='/profile' component={Profile} />
    <Route exact path='/:ref/introduce' component={Introduce} />

    <PrivateRoute exact path='/orders/:t?/:q?/:pi?/:ps?' component={Orders} />
    {/* <Route exact path='/orders/:t?/:q?/:pi?/:ps?' component={Orders} /> */}
    
    <Route exact path='/allorders/:q?/:pi?/:ps?' component={AllOrders} />
    <Route exact path='/we/:code' component={We} />
    <Route exact path='/counter' component={Counter} />
    <Route exact path='/menubuilder/:file?' component={MenuBuilder} />
    <Route exact path='/files/:path?' component={Files} />
    <Route exact path='/calendar' component={Calendar} />
    <Route exact path='/carousels' component={Carousels} />
    <Route exact path='/reviews' component={Reviews} />
    <Route exact path='/drafts' component={Drafts} />
    <Route exact path='/logsms' component={LogSms} />
            
    <Route exact path='/fetchdata/:startDateIndex?' component={FetchData} />
    
    <Route exact path='/promotions' component={Promotions} />
    <Route exact path='/landings' component={Landing} />
    <Route exact path='/goods' component={Goods} />
    <Route exact path='/bearertoken' component={BearerToken} />
    <Route exact path='/landingpage/:id' component={LandingPage} />
    <Route exact path='/landingeditor/:id' component={LandingEditor} />
    <Route exact path='/news/:q?/:page?/:size?' component={News} />
    <Route exact path='/programs/:q?/:page?/:size?' component={Programs} />
    <Route exact path='/promo' component={Promo} />
    <Route exact path='/promotion/:id' component={Promotion} />
    
    {/* Reports */}
    <Route exact path='/dashboard' component={Dashboard} />
    <Route exact path='/personalindicators' component={PersonalIndicators} />
    <Route exact path='/currentperiod' component={CurrentPerion} />
    <Route exact path='/terminated' component={Terminated} />
    <Route exact path='/networkstructure' component={NetworkStructure} />
    <Route exact path='/activity' component={Activity} />
    
    <Route path="/apple-developer-merchantid-domain-association" onEnter={reload} />
    
    <Route  path="/notfound" component={NotFound} status={404} />
    <Route path="*" component={NotFound} status={404} />
    <Redirect to="/" />
  </Switch>
);

