import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";


import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import Slider from "./slider";

import Typography from "@material-ui/core/Typography";
import { OidcUserManager } from "../../helpers/OidcSettings";
import gray from "@material-ui/core/colors/grey";
// import moment from "moment";
import "moment/locale/ru";
import "moment/locale/uk";

import axios from "axios";


const styles = theme => ({
    root: {
        flexGrow: 1,        
        padding: theme.spacing(1),
    },
    rootTree: {
        minHeight: 264,
        height: "auto",
        flexGrow: 1,
        
    },
    progress: {
        height: 2,
        position: "absolute",
        top: 0,
        left: 0,
        right:0,
      },  
      linearColorPrimary: {
        backgroundColor: "#abdcda",
      },
      linearBarColorPrimary: {
        backgroundColor: "#d20000",
      },
    
    paper: {
        padding: theme.spacing(2),
    },
    info: {
        padding: theme.spacing(1),
        height: "auto",
        [theme.breakpoints.down("sm")]: {
            height: 100,
          },
    },
    divider: {        
        marginBottom:theme.spacing(2),
    },
    customer: {
        textTransform: "capitalize",
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    media: {
        height: 183,
        // backgroundSize: "contain",
    },
    value: {
        fontWeight: "bold",
    },
    table: {
        minWidth: 500,
    },
    bold: {
        fontWeight: "500",
    },
    total: {
        fontWeight: "500",
        backgroundColor: gray[200],
    },
    step: {
        marginRight: theme.spacing(1),        
      },  
    fixcell: {
        backgroundColor: gray[100],
    },
});



class Slides extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: { slides: [] },       
            // items: ["Item 1", "Item 2", "Item 3", "Item 4", "Item 5", "Item 6"],     
            isLoading: false,
        };    
    }

    componentDidMount() {
        this.loadData();        
    }

    componentWillUnmount() {        
    }

    loadData = async () => {
        const self = this;
        const userManager = await OidcUserManager();
        const file = "slider";        
        userManager.getUser().then(user => {
            let token = null;

            if (user != null) {
                token =  `Bearer ${user.access_token}`;                
                this.setState({isLoading: true});
                // const URL =  "/api/v1.0/slider/slider/false";
                const URL =  `/api/v1.0/slider/${file}/true`;
                axios.get(URL,  
                    { headers: { Authorization: token } }
                )                
                .then(function(response){
                    const data = self.state.data;

                    // response.data.Name = "Home page";

                    data.slides.push(response.data);
                                        
                    self.setState({ data: data, isLoading: false });
                    
                    // console.log("Slide `slider.json`:", data); 
                })
                .catch((error) => {        
                    this.setState({isLoading: false});
                    console.log(error);
                });                
            }
        });
    };

    render() {
        const { classes, t } = this.props; 
        const { data, isLoading } = this.state;

        return (
            <div className={classes.root}>
                { isLoading && 
                    <LinearProgress className={classes.progress} 
                    classes={{
                        colorPrimary: classes.linearColorPrimary,
                        barColorPrimary: classes.linearBarColorPrimary,
                    }}
                />
                }

                <Grid container direction="row" spacing={2} justify="flex-start" alignItems="center" >
                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={1}>
                            <Typography variant="h5" align="center">
                                {t("slides.title")}
                            </Typography>
                            
                        </Paper>
                    </Grid>
                </Grid><br />
                
                {                                 
                <Grid container direction="row"  >                                             
                {
                    data.slides.map((slider, idx) => (                    
                    <Grid item key={idx} xs={12}>
                        <Slider slider={slider}></Slider>                        
                    </Grid>                    
                    ))
                }                                        
                </Grid>                
            }
            </div>            
        );
    }
}


export default  withTranslation()(withWidth() (withStyles(styles)((Slides))));

