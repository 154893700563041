import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { OidcUserManager } from "../../helpers/OidcSettings";
import gray from "@material-ui/core/colors/grey";
import { PieChart, Pie, Cell, Sector } from "recharts";
import Divider from "@material-ui/core/Divider";
import axios from "axios";

const COLORSONE = ["#4caf50", "#673ab7"];
const COLORSTWO = ["#ff9800", "#2196f3", "#4caf50",  "#673ab7" ];
const COLORSTHREE = ["#673ab7", "#4caf50", "#ff9800", "#2196f3", "#4caf50"];

const styles = theme => ({
    root: {
        flexGrow: 1,        
        padding: theme.spacing(1),
    },
    progress: {
        height: 2,
        position: "absolute",
        top: 0,
        left: 0,
        right:0,
      },  
      linearColorPrimary: {
        backgroundColor: "#abdcda",
      },
      linearBarColorPrimary: {
        backgroundColor: "#d20000",
      },
    
    paper: {
        padding: theme.spacing(2),
        
    },
    info: {
        padding: theme.spacing(1),
        height: "auto",
        [theme.breakpoints.down("sm")]: {
            height: 100,
          },
    },
    divider: {        
        marginBottom:theme.spacing(2),
    },
    customer: {
        textTransform: "capitalize",
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    media: {
        height: 183,
        // backgroundSize: "contain",
    },
    value: {
        fontWeight: "bold",
    },
    table: {
        minWidth: 500,
    },
    bold: {
        fontWeight: "500",
    },
    total: {
        fontWeight: "500",
        backgroundColor: gray[200],
    },
    step: {
        marginRight: theme.spacing(1),        
      },  
    fixcell: {
        backgroundColor: gray[100],
    },
});

const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
      fill, payload, percent, value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";
  
    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text x={ex + (cos >= 0 ? 1 : -1) * 2} y={ey} textAnchor={textAnchor} fill="#333">{`${payload.name}: ${value}`}</text>
        <text x={ex + (cos >= 0 ? 1 : -1) * 2} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
          {`(${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };


class CurrentPeriod extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndexOne: 0,
            activeIndexTwo: 0,
            activeIndexThree: 0,
            data: null,
            keys: [],
            isLoading: false,

        };    
    }

    componentDidMount() {
        this.loadDashBoard();        
    }

    componentWillUnmount() {        
    }

    loadDashBoard = async () => {
        const self = this;
        const userManager = await OidcUserManager();

        userManager.getUser().then(user => {
            let token = null;

            if (user != null) {
                token =  `Bearer ${user.access_token}`;                
                this.setState({isLoading: true});
                const URL =  "/api/v1.0/reports/currentperiod";
                axios.get(URL,  
                    { headers: { Authorization: token } }
                )                
                .then(function(response){
                    const keys = [];
                    const data = {...response.data, 
                        chartOne: [
                            { name: "ОО", value: response.data.ConsultantCurrentData.ОО},
                            { name: "ГО", value: response.data.ConsultantCurrentData.ГО}
                        ],
                        chartTwo: [],
                        TotalchartTwo: 0,
                        chartThree: [],
                        TotalchartThree: 0,
                    };
                    
                    let activeIndexTwo = 0;
                    let activeIndexThree = 0;
                    const {t} = self.props;

                    Object.keys(data.ConsultantGiftCurrentData).forEach((key) => {
                        if(key !== "$type"){                            
                            if( key.endsWith("BB")) {
                                data.TotalchartTwo += parseFloat(data.ConsultantGiftCurrentData[key]);
                                data.chartTwo.push( { name: t(`reports.currentperiod.${key}`), value: data.ConsultantGiftCurrentData[key] });
                            } else if( key.endsWith("UAH")) {
                                data.TotalchartThree += parseFloat(data.ConsultantGiftCurrentData[key]);
                                data.chartThree.push( { name: t(`reports.currentperiod.${key}`), value: data.ConsultantGiftCurrentData[key] });
                            } 
                        }
                      });
                    
                      for (let i = 0; i < data.chartTwo.length; i++) {
                          const element = data.chartTwo[i];
                          if(element.value !== 0){
                            activeIndexTwo = i;
                            break;
                          }                                                    
                      }
                    
                      for (let i = 0; i < data.chartThree.length; i++) {
                        const element = data.chartThree[i];
                        if(element.value !== 0){
                          activeIndexThree = i;
                          break;
                        }                                                    
                    }

                    self.setState({activeIndexTwo: activeIndexTwo, activeIndexThree: activeIndexThree, data: data, isLoading: false, keys: keys });
                    
                    console.log("CurrentPeriod data", data); 
                })
                .catch((error) => {        
                    this.setState({isLoading: false});
                    console.log(error);
                });                
            }
        });
    };

    onPieEnterOne = (data, index) => {
        this.setState({
          activeIndexOne: index,
        });
    };
    
    onPieEnterTwo = (data, index) => {
        this.setState({
          activeIndexTwo: index,
        });
    };

    onPieEnterThree = (data, index) => {
        this.setState({
          activeIndexThree: index,
        });
    };

    render() {
        const { classes, width, t } = this.props; 
        const { data, isLoading } = this.state;
        
        let w = 450;
        let h = 300;

        switch (width) {
            case "xs":
                w=300;
                h=300;
                break;
            // case "sm":
            //     w=400;
            //     h=400;
            //     break;
            // case "md":
            //     w=450;
            //     h=450;
            //     break;                
            // case "lg":
            //     w=450;
            //     h=450;
            //     break;            
            // case "xl":
            //     w=450;
            //     h=450;
            //     break;            
            default:
                w=450;
                h=300;
                break;
        }

        return (
            <div className={classes.root}>
                { isLoading && 
                    <LinearProgress className={classes.progress} 
                    classes={{
                        colorPrimary: classes.linearColorPrimary,
                        barColorPrimary: classes.linearBarColorPrimary,
                    }}
                />
                }

                <Grid container direction="row" spacing={2} justify="flex-start" alignItems="center" >
                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={1}>
                            <Typography variant="h6" align="center">
                                {t("reports.currentperiod.title")}
                            </Typography>
                            { data && 
                            <Typography align="center">
                                { data ? `${data.Adviser.RegistrationCode} ${data.Adviser.Name}` : null }
                            </Typography>
                            }
                        </Paper>
                    </Grid>
                </Grid>
                { 
                
                data && (
                <div>                    
                        <Grid container spacing={2} direction="row" justify="center" alignItems="stretch">
                            <Grid item xs={12} sm={3}>
                                <Paper className={classes.paper} elevation={1} style={{height: "100%"}}>
                                    <Typography align="center" >
                                        {"ОО поточної кампанії, ББ"}: {data.ConsultantCurrentData.ОО}
                                    </Typography>
                                    <Typography align="center">
                                        {"ГО поточної кампанії, ББ"}: {data.ConsultantCurrentData.ГО}
                                    </Typography>
                                    <PieChart width={w} height={h}>
                                        <Pie
                                        activeIndex={this.state.activeIndexOne}
                                        activeShape={renderActiveShape}
                                        data={data.chartOne}
                                        cx={140}
                                        cy={150}
                                        innerRadius={70}
                                        outerRadius={100}
                                        fill="#4caf50"
                                        dataKey="value"
                                        onMouseEnter={this.onPieEnterOne}
                                        >
                                        {
                                            data.chartOne.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORSONE[index % COLORSONE.length]} />)
                                        }
                                        </Pie>
                                    </PieChart>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={9}>
                                <Grid container spacing={2} direction="row" justify="center">
                                    <Grid item xs={12}>
                                        <Paper className={classes.paper} elevation={1}>
                                            <Typography variant="h6" gutterBottom>
                                                {t("reports.currentperiod.MyStatuses")}
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} sm={4} >
                                        <Paper className={classes.paper} elevation={1}>
                                            <Typography gutterBottom align="center">
                                                {t("reports.currentperiod.StructuralStatus")} 
                                            </Typography>
                                            <Typography variant="h6" align="center">
                                                {data.ConsultantCurrentData.СтруктурныйСтатус}&nbsp;
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Paper className={classes.paper} elevation={1}>
                                            <Typography gutterBottom align="center">
                                                {t("reports.currentperiod.DirectorStatus")}
                                            </Typography>
                                            <Typography variant="h6" align="center">
                                                {data.ConsultantCurrentData.ДиректорскийСтатус}&nbsp;
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} sm={4} >
                                        <Paper className={classes.paper} elevation={1}>
                                            <Typography gutterBottom align="center">
                                                {t("reports.currentperiod.GlobalStatus")}
                                            </Typography>
                                            <Typography variant="h6" align="center">
                                                {data.ConsultantCurrentData.ГлобалСтатус}&nbsp;
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                </Grid>                                                                                                
                                <Grid container spacing={2} direction="row" alignItems="stretch">
                                    <Grid item xs={12}>
                                        <Paper className={classes.paper} elevation={1} >
                                            <Typography variant="h6" gutterBottom>
                                                {t("reports.currentperiod.RemunerationCurrentPeriod")}                                                    
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                    {/* BB */}
                                    <Grid item xs={12} sm={6}>
                                        <Paper className={classes.paper} elevation={1} style={{height: "100%"}}>
                                            <div style={{marginBottom: "8px"}}>
                                            <PieChart width={w} height={h}>
                                                <Pie
                                                activeIndex={this.state.activeIndexTwo}
                                                activeShape={renderActiveShape}
                                                data={data.chartTwo}
                                                cx={ width === "xs" ? 150: 200}
                                                cy={150}
                                                innerRadius={70}
                                                outerRadius={100}
                                                fill="#ff9800"
                                                dataKey="value"
                                                onMouseEnter={this.onPieEnterTwo}
                                                >
                                                {
                                                    data.chartThree.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORSTWO[index % COLORSTWO.length]} />)
                                                }
                                                </Pie>
                                            </PieChart>
                                            <Divider />
                                            </div>
                                            {
                                                data.chartTwo.map((row, i) => (
                                                    <Grid key={i} container spacing={2} direction="row">
                                                        <Grid item xs={8} sm={4} >{row.name}</Grid>
                                                        <Grid item xs={4} sm={8}>{row.value}</Grid>    
                                                    </Grid>
                                                ))
                                            }                                            
                                            <Grid container spacing={2} direction="row">
                                                <Grid item xs={8} sm={4} ><b>{t("reports.currentperiod.TotalchartTwo")}</b></Grid>
                                                <Grid item xs={4} sm={8} ><b>{data.TotalchartTwo}</b></Grid>    
                                            </Grid>

                                            {/* { data.ConsultantGiftCurrentData && (
                                                Object.keys(data.ConsultantGiftCurrentData).map((key, i) => (                                    
                                                    key !== "$type" ?  <Typography key={i}>
                                                        {key}: {data.ConsultantGiftCurrentData[key]}
                                                    </Typography> : null
                                                ))
                                            )} */}
                                        </Paper>
                                    </Grid>
                                    {/* UAH */}
                                    <Grid item xs={12} sm={6}>
                                        <Paper className={classes.paper} elevation={1} style={{height: "100%"}}>
                                            <div style={{marginBottom: "8px"}}>
                                            <PieChart width={w} height={h}>
                                                <Pie
                                                activeIndex={this.state.activeIndexThree}
                                                activeShape={renderActiveShape}
                                                data={data.chartThree}
                                                cx={ width === "xs" ? 150: 200}
                                                cy={150}
                                                innerRadius={70}
                                                outerRadius={100}
                                                fill="#03a9f4"
                                                dataKey="value"
                                                onMouseEnter={this.onPieEnterThree}
                                                >
                                                {
                                                    data.chartThree.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORSTHREE[index % COLORSTHREE.length]} />)
                                                }
                                                </Pie>
                                            </PieChart>
                                            <Divider />
                                            </div>
                                            {
                                                data.chartThree.map((row, i) => (
                                                    <Grid key={i} container spacing={2} direction="row">
                                                        <Grid item xs={8} sm={4} >{row.name}</Grid>
                                                        <Grid item xs={4} sm={8} >{row.value}</Grid>    
                                                    </Grid>
                                                ))                                                                                  
                                            }                                            
                                            <Grid container spacing={2} direction="row">
                                                <Grid item xs={8} sm={4} ><b>{t("reports.currentperiod.TotalchartThree")}</b></Grid>
                                                <Grid item xs={4} sm={8} ><b>{data.TotalchartThree}</b></Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>                             
                            </Grid>
                        </Grid>                                    
                </div>
                )
            }
            </div>            
        );
    }
}


export default  withTranslation()(withWidth() (withStyles(styles)((CurrentPeriod))));

