const requestListContentsType = "REQUEST_LIST_CONTENT";
const receiveListContentsType = "RECEIVE_LIST_CONTENT";
const initialState = { Data:[] ,IndexPage: 0, PageSize: 10, TotalCount: 0, isLoading: false };

export const actionCreators = {
    
    requestListContents: (IndexPage, PageSize, query, contenttype) => async (dispatch, getState) => {  
    
      // console.log(IndexPage, PageSize, query, contenttype);
      const state = getState();

      if (IndexPage === state.IndexPage &&
          PageSize === state.PageSize  && 
          contenttype === state.contenttype && 
          query === state.query
          ) {
        // Don't issue a duplicate request (we already have or are loading the requested data)      
        return;
      }

      dispatch({ type: requestListContentsType, IndexPage, PageSize, query, contenttype });
      
      const url = `/api/v1.0/content?i=${IndexPage}&s=${PageSize}` + (contenttype ? `&tc=${contenttype}` : "") + (query ? `&q=${query}` : "");
      const response = await fetch(url); 
      const contents = await response.json();
      
      // console.log("Contents:", contents);
    
      dispatch({ type: receiveListContentsType, IndexPage, PageSize, contents, query, contenttype });
  }
};

export const reducer = (state, action) => {
  state = state || initialState;  

  switch (action.type) {
    case requestListContentsType:
      return {
        ...state,
        IndexPage: action.IndexPage,
        PageSize: action.PageSize,
        query: action.query,
        contenttype: action.contenttype,  
        isLoading: true
      };
    case receiveListContentsType:
      return {
        ...state,
        IndexPage: action.IndexPage,
        PageSize: action.PageSize,
        query: action.query,
        ...action.contents,
        contenttype: action.contenttype,
        isLoading: false
      };
  
      default:
        return state;
  }
};
