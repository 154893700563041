const EntityState = Object.freeze({
  Detached: 0,
  Unchanged: 1,
  Deleted: 2,
  Modified: 3,
  Added: 4,
});

const OrderRowType = Object.freeze({
  Default: 0,
  Promotion: 1,
  Chest: 2,
  Script: 3,
});

const PromotionType = Object.freeze({ Promotion: 0, Chest: 1, Script: 2 });

class Order {
  constructor(options) {
    this.$type =
      "Start.Doing.Module.Core.Models.Order, Start.Doing.Module.Core";
    this.ExtOrderId = options.ExtOrderId ? options.ExtOrderId : "00000000-0000-0000-0000-000000000000";
    this.DocNumber = options.DocNumber;
    this.AppSsid = options.AppSsid;
    this.Email = options.Email;
    this.ExtUserId = options.ExtUserId;
    this.Amount = options.Amount ? options.Amount : 0;    
    this.AmountCatalogPrice = options.AmountCatalogPrice ? options.AmountCatalogPrice : 0 ; 
    this.ApplayDiscountAmount = options.ApplayDiscountAmount;
    this.DiscountAmount = options.DiscountAmount;
    this.AmountOrder = options.AmountOrder;
    this.CreatedOn = options.CreatedOn;
    this.UpdatedOn = options.UpdatedOn;
    this.Id = options.Id;
    this.Rows = options.Rows ? options.Rows : [];
    this.UseRepayment = options.UseRepayment;
    // this.UseFastUp = options.UseFastUp;
    this.ShowedRecomProducts = options.ShowedRecomProducts;
    this.Comment = options.Comment;
    this.UseBusinessPack = options.UseBusinessPack;
    this.FastUpBonus = options.FastUpBonus ? options.FastUpBonus : 0;
    this.FastUpBonusPv = options.FastUpBonusPv ? options.FastUpBonusPv : 0;
    this.PercentFastUpBonus = options.PercentFastUpBonus ? options.PercentFastUpBonus : 0;
    this.UsePaymentOB = options.UsePaymentOB;
    this.AmountOB = options.AmountOB;
    this.DeliveryProviderId = options.DeliveryProviderId;
    this.DeliveryMethodId = options.DeliveryMethodId ? options.DeliveryMethodId : 0;
    this.PaymentTypeId = options.PaymentTypeId;
    this.AmountCOD = options.AmountCOD ? options.AmountCOD : 0;
    this.AmountDelivery = options.AmountDelivery ? options.AmountDelivery : 0;
    this.AmountService = options.AmountService ? options.AmountService : 0;
    this.RecipientFirstName = options.RecipientFirstName ? options.RecipientFirstName: "";
    this.RecipientLastName = options.RecipientLastName ? options.RecipientLastName : ""; 
    this.RecipientMiddleName = options.RecipientMiddleName ? options.RecipientMiddleName : "";        
    this.RecipientPhone = options.RecipientPhone ? options.RecipientPhone  : ""; 
    this.RecipientMail = options.RecipientMail ? options.RecipientMail : "";
    
    this.RecipientPostCode = options.RecipientPostCode ? options.RecipientPostCode : "";  
    this.RecipientRegion = options.RecipientRegion ? options.RecipientRegion : "";
    this.RecipientRaion = options.RecipientRegion ? options.RecipientRaion : "";
    this.RecipientVillageCityTown = options.RecipientVillageCityTown ? options.RecipientVillageCityTown : "";
    this.RecipientStreetname = options.RecipientStreetname ? options.RecipientStreetname : "";
    this.RecipientStreetnameNumber = options.RecipientStreetnameNumber ? options.RecipientStreetnameNumber : "";
    this.RecipientApartment = options.RecipientApartment ? options.RecipientApartment : "";
    this.PostOfficeId = options.PostOfficeId ? options.PostOfficeId : "";
    this.PostOffice = options.PostOffice ? options.PostOffice : null;
    this.DeliveryMethodOrder = options.DeliveryMethodOrder ? options.DeliveryMethodOrder : 0;
    this.RefId  = options.RefId ? options.RefId : "";

  }
}

class OrderRow {
  constructor(options) {
    this.$type =
      "Start.Doing.Module.Core.Models.OrderRow, Start.Doing.Module.Core";
    this.Id = options.Id;
    this.Price = options.Price;
    this.Rate = options.Rate;
    this.CatalogPrice = options.CatalogPrice;
    this.CatalogPricePv = options.CatalogPricePv;    
    this.Amount = options.Amount ? options.Amount : 0.0 ; // parseFloat(options.Price * options.Qty).toFixed(2);
    this.AmountPv = options.AmountPv ? options.AmountPv : 0.0;// parseFloat(options.Price * options.Qty / options.Rate).toFixed(2);
    this.AmountCdc = options.AmountCdc;
    this.AmountCdcPv = options.AmountCdcPv;
    this.PriceCdc = options.PriceCdc;
    this.PriceCdcPv = options.PriceCdcPv;
    this.DiscountCdc = options.DiscountCdc ? options.DiscountCdc : 0;
    this.DiscountCdcPv = options.DiscountCdcPv ? options.DiscountCdcPv : 0;
    this.FastUpBonus = options.FastUpBonus;
    this.PersonalVolume = options.PersonalVolume;
    this.PersonalVolumePv = options.PersonalVolumePv;
    this.AnonymousDiscount = options.AnonymousDiscount;
    this.DeferredDiscount = options.DeferredDiscount;
    this.PromoDiscount = options.PromoDiscount;
    this.Qty = options.Qty;
    this.ProductId = options.ProductId;
    this.Code = options.Code;
    this.Name = options.Name;
    this.CatalogId = options.CatalogId;
    this.LocalName = options.LocalName;
    this.Image = options.Image;
    this.OrderId = options.OrderId;
    this.CreatedOn = options.CreatedOn;
    this.UpdatedOn = options.UpdatedOn;    
    this.State = options.State;
    this.OutsideCatalog = options.OutsideCatalog;
    this.UseLO = options.UseLO;
    this.OrderRowDetails = options.OrderRowDetails ? options.OrderRowDetails: [] ;
    this.AmountCOD = options.AmountCOD ? options.AmountCOD : 0;
    this.AmountDelivery = options.AmountDelivery ? options.AmountDelivery : 0;
    this.AmountService  = options.AmountService ? options.AmountService : 0;
  }
}

class OrderRowDetail {
  constructor(options) {
    this.$type =
      "Start.Doing.Module.Core.Models.OrderRowDetail, Start.Doing.Module.Core";
    this.Id = options.Id;
    this.OrderRowType = options.OrderRowType;
    this.Qty = options.Qty;
    this.Price = options.Price;
    this.CatalogPrice = options.CatalogPrice;
    this.CatalogPricePv = options.CatalogPricePv;
    this.Rate = options.Rate;
    this.Amount = options.Amount;
    this.AmountPv = options.AmountPv;
    this.AmountCdc = options.AmountCdc;
    this.AmountCdcPv = options.AmountCdcPv;
    this.OrderRowPromotions = options.OrderRowPromotions ? options.OrderRowPromotions : [];
    this.State = options.State;
    this.OutsideCatalog = options.OutsideCatalog;
    this.UseLO = options.UseLO;
   
    if(options.OutsideCatalog || options.OrderRowType === OrderRowType.Chest || options.OrderRowType === OrderRowType.Script ){
      this.PricePromotionCacl = 0;
      this.PricePromotionCaclPv = 0;
    } else {
      this.PricePromotionCacl = options.CatalogPrice;
      this.PricePromotionCaclPv = options.CatalogPricePv;
    }     
  }
}

class OrderRowPromotion {
  constructor(options) {
    this.$type =
      "Start.Doing.Module.Core.Models.OrderRowDetail, Start.Doing.Module.Core";
    this.Id = options.Id;
    this.Idrref = options.Idrref;
    this.Qty = options.Qty;
    this.Price = options.Price;
    this.Rate = options.Rate;
    this.CatalogPrice = options.CatalogPrice;
    this.CatalogPricePv = options.CatalogPricePv;
    this.Rate = options.Rate;
    this.AmountCdc = options.AmountCdc;
    this.AmountCdcPv = options.AmountCdcPv;
    this.PromotionType = options.PromotionType;
    this.PromotionsId = options.PromotionsId;
    this.PromotionsName = options.PromotionsName;
    this.PromotionsDescr = options.PromotionsDescr;
    this.OrderRowDetailId = options.OrderRowDetailId;
    this.OrderRowPromotions = options.OrderRowPromotions ? options.OrderRowPromotions : [];
    this.State = options.State;
  }
}

export { EntityState, Order, OrderRow, OrderRowDetail, OrderRowPromotion, OrderRowType, PromotionType };