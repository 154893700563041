import { AppTheme } from "../../../AppTheme";
// import blue from "@material-ui/core/colors/blue";
import purple from "@material-ui/core/colors/purple";
import lightBlue from "@material-ui/core/colors/lightBlue";

const appThemeOptions = {
  [AppTheme.LIGHT]: {
    palette: {
      type: "light",
      primary: {
        supperdark: "#135c75",
        dark: "#166e8d",
        main: "#209eca",
        light: "#4cb1d4",
      },
      secondary: {
        dark: "#b1cc3c",
        main: "#ff3d00",
        light: "#b4c372",
      },
      success: {
        dark: "#aec73f",
        main: "#bbd05e",
        light: "#81c784",
      },
      background: {
        default: "#fff"
      },
      appbar: {
        // background: blue[700],
      },
      appbarsecond: {
        background: "linear-gradient(180deg, rgba(245,251,255,1) 0%, rgba(254,254,254,1) 100%)",        
      },      
      footer: {
        background: "#f5f5f5"
      },            
    },
    overrides: {
      MUIDataTable: {
        paper: {
          boxShadow: "none",
        }
      },
      
      MuiDrawer: {
        paper: {
          // background: "#064c9c",
          background: "linear-gradient(338deg, rgba(24,166,242,1) 4%, rgba(29,92,117,1) 100%)",
          // this is where magic happens
          "& *": { color: "rgba(255, 255, 255, 0.8)" }
        }
      },
      MuiPaper: {
        root: {
          backgroundColor: "#fff"
        }
      },
    },
    typography: {
      useNextVariants: true
    }
    // "@global": {
    //     body: {
    //       fontFamily: "Roboto,Helvetica,Arial,sans-serif",
    //     },
    //   },

    // shadows: ["none"],
  },
  [AppTheme.DARK]: {
    palette: {
      type: "dark",
      primary: {
        supperdark: "#090c10",
        dark: lightBlue[700],
        main: lightBlue[500],
        light: lightBlue[200],
        contrastText: "#dedede"
      },
      secondary: {
        dark: purple[700],
        main: purple[500],
        light: purple[200],
      },
      appbar: {
        background: "#212121"
      },
      appbarsecond: {
        background: "#383838"
      },
      footer: {
        background: "#232323"
      }
    },
    overrides: {
    //   MuiPaper: {
    //     root: {
    //       padding: "15px"
    //     }
    //   },
    MUIDataTable: {
      paper: {
        boxShadow: "none",
      }
    },
    
    MuiDrawer: {
        paper: {
          background: "#18202c",
          // this is where magic happens
          "& *": { color: "rgba(255, 255, 255, 0.7)" }
        }
      }
    },
    typography: {
      useNextVariants: true
    }
    // "@global": {
    //     body: {
    //       fontFamily: "Roboto,Helvetica,Arial,sans-serif",
    //     },
    //   },
  }
};

export default appThemeOptions;
