import { OidcUserManager } from "../helpers/OidcSettings";
import axios from "axios";
// import Promotion  from "../data/Promotion";
// import moment from "moment";

const showDialogType = "SHOW_DIALOG";
const newPromotionType = "ADD_NEW_PROMOTION";
const editPromotionType = "EDIT_PROMOTION";
const delPromotionType = "DEL_PROMOTION";
const newConditionType = "NEW_CONDITION";


const choosenDefPromotionType = "CHOOSEN_DEFALULT_PROMOTION";
const savePromotionType = "SAVE_PROMOTION";
// const requestProductItemsType = "REQUEST_PRODUCT_ITEMS";
const requestCatalogItemsType = "REQUEST_CATALOG_ITEMS";

const loadingType = "LOADING";
const reciveAllCatalogItemsType = "RECIVE_ALLCATALOG_ITEMS";

const requestPromotionsByCatalogType = "REQUEST_PROMOTIONS_BY_CATALOG";
const recivePromotionsByCatalogType = "RECIVE_PROMOTIONS_BY_CATALOG";

// const recivePromotionsItemsType = "RECIVE_PROMOTIONS_ITEMS";
// const reciveChestsItemsType = "RECIVE_CHESTs_ITEMS";
// const reciveScriptsItemsType = "RECIVE_SCRIPTS_ITEMS";
// const reciveAutoInvocesItemsType = "RECIVE_AUTOINVOICES_ITEMS";

const updPnomorionFieldType = "UPD_PROMOTION_FIELD";

const promotionsTypesType = "RECIVE_PROMOTIONS_TYPES";

const initialState = {    
  catalogs: null,
  
  promotions: [],
  Promotions: {},
  Chests: {},
  Scripts: {},
  Autoinvoices: {},

  promotionstype: null,
  selectedPromotion: null,
  idxPromotionType: -1,
  promotionType: 0,
  catalogid : null,
  catalogitems: [],
  isLoading: false,
  showDlg: false,
  locale: "en",
  Page: 0,
  RowsPerPage: 0,
  count: 0,
  rowsPerPageOptions: [10, 25, 50, 100],
};

function getLocale(langs) {  
  for (let i = 0; i < langs.length; i++) {
    const element = langs[i];
    if (element.Active) {
      return  element.TwoLetterISOLanguageName;
    }
  }
}

function getDefaultPromotionType(promotions){ 
  return {index: 0, promotiontype: promotions[0]}
}

export const actionPromotionItemCreators = {

    showDialog: (show) => async (dispatch ) => {
      dispatch({ type: showDialogType, showDlg: show });
    },
    newPromotion: () => async (dispatch, getState) => {
      const req =  getState().promotionItemsRequest;      
      const promotion = {...req.promotionstype[req.idxPromotionType].Promotion};        
      // promotion.TypeName = req.promotionstype[req.idxPromotionType].Name;
      // promotion.TypeDescription = req.promotionstype[req.idxPromotionType].Description;
      dispatch({ type: newPromotionType, selectedPromotion: promotion, showDlg: true });
    },

    editPromotion: (id) => async (dispatch) => {      
      dispatch({ type: loadingType });
      
      let token = null;
      const userManager = await OidcUserManager();
      userManager.getUser().then(function (user) {
        if(user != null){      
          token =  `Bearer ${user.access_token}`;        
        }         
        const url = `/api/v1.0/Promotions/GetById/${id}/false`; 
        axios.get(url,         
          { 
            headers: { Authorization: token }        
          }
        )
        .then(function(response){
          dispatch({ type: editPromotionType, selectedPromotion: response.data, showDlg: true });
        })
        .catch((error) => {          
          console.log(error);           
        });
      });
    },

    delPromotion: (id) => async (dispatch) => {
      
      let token = null;
      const userManager = await OidcUserManager();
    
      userManager.getUser().then(function (user) {
        if(user != null){      
          token =  `Bearer ${user.access_token}`;        
        } 
        
        console.log(token);
        
        const url = `/api/v1.0/promotions/delete/${id}`;
        axios.delete(
          url, { 
            headers: { Authorization: token },            
        })
        .then(function(response){
          console.log("Deleted response: ", response);
          axios.get(
            "/api/v1.0/promotions/getpromotions", null, { 
              headers: { Authorization: token },            
            })
            .then(function(response){
              dispatch({ type: delPromotionType, promotions: response.data.Promotions, count: response.data.Count });    
            })   
            .catch((error) => {          
              console.log(error);            
            });
        })
        .catch((error) => {          
          console.log(error);            
        });
      });
    },

    savePromotion: (promotion ) => async (dispatch) => {
      
      dispatch({ type: requestCatalogItemsType });
            
      console.log("Before save promotion: ", promotion);
      let token = null;
      const userManager = await OidcUserManager();
    
      userManager.getUser().then(function (user) {
        if(user != null){      
          token =  `Bearer ${user.access_token}`;        
        }          
        
        const url = "/api/v1.0/promotions";         
        axios.post(url, promotion, { headers: { Authorization: token } })
        // axios.post(
        //   url, null,
        //   { 
        //     headers: { Authorization: token },
        //     params: { json: JSON.stringify(promotion)}
        // }
        // )
        .then(function(response){
          console.log("Saved promotion: ", response.data);
          
          dispatch({ type: savePromotionType, selectedPromotion: response.data });            
        })
        .catch((error) => {          
          console.log(error);            
        });
      });
    },

    newCondition: () => async (dispatch) => {
      let condition = {
        Name: "",
        Description: "",
        Products: {}
      };
      dispatch({ type: newConditionType, condition: condition });
    },

    choosePromotion : (index) => async (dispatch, getState) => {      
      const promotions =  getState().promotionItemsRequest.promotionstype;            
      const promotion  = promotions[index].Promotion;
      dispatch({ type: choosenDefPromotionType, selectedPromotion: promotion, idxPromotionType: index });
    },

    getAllCatalogItems: (id) => async (dispatch, getState) =>{
      if (getState().promotionItemsRequest.catalogid === id) {       
        return;
      }
      dispatch({ type: loadingType });
      
      let token = null;
      const userManager = await OidcUserManager();
    
      userManager.getUser().then(function (user) {
        if(user != null){      
          token =  `Bearer ${user.access_token}`;        
        }         
        const url = `/api/v1.0/CatalogItem/GetAllCatalogItemsByCatalog?id=${id}`; 
        axios.get(url,         
          { 
            headers: { Authorization: token }        
          }
        )
        .then(function(response){          
          dispatch({ type: reciveAllCatalogItemsType, catalogid: id, catalogitems: response.data  });    
        })
        .catch((error) => {          
          console.log(error);           
        });
      });

    },

    // Remove this method
    needDelpromotionsByCatalogRequest: (id) => async (dispatch, getState) => {
      if (getState().promotionItemsRequest.catalogid === id) {       
        return;
      }

      dispatch({ type: requestPromotionsByCatalogType });

      const locale = getLocale(getState().appLayout.Langs);
      
      let token = null;
      const userManager = await OidcUserManager();
    
      userManager.getUser().then(function (user) {
        if(user != null){      
          token =  `Bearer ${user.access_token}`;        
        }         
        const url = `/api/v1.0/catalog/getpromotionsbycatalog?id=${id}`; 
        axios.get(url,         
          { 
            headers: { Authorization: token }        
          }
        )
        .then(function(response){          
          dispatch({ type: recivePromotionsByCatalogType, catalogid: id, promotions: response.data, locale: locale  });    
        })
        .catch((error) => {          
          console.log(error);           
        });
      });
    }, 

    promotionsType: () => async (dispatch) => { 
      let token = null;
      const userManager = await OidcUserManager();
    
      userManager.getUser().then(function (user) {
         if(user != null){      
            token =  `Bearer ${user.access_token}`;
          }                  
       
        axios.get("/api/v1.0/promotions/getpromotionstype", { headers:{Authorization: token }})
        .then((response) => {          
          const selected = getDefaultPromotionType(response.data);
          dispatch({ type: promotionsTypesType, promotionstype: response.data, idxPromotionType: selected.index});
        })
        .catch((error) => {
          console.log(error);
        });
        });  
      }, 

    promotionsItemsRequest: (req) => async (dispatch, getState) => {
      const request = getState().promotionItemsRequest;
      const locale = getLocale(getState().appLayout.Langs);            
      if ( request.locale === locale && request.Page === req.Page && request.RowsPerPage === req.RowsPerPage && !req.Refresh && request.promotionType === req.PromotionType ) {
        // Don't issue a duplicate request (we already have or are loading the requested data)
        return;
      }
                  
      // dispatch({ type: requestCatalogItemsType, locale: locale, Page: req.Page, RowsPerPage: req.RowsPerPage });

      // let token = null;
      // const userManager = await OidcUserManager();
    
      // userManager.getUser().then(function (user) {
      //   if(user != null){      
      //     token =  `Bearer ${user.access_token}`;        
      //   }                  
                

      //   const _empty = "";        
      //   const urlProms = `/api/v1.0/promotions/getpromotions?p=${req.Page}&rpp=${req.RowsPerPage}&ord=${_empty}&q=${_empty}&pt=${req.PromotionType}`;
        
      //   axios.all([          
      //     axios.get("/api/v1.0/promotions/getpromotionstype", { headers:{Authorization: token }}),
      //     axios.get(urlProms, { headers: { Authorization: token } }),
      //   ])
      //   .then(axios.spread((promotionstype, promotions) => {                    
      //     const selected = getDefaultPromotionType(promotionstype.data);
          
      //     console.log("Promotions Data", promotions.data);
      //     switch (req.PromotionType) {
      //       case 0:
      //         dispatch({ type: recivePromotionsItemsType, promotionstype: promotionstype.data, 
      //           Promotions: promotions.data, count: promotions.data.count,
      //           idxPromotionType: selected.index, locale: locale, promotionType: req.PromotionType });
      //         break;
      //       case 1:
      //         dispatch({ type: reciveChestsItemsType, promotionstype: promotionstype.data, 
      //           Chests: promotions.data, count: promotions.data.count,
      //           idxPromotionType: selected.index, locale: locale, promotionType: req.PromotionType });
      //         break;
      //       case 2:
      //         dispatch({ type: reciveScriptsItemsType, promotionstype: promotionstype.data, 
      //           Scripts: promotions.data, count: promotions.data.count,
      //           idxPromotionType: selected.index, locale: locale, promotionType: req.PromotionType });
      //         break;
      //       case 4:
      //         dispatch({ type: reciveAutoInvocesItemsType, promotionstype: promotionstype.data, 
      //           Autoinvoices: promotions.data, count: promotions.data.count,
      //           idxPromotionType: selected.index, locale: locale, promotionType: req.PromotionType});
      //         break;
      //       default:
      //         dispatch({ type: recivePromotionsItemsType, promotionstype: promotionstype.data, 
      //           Promotions: promotions.data, count: promotions.data.count,
      //           idxPromotionType: selected.index, locale: locale, promotionType: req.PromotionType });
      //         break;
      //     }                    
      //   }))          
      //   .catch((error) => {          
      //     console.log(error);        
      //   });
  
        
      // });  


    }
};

export const reducer = (state, action) => {
    state = state || initialState;        
    switch (action.type) {
      case loadingType:
        return {
          ...state,     
          isLoading: true,        
        }; 
        case reciveAllCatalogItemsType:
          return {
            ...state,        
            isLoading: false,
            catalogitems: action.catalogitems
          };
        case newConditionType:
          return {
            ...state,
            condition: action.condition,
            isLoading: false,        
          };
        case showDialogType:
          return {
            ...state,
            showDlg: action.showDlg,
            isLoading: false,        
          };
        case savePromotionType:
          return {
            ...state,
            isLoading: false,
            selectedPromotion: { ...action.selectedPromotion }
          };
        case delPromotionType:
          return {
            ...state,        
            promotions: action.promotions,
            count: action.count,
            isLoading: false
          };
        case updPnomorionFieldType:
          return {
            ...state,
            selectedPromotion: { ...action.selectedPromotion }
          };
        case newPromotionType:
        case editPromotionType:
          return {
            ...state, 
            selectedPromotion: action.selectedPromotion,
            showDlg: action.showDlg
          };
        case requestCatalogItemsType:
        case requestPromotionsByCatalogType:
          return {
            ...state,        
            isLoading: true,
            locale: action.locale,
            Page: action.Page,
            RowsPerPage: action.RowsPerPage,
          };
        case recivePromotionsByCatalogType:
          return {
            ...state,
            catalogid: action.catalogid,        
            promotions: action.promotions,
            locale: action.locale,
            isLoading: false
          };

        case promotionsTypesType: 
          return {
            ...state,        
            promotionstype: action.promotionstype,                                    
            idxPromotionType: action.idxPromotionType,             
            isLoading: false,
          };
        // case recivePromotionsItemsType:
        //   return {
        //     ...state,        
        //     promotionstype: action.promotionstype,
        //     Promotions: {...action.Promotions},
        //     count: action.count,
        //     selectedPromotion: action.selectedPromotion,
        //     idxPromotionType: action.idxPromotionType,            
        //     locale: action.locale,
        //     promotionType: action.promotionType,
        //     isLoading: false,
        //   };
        //   case reciveChestsItemsType:
        //     return {
        //       ...state,        
        //       promotionstype: action.promotionstype,
        //       Chests: {...action.Chests},
        //       count: action.count,
        //       selectedPromotion: action.selectedPromotion,
        //       idxPromotionType: action.idxPromotionType,            
        //       locale: action.locale,
        //       promotionType: action.promotionType,
        //       isLoading: false,
        //     };
        //   case reciveScriptsItemsType:
        //       return {
        //         ...state,        
        //         promotionstype: action.promotionstype,
        //         Scripts: {...action.Scripts},
        //         count: action.count,
        //         selectedPromotion: action.selectedPromotion,
        //         idxPromotionType: action.idxPromotionType,            
        //         locale: action.locale,
        //         promotionType: action.promotionType,
        //         isLoading: false,
        //       };
        //     case reciveAutoInvocesItemsType:
        //         return {
        //           ...state,        
        //           promotionstype: action.promotionstype,
        //           Autoinvoices: {...action.Autoinvoices},
        //           count: action.count,
        //           selectedPromotion: action.selectedPromotion,
        //           idxPromotionType: action.idxPromotionType,            
        //           locale: action.locale,
        //           promotionType: action.promotionType,
        //           isLoading: false,
        //         };
        case choosenDefPromotionType:
          return {
            ...state,        
            idxPromotionType: action.idxPromotionType,
          };
      default:
        return state;
    }
  
  };