import React from "react";
// import Link from "@material-ui/core/Link";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionOrderCreators } from "../../store/Order";
import { Helmet } from "react-helmet";
import withWidth from "@material-ui/core/withWidth";
import red from "@material-ui/core/colors/red";
import blue from "@material-ui/core/colors/blue";
import { withTranslation } from "react-i18next";
import { OidcUserManager } from "../../helpers/OidcSettings";
import CatalogItemLg from "../Catalog/CatalogItemLg";
// import NotFound from "../NotFound";
import { Typography } from "@material-ui/core";

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),

    [theme.breakpoints.down("sm")]: {
      minHeight: 300
    }
  },

  specialprice: {
    position: "absolute",
    top: theme.spacing(4),
    right: theme.spacing(1),
    zIndex: 101,
  },
  burst: {
    width: 60,
    padding: theme.spacing(1),
    position: "absolute",
    top: 55,
    right: 45,  
    zIndex: 100,
},
specialcomment: {
  padding: theme.spacing(1),
  position: "absolute",
  top: 12,
  right: -8,
  fontWeight: "bold",
  fontSize: "1.3rem",
  color: "#fff"
},

  progress: {
    height: 2,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0
  },
  linearColorPrimary: {
    backgroundColor: "#abdcda"
  },
  linearBarColorPrimary: {
    backgroundColor: "#d20000"
  },

  card: {  
  },

  paper:{
    padding: theme.spacing(2),
    height: "100%",
  },
  section: {
    marginTop: theme.spacing(2),
  },
  textField: {        
    marginButtom: theme.spacing(1) * 2,
  },
  playIcon: {
    height: 38,
    width: 38
  },
  description: {
    paddingTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
    // maxWidth: "100%;",
    // width: "100%",    
    
  },
  
  price: {  
    marginLeft: theme.spacing(1) * 2,
  },

  priceOld: {
    textDecoration: "line-through",        
    fontSize: ".8em",        
    marginLeft: 10,
    color: "#000",        
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontWeight: 300,
    // fontSize: theme.typography.pxToRem(15),
    // color: theme.palette.text.secondary,
  },

  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {
    alignItems: "center",
  },
  column: {
    flexBasis: "33.33%",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: `${theme.spacing(1)}px ${theme.spacing(1) * 2}px`,
  },
  colorRed: {
    color: red[500],
  },
  colorBlue: {
    color: blue[500],
  },
  share: {
    marginRight: theme.spacing(1)
  },
  divider:{
    marginBottom: theme.spacing(1)
  },
  SubstanceComposition:{
    [theme.breakpoints.down("sm")]: {
      whiteSpace: "initial",
      width: "19rem",
      overflowX: "auto",
    },
    [theme.breakpoints.down("md")]: {       
      width: "100%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "100%",
    },
  },
  circularProgress: {
    marginLeft: 0,
    marginRight: theme.spacing(1), 
    color: "#fff"       
  },

});

class Product extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // eslint-disable-next-line no-undef
      cdn: `${process.env.REACT_APP_CDN}Pictures/`,
      imgLgSize: "392x392",
      imgMdSize: "183x183",
      expanded: false,
      IsAuthenticated: false,
      qty: 1,
      code: this.props.match.params.code || "",      
    };    
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.match.params.code !== prevState.code){        
      return { code: nextProps.match.params.code};
    }
    else return null;
  }

  componentDidMount() {    
    window.scrollTo({top: 40, behavior: "smooth"});
    // console.log("componentDidMount", this.state.code);
    this.props.actions.requestCatalogItem(this.state.code);
    this.loadUser();    
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.code !== prevState.code ) {
      // console.log("componentDidUpdate", this.state.code);
      this.props.actions.requestCatalogItem(this.state.code);
    }
  }

  loadUser = async () => {
      const userManager = await OidcUserManager();
      userManager.getUser().then(user => {
          if (user != null) {
            this.setState({IsAuthenticated: true});
          }
      });
  };

  
  render() {
    const { classes, isLoading, product } = this.props;
    const {cdn, IsAuthenticated} = this.state;    
    return (
      <React.Fragment>
        <Helmet>
          <title>{ `Jerelia, ${product ? product.LocalName : "Офіційний сайт"}` }</title>
          <meta property="og:type"          content="website"></meta>
          <meta property="og:url"           content={window.location.href} />
          <meta name="description"          content= { `Jerelia, ${product ? product.LocalName : "Офіційний сайт"}` } /> 

          { product && product.Photos.length > 0 ? 
          <meta property="og:image"         content={`${cdn}/392x392/${product.Photos[0].FileName}${product.Photos[0].Extention}`} />
          : null 
          }                    
        </Helmet>
      
        <div className={classes.root}>
          {isLoading && (
            <LinearProgress
              className={classes.progress}
              classes={{
                colorPrimary: classes.linearColorPrimary,
                barColorPrimary: classes.linearBarColorPrimary
              }}
            />
          )}

          {/* CatalogItem content */}
          {
            isLoading ? <Typography align="center">Loading...</Typography> 
                      :
                      (product ?
                        <CatalogItemLg item={product} IsAuthenticated={IsAuthenticated}></CatalogItemLg> : 
                        <Typography align="center">Loading...</Typography> 
                        // <NotFound />
                      )
          }

        </div>            
      </React.Fragment>
    );
  }
}

export default withTranslation()(withWidth()(
  withRouter(
    withStyles(styles, { withTheme: true })(
      connect(
        state => state.orderRequest,
        dispatch => {
          return {
             actions: bindActionCreators(Object.assign({}, actionOrderCreators), dispatch)
      }}
      )(Product)
    )
  )
));
