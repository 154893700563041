
import React from "react";
import {withStyles} from "@material-ui/core/styles";
import {withRouter} from "react-router-dom";
import withWidth from "@material-ui/core/withWidth";
// import classnames from "classnames";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../store/Catalog";
import { actionOrderCreators } from "../../store/Order";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import gray from "@material-ui/core/colors/grey";

// import CatalogItem from "../Catalog/CatalogItem";
import CatalogItemXs from "../Catalog/CatalogItemXs";
import CatalogItemList from "../Catalog/CatalogItemList";

import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";


// import IconButton from "@material-ui/core/IconButton";
// import Tooltip from "@material-ui/core/Tooltip";
// import ViewListIcon from "@material-ui/icons/ViewList";
// import ViewModuleIcon from "@material-ui/icons/ViewModule";

import { OidcUserManager } from "../../helpers/OidcSettings";
// import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
// import { blue } from "@material-ui/core/colors";

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(2),
        marginBottom: 90,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1 * 0.5),
            minHeight: 300,
        },
    },

    listview: {
        display: "flex",
        justifyContent: "start",
        flexWrap: "wrap",
        position: "relative",
        zIndex: 1    
    },
    catalogItem: {
        // flexGrow: 1,
        // flexBasis: "calc(33.333333% - 1px)",
        // maxWidth: "calc(33.333333% - 1px)",
        
        height: "508px",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: gray[200],
        borderRadius: "8px",
        // borderRight: "1px solid #f5f6fa",
        position: "relative",
        zIndex: 1,
        [theme.breakpoints.down("sm")]: {
            height: "390px",
        }, 
        "&:hover": {
            zIndex: 1000,
        },
    },
    wrapper: {        
        position: "absolute",        
        zIndex: 1,
        width: "100%",
        // minHeight: "503",
        height: "504px",
        overflow: "hidden",
        left: 0,
        top: 0,        
        // borderRadius: theme.spacing(1 / 2),
        transition: "box-shadow .5s ease-in-out",
        [theme.breakpoints.down("sm")]: {
            height: "376px",
            minHeight: "376px",
        }, 
        "&:hover": {
            height: "auto !important",
            borderRadius: "8px",
            transform: "scale(1.04)",
            boxShadow: "0 8px 10px rgb(54 74 178 / 16%), 0 6px 30px rgb(54 74 178 / 4%), 0 16px 24px rgb(54 74 178 / 8%)",
        }
    },
    
    view: {
        textAlign: "right",
    },
    group: {                         
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",        
        // fontSize: "1.5rem",
        [theme.breakpoints.down("sm")]: {
            textAlign: "center",
            fontSize: "1.3rem",
        },        
    },
    groupBox: {
        marginBottom: theme.spacing(1)
    },
    progress: {
        height: 2,
        position: "absolute",
        top: 0,
        left: 0,
        right:0,
    },  
    linearColorPrimary: {
        backgroundColor: "#abdcda",
    },
    linearBarColorPrimary: {
        backgroundColor: "#d20000",
    },
    bntview: {
        color: theme.palette.primary.light
    },
    icon: {
        position: "relative",
        // top: theme.spacing(1),
        // width: 26,
        // height: 26
    },
});

class Catalog extends React.Component {  
    constructor(props) {        
        super(props);
        let N = 1;         
        // switch (this.props.width) {
        //     case "xs":                
        //         N = 1;
        //         break;
        //     case "sm":                
        //         N = 2;
        //         break;
        //     case "md":                
        //         N = 3;
        //         break;
        //     case "lg":                
        //         N = 4;
        //         break;
        //     case "xl":                
        //         N = 4;
        //         break;
        //     default:
        //         N = 1;
        //         break;
        // }            
        
        const view = localStorage.getItem("view");
        
        this.state = {
            grp: this.props.match.params.grp || "",
            pi: parseInt(this.props.match.params.pi, 10) || 0,
            splash: Array.apply(null, {length: N}).map(Number.call, Number),
            view: view ? view : 1,
            IsAuthenticated: false,            
        };
        this.refId = React.createRef();
    }

    static getDerivedStateFromProps(props, state) {
        const grp = props.match.params.grp || "";
        const pi = parseInt(props.match.params.pi, 10) || 0;
                
        if (grp !== state.grp || pi !== state.pi  ) {
          return {
            grp: grp,
            pi: pi,
          };
        }
        return null;
    }

    componentDidMount() {        
        this.props.actions.requestCatalogItems(this.state.grp, this.state.pi);
        this.loadUser();
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.state.pi !==  prevState.pi || this.state.grp !== prevState.grp ) {
            this.props.actions.requestCatalogItems(this.state.grp, this.state.pi);
            window.scrollTo({top: 0, behavior: "smooth"});
        }    
    }

    renderProductThumb = (index, item) => {
        return (
            <Grid item key={index} xs={6} sm={6} md={4} lg={3} xl={2}>
                <CatalogItemXs item={item} IsAuthenticated={this.state.IsAuthenticated}></CatalogItemXs>
            </Grid>
        );
    };

    renderProductList = (index, item) => {
        return (
            <Grid item key={index} xs={12} sm={6} md={4} lg={4} xl={2} >
                <CatalogItemList item={item} IsAuthenticated={this.state.IsAuthenticated}></CatalogItemList>
            </Grid>
        );
    };

    changeView = (i) => {                
        this.setState({view: i}) 
        localStorage.setItem("view", i);
        
    }

    loadUser = async () => {         
        const userManager = await OidcUserManager();
        userManager.getUser().then(user => {
            if (user != null) {
              // const token = `Bearer ${user.access_token}`;
              // console.log("token", token);
              this.setState({IsAuthenticated: true});
            }
        });
    };

    render(){
        const { classes, response, isLoading, width  } = this.props;
        const { view } = this.state;
        if(!response){
            this.props.history.push("/notfound");
            return (
                <div></div>
            );
        }
        
        return(
            <div className={classes.root}>
                { isLoading && 
                <LinearProgress className={classes.progress} 
                    classes={{
                        colorPrimary: classes.linearColorPrimary,
                        barColorPrimary: classes.linearBarColorPrimary,
                    }}
                />
                }                
                <Grid style={{marginBottom: "8px"}} container direction="row" justify="flex-start" alignItems="center" spacing={0} >
                    <Grid item xs={12} sm={9}>                    
                        <Typography  className={classes.group} component="h4" variant="h5" >
                            {response.ProductGroup.LocalName} { response.CatalogItems ? <small>{`(${response.CatalogItems.length})`}</small> : null}
                        </Typography>
                    </Grid>
                    {/* <Grid item xs={4} sm={3} className={classes.view}>
                        <Tooltip title={"List"}>
                            <IconButton className={classes.bntview} onClick={ () => this.changeView(0)} >
                                <ViewListIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={"Thumbnails"}>
                            <IconButton className={classes.bntview} onClick={() => this.changeView(1)} >
                                <ViewModuleIcon  />
                            </IconButton>
                        </Tooltip>
                    </Grid> */}
                </Grid>
                
                { response.CatalogItems && 
                   
                   <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={width === "xs" ? 1 : 3}>
                       { response.CatalogItems.map((item) => (
                           <Grid item  key={item.Code} xs={6} sm={6} md={4} lg={3} xl={2}>
                               <div className={classes.catalogItem}>
                                    <div className={classes.wrapper}>
                                        { view === 0 ? null: <CatalogItemXs item={item} IsAuthenticated={this.state.IsAuthenticated}></CatalogItemXs> }                                                                                                                
                                    </div>
                               </div>
                           </Grid>
                       ))}
                   </Grid>



                    // <div className={classes.listview}>
                    //     {
                    //         response.CatalogItems.map((item) => (
                    //             <div className={classes.catalogItem} key={item.Code}>
                    //                 <div className={classes.wrapper}>
                    //                 <Typography variant="body1">
                    //                     {item.LocalName}
                    //                 </Typography>
                    //                 </div>
                    //             </div>
                    //         ))
                    //     }
                    // </div>
                
                // <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={width === "xs" ? 1 : 3}>
                // {                    
                //     response.CatalogItems.length > 0 ?
                //     response.CatalogItems.map((item, index) =>(
                //         view === 0 ? 
                //             this.renderProductList(index, item) : 
                //             this.renderProductThumb(index, item)
                //     )) :                 
                //     this.state.splash.map((item ,index) =>(
                //         <Grid item key={index} xs={12} >
                //             <EmptyCard></EmptyCard>
                //         </Grid>                                        
                //     ))
                // }
                // </Grid>


                }
                
            </div>
        );
    }
}

export default withTranslation()(withWidth()(withRouter(withStyles(styles)(
    connect(    
        state => state.catalogRequest,
        dispatch => {
            return {
               actions: bindActionCreators(Object.assign({}, actionCreators, actionOrderCreators), dispatch)
        }}
        // dispatch => bindActionCreators(actionCreators, dispatch)
    )(Catalog))
)));