import React, { Component } from "react";

import { instanceOf } from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import { StylesProvider } from "@material-ui/core/styles";
import Layout from "./components/Layout/Layout";
import { Routes } from "./Routes";

import { withCookies, Cookies } from "react-cookie";
import "./styles/index.css";



class App extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {        
    super(props);        
    const query = new URLSearchParams(window.location.search.toLowerCase());        
    const fromquery = query.getAll("ref");
    if(fromquery.length !== 0){
      const  ref = fromquery.toString();
      if(ref.length > 0){
        this.setCookiesFromUrl("ref", ref);
      }      
    }                
  }

  setCookiesFromUrl = (name, val) => {
    const { cookies } = this.props;        
    const days = 31;            
    // const expires = new Date();  // date.getTime() + (days*24*60*60*1000);        
    // expires.setDate(expires.getDate() + days);    
    cookies.set(name, val, { path: "/", maxAge: days*24*60*60});
  };

  render () {        
    const { theme, toggleTheme } = this.props;
    return (       
        <StylesProvider>
          <Layout theme={theme} toggleTheme={toggleTheme} > 
            <CssBaseline />              
            {Routes}              
          </Layout>
        </StylesProvider>        
    );
  }
}

export default withCookies(App);
