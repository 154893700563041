import React from "react";
import { bindActionCreators } from "redux";
import {withRouter} from "react-router-dom";
import { connect } from "react-redux";
import { actionCreators } from "../../store/NavItems";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import Link from "@material-ui/core/Link";
// import {Link} from "react-router-dom";

import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// import ListItemIcon from "@material-ui/core/ListItemIcon";
// import ListItemText from "@material-ui/core/ListItemText";
// import Icon from "@material-ui/core/Icon";
import { Link as RouterLink } from "react-router-dom";
import grey from "@material-ui/core/colors/grey";


const styles = (theme) => ({  
  // "@global": {
  //   ul: {
  //     margin: 0,
  //     padding: 0,
  //     listStyle: "none",
  //   },
  // },
  root: {    
    backgroundColor: theme.palette.type === "light" ? "#132530" : theme.palette.grey[800],    
  },

  list: {
    margin: 0,
    padding: 0,
    listStyle: "none",  
  },
  colhead: {
    color: theme.palette.type === "light" ? grey[500] : theme.palette.grey[400],
  },
  link: {
    [theme.breakpoints.down("xs")]: {
      display: "block",
      textAlign: "center",
    },    
    fontSize: "0.875rem",
    padding: theme.spacing(0, 0),   
    margin: theme.spacing(0, 0),
    color: theme.palette.type === "light" ? grey[300] : theme.palette.grey[300],
  },

  footer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.type === "light" ? grey[300] : theme.palette.grey[300],
    // [theme.breakpoints.down("xs")]: {
    //   marginBottom: theme.spacing(6),
    // },
        
    // [theme.breakpoints.up("sm")]: {      
    //   paddingBottom: theme.spacing(8),
    // },
  },
});

function Copyright() {
  return (
    <Typography variant="body2" align="center" style={{marginTop: 8, fontSize: ".7rem"}}>
      {"Copyright © "}
      <Link color="inherit" href="https://jerelia.com/">
        Jerelia
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const SubItem = withStyles(styles)( props => {
  const { items, classes } = props;
  return (
    
    <List className={classes.list}>
    { items.map((item) => (      
      <li className={classes.link} key={item.id}>
        { item.href.startsWith("/") ?
         <ListItem className={classes.link} component={RouterLink} to={item.href} >
           {item.title}
          {/* <ListItemText className={classes.link} secondary={item.title} /> */}
        </ListItem> : 
        <Link className={classes.link} href={item.href} title={item.subtitle} target={item.target} variant="subtitle1">
          {item.title}
        </Link>
      }

      <SubItem items={item.children} />
      </li>            
    ))} 
    </List>


  );
});

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {           
    this.props.requestFooterNavItems();    
  }

  render() {
    const { classes, FooterNavItems, width } = this.props;
    return (
      
        <div className={classes.root}>        
        {/* Footer */}
        <Container maxWidth="lg" component="footer" className={classes.footer}>
          <Grid container spacing={2} justify="center" alignItems="flex-start" >            
            {FooterNavItems.map((footer) => (
              <Grid item xs={12} sm={4} md={3} lg={2} key={footer.title}>
                <Typography variant="body1" className={classes.colhead} align={ width === "xs" ? "center": "left"}  title={footer.subtitle} gutterBottom>
                  {footer.title}
                </Typography>                
                <SubItem items={footer.children} />
              </Grid>
            ))}
          </Grid>
          
          <Box mt={2}>
            <Divider />
            <Copyright  />
          </Box>
        </Container>
        {/* End footer */}
        </div>      
    );
  }
}

export default withRouter( withWidth()(withStyles(styles)(connect(
  state => state.appLayout,
  dispatch => bindActionCreators(actionCreators, dispatch)
)(Footer))));

// export default withWidth()(withStyles(styles)(Footer));
