import React, { Component } from "react";
import {withRouter} from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionOrderCreators } from "../../store/Order";
import {withStyles} from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { connect } from "react-redux";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import AddShoppingCard from "@material-ui/icons/AddShoppingCart";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { withTranslation } from "react-i18next";

const styles = theme => ({
    root: {
        display: "flex",        
      },
      details: {          
        display: "flex",
        flexDirection: "column",
        width: "100%",
      },
      content: {        
        flex: "1 0 auto",        
        height: 70,
        [theme.breakpoints.down("sm")]: {
            height: 70,
        },
      },
      cover: {
        width: 76,
        backgroundSize: "auto",
        [theme.breakpoints.down("sm")]: {
            height: 76,
        },
      },
      controls: {        
        padding: theme.spacing(1),        
      },
      playIcon: {
        height: 28,
        width: 28,
      },
      price: {
        marginRight: theme.spacing(2),
      },
});

class CatalogItemSmall extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // eslint-disable-next-line no-undef
            cdn: `${process.env.REACT_APP_CDN}Pictures/`,
            imgLgSize: "392x392",
            imgMdSize: "183x183",
            imgXsSize: "76x76",
            Qty: this.props.item.OnlyFirstTime ? 1 : "",            
        };
        this.handleBuy = this.handleBuy.bind(this);
        this.handleChangeQty = this.handleChangeQty.bind(this);        
    }
    
    handleChangeQty = (e) => {
        const qty = e.target.value;// isNaN(parseInt(e.target.value)) ? 1 : parseInt(e.target.value);
        this.setState({Qty: qty});
        this.props.item.Qty =  isNaN(parseInt(qty)) ? 0 : parseInt(qty);        
    };

    handleBuy() {
        if(!isNaN(parseInt(this.state.Qty)))
            this.props.actions.addToOrder([{ pos: this.props.item, qty: parseInt(this.state.Qty), orderrowtype: 0 }]);
    }

    render() {
        const {item, classes} = this.props;
        const {cdn} = this.state;
        return (
            <Card className={classes.root}>
                <CardMedia
                    className={classes.cover}
                    image={`${cdn}${this.state.imgXsSize}/${item.Image}`}
                    title={item.Name}
                />
                <div className={classes.details}>
                    <CardContent className={classes.content}>
                        <Typography variant="body2" gutterBottom>
                            {item.Code} : {item.Name}
                        </Typography>                        
                    </CardContent>
                    <Grid className={classes.controls} container  direction="row" justify="flex-end" alignItems="center">
                        <Grid item xs={4}>
                            {parseFloat(item.CatalogPrice).toFixed(2)}
                        </Grid>
                        <Grid item xs={4}>
                            <Input className={classes.qty}                                     
                                type="number"
                                inputProps={{ min: 0, max: 1000  }}
                                value={this.state.Qty}                                                        
                                onChange={this.handleChangeQty}  
                                startAdornment={<InputAdornment position="start">шт</InputAdornment>}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Button                                                         
                            color="primary" 
                            className={classes.buy}
                            onClick={this.handleBuy}
                            >
                            <AddShoppingCard /> 
                        </Button>
                        </Grid>
                        
                                    
                    
                </Grid>
            </div>
        </Card>
        );
    }
}


export default withTranslation()(withWidth() (withRouter( withStyles(styles)(
    connect(    
        state => state.orderRequest,
        dispatch => {
            return {
               actions: bindActionCreators(Object.assign({}, actionOrderCreators), dispatch)
        }}
    )(CatalogItemSmall)
))));