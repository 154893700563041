import axios from "axios";
// import userManager from "../helpers/userManager";
import { OidcUserManager } from "../helpers/OidcSettings";

const requestSearch = "REQUEST_SEARCH";
const receiveSearch = "RECEIVE_RECIVE";
const initialState = { 
  response: {     
    CatalogItems: null
  },
  q: "",
  page: 1,
  size: 12,  
  isLoading: false 
};


export const actionCreators = {
    requestSearch: (q, page, size) => async (dispatch, getState) => {
      if(q.length === 0)
      if (q === getState().searchRequest.q && page === getState().searchRequest.page && size === getState().searchRequest.size) {
        // Don't issue a duplicate request (we already have or are loading the requested data)
        return;
      }    
      if(q.length === 0){      
        return;
      }
      dispatch({ type: requestSearch, q, page, size });
  
      let token = null;
      const userManager = await OidcUserManager();
      userManager.getUser().then(function (user) {
        if(user != null){      
          token =  `Bearer ${user.access_token}`;        
        }  
        const url = `/api/v1/CatalogItem/Search/${q}/false/${page}/${size}`;
        axios.get(url,         
          { 
            headers: { Authorization: token }        
          }
        )
        .then(function(response) {          
          dispatch({ type: receiveSearch, q, page, size, result: response.data });    
        })
        .catch((error) => {        
          console.log(error);  
          dispatch({ type: receiveSearch, q, page, size, initialState  });      
        });
      });
  
    }
};

export const reducer = (state, action) => {
    state = state || initialState;
  
    if (action.type === requestSearch) {
      return {
        ...state,
        q: action.q,
        page: action.page,
        size: action.size,
        isLoading: true
      };
    }
  
    if (action.type === receiveSearch) {       
      return {
        ...state,
        q: action.q,
        page: action.page,
        size: action.size,
        response: action.result,
        isLoading: false
      };
    }
  
    return state;
  };
  