import React from "react";
import Slider from "../Slider/Slider";
import { OidcUserManager } from "../../helpers/OidcSettings";
import { withStyles } from "@material-ui/core/styles";
// import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
// import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import StarIcon from "@material-ui/icons/StarBorder";
import { withTranslation } from "react-i18next";
import axios from "axios";
// import { Helmet } from "react-helmet";

const styles = theme => (
{ 
  root: {
    flexGrow: 1,
  },
  layout: {
    // width: "auto",
    marginTop: theme.spacing(1) * 1,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.up(1200 + theme.spacing(1) * 1 * 1)]: {
      width: 1200,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  middle: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1), 
    paddingRight: theme.spacing(1) 
  },
  cardHeader: {
    backgroundColor: theme.palette.paper,
  },

  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(1) * 1,
  },
  cardActions: {
    [theme.breakpoints.up("sm")]: {
      paddingBottom: theme.spacing(1) * 1,
    },
  },
  media: {
    height: 0,
    backgroundSize: "100%",
    backgroundPosition: "center bottom",
    paddingTop: "56.25%", // 16:9
  },
  star: {
    color: theme.palette.secondary.dark
  }

});

class Index extends React.Component {
  constructor(props) {        
    super(props);
    this.state = {
      cards: [],
      slider: null,
    };
  }

  componentDidMount(){
    this.getSlider("slider");
    this.getInfoCards("hp-small-slider");
  }

  getSlider = async (file) => {
    const URL = `/api/v1.0/slider/${file}`;        
    const userManager = await OidcUserManager();
    
    userManager.getUser().then(user => {
      let token = null;

      if (user != null) {
          token =  `Bearer ${user.access_token}`;                                                          
      }

      axios.get(URL,
        { headers: { 
          Authorization: token,          
        }} 
      ) 
      .then((response) => {
        if(response.data){          
          // console.log("SLIDER",response.data)
          this.setState({ slider: response.data });           
        }          
      })
      .catch((error) => {            
          console.log(error);                
      });
    });
  }

  getInfoCards = (file) => {
    const URL = `/api/AppLayout/GetInfoCards/${file}`;        
    axios.get(URL) 
    .then((response) => {
      if(response.data){                          
        this.setState({ cards: response.data });            
      }          
    })
    .catch((error) => {            
        console.log(error);                
    });
  }

  render(){
    const { classes, t } = this.props;    
    const { cards, slider } = this.state;    
    // const { t } = this.props;
    return(
      <React.Fragment>
        {/* <Helmet>
          <title>{title}</title>
          <meta name="description"          content={description} />
          <meta property="og:url"           content={`${window.location.protocol}//${window.location.host}`} />
          <meta property="og:type"          content="website"></meta>          
          <meta property="og:title"         content={title} />
          <meta property="og:description"   content={description} />
          { slider ? 
          <meta property="og:image"         content={`${window.location.protocol}//${window.location.host}${slider.Items[0].Path}/sm/${slider.Items[0].Img}`} />
          : null 
          }
          
        </Helmet> */}
      
      <div className={classes.root}>


        { slider ? <Slider slider = {slider}></Slider> : null}

        <div className={classes.middle}>        
          <Grid container spacing={2}>
          {cards.map(card => (
              
              <Grid item key={card.Title} xs={12} sm={card.Title === "Enterprise" ? 12 : 6} md={4}>
                <Card>
                    <CardHeader
                      title={card.Title}
                      subheader={card.Subheader}
                      titleTypographyProps={{ align: "center" }}
                      subheaderTypographyProps={{ align: "center" }}
                      action={card.Star ? <StarIcon className={classes.star} /> : null}
                      className={classes.cardHeader}
                    />                  
                      <CardMedia
                      className={classes.media}
                      image={card.Img}
                      title={card.Title}
                    />
                    {/* <CardContent>
                      {card.Description.map(line => (
                        <Typography variant="subtitle1" align="center" key={line}>
                        {line}
                        </Typography>
                      ))}
                    </CardContent> */}
                    <CardActions className={classes.cardActions}> 

                    {
                      card.Url.startsWith("http") ?
                      <Button target="_blank" href={card.Url} fullWidth variant={card.ButtonVariant} color={card.Color} >
                        { t(`index.smallslider.${card.ButtonText}`) }
                    </Button> :
                    <Button to={card.Url} fullWidth variant={card.ButtonVariant} color={card.Color} component={Link}>
                        { t(`index.smallslider.${card.ButtonText}`) }
                    </Button> 
                    }

                    </CardActions>
                </Card>
              </Grid>
          ))}
        </Grid>        
        </div>      
      </div>        
      </React.Fragment>
    );    
  }
}

export default withTranslation()(withStyles(styles)(Index));