import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actionOrderCreators } from "../../store/Order";
import {withStyles} from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const styles = theme => ({    
      // icon: {
      //   fontSize: 20,
      // },
      iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
      },
      // message: {
      //   display: "flex",
      //   alignItems: "center",
      // },
});

  
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Notification extends React.Component {  
    constructor(props) {        
        super(props);
        this.state = {};               
    }

    render(){
        const {snackbarTxt, snackbarShow, variant } = this.props;        
        return(            
          <Snackbar open={snackbarShow} autoHideDuration={ variant ==="error" ? 20000 : 2000} 
                    onClose={this.props.hideSnackBar} 
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}>
            <Alert onClose={this.props.hideSnackBar} severity={variant}>
              <div style={{whiteSpace: "pre-line"}}>
                {snackbarTxt}
              </div>              
            </Alert>
          </Snackbar>            
        );
    }
}

export default withStyles(styles)(
    connect(    
        state => state.orderRequest,
        dispatch => bindActionCreators(actionOrderCreators, dispatch)
    )(Notification)
);