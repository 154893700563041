import React from "react";
import { bindActionCreators } from "redux";
import {withRouter} from "react-router-dom";
import { withTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Divider from "@material-ui/core/Divider";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Icon from "@material-ui/core/Icon";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import PermContactCalendarOutlinedIcon from "@material-ui/icons/PermContactCalendarOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExitToAppSharpIcon from "@material-ui/icons/ExitToAppSharp";
import RedeemIcon from "@material-ui/icons/Redeem";
import axios from "axios";
import { connect } from "react-redux";
import { actionCreators } from "../../store/NavItems";
// import { OidcUserManager } from "../../helpers/OidcSettings"
import { AuthContext } from "../../providers/authProvider";

import Hidden from "@material-ui/core/Hidden";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import BigMenu from "../BigMenu/BigMenu";
import Basket from "../Basket/Basket";
import i18n from "../../i18n";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
// import { FacebookIcon, TelegramIcon, ViberIcon } from "../BigMenu/jerelia-icons";
import grey from "@material-ui/core/colors/grey";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import AuthService from "../../helpers/authService";



const styles = theme => ({
  root: {
    width: "100%",
  },
  topLine: {    
    color: "#fff",
    //background: "rgb(50,162,206)",
    background: "linear-gradient(338deg, rgba(116,251,253,1) 0%, rgba(85,209,231,1) 24%, rgba(50,162,206,1) 100%)"
  },
  topLineTxt: {
    "& *":{
      color: "#fff",
      margin: 0,
      padding: theme.spacing(1),
      whiteSpace: "nowrap",
      textDecoration: "none",
    }
  },
  appbar: {
    color: theme.palette.appbar.background,
    backgroundColor: "#fff"
  },
  // drawer: {
  //   backgroundColor: theme.palette.appbar.background,
  // },
  toolbar: {    
    // paddingLeft: theme.spacing(2),
    paddingRight: 0, 
  },

  logo: {
    paddingTop: theme.spacing(1),
    width: 152,
    height: "auto",
    [theme.breakpoints.down("xs")]: {  
      paddingTop: 4,
      width: 96,
    },
  },

  list: {    
    width: 270,
  },

  listItemIcon: {
    minWidth: 40,
  },

  fullList: {
    width: "auto",
  },

  nested: {
    paddingLeft: theme.spacing(4) * 1,
  },

  nestedThird: {
    paddingLeft: theme.spacing(4) * 2,
  },

  userName: {
    textTransform: "capitalize"
  },

  userAvatar:{
    width: theme.spacing(4),
    height: theme.spacing(4),    
    backgroundColor: theme.palette.primary.main,
    // backgroundColor: deepOrange[500],
    // color: "#fff",
    // border: "2px solid #48bfde",
  },

  usesAuth:{
    // backgroundColor: deepOrange[500],
    backgroundColor: theme.palette.primary.main
  },
  userAnonymus:{
    width: theme.spacing(4),
    height: theme.spacing(4),        
    // backgroundColor: deepOrange[500],
    // color: "#fff",
    // border: "2px solid #48bfde",
  },

  imgAvatar: {
    borderRadius: "50%",
    width: theme.spacing(4)-2,
    height: theme.spacing(4)-2,
  },

  content: {    
    padding: theme.spacing(1) * 1
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -15,
    marginRight: 10,
  },
  buttons: {        
    margin: theme.spacing(0, 1),
  },
  title: {
    display: "none",
    marginRight: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
    textDecoration: "none",
    fontWeight: 300,
    fontSize: "1.5rem"
  },
  userinfo: {
    // backgroundColor: theme.palette.primary.dark,
    backgroundColor: "linear-gradient(338deg, rgba(0,155,238,1) 4%, rgba(6,28,61,1) 100%)"
  },

  searchbox: {  
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: grey[300],

    // border: "1px solid #ccc",
    display: "flex",
    alignItems: "center",    
    width: "100%",
    // [theme.breakpoints.up("sm")]: {
    //   width: 300,
    // },
  },

  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    color: theme.palette.primary.dark,
    // color: "#fff"
  },
  iconButton: {
    // padding: 10,
    color: theme.palette.primary.main,
  },
  iconLogin: {
    color: "#bbd5f2",
  },

  divider: {
    height: 26,
    // padding: theme.spacing(0, 0),
  },

  // searchIcon: {
  //   width: theme.spacing(1) * 6,
  //   height: "100%",
  //   position: "absolute",
  //   pointerEvents: "none",
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "center",
  // },
  
  // inputRoot: {
  //   color: "inherit",
  //   width: "100%",
  // },
  
  // inputInput: {
  //   paddingTop: theme.spacing(1),
  //   paddingRight: theme.spacing(1),
  //   paddingBottom: theme.spacing(1),
  //   paddingLeft: theme.spacing(1) * 7,
  //   transition: theme.transitions.create("width"),
  //   width: "100%",
  //   [theme.breakpoints.up("md")]: {
  //     width: 200,
  //   },
  // },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
});

// function getIcon (icon) {
//   switch (icon) {
//     case "facebook":          
//       return (<FacebookIcon width={24} height={24} />);
//     case "telegram":          
//       return (<TelegramIcon width={24} height={24} />);
//     case "viber":          
//       return (<ViberIcon width={24} height={24} />);      
//     default:
//       return null; 
//   }
// }

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function getDefaultLang() {
  const I18N_LANGUAGE = "i18nextLng";
  return localStorage.getItem(I18N_LANGUAGE) || "uk";
}

class NavMenu extends React.Component {
  constructor(props) {
    super(props);    
    this.state = {
          anchorEl: null,
          mobileMoreAnchorEl: null,
          anchorElLang: null,
          anchorElChat: null,
          left: false,
          open: false,
          accountOpen: false,
          showSearchBox: false,
          userProfile : undefined,
          search: "",
          themeType: false,
          // showDlgGiftCard: false,
          CertificateCode: "",
    };

    this.accountRef = React.createRef();
    this.DefaultLang = getDefaultLang();
    this.authService = new AuthService();
    // this.getUserProfile();
  }
  
  static contextType = AuthContext;
  
  handleToggleSearchBox = () => {
    const {showSearchBox} = this.state;
    this.setState({showSearchBox: !showSearchBox});    
  };

  handleCloseSearchBox = () => {    
    this.setState({showSearchBox: false});
  };

  handleToggle = () => {
    const {accountOpen} = this.state;
    this.setState({accountOpen: !accountOpen});    
  };

  handleClose = (event) => {
    if (this.accountRef.current && this.accountRef.current.contains(event.target)) {
      return;
    }
    this.setState({accountOpen: false});
  };

  handleListKeyDown =(event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      this.setState({accountOpen: false});
    }
  }

  getUserProfile = async () => {    
    if(this.authService){      
      if(this.authService.isAuthenticated()){
        this.authService.getUser()
          .then( user => {          
            if(user != null && !user.expired){
              // console.log("USER", user); 
              this.setState({ userProfile: user.profile });  
            }
            else{
              this.setState({ userProfile: null });
            }
          });
        }
      } else {
        this.setState({ userProfile: null });
      }

    // OidcUserManager().then( userManager =>{
    //   userManager.getUser().then(user => {      
    //     if(user != null && !user.expired){        
    //       this.setState({ userProfile: user.profile });  
    //     }
    //     else{
    //       this.setState({ userProfile: null });
    //     }
    //   }); 
    // });    
  }
 
  // This method runs when incoming props (e.g., route params) change
  componentDidMount() {
    const context = this.context;
    const userManager = context.UserManager;

    userManager.getUser()
      .then(user => {
            if(!user)
                return;
            console.log(context.parseJwt(user.access_token));
            
            // const URL = `/api/v1.0/orders/alldrafts/${search.length > 0 ? search : " " }/${page}/${pageSize}`;            
            // fetch(URL, {
            //         method: "GET",
            //         headers: {"Authorization": `${user.token_type} ${user.access_token}` }
            //     }
            // )
            // .then(response => response.json())
            //     .then(data => {                    
            //         this.setState({drafts: {...data}});
            // })
            // .catch(error => console.log(error));
    });

    this.getUserProfile();
    this.props.requestNavItems();
    this.props.getCustomer();
  }

  toggleDrawer = (side, open) => () => {
    this.setState({ [side]: open });
  };
  
  handleChange = (e) => {                
    this.setState({ [e.target.name]: e.target.value  });                
  };

  ApplayCertificateCode = () => {
    const {CertificateCode} = this.state;
    if(CertificateCode.length > 0){
      this.props.applayPromoCode(this.state.CertificateCode);
    }    
    // alert(`Applay cert. code: ${this.state.CertificateCode}`);
  };

  newOrder = (e) => {    
      this.props.history.push("/order/new");
      this.handleMobileMenuClose();
      e.preventDefault();
  };

  keyPressSearch = e => {
    if (e.key === "Enter") {      
      this.props.history.push(`/search/${this.state.search}`);
      e.preventDefault();
    }
  };

  handleSearch = (e) => {
    this.props.history.push(`/search/${this.state.search}`);
    e.preventDefault();
  };

  handleChangeSearch = e =>{
    this.setState({ search: e.target.value });    
  };

  handleChangeThemeType = () => {
    const {Adviser } = this.props;
    if(Adviser){
      this.setState({ themeType: !this.state.themeType });
      this.props.onThemeTypeSwitch();
    }
    // this.handleMenuClose();
  };

  handleLogin = () => {
    
    this.authService.signinRedirect();
    
    // OidcUserManager().then(userManager => {
    //   userManager.signinRedirect();
    // });
    
  };

  handleRegister = () => {    
    this.setState({ ["left"]: false });
    this.props.history.push("/register"); 
  };

  handleLogout = () => {
    this.authService.logout();
    // OidcUserManager().then(userManager => {   
    //   userManager.signoutRedirect()
    //     .then(() => {userManager.removeUser()})
    //     .then(() => {this.user = null;})
    //     .catch((error) => this.handleError(error));
    // });
  };

  handleLangMenuOpen = event => {
    this.setState({ anchorElLang: event.currentTarget });
  };

  handleChatMenuOpen = event => {
    
    this.setState({ anchorElChat: event.currentTarget });
  };

  handleLangMenuClose = () => {
    this.setState({ anchorElLang: null });    
  };

  handleChangeLang = lang => {    
    const URL = `/api/Languege/${lang}`;     
    i18n.changeLanguage(lang);
    
    axios.post(URL)
      .then(response => {   
        console.log(response.data);     
        window.location.reload();
      })
      .catch((error) => {
        console.log("error " + error);
    });

    this.handleMenuClose();
  };

  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleGiftCardOpen = () => {
    // this.toggleDrawer("left", false);    
    this.setState({["left"]:false });
    this.props.showDlgGift(true);
    
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null, anchorElLang: null, anchorElChat: null });    
    this.handleMobileMenuClose();
    this.handleLangMenuClose();
  };

  handleMobileMenuOpen = event => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };

  buildLeftNavBar = (items) => {       
    return items.map((item) => (                    
          <ListItem button key={item.id} component={Link} to={item.href} >
            <ListItemIcon><Icon>{item.icon}</Icon></ListItemIcon>
            <ListItemText primary={item.title} />
          </ListItem>
    ));  
  };

  handleClick = (e) => {    
    this.setState({ [e]: !this.state[e] });
    // this.setState(state => ({ open: !state.open }));
  };

  activeLangISOName = () => {
    for (let i = 0; i < this.props.Langs.length; i++) {
      const lang = this.props.Langs[i];
      // if(lang.Active){
        if(lang.Name === this.DefaultLang){
        return lang.TwoLetterISOLanguageName;
      }
    }
    return "";
  };


  render() {
    const { classes, location, t, snackbarTxt, snackbarShow, variant, showDlgGiftCard, Langs, width, LeftNavItems, Adviser } = this.props;
    const { search, anchorElLang, userProfile, accountOpen, showSearchBox } = this.state;    
    // const isMenuOpen = Boolean(anchorEl);
    const isMenuLangOpen = Boolean(anchorElLang);

    
    // eslint-disable-next-line no-undef
    const logo = process.env.REACT_APP_LOGO;
    
    // const isMenuChatOpen = Boolean(anchorElChat);
    // const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    
    const leftsideList = (
      <SwipeableDrawer
        open={this.state.left}
        onClose={this.toggleDrawer("left", false)}
        onOpen={this.toggleDrawer("left", true)}
      >     
      
      <List component="nav" className={classes.list +" "+ classes.userinfo} disablePadding>
      <ListItem alignItems="flex-start" style={{ paddingRight: 4}}>
        <ListItemText 
          primary={
            <React.Fragment>              
              { userProfile ?
                <Grid container direction="row" >
                  <Grid item xs={9}>
                    <Button size="small"  onClick={this.handleLogout}             
                      startIcon={<ExitToAppSharpIcon />}
                    >
                      {t("appbar.menu.Logout")}
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    <Button size="small" onClick={ this.handleLangMenuOpen }          
                      endIcon={<ExpandMoreIcon />}
                    >
                      {this.activeLangISOName()}
                    </Button>
                  </Grid>
                  
                </Grid>
              :
              <Grid container direction="row" >
                <Grid item xs={4}>
                  <Button size="small"  onClick={this.handleLogin}             
                    startIcon={<PersonOutlineOutlinedIcon />}
                  >
                    {t("appbar.menu.Login")}
                  </Button>
                </Grid>
                <Grid item xs={5}>
                  <Button size="small" onClick={this.handleRegister}  >
                    {t("appbar.menu.Register")}
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button size="small" onClick={ this.handleLangMenuOpen }          
                    endIcon={<ExpandMoreIcon />}
                  >
                    {this.activeLangISOName()}
                  </Button>
                </Grid>
              </Grid>
            }
            </React.Fragment>
          }
        />
      </ListItem>
        
      </List>
      {/* <Divider /> */}
      {Adviser && 
          <>
          <Box p={1} mb={1}>
          <Paper style={{backgroundColor: "#00000038"}} elevation={0}>
          <Grid container direction="row" spacing={2}>
            <Grid item>
              {Adviser.Avatar && Adviser.Avatar.length > 0 ?
                <Avatar className={classes.usesAuth} alt={`${Adviser.FirstName} ${Adviser.LastName}`} src={Adviser.Avatar} style={{ marginLeft: 8 }}></Avatar> : 
                <Avatar className={classes.usesAuth} alt={`${Adviser.FirstName} ${Adviser.LastName}`} style={{ marginLeft: 8 }}>{`${Adviser.FirstName.charAt(0)}${Adviser.LastName.charAt(0)}`}</Avatar>
              }
            </Grid>
            <Grid item>
              <Typography className={classes.userName} variant="body2" >
                {Adviser.FirstName.toLowerCase()} {Adviser.LastName.toLowerCase()} 
              </Typography>
              <Typography variant="body2">
                {Adviser.RegistrationCode}
              </Typography>
            </Grid>
          </Grid>
          </Paper>
          </Box> 
          <Divider />
          </>
      }
      
      <List component="nav" className={classes.list} disablePadding>

        { userProfile &&
          <ListItem button onClick={this.handleGiftCardOpen}>
            <ListItemIcon className={classes.listItemIcon}><RedeemIcon /></ListItemIcon>
            <ListItemText primary={t("common.GiftCode")}/>
        </ListItem>        
        }

        {            
            LeftNavItems.map((item) => {
              return (
                // Top level              
                 item.children.length === 0 ? (
                  <ListItem button key={item.id} component={Link} to={item.href}
                    onClick={this.toggleDrawer("left", false)}
                    onKeyDown={this.toggleDrawer("left", false)}
                  >
                    <ListItemIcon className={classes.listItemIcon}><Icon fontSize="small">{item.icon}</Icon></ListItemIcon>
                    <ListItemText primary={item.title} />
                  </ListItem>
                ) : (
                    <React.Fragment key={item.id}>
                    <ListItem button key={item.id} onClick={this.handleClick.bind(this, item.id)} >
                      <ListItemIcon className={classes.listItemIcon}><Icon fontSize="small">{item.icon}</Icon></ListItemIcon>
                      <ListItemText primary={item.title} />
                      {this.state[item.id] ? <ExpandMore /> : <ChevronRight />}
                    </ListItem>
                    <Divider />
                    <Collapse in={this.state[item.id]} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {item.children.map((sitem) => {
                          return (
                            // Second level                            
                            sitem.children.length === 0 ? (
                              <ListItem key={sitem.id}  className={classes.nested} button target={sitem.target} component={Link} to={sitem.href} onClick={this.toggleDrawer("left", false)} onKeyDown={this.toggleDrawer("left", false)} >
                                <ListItemIcon className={classes.listItemIcon}><Icon fontSize="small">{sitem.icon}</Icon></ListItemIcon>
                                <ListItemText key={sitem.id} primary={sitem.title} />
                              </ListItem> 
                            ) : (                              
                              <React.Fragment key={sitem.id}>
                                <ListItem key={sitem.id} className={classes.nested} button onClick={this.handleClick.bind(this, sitem.id)} >
                                  <ListItemIcon className={classes.listItemIcon}><Icon fontSize="small">{sitem.icon}</Icon></ListItemIcon>
                                  <ListItemText primary={sitem.title} />
                                  {this.state[sitem.id] ? <ExpandMore /> : <ChevronRight />}
                                </ListItem>
                                {/* <Divider /> */}
                                <Collapse in={this.state[sitem.id]} timeout="auto" unmountOnExit>
                                  <List component="div" disablePadding>
                                    {sitem.children.map((titem) => {
                                      return (
                                        // Third level  
                                        <ListItem key={titem.id} className={classes.nestedThird} button  target={titem.target} component={Link} to={titem.href} onClick={this.toggleDrawer("left", false)} onKeyDown={this.toggleDrawer("left", false)}>
                                          <ListItemIcon className={classes.listItemIcon}><Icon fontSize="small">{titem.icon}</Icon></ListItemIcon>
                                          <ListItemText key={titem.id} primary={titem.title} />
                                        </ListItem> 
                                      )})}
                                  </List>
                                </Collapse>
                              </React.Fragment>
                            )                            
                          )
                        })}
                      </List>
                    </Collapse>
                    </React.Fragment>                  
                )
                
            )})      
        }
        </List>
      
    </SwipeableDrawer>         
    );

    const renderBigMenu = (
      <BigMenu items={this.props.GroupNavItems}></BigMenu>
    );
    
    const renderSearchBox = (
      <Grid container direction="row" justify="center" alignItems="center" >
        <Grid item xs={8}>
          <Paper component="form" className={classes.searchbox} elevation={0}  style={{marginLeft: 16}}>            
            <InputBase
              value={search}                
              onChange={e => this.handleChangeSearch(e)}
              onKeyDown={e => this.keyPressSearch(e) }
              className={classes.input}
              placeholder={t("common.SearchProducts")}
              inputProps={{ "aria-label": t("common.SearchProducts") }}
            />                
          </Paper>
        </Grid>
        <Grid item xs={4} style={{textAlign: "right"}}>
          
            <IconButton type="submit"
              size="small"
              aria-label="search" 
              onClick={this.handleSearch}
              color="inherit"
              >
              <SearchIcon />
            </IconButton>

            <IconButton className={classes.buttons} role="button" aria-label="close search" onClick={this.handleCloseSearchBox} color="inherit">
              <CloseIcon />
            </IconButton>
          
        </Grid>
      </Grid>      
    );

    const renderMenuLang = (      
      <Menu
        anchorEl={anchorElLang}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuLangOpen}
        onClose={this.handleMenuClose}
      >
          {Langs.map(lang => {
            return (
              <MenuItem 
                key={lang.Name}
                selected={lang.Active === this.DefaultLang} 
                onClick= {() => this.handleChangeLang(lang.TwoLetterISOLanguageName)}  
              >
                {lang.NativeName}
              </MenuItem>
            );                   
          })}
      </Menu>
    );

    return (      
        <div className={classes.root}>
        <AppBar 
          position="static" 
          color="inherit" elevation={0}>          
            { this.props.TopLine && 
              <Typography className={classes.topLine} align="center" display="block" component="div" variant="body2" noWrap>            
                  <div className={classes.topLineTxt}  dangerouslySetInnerHTML={{__html: this.props.TopLine.LocalValues[0].Value  }}></div>
              </Typography>
            }
            { /* Header toolbar */}        
            <Grid  container direction="row" justify="flex-start" alignItems="center" >
              <Grid item xs={4}>
                <Toolbar variant= {width === "xs" ? "dense" : "regular"  } className={classes.toolbar}>
                  <IconButton name="bnt-drawer" onClick={this.toggleDrawer("left", true)} className={classes.menuButton} color="inherit" aria-label="Open drawer">
                    <MenuIcon />
                  </IconButton>
                  {/* Section Mobile */}
                  <div className={classes.sectionMobile}>
                    <IconButton aria-label="search" color="inherit" style={{padding: 4}} onClick={this.handleToggleSearchBox} >
                      <SearchIcon />
                    </IconButton>
                  </div>
                  {/* Search section Desktop */}
                  <div className={classes.sectionDesktop}>
                    {/* Search box */}
                    <Paper component="form"  className={classes.searchbox} elevation={0} style={{width: 300}}>
                      <InputBase
                        value={search}                
                        onChange={e => this.handleChangeSearch(e)}
                        onKeyDown={e => this.keyPressSearch(e) }
                        className={classes.input}
                        placeholder={t("common.SearchProducts")}
                        inputProps={{ "aria-label": t("common.SearchProducts") }}
                      />
                      {/* <Divider className={classes.divider} orientation="vertical" />  */}
                      <IconButton type="submit" style={{paddingLeft: 8}}
                        size="small"
                        className={classes.iconButton} 
                        aria-label="search" 
                        onClick={this.handleSearch}
                        >
                        <SearchIcon />
                      </IconButton> 
                    </Paper>
                  </div>
                </Toolbar>
              </Grid>
              <Grid item xs={4}>                
                  <div style={{textAlign: "center"}}>
                    <Typography component={Link} to={"/"}>
                        <img className={classes.logo} alt="Jerelia" src={`assets/${logo}`} />
                    </Typography>                
                  </div>                
              </Grid>
              <Grid item xs={4}>
                <Toolbar variant= {width === "xs" ? "dense" : "regular"  } className={classes.toolbar}>
                  <div className={classes.grow}></div>
                  {/* Section Desktop */}
                  <div className={classes.sectionDesktop}>
                    { userProfile &&
                      <IconButton role="button" aria-label="Gift card" name="giftcardbutton" aria-haspopup="true" onClick={this.handleGiftCardOpen} color="inherit">
                        <RedeemIcon />
                      </IconButton>
                    }
                  </div>
                  <Avatar size="small" className={ Adviser ? classes.userAvatar: classes.userAnonymus} >
                    <IconButton ref={this.accountRef} color="inherit"              
                      aria-controls={accountOpen ? "menu-list-grow" : undefined}
                      aria-haspopup="true"
                      onClick={this.handleToggle}>
                        {/* { Adviser ? <AccountCircleOutlinedIcon /> : <PermIdentityIcon /> } */}
                        { Adviser  ? 
                          ( Adviser.Avatar && Adviser.Avatar.length > 0 ? <img alt={Adviser.RegistrationCode} title={Adviser.RegistrationCode} className={classes.imgAvatar} src={Adviser.Avatar} /> : <small style={{fontSize: 14}}>{`${Adviser.FirstName.charAt(0)}${Adviser.LastName.charAt(0)}`}</small>)
                          : <PermIdentityIcon /> }
                    </IconButton>
                  </Avatar>
                  {/* User basket */}
                  <Basket /> 
                </Toolbar>
              </Grid>
            </Grid>                                                                                                          
          
            {/* User menu */}
            <Popper open={accountOpen} placement="bottom-end" 
                        anchorEl={this.accountRef.current} role={undefined} 
                        transition disablePortal style={{ zIndex: 2}}                
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{ transformOrigin: placement === "bottom" ? "end top" : "end bottom" }}
                    >
                      <Paper elevation={6} style={{padding: "0 4px"}}>
                        <ClickAwayListener onClickAway={this.handleClose}>                        
                            { Adviser ?
                              <Box p={1}>
                                <Typography variant="body2" align="center">
                                  {Adviser.RegistrationCode}
                                </Typography>
                                
                                <Typography className={classes.userName} variant="body2" align="center">
                                  {Adviser.FirstName.toLowerCase()} {Adviser.LastName.toLowerCase()} 
                                </Typography>

                                <MenuList autoFocusItem={accountOpen} id="menu-list-grow" onKeyDown={this.handleListKeyDown}>
                                  <MenuItem to="/profile" component={Link} onClick={this.handleClose}>
                                    <ListItemIcon>
                                      <PermContactCalendarOutlinedIcon />
                                    </ListItemIcon> 
                                    {t("appbar.menu.Profile")}
                                  </MenuItem>
                                  <Divider />
                                  <MenuItem onClick={this.handleLogout}>
                                    <ListItemIcon><ExitToAppSharpIcon /></ListItemIcon>
                                    {t("appbar.menu.Logout")}
                                  </MenuItem>
                                </MenuList>
                                </Box>
                              :
                              <MenuList autoFocusItem={accountOpen} id="menu-list-grow" onKeyDown={this.handleListKeyDown}>
                                <MenuItem component={Link} to="/register" onClick={this.handleClose}>
                                  <ListItemIcon><PersonAddOutlinedIcon /></ListItemIcon>
                                  {t("appbar.menu.Register")}
                                </MenuItem>
                                <Divider />
                                <MenuItem component={Link} to="/signin">
                                  <ListItemIcon><PermIdentityIcon /></ListItemIcon>                          
                                    {t("appbar.menu.Login")}
                                </MenuItem>
                              </MenuList>
                            }

                            {/* <MenuItem onClick={this.handleClose}>Profile</MenuItem>                    
                            <MenuItem onClick={this.handleClose}>Logout</MenuItem> */}                  
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>

          <Hidden smUp>
            <Collapse in={showSearchBox} timeout="auto" >
              <Divider />
              { renderSearchBox }              
              <Divider />
            </Collapse>
          </Hidden>

          {/* Product group menu */}
          {location.pathname.toLowerCase() === "/register" ? (null): ( renderBigMenu )}
        </AppBar>
        
        {renderMenuLang}

        {/* {renderMenu}        
        {renderMenuChat}
        {renderMobileMenu} */}

        {leftsideList}      
                
      <Snackbar open={snackbarShow} autoHideDuration={ variant ==="error" ? 20000 : 3000} 
                    onClose={this.props.hideSnackBar} 
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}>
            <Alert onClose={this.props.hideSnackBar} severity={variant}>
              <div style={{whiteSpace: "pre-line"}}>
                {snackbarTxt}
              </div>              
            </Alert>
        </Snackbar>            

      <Dialog 
        open={ showDlgGiftCard }
        fullWidth={true}
        onClose={()=> { this.props.showDlgGift(false) } } 
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t("appbar.menu.CertificateCode")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Введіть код подарункового сертифіката або промо карти.
          </DialogContentText>
          <TextField
            autoFocus
            variant="outlined"
            
            id="CertificateCode"
            name="CertificateCode"
            label={t("appbar.menu.CertificateCodeInput")}
            type="text"
            fullWidth
            onChange={e => this.handleChange(e)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.ApplayCertificateCode } color="primary">
            {t("buttons.Applay")}
          </Button>
          <Button onClick={() => { this.props.showDlgGift(false) }  } color="primary">
          {t("buttons.Cancel")}
          </Button>
          
        </DialogActions>
      </Dialog>        

      </div>
      
    );
  }
}

NavMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTranslation()( withWidth() (withRouter(withStyles(styles)(connect(
  state => state.appLayout,
  dispatch => bindActionCreators(actionCreators, dispatch)
)(NavMenu)))));
