import React, { Component } from "react"
import {withRouter} from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionOrderCreators } from "../../store/Order";
import {withStyles} from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { OidcUserManager } from "../../helpers/OidcSettings";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
// import {Helmet} from "react-helmet";


const styles = theme => ({
    root: {
        flexGrow: 1,        
        padding: theme.spacing(1),
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(0),
          },
    },
    progress: {
        height: 2,
        position: "absolute",
        top: 0,
        left: 0,
        right:0,
    },  
    linearColorPrimary: {
        backgroundColor: "#abdcda",
    },
    linearBarColorPrimary: {
        backgroundColor: "#d20000",
    },
    paper: {
        padding: theme.spacing(1),
        margin: theme.spacing(1),
        
    },
    
    line: {
        // padding: theme.spacing(1),
        marginTop: theme.spacing(2),
    },
    actionsContainer: {
        margin: theme.spacing(1, 1),
    },
    
    liqpay:{
        minWidth: 360,
        [theme.breakpoints.down("xs")]: {            
            margin: theme.spacing( 0, -1, 0 -1),
          },
    },
    btnSuccess: {
        marginRight: theme.spacing(1),
        color: "#fff",
        backgroundColor: theme.palette.success.main,
        "&:hover":{
          backgroundColor: theme.palette.success.dark,
        },      
      },
      boxgoto: {
          padding: theme.spacing(2, 0),
          margin: theme.spacing(2, 0),
      },
      goto: {
          marginRight: theme.spacing(1),
      },

});

class Recharge extends Component {
    constructor(props){
        super(props);
        this.state = {
            opened: true,
            isLoading: false,
            amount: parseFloat(100).toFixed(2),
            activeStep: 0,
            showSuccess: false,
            data: null,
            hidden: false,
            progress: false,
        }; 
    }

    componentDidMount() {     
        this.loadBalance();
    }
    
    loadBalance = async () => {         
        const self = this;
        const userManager = await OidcUserManager();

        userManager.getUser().then(user => {
            let token = null;

            if (user != null) {
                token =  `Bearer ${user.access_token}`;
            }

            this.setState({isLoading: true});
            const URL =  "/api/v1.0/balance/total";
            axios.get(URL,  
                { headers: { Authorization: token } }
            )                
            .then(function(response){                                    
                self.setState({ data: response.data, isLoading: false });                
                // console.log("Load balance", response.data); 
            })
            .catch((error) => {        
                this.setState({isLoading: false});
                console.log(error);
            });                
            
        });
    };

    handleChangeAmount = (e) => {
        let amount = parseFloat(e.target.value).toFixed(2);
        if (isNaN(amount)){
            amount = parseFloat(1).toFixed(2);
        }                 
        this.setState({ amount: amount });
    };

    handleClickRecharge = async () => {
        const {width} = this.props;        
        const self = this;
        const userManager = await OidcUserManager();

        userManager.getUser().then(user => {
            let token = null;

            if (user != null) {
                token =  `Bearer ${user.access_token}`;
            }

            this.setState({isLoading: true});
            const URL =  `/api/v1.0/liqpay/recharge?amount=${this.state.amount}`; 
            
            axios.get(URL,  
                { headers: { Authorization: token } }
            )                
            .then(function(response){                
                self.setState({isLoading: false});
                
                // 392console.log("Recharge balance", response.data);
                                
                window.LiqPayCheckout.init({
                    data: response.data.Data,
                    signature: response.data.Signature,
                    embedTo: "#liqpay_checkout",
                    mode: "embed", // embed || popup
                    language: response.data.Language
                }).on("liqpay.callback", function (data) {                                                            
                    console.log("liqpay.callback data", data);
                    if(data){
                        const formData = new FormData();
                        formData.append("data", data.data);
                        formData.append("signature", data.signature);
                        axios.post(
                            "/api/v1.0/liqpay/callback",
                            formData,
                            { headers: { 
                                Authorization: token },
                                "Content-Type": "multipart/form-data"
                            }
                        )
                        .then(function(result){
                            console.log("liqpay.callback RESULT", result);
                            if(result.status === 200){
                                self.setState({showSuccess: true });
                            }                        
                        })
                        .catch((error) => {
                            // Error 😨
                            if (error.response) {
                                /*
                                * The request was made and the server responded with a
                                * status code that falls out of the range of 2xx
                                */
                                console.log("error.response.data", error.response.data);
                                console.log("error.response.status", error.response.status);
                                console.log("error.response.headers", error.response.headers);
                            } else if (error.request) {
                                /*
                                * The request was made but no response was received, `error.request`
                                * is an instance of XMLHttpRequest in the browser and an instance
                                * of http.ClientRequest in Node.js
                                */
                                console.log("error.request", error.request);
                            } else {
                                // Something happened in setting up the request and triggered an Error
                                console.log("error.message", error.message);
                            }
                            console.log("error.config",error.config);
                        });
                    }
                }).on("liqpay.ready", function (data) {
                    console.log("!!! LIQPAY READY", data); 
                    self.setState({progress: false}); 
                    if(width === "xs")                                                                                  
                        window.scrollTo({top: 250, behavior: "smooth"});
                    else
                        window.scrollTo({top: 170, behavior: "smooth"});

                }).on("liqpay.close", function (data) {                    
                    console.log("liqpay.close", data);
                });

            })
            .catch((error) => {        
                this.setState({isLoading: false});
                console.log(error);
            });                            
        });
    };

    handleNext = async () => {        
        this.setState((state) => ({
            hidden: !state.hidden,
            showSuccess: false,
            progress: true,
        }));
          await this.handleClickRecharge();
    };
    
    handleBack = () => {
        this.setState((state) => ({
            activeStep: state.activeStep - 1,
            showSuccess: true
          }));        
    };

    handleStep = (idx) => {
        this.setState(() => ({
            activeStep: idx
          }));        
    };

    render() {
        const { classes, width, t } = this.props;
        const { isLoading, opened, amount, showSuccess, data, progress } = this.state;

        return (
            <div className={classes.root}>
                {/* <div>                            
                    <Helmet>
                        <script src="//static.liqpay.ua/libjs/checkout.js" type="text/javascript" />
                    </Helmet>
                </div> */}
                { isLoading && 
                    <LinearProgress className={classes.progress} 
                    classes={{
                        colorPrimary: classes.linearColorPrimary,
                        barColorPrimary: classes.linearBarColorPrimary,
                    }}
                />
                }

                        <Paper className={classes.paper} elevation={width !== "xs" ? 1 : 0}>                        
                            <Typography variant="h5" align="center" >
                                {t("pay.Recharge")}: {amount}
                            </Typography>
                            
                            {data && 
                                <div style={{marginBottom: 16}}> 
                                    <Typography variant="caption" display="block" align="center">
                                        {`${data.Adviser.RegistrationCode} ${data.Adviser.Name}`}
                                    </Typography>
                                    <Typography variant="caption" display="block" align="center" gutterBottom>
                                        {t("balance.Balance")}: {t("balance.Amount")}: <b>{parseFloat(data.Balance.Amount).toFixed(2)}</b>, {t("balance.AmountOB")}: <b>{parseFloat(data.Balance.AmountOB).toFixed(2)}</b>
                                    </Typography>                                    
                                                                    
                                </div>    
                            }
                            { progress && 
                                <Grid spacing={2}  container direction="row" justify="center" alignItems="center" >
                                    <Grid item xs={12} sm={6} md={4}>
                                        <LinearProgress />                                                                                    
                                        <Typography>Loading... Liqpay</Typography>                                        
                                    </Grid>
                                </Grid>                                
                            }

                            { opened && 
                                <React.Fragment>
                                { !this.state.hidden &&
                                <Grid spacing={2}  container direction="row" justify="center" alignItems="center" >
                                    <Grid item xs={7} sm={3} md={2}>
                                        <FormControl fullWidth  variant="outlined">
                                            <InputLabel htmlFor="outlined-adornment-amount">{t("pay.Amount")} </InputLabel>
                                                <OutlinedInput
                                                margin="dense"
                                                type="number"
                                                id="outlined-adornment-amount"
                                                value={amount}
                                                inputProps={{ min: 1 }}
                                                onChange={ (e) => this.handleChangeAmount(e)}
                                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                labelWidth={60}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4} sm={2}>                                    
                                        <Button size="large"  fullWidth variant="contained" color="primary" onClick={this.handleNext}>
                                            {t("pay.Next")}
                                        </Button>                                    
                                    </Grid>
                                </Grid>
                                }
                                
                                <Grid spacing={0}  container direction="row" justify="center" alignItems="center" >
                                    <Grid item >
                                        {/* LIQPAY: Платежный виджет */}
                                        <div className={classes.liqpay} id="liqpay_checkout"></div>
                                    </Grid>                                    
                                </Grid>
                                {
                                   showSuccess &&                                    
                                    <Grid container direction="row" justify="center" alignItems="center" >
                                        <Grid item style={{textAlign: "center"}}> 
                                            <Button className={classes.goto} color="primary" onClick={() => {this.props.history.push("/order");}} >
                                                {t("pay.GoToOrder")}
                                            </Button>                                        
                                        </Grid> 
                                        <Grid item style={{textAlign: "center"}}> 
                                            <Button className={classes.goto} color="primary" onClick={() => {this.props.history.push("/orders");}} >
                                                {t("pay.GoToOrders")}
                                            </Button>
                                        </Grid>
                                    </Grid>                                   
                                }
                            </React.Fragment>
                            }                                                                                                        
                        </Paper>

            </div>
        )
    }
}

export default withTranslation()(withWidth() (withRouter( withStyles(styles)(
    connect(    
        state => state.orderRequest,
        dispatch => bindActionCreators(actionOrderCreators, dispatch)
    )(Recharge)
))));