import React from "react";
import {withRouter} from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionOrderCreators } from "../../store/Order";
import { connect } from "react-redux";
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import withWidth from "@material-ui/core/withWidth";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import AccessTime from "@material-ui/icons/AccessTime";
import AddShoppingCard from "@material-ui/icons/AddShoppingCart";
import { fade } from "@material-ui/core/styles/colorManipulator";
import Button from "@material-ui/core/Button";
import { withTranslation } from "react-i18next";
import Badge from "@material-ui/core/Badge";
import red from "@material-ui/core/colors/red";
import blue from "@material-ui/core/colors/blue";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import BrightnessAutoIcon from "@material-ui/icons/BrightnessAuto";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = theme => ({
    root: {
        display: "flex",
        // padding: theme.spacing(1),
      },
      details: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
      },
      content: {        
        flex: "1 0 auto",
        padding: 4,     
        // height: 160,
        [theme.breakpoints.down("sm")]: {
            // height: 175,
        },
      },
      actcover:{
        width: 148,
      },
      cover: {
        width: 128,
        height: 183,
        // maxWidth: "100%",
        backgroundSize: "contain",
        backgroundColor: fade(theme.palette.common.black, 0.03),    
        "&:hover": {
          backgroundColor: fade(theme.palette.common.black, 0.04),
        },
        // [theme.breakpoints.down("sm")]: {
        //     height: 96,
        // },
      },
      specialprice: {
        position: "absolute",
        top: theme.spacing(1),
        right: theme.spacing(1),
    },
    burst: {
        width: 60,
        padding: theme.spacing(1),
        position: "absolute",
        top: 45,
        right: 20,  
    },
    specialcomment: {
        padding: theme.spacing(1),
        position: "absolute",
        top: 12,
        right: -8,
        fontWeight: "bold",
        fontSize: "1.3rem",
        color: "#fff"
    },

      seria: {
        textTransform: "uppercase",
        fontSize: ".9em",
    },
    code: {        
    },
    name: {
        paddingTop: 4,                 
        overflow: "hidden",
        height: 68,
    },
    actions: {
        paddingTop: 0,
        paddingBottom: theme.spacing(1),
    },
    redprice: {
        color: "#ff0000",
        fontSize: "1.1em",
        marginRight: theme.spacing(1),
    },
    priceOld: {
        textDecoration: "line-through",        
        color: "#8a8a8a",        
    },
    price: {
        marginRight: theme.spacing(1) * 2,
    },
    colorRed: {
        color: red[500],
        fontSize: "1.2rem"
    },
    colorBlue: {
        color: blue[500],
      },
    blabel: {        
        [theme.breakpoints.down("xs")]: {
          display: "none",
        },
      },      
      grayscale: {        
        webkitFilter: "opacity(30%) grayscale(100%)",
        filter: "opacity(30%) grayscale(100%)",

        "&:hover": {
            webkitFilter: "none",
            filter: "none",
          },
    },
    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(1),        
      }  
});

// const ProductLink = props => <RouterLink {...props} />

class CatalogItemList extends React.Component {  
    constructor(props) {
        super(props);
        this.state = {
            // eslint-disable-next-line no-undef
            cdn: `${process.env.REACT_APP_CDN}Pictures/`,
            imgLgSize: "392x392",
            imgMdSize: "183x183",
            imgXsSize: "76x76",
            expanded: false,
            ProductId: null,
        };
    }

    SpecialPriceLabel = (value) => {
        const { classes } = this.props;        
        switch (value) {
            case 0:
                return  <Avatar title="Wow ціна" className={classes.specialprice} style={{backgroundColor: red[500] }}  aria-label="recipe"><NewReleasesIcon /></Avatar>  // "Wow ціна";
            case 1:
                return <Avatar  title="Акція" className={classes.specialprice} style={{backgroundColor: blue[500] }} aria-label="recipe"><BrightnessAutoIcon /></Avatar>  //"Акція";    
            default:
                return null;    
        }
    }

    AvailableCSS = (value) =>{
        const { classes} = this.props;
        if(value){
            return "";
        }
        else{
           return classes.grayscale;
        }
    }
    handleAddToOrder = (e, pos) => {
        const {disabled} = this.props;
        if(disabled === true)
            return;
        this.setState({ProductId: pos.ProductId});         
        this.props.actions.addToOrder([ { pos: pos, qty: 1, orderrowtype: 0}] )        
    }

    render(){
        const {item, classes, IsAuthenticated, disabled  } = this.props;        
        const { ProductId, cdn } = this.state;
        return(
            <Card className={classes.root} >
                <CardActionArea className={classes.actcover}  onClick = {() => this.props.history.push(`/product/${item.Code}`)} >                    
                    <CardMedia                                        
                        className={clsx(classes.cover, this.AvailableCSS(item.Available))}
                        image={`${cdn}${this.state.imgMdSize}/${item.Image}`}
                        title={item.LocalName}                        
                    />
                    
                </CardActionArea>
                <div className={classes.details}>
                    <CardContent className={classes.content}>
                        <CardActionArea>
                        {this.SpecialPriceLabel(item.SpecialPriceType)}                                
                        { item.Comment.length > 0  && 
                                    <div className={classes.burst}>
                                            <div className="burst-8">                                
                                            </div>                   
                                            <span className={classes.specialcomment}>{item.Comment}</span>     
                                        </div>                        
                        }
                        </CardActionArea>

                        <Grid container direction="row" justify="flex-start" alignItems="center" >
                            <Grid item xs={10}>
                                <small>{item.Code}</small>
                                <div className={classes.seria}>{item.Series}</div>                                    
                            </Grid>
                            <Grid item align="right" xs={2}>
                                
                            </Grid>
                        </Grid>                            
                            
                        <Typography className={classes.name} display="block" variant="body2">{item.LocalName}</Typography>                                          
                    </CardContent>

                

                <CardActions className={classes.actions}>
                    <Grid container direction="row" spacing={2} justify="flex-start" alignItems="center" >
                        {IsAuthenticated ?                         
                        <Grid item xs={8}  >
                            <Badge color="error" variant="dot" className={classes.price} >
                                <Typography variant="body1" className={classes.colorRed}>
                                    {parseFloat(item.SellingPrice).toFixed(2)}
                                </Typography>
                            </Badge>
                            <Badge className={classes.price} badgeContent={0}>
                                <Typography variant="body1" >
                                    <span className="price--line-through text-gray">{parseFloat(item.Price).toFixed(2)}</span>&nbsp;
                                    
                                    {item.CatalogPricePv > 0.01 ? <small className={classes.colorBlue}>{parseFloat(item.CatalogPricePv).toFixed(1)}</small> : null}
                                </Typography>
                            </Badge> 
                        </Grid> :                        
                         <Grid item xs={8}  >
                            <Badge color="error" variant="dot" className={classes.price} >
                                <Typography variant="body1" className={classes.colorRed}>
                                    {parseFloat(item.Price).toFixed(2)}
                                </Typography>
                            </Badge>
                            { item.OldPrice > 0 &&
                                <Badge className="price--line-through text-gray" badgeContent={0}>
                                    <Typography  >
                                        {parseFloat(item.OldPrice).toFixed(2)} 
                                    </Typography>                                
                                </Badge>
                                }



                         </Grid>
                        }
                        <Grid item xs={4}>
                            <Button color="primary" size="small"  fullWidth disabled={item.FreeRest <= 0  }
                                onClick={(e) => this.handleAddToOrder(e, item)}
                            >
                                { item.FreeRest <= 0 ? <AccessTime /> : 
                                      (disabled && item.ProductId === ProductId ? <CircularProgress  className={classes.circularProgress} size={24}/> :  <AddShoppingCard /> )
                                }
                                
                            </Button>

                        </Grid>
                    </Grid>
                </CardActions>

                </div>
            </Card>
        );
    }
}

export default withTranslation()(withWidth()(withRouter( withStyles(styles)(
    connect(    
        state => state.orderRequest,
        dispatch => {
            return {
               actions: bindActionCreators(Object.assign({}, actionOrderCreators), dispatch)
        }}
    )(CatalogItemList)
))));
