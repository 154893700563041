import React from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";

import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import { fade } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
// import Checkbox from "@material-ui/core/Checkbox";
import CachedIcon from "@material-ui/icons/Cached";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
// import FilterListIcon from "@material-ui/icons/FilterList";
import Grid from "@material-ui/core/Grid";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import moment from "moment";
import {Link } from "react-router-dom";


import { actionCreators } from "../../store/ListContents";

//let counter = 0;
// function createData(name, calories, fat, carbs, protein) {
//   counter += 1;
//   return { id: counter, name, calories, fat, carbs, protein };
// }

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {  
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc" ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: "name", numeric: false, disablePadding: false, label: "Name of content" },
  { id: "key", numeric: false, disablePadding: false, label: "Friendly url" },
  { id: "fat", numeric: false, disablePadding: false, label: "Started On" },
  { id: "carbs", numeric: false, disablePadding: false, label: "Finished On" },
  { id: "protein", numeric: false, disablePadding: false, label: "Action" },
];

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    // const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;
    const { order, orderBy } = this.props;
    return (

      <TableHead>
        <TableRow>
          {/* <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell> */}
          {rows.map(row => {
            return (
              <TableCell
                key={row.id}
                align="left"
                padding={row.disablePadding ? "none" : "default"}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 20%",
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: "0 0 auto",
  },
});


let EnhancedTableToolbar = props => {
  const { numSelected, classes, contenttype } = props;
  
  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            {contenttype}
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        <Tooltip title={`Add new ${contenttype}`}>
            <IconButton color="primary" aria-label="Add" component={Link} to={ `/contenteditor/${contenttype}/new`} >
              <AddIcon />
            </IconButton>
          </Tooltip>
        {numSelected > 0 ? 
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
         :  null
          // <Tooltip title="Filter list">
          //   <IconButton aria-label="Filter list">
          //     <FilterListIcon />
          //   </IconButton>
          // </Tooltip>
        }
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  contenttype: PropTypes.string.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    flexGrow: 1,      
    padding: theme.spacing(1),
  },
  appbar: {
    marginBottom: theme.spacing(1),
  },
  grow: {
    flexGrow: 1,
  },

  table: {
    minWidth: 1020,
  },

  tableWrapper: {
    overflowX: "auto",
  },
  title: {
    textTransform: "capitalize",
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },

  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,    
    border: "1px solid #e8e8e8",
    backgroundColor: fade(theme.palette.common.white, 0.75),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 1),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),    
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  
});

class ListContent extends React.Component {

  constructor(props) {
    super(props);
      const page = parseInt(this.props.match.params.page, 10) || 0;   
      const query =  this.props.match.params.query || "";
      const contenttype = this.props.match.params.contenttype || "page" ;

      this.state = {
        order: "desc",
        orderBy: "Id",
        selected: [],
        contenttype: contenttype,        
        page: page,
        query: query,
        rowsPerPage: 10,
      };
      // this.props.requestListContents(indexPage, 10, query, contenttype);
  }

  loadData = (query, contenttype, page, rowsPerPage) => {     
    this.props.requestListContents(page, rowsPerPage, query, contenttype);
  };

  runSearch = () => {
    const { query, contenttype, page, rowsPerPage } = this.state;
    this.loadData(query, contenttype, page, rowsPerPage);
  };

  clearSearch = () => {
    this.setState({ query: "", page: 0});
    const { contenttype,  rowsPerPage } = this.state;
    this.loadData("", contenttype, 0, rowsPerPage);
    // this.runSearch();
  };

  componentDidMount() {
    this.runSearch();
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.match.params.contenttype !== prevState.contenttype || nextProps.match.params.indexPage !== prevState.indexPage){
      return { contenttype: nextProps.match.params.contenttype, indexPage: (nextProps.match.params.indexPage || 1) };
    }
    else return null;
  }         

  componentDidUpdate(prevProps, prevState) {
    if (prevState.contenttype !== this.state.contenttype || prevState.indexPage !== this.state.indexPage  ) {
      console.log(this.state.indexPage, this.state.contenttype);
      this.props.requestListContents( this.state.indexPage || 1,  this.state.rowsPerPage || 10, "",  this.state.contenttype || "page");      
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    const {Data} = this.props;
    if (event.target.checked) {
      this.setState(() => ({
        selected: Data.map(n => n.id)
      }));
      // this.setState(state => ({ selected: Data.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (id) => {    
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {    
    const { query, contenttype, rowsPerPage } = this.state;        
    this.setState({ page: page });
    this.loadData(query, contenttype, page, rowsPerPage);    
  };

  handleChangeRowsPerPage = event => {    
    this.setState({ rowsPerPage: event.target.value });
    this.loadData();
    //this.props.requestListContents( this.state.indexPage || 1,  event.target.value || 10, "",  this.state.contenttype || "page");
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  renderTableBody = () => {
    const {Data} = this.props;
    const { order, orderBy, contenttype} = this.state;
      // const emptyRows = rowsPerPage - Math.min(rowsPerPage, Data.length - page * rowsPerPage);
    
    return(      
      <TableBody>  
      {stableSort(Data, getSorting(order, orderBy))
        // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map(n => {
          const isSelected = this.isSelected(n.Id);
          return (
            <TableRow
              hover
              onClick={() => this.handleClick(n.Id)}
              role="checkbox"
              aria-checked={isSelected}
              tabIndex={-1}
              key={n.Id}
              selected={isSelected}
            >
              {/* <TableCell padding="checkbox">
                <Checkbox checked={isSelected} />
              </TableCell> */}
              <TableCell component="th" scope="row">{n.Name}</TableCell>
              <TableCell>{n.Key}</TableCell>
              <TableCell>{moment(n.StartedOn).format("YYYY-MM-DD kk:mm:ss")}</TableCell>
              <TableCell>{moment(n.FinishedOn).format("YYYY-MM-DD kk:mm:ss")}</TableCell>
              <TableCell>
                <IconButton color="primary" aria-label="Edit" component={Link} to={ `/contenteditor/${contenttype}/${n.Key}`}>
                  <EditIcon />
                </IconButton>                
              </TableCell>
            </TableRow>
          );
        })} 
      
      {/* {emptyRows > 0 && (
        <TableRow style={{ height: 49 * emptyRows }}>
          <TableCell colSpan={6} />
        </TableRow>
      )} */}

    </TableBody>      
    );
  }
  
  handleChangeSearch = e =>{
    this.setState({ query: e.target.value });    
  };

  keyPressSearch = e => {
    if (e.key === "Enter") {      
      this.runSearch();  
      e.preventDefault();
    }
  };
  
  render() {
    const { classes, TotalCount } = this.props;
    const { order, orderBy, selected, rowsPerPage, page, query, contenttype } = this.state;


    return (
      <div className={classes.root}>
      
      <AppBar color="default" className={classes.appbar} position="static" elevation={1}>
        <Toolbar>
          
          <Typography className={classes.title} variant="h6" noWrap>
          {contenttype}
          </Typography>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
              value={query}
              onChange={e => this.handleChangeSearch(e)}
              onKeyDown={e => this.keyPressSearch(e) }
            />
          </div>
          <Button startIcon={<SearchIcon />} color="inherit" onClick={this.runSearch}>Search</Button>
          <Button startIcon={<CachedIcon />} color="inherit" onClick={this.clearSearch}>Clear</Button>
          <div className={classes.grow} />
          
          <div className={classes.sectionDesktop}> 
          
            <Button startIcon={<AddIcon />} component={Link} to={ `/contenteditor/${contenttype}`} color="inherit">{`Add new ${contenttype}` }</Button>
          </div>
          
        </Toolbar>
      </AppBar>

      <Grid container spacing={1} direction="row" justify="center">
        <Grid item xs={12}>
          <Paper>
            {/* <EnhancedTableToolbar numSelected={selected.length} contenttype={this.state.contenttype} /> */}

            <div className={classes.tableWrapper}>
              <Table className={classes.table} aria-labelledby="tableTitle">
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={this.handleSelectAllClick}
                  onRequestSort={this.handleRequestSort}
                  rowCount={this.props.Data.length}
                />
                
                {this.renderTableBody()}
              </Table>
            </div>

            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={TotalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                "aria-label": "Previous Page",
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page",
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>  
      </div>
    );
  }
}

ListContent.propTypes = {
  classes: PropTypes.object.isRequired,
};

// export default withStyles(styles)(ListContent);

export default withStyles(styles) (connect(
  state => state.listContents,
  dispatch => bindActionCreators(actionCreators, dispatch)
)(ListContent));
