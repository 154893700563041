import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import { bindActionCreators } from "redux";
import { actionPromotionItemCreators } from "../../store/Promotions";
import { withTranslation } from "react-i18next";


const styles = theme => ({
    appBar: {
        position: "relative",        
        backgroundColor: theme.palette.appbar.background,        
    },
    title: {
        flexGrow: 1,
    },
    paper:{
        padding: theme.spacing(1),
    },
    dialogcontent:{
        padding: theme.spacing(2),
        margin: theme.spacing(1),
    },
});
  

class Promotion extends Component {
    constructor(props) {
        super(props);        
        this.state = {            
            module: React.lazy(() => import(`../Promotions/${this.props.promotion.ModuleName}.jsx`)),
        };        
        // this.promotionRef = React.createRef();            
    }

    componentDidMount() {        
        // console.log("Module:", this.props.promotion.ModuleName);
        // import(`../Promotions/${this.props.promotion.ModuleName}.jsx`)
        //         .then(module => this.setState({ module: module.default }));
    }

    handleClickDlgClose = () => {
        this.props.actions.showDialog(false);
    };
  
    handleClickSavePromotion = ()  => {
        // this.promotionRef.current.Save();        
        this.child.Save();
    };

    render() {
        const { classes, promotion, t } = this.props;
        const { module: Component } = this.state;
        return (
            <div>                        
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Button
                            variant="outlined"
                            color="inherit"
                            onClick={this.handleClickSavePromotion}
                            className={classes.button}
                            startIcon={<SaveIcon />}
                        >
                            {t("buttons.Save")}
                        </Button>                       
                        <Typography variant="h6" className={classes.title}></Typography>

                        <IconButton
                            color="inherit"
                            onClick={this.handleClickDlgClose}
                            aria-label="Close">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Paper className={classes.dialogcontent}>
                { Component && 
                    <Component childRef={ref => (this.child = ref)} promotion={promotion}></Component>
                }
                </Paper>
        </div>
    );}
}

export default withTranslation()(withStyles(styles)(
    connect(        
        state => state.promotionItemsRequest,
        dispatch => {
            return {
               actions: bindActionCreators(Object.assign({}, actionPromotionItemCreators ), dispatch)
        }}        
    )(Promotion)
));