import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {withStyles} from "@material-ui/core/styles";
import BlockIcon from "@material-ui/icons/Block";
import gray from "@material-ui/core/colors/grey";

const styles = theme => ({
    card: {            
        height: "auto",
    },
    block: {        
        width: "100%",
        backgroundColor: gray[200],
        padding: "4px",
        marginTop: theme.spacing(2),
    },
    largeIcon: {
        margin: theme.spacing(1, 5),
        width: 128,
        height: 128,
        color: gray[200],
      },
});

class EmptyCard extends React.Component {  
    constructor(props) {
        super(props);
        this.state = { };
    }


    render(){
        const { classes } = this.props;
        return(
            <Card className={classes.card} >
                
                    <CardContent className={classes.cardcontent}>
                        <div className={classes.block}></div>                        
                        <div className={classes.block} style={{width: "50%"}} ></div>
                        <Typography align="center">
                            <BlockIcon className={classes.largeIcon} />
                        </Typography>
                    </CardContent>
                
            </Card>
        );
    }
}

export default withStyles(styles)(EmptyCard);
