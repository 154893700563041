import React, { Component } from "react";
import withWidth from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
    root: {
        // margin: "0px auto",  
        position: "relative",        
        paddingBottom: "56.25%" /* 16:9 */,        
        height: 0,
        
    },
    video: {
          position: "absolute",          
          // top: theme.spacing(0),
          // left: 0,
          paddingRight: theme.spacing(1),
          width: "100%",
          height: "100%",          
    }
  });

class Youtube extends Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        const { youtubeId, classes } = this.props;
        
        return (
            <div className={classes.root}>
                <iframe className={classes.video} title="Jerelia youtube video"
                    src={`https://www.youtube.com/embed/${youtubeId}?controls=1&fs=1&modestbranding=1&rel=0&enablejsapi=1&origin=https://jerelia.com&widget_referrer=https://jerelia.com`}
                    frameBorder="0"
                />
            </div>
        );
    }
}


export default  withWidth()(withStyles(styles, { withTheme: true })(Youtube));
// export default Youtube;