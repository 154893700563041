import React, { Component } from "react"
import {withRouter} from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionOrderCreators } from "../../store/Order";
import {withStyles} from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { OidcUserManager } from "../../helpers/OidcSettings";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
// import {Helmet} from "react-helmet";
// import { useHistory } from "react-router-dom";

const styles = theme => ({
    root: {
        flexGrow: 1,        
        padding: theme.spacing(1),
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(0),
          },
    },
    progress: {
        height: 2,
        position: "absolute",
        top: 0,
        left: 0,
        right:0,
    },  
    linearColorPrimary: {
        backgroundColor: "#abdcda",
    },
    linearBarColorPrimary: {
        backgroundColor: "#d20000",
    },
    paper: {
        padding: theme.spacing(1),
    },
    stepper: {
        padding: theme.spacing(1),
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(0),
          },
        margin: theme.spacing(0, 0, 4, 0),
    },
    line: {
        // padding: theme.spacing(1),
        marginTop: theme.spacing(2),
    },
    actionsContainer: {
        margin: theme.spacing(1, 1),
    },
    stepContentLiqpay: {
        marginLeft: 0,
        paddingLeft: 0,
        paddingRight: 0,
        minHeight: 280
    },
    liqpay:{
        minWidth: 360,
        [theme.breakpoints.down("xs")]: {
            margin: theme.spacing( 0, -1, 0 -1),
        },
    },
    btnSuccess: {
        marginRight: theme.spacing(1),
        color: "#fff",
        backgroundColor: theme.palette.success.main,
        "&:hover":{
          backgroundColor: theme.palette.success.dark,
        },      
      },
      boxgoto: {
          padding: theme.spacing(2, 0),
          margin: theme.spacing(2, 0),
      },
      goto: {
          marginRight: theme.spacing(1),
      }
});

class Pay extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoading: false,
            id: this.props.match.params.id,
            data: null,
            showSuccess: false,
            progress: true,
        }; 
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.match.params.id !== prevState.id){
          return { id: nextProps.match.params.key};
        }
        else return null;
    }

    componentDidUpdate(prevProps, prevState) {        
        if(this.state.id !==  prevState.id ){            
            this.loadPaymentData(this.state.id);
        }        
    }

    componentDidMount() {
        this.loadPaymentData(this.state.id);
    }

    loadPaymentData = async (id) => {
        const {width} = this.props;
        const self = this;

        const userManager = await OidcUserManager();

        userManager.getUser().then(user => {
            let token = null;
            let auth = "noreg";
            if (user !== null) {             
                token = `Bearer ${user.access_token}`;
                auth = "reg";
            }
                    
            this.setState({isLoading: true});

            const URL = `/api/v1.0/liqpay/payorder/${id}`;
            
            axios.get(URL,  
                { headers: { Authorization: token } }
            )                
            .then(function(response){                
                if(response.data.Order.Paid || response.data.Order.AmmoutPaymenet === 0){ 
                    // self.props.history.push(`/order/thanks/${response.data.Order.Id}`); 
                    self.props.history.push(`/orderfin/${auth}/pp/${response.data.Order.Id}`);
                } else {
                    console.log("DATA", response.data);
                    self.setState({ data: response.data, isLoading: false });                                         
                                        
                    if(response.data){
                        console.log("INIT LIQPAY PAYMENT", response.data); 
                        window.LiqPayCheckout.init({
                            data: response.data.Data,
                            signature: response.data.Signature,
                            embedTo: "#liqpay_checkout",
                            mode: "embed", // embed || popup
                            language: response.data.Language
                        }).on("liqpay.callback", function (data) {                            
                            console.log("liqpay.callback data", data);
                            if(data){
                                const formData = new FormData();
                                formData.append("data", data.data);
                                formData.append("signature", data.signature);
                                axios.post(
                                    "/api/v1.0/liqpay/callback",
                                    formData,
                                    { headers: { 
                                        Authorization: token },
                                        "Content-Type": "multipart/form-data"
                                    }
                                )
                                .then(function(result){
                                    console.log("liqpay.callback RESULT", result);
                                    if(result.status === 200){
                                        self.setState({showSuccess: true });
                                    }                                    
                                })
                                .catch((error) => {
                                    // Error 😨
                                    if (error.response) {
                                        /*
                                        * The request was made and the server responded with a
                                        * status code that falls out of the range of 2xx
                                        */
                                        console.log("error.response.data", error.response.data);
                                        console.log("error.response.status", error.response.status);
                                        console.log("error.response.headers", error.response.headers);
                                    } else if (error.request) {
                                        /*
                                        * The request was made but no response was received, `error.request`
                                        * is an instance of XMLHttpRequest in the browser and an instance
                                        * of http.ClientRequest in Node.js
                                        */
                                        console.log("error.request", error.request);
                                    } else {
                                        // Something happened in setting up the request and triggered an Error
                                        console.log("error.message", error.message);
                                    }
                                    console.log("error.config",error.config);
                                    self.props.history.push(`/order/thanks/${response.data.Order.Id}`);
                                })
                                .then(function () {
                                    // always executed
                                    self.props.history.push(`/orderfin/${auth}/pp/${response.data.Order.Id}`);
                                }); 
                            }
                        }).on("liqpay.ready", function (data) {                                        
                            console.log("!!! LIQPAY READY", data);
                            self.setState({progress: false}); 

                            if(width === "xs")                                                                                  
                                window.scrollTo({top: 250, behavior: "smooth"});
                            else
                                window.scrollTo({top: 170, behavior: "smooth"});
                        }).on("liqpay.close", function (data) {                    
                            console.log("liqpay.close", data);
                        });
                    }
                    else{
                        console.log("ERROR DATA FROM JERELIA API");
                    }
                }
            })
            .catch((error) => {
                console.log("ERROR JERELIA API: payorder");                
                console.log(error);
                this.setState({isLoading: false});
            });
        });

    };

    render() {
        const { classes, width, t } = this.props;
        const { isLoading, data, showSuccess, progress } = this.state;        

        return (
            <div className={classes.root}>
                <div>                            
                    {/* <Helmet>
                        <script src="//static.liqpay.ua/libjs/checkout.js" async></script>
                    </Helmet> */}
                </div>
                { isLoading && 
                    <LinearProgress className={classes.progress} 
                    classes={{
                        colorPrimary: classes.linearColorPrimary,
                        barColorPrimary: classes.linearBarColorPrimary,
                    }}
                />
                }
                { data && 
                <Grid spacing={width === "xs" ? 0 : 2}  container direction="row" justify="center" alignItems="center" >
                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={width !== "xs" ? 1 : 0}>                            
                            
                            <Typography variant="h5" align="center">
                                {t("pay.Order")}
                            </Typography>
                            
                            <Typography align="center" variant="subtitle2">
                                {data.Order.DocNum}
                            </Typography>
                            
                            
                            { data.Order.Customer.RegistrationCode !== 9999999 ?
                                <>
                                    <Typography align="center">
                                        {`${data.Order.Customer.RegistrationCode}, ${data.Order.Customer.Name} ` }
                                    </Typography>
                                    <Typography align="center" gutterBottom>
                                        {/* <small>{`${data.Order.Customer.Phone}, ${data.Order.Customer.Email} ` }</small>  */}
                                    </Typography>                                    
                                </> :
                                <>
                                <Typography align="center">
                                    {`${data.Order.RecipientLastName} ${data.Order.RecipientFirstName} ${data.Order.RecipientMiddleName}`}
                                    </Typography>
                                </>
                            }
                            
                            { progress && 
                                <Grid spacing={2}  container direction="row" justify="center" alignItems="center" >
                                    <Grid item xs={12} sm={6} md={4}>
                                        <LinearProgress />                                                                                    
                                        <Typography>Loading... Liqpay</Typography>                                        
                                    </Grid>
                                </Grid>                                
                            }

                            {/* LIQPAY: Платежный виджет */}
                            <div className={classes.liqpay} id="liqpay_checkout"></div>                                                        
                            
                            {
                            showSuccess && 
                                <Grid container direction="row" justify="center" alignItems="center" >
                                    <Grid item style={{textAlign: "center"}}> 
                                        <Button className={classes.goto} color="primary" onClick={() => {this.props.history.push("/order");}} >
                                            {t("pay.GoToOrder")}
                                        </Button>                                        
                                    </Grid> 
                                    <Grid item style={{textAlign: "center"}}> 
                                        <Button className={classes.goto} color="primary" onClick={() => {this.props.history.push("/orders");}} >
                                            {t("pay.GoToOrders")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            }

                        </Paper>                        
                    </Grid>
                </Grid>
                }

            </div>
        )
    }
}

export default withTranslation()(withWidth() (withRouter( withStyles(styles)(
    connect(    
        state => state.orderRequest,
        dispatch => bindActionCreators(actionOrderCreators, dispatch)
    )(Pay)
))));