/*eslint no-unused-vars: ["error", { "args": "none" }]*/

import { IDENTITY_CONFIG, METADATA_OIDC } from "./authConst";
// import { UserManager, WebStorageStateStore, Log } from "oidc-client";
import { UserManager, WebStorageStateStore } from "oidc-client";

export default class AuthService {
    UserManager;

    constructor() {
        // console.log(IDENTITY_CONFIG);
        this.UserManager = new UserManager({
            ...IDENTITY_CONFIG,
            userStore: new WebStorageStateStore({ store: window.sessionStorage }),
            metadata: { ...METADATA_OIDC }
        });
        
        // Logger
        // Log.logger = console;
        // Log.level = Log.DEBUG;
                
        this.UserManager.events.addUserLoaded((user) => {
            if (window.location.href.indexOf("signin-oidc") !== -1) {
                this.navigateToScreen();
            }
        });
        this.UserManager.events.addSilentRenewError((e) => {
            console.log("silent renew error", e.message);
        });

        this.UserManager.events.addAccessTokenExpired(() => {
            console.log("token expired");
            this.signinSilent();
        });
    }

    signinRedirectCallback = () => {
        this.UserManager.signinRedirectCallback()
        // do something after successful authentication
        .then(() => {
          window.location.replace("/");
        })
        // do something if there was an error
        .catch(() => {
          window.location.replace("/");
        });
    };

    // signinRedirectCallback = () => {
    //     this.UserManager.signinRedirectCallback().then(() => {
    //         "";
    //     });
    // };

    signinPopup = () => {
        this.UserManager.signinPopup();
    };
      
    signinPopupCallback = () => {
        this.UserManager.signinPopupCallback();
    };

    getUser = async () => {
        const user = await this.UserManager.getUser();
        if (!user) {
            return await this.UserManager.signinRedirectCallback();
        }
        return user;
    };

    parseJwt = (token) => {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace("-", "+").replace("_", "/");
        return JSON.parse(window.atob(base64));
    };

    signinRedirect = () => {
        localStorage.setItem("redirectUri", window.location.pathname);
        this.UserManager.signinRedirect({});
    };

    navigateToScreen = () => {
        window.location.replace("/en/dashboard");
    };

    isAuthenticated = () => {
        // eslint-disable-next-line no-undef
        const oidcStorage = JSON.parse(sessionStorage.getItem(`oidc.user:${process.env.REACT_APP_AUTHORITY}:${process.env.REACT_APP_CLIENT_ID}`))

        return (!!oidcStorage && !!oidcStorage.access_token)
    };

    signinSilent = () => {
        this.UserManager.signinSilent()
            .then((user) => {
                console.log("signed in", user);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    signinSilentCallback = () => {
        this.UserManager.signinSilentCallback();
    };

    createSigninRequest = () => {
        return this.UserManager.createSigninRequest();
    };

    logout = () => {
        this.UserManager.signoutRedirect({
            id_token_hint: localStorage.getItem("id_token")
        });
        this.UserManager.clearStaleState();
    };

    signoutRedirectCallback = () => {
        this.UserManager.signoutRedirectCallback().then(() => {
            localStorage.clear();
            // eslint-disable-next-line no-undef
            window.location.replace(process.env.REACT_APP_API_DOMAIN);
        });
        this.UserManager.clearStaleState();
    };
}