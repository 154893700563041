import React, { Component } from "react";
import withWidth from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import { Typography } from "@material-ui/core";
import NotFound from "../NotFound";
import { Helmet } from "react-helmet";


const styles = theme => ({
    root: {            
        flexGrow: 1,        
    },
    page: {
        padding: theme.spacing(3,1),
        marginTop: theme.spacing(3,1),
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",        
        boxShadow: "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
    },
    topbanner: {
        maxWidth: "100%",
        height: "auto",
    },
    custom: {
        width: "100%",        
        [theme.breakpoints.down("sm")]: {
            width: 340            
        },
        overflowX: "auto"
        
    },
});

const extractFilename = (path) => {
    const pathArray = path.split("/");
    const lastIndex = pathArray.length - 1;
    return pathArray[lastIndex];
 };

class Page extends Component {
    constructor(props){
        super(props);
        this.state = {
            key: this.props.match.params.key || "",
            content: null,
            folder: "",
            file: "",
            error404: false,
        };        
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.match.params.key !== prevState.key){        
          return { key: nextProps.match.params.key};
        }
        else return null;
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log("prevProps", prevProps.key);
        // console.log("prevState", prevState.key);
        
        if(this.state.key !==  prevState.key ){
            this.loadContent(this.state.key);
        }        
      }

    componentDidMount() {       
        this.loadContent(this.state.key);
    }

    loadContent = (key) => {
        const URLCONT = `/api/v1.0/content/${key}/%20`;        
        const self = this;
        this.setState({ error404: false });
        fetch(URLCONT)
            .then( (response) => {                
                if(response.status === 200){
                    response.json().then((content) => {
                        self.setState({ content: content, file: content.TopBanner  ? extractFilename(content.TopBanner): "" });                
                    });
                }else if(response.status === 404){
                    self.setState({ error404: true });
                }
          })
          .catch(error => {
                self.setState({ error404: true });
                console.log("Error", error);
        });
    };
     
    render() {
        const {classes} = this.props;
        const { content, file, error404 } = this.state;
        return (    
        <div className={classes.root}>
            {
            content &&
            <>
            <Helmet>
                <title>{ `Jerelia, ${content.LocalValues[0].Title}` }</title>                
            </Helmet>
            <Paper className={classes.page}>
                <Grid  container spacing={2} direction="row" justify="center" alignItems="stretch">
                    { (content.TopBanner) &&
                    <Grid item xs={12}> 
                        <figure style={{margin: "0", padding: "0"}}>
                            <picture className={classes.topbanner}>
                                {/* large */}
                                <source media="(min-width: 1134px)" srcSet={content.TopBanner} />
                                {/* <source media="(min-width: 1134px)" srcSet={content.TopBanner.replace(file, `/xl/${file}`)} /> */}
                                
                                {/* medium */}
                                {/* <source media="(max-width: 1133px)" srcSet={content.TopBanner.replace(file, `/xl/${file}`)} /> */}
                                
                                {/* small */}
                                { content.TopMobileBanner.length > 0 ? 
                                    <source srcSet={content.TopMobileBanner} /> :
                                    <source srcSet={content.TopBanner.replace(file, `/md/${file}`)} />
                                }
                                {/* <source srcSet={content.TopBanner.replace(file, `/md/${file}`)} /> */}                            
                                <img className={classes.topbanner} src={content.TopBanner} alt={content.Name} />
                            </picture>
                        </figure>
                    </Grid>
                    }                    
                </Grid>
                
                {
                    content.LocalValues.map((item) => (
                        <div className={classes.custom} key={item.ContentId} dangerouslySetInnerHTML={{__html: item.Value.replace(/(<? *script)/gi, "illegalscript")}} ></div>
                    ))
                } 

                <Typography align="right">
                    <Button href="/" >
                        <HomeOutlinedIcon />
                    </Button>
                </Typography>                
            </Paper>                            
            </>
            }
            
        { error404 ? <NotFound /> : null } 

        </div>
        );
    }
}

export default withWidth()(withStyles(styles, { withTheme: true })(Page));
// export default withWidth()(withStyles(styles)(Footer));