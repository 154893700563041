/*eslint multiline-ternary: ["error", "always-multiline"]*/

import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import InputBase from "@material-ui/core/InputBase";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
// import { Editor } from "@tinymce/tinymce-react";
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import CodeMirror from "codemirror";

// import CodeMirror from "codemirror";
import "codemirror/mode/htmlembedded/htmlembedded";
import "codemirror/lib/codemirror.css";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Link } from "react-router-dom";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Box from "@material-ui/core/Box";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icon from "@material-ui/core/Icon";
import SaveIcon from "@material-ui/icons/Save";
import ArrowBack from "@material-ui/icons/ArrowBack";

import classNames from "classnames";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import FileCopyIcon from "@material-ui/icons/FileCopy";

import { bindActionCreators } from "redux";
import { actionCreators } from "../../store/ContentEditor";
import moment from "moment";

import { connect } from "react-redux";

const styles = (theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },  
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  spacingBottom: {
    paddingBottom: theme.spacing(3),
  },
  editor: {
    marginTop: theme.spacing(3)
  },
  preview: {
    width: "100%",
    height: "auto"
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  paper:{
    margin:theme.spacing(1), 
    padding: theme.spacing(1),
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  message: {
    display: "flex",
    alignItems: "center",
    // paddingTop: theme.spacing(1),
    // paddingBottom: theme.spacing(1),
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    // opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  heading: {    
  }
});

function TabContainer(props) {
  return (
    <Typography component="div" style={{ paddingBottom: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class ContentEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedCommon: true,
      expandedEditor: false,
      expandedAttributes: false,
      value: "",
      contentType: this.props.match.params.contenttype || "page",
      key: this.props.match.params.id || "",      
      tabindex: 0,
      labelWidth: 0,                  
      nameError: false,
      keyError: false,      
    };

    this.ids= [React.createRef(), React.createRef(), React.createRef()];
  }



  handleSaved = ( key ) => {
    this.setState( { key: key });
  };

  handleClose = () => {
    this.props.clearError();
  };

  loadContent = (key, contentType ) => {    
    this.setState({key: key, contentType: contentType});

    this.props.requestContent(key, contentType);    
  };

  componentDidMount() {
    const {key, contentType} = this.state;
    this.loadContent(key, contentType);                        
  }
  
  static getDerivedStateFromProps(props, state) {
    const  { content } = props;
    const key = content.Key || "";
                
    if ( key !== state.key  ) {  
      return key;
    }
    return null;
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if(this.state.key !==  prevState.key ) {
  //     debugger;
  //     this.props.requestContent(this.state.key, this.state.contentType);    
  //   }
  // }

  validate = () => {
    const errors = {};
    let hasError = false;

    if (this.props.content.Name.length === 0) {
      hasError = true;
      errors.nameError = true;
    }

    if (this.props.content.Key.length === 0) {
      hasError = true;
      errors.keyError = true;
    }

    if (hasError) {
      this.setState({
        ...this.state,
        ...errors,
      });
    }
    return hasError;
  };

  handleSave = () => {
    if (!this.validate()) {      
      this.props.saveContent(this.handleSaved);
    }
  };

  callBackSave = () => {
    // alert(contexts);
    if (!this.validate()) {      
      this.props.saveContent(this.handleSaved);
    }
  }

  onUpdateContent = (value) => {
    this.props.updateContent(this.state.tabindex, "Value", value);
  };

  handleChangeCheck = (name) => (event) => {
    this.props.updateContentChecked(name, event.target.checked);
    // this.setState({ [this.props.content[name]]: event.target.checked });
  };

  handleChange = (index, event) => {
    this.props.updateContent(index, event.target.name, event.target.value);
  };

  handleChangeTab = (event, tabindex) => {
    this.setState({ tabindex });
  };

  updateContent =(value) => {
    this.setState({value:value})
  }

  handleEditorChange = (content) => {
    this.props.updateContent(this.state.tabindex, "Value", content);
    // this.setState({value: content})
    // console.log("Content was updated:", content);
  }

  // handlePaste = (e, cleanData, maxCharCount) =>
  // {       
  //   console.log( cleanData, maxCharCount);
  //   return true;   
  // }

  copyCodeToClipboard = (id) => {
    const el = document.getElementById(id);
    el.select();
    document.execCommand("copy");
  }
  
  handleChangeAccordionCommon = () =>  {
    this.setState((state) => ({
      expandedCommon: !state.expandedCommon
    }));
  };

  handleChangeAccordionEditor = () =>  {
    this.setState((state) => ({
      expandedEditor: !state.expandedEditor
    }));
  };

  handleChangePanelAttr = () =>  {
    this.setState((state) => ({
      expandedAttributes: !state.expandedAttributes
    }));
  };

  render() {
    const { classes, content, Langs, showDlg, dlgMessage } = this.props;
    const { tabindex, contentType, expandedCommon, expandedEditor, expandedAttributes } = this.state;
    moment.locale(content.lang);
    
    return (
      content &&  Langs && 
      <Grid container  direction="row" justify="center">
        <Grid item xs={12}>
          <Paper className={classes.paper} elevation={1}>
            <Toolbar variant="dense">
              <Button
                className={classes.menuButton}
                variant="outlined"
                color="primary"
                aria-label="GO TO"
                component={Link}
                to={`/content/${contentType}`}>
                <ArrowBack />
              </Button>

              <Button
                className={classes.menuButton}
                variant="outlined"
                color="primary"
                onClick={this.handleSave}>
                <SaveIcon />
                Save {contentType}
              </Button>

              <Button  className={classNames.button} color="default" startIcon={<FileCopyIcon />}
                        onClick={() => this.copyCodeToClipboard("pageUrl")}>
                        Copy url:
              </Button> 
              <InputBase style={{width: "60%"}} className={classNames.button} readOnly  id="pageUrl" variant="outlined" label="Url" value={`/${contentType}/${content.Key}`} />
            </Toolbar>

            <Accordion expanded={ expandedCommon } onChange={this.handleChangeAccordionCommon} style={{marginTop: "16px" , marginBottom: "16px"}}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" >
                <Typography className={classes.heading}>Name: {content.Name}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{width: "100%"}}>
                  <Grid container direction="row" >
                  <Grid item xs={12}>
                    <TextField
                      required
                      id="Name"
                      name="Name"
                      label="Name"
                      value={content.Name}
                      variant="outlined"
                      error={this.state.nameError}
                      fullWidth={true}
                      margin="normal"
                      onChange={(e) => this.handleChange(0, e)}
                    />
                  </Grid>                  
                </Grid>

                  <Grid container direction="row" spacing={2} justify="flex-start" alignItems="flex-end">
                  <Grid item xs={12} sm={9}>
                    <Grid container direction="row" spacing={1}  justify="flex-start" >
                      <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        id="Key"
                        required
                        name="Key"
                        label="Key"
                        variant="outlined"
                        value={content.Key}
                        error={this.state.keyError}
                        fullWidth={true}
                        margin="normal"
                        onChange={(e) => this.handleChange(0, e)}
                      />
                    </Grid>                                                                       
                      <Grid item xs={12} sm={6} md={3} >
                      <TextField
                        label="Started On"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        type="datetime-local"
                        value={moment(content.StartedOn).format(
                          "YYYY-MM-DDTkk:mm:ss"
                        )}
                        name="StartedOn"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => this.handleChange(0, e)}
                      />
                    </Grid>
                      <Grid item xs={12} sm={6} md={3} >
                      <TextField
                        label="Finish On"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        type="datetime-local"
                        value={moment(content.FinishedOn).format(
                          "YYYY-MM-DDTkk:mm:ss"
                        )}
                        name="FinishedOn"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => this.handleChange(0, e)}
                      />
                    </Grid>                
                      <Grid item xs={12} sm={3} md={2} >
                    <Box mt={3} pl={2} component="div" >
                    <FormControlLabel
                      control={
                        <Checkbox
                          value="IsActive"
                          checked={content.IsActive}
                          onChange={this.handleChangeCheck("IsActive")}
                        />
                      }
                      label="IsActive"
                    />
                    </Box>
                  </Grid>
                      <Grid item xs={12}>
                      <TextField
                        id="TopBanner"                      
                        name="TopBanner"
                        label="Desktop banner"
                        variant="outlined"
                        value={content.TopBanner ? content.TopBanner : ""}                      
                        fullWidth={true}
                        margin="normal"
                        onChange={(e) => this.handleChange(0, e)}
                      />
                    </Grid>
                      <Grid item xs={12}>
                      <TextField
                        id="TopMobileBanner"                      
                        name="TopMobileBanner"
                        label="Mobile banner"
                        variant="outlined"
                        value={content.TopMobileBanner ? content.TopMobileBanner : ""}                      
                        fullWidth={true}
                        margin="normal"
                        onChange={(e) => this.handleChange(0, e)}
                      />
                    </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    {content.TopBanner && content.TopBanner.length > 0 &&
                      <div>                        
                        <img width="100%" src={content.TopBanner} alt="preview" />
                        <Typography component="p" variant="caption" gutterBottom align="center" style={{paddingTop: "8px"}}>
                          Desktop preview banner
                        </Typography> 
                      </div>
                    }                  
                  </Grid>
                </Grid>                                                                                  
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={ expandedEditor } onChange={this.handleChangeAccordionEditor} style={{marginTop: "16px" , marginBottom: "16px"}}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" >
                <Typography className={classes.heading}>Content editor <b>✎</b></Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{width: "100%"}}>
                  <Tabs
                    value={this.state.tabindex}
                    onChange={this.handleChangeTab}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth">
                    {Langs.map((lang) => {
                      return (
                        <Tab key={lang.Name} label={lang.NativeName} />                  
                      );
                    })}
                  </Tabs>
                  <div>
                  { content.LocalValues && 
                    <React.Fragment>
                      {
                      content.LocalValues.map((val, index) => {
                          return (
                            tabindex === index && (
                              <TabContainer key={val.TwoLetterISOLanguageName}>
                                
                                <Accordion expanded={ expandedAttributes } onChange={this.handleChangePanelAttr} style={{ marginTop: "16px" , marginBottom: "16px" }}>
                                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1ab-content" >
                                    <Typography className={classes.heading}>Attributes: {val.TwoLetterISOLanguageName}</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                  
                                  <Grid container direction="row" spacing={1} > 
                                    <Grid item xs={12} sm={4}> 
                                      <TextField
                                        required
                                        name="Title"
                                        label="Title"
                                        variant="outlined"
                                        value={val.Title}
                                        fullWidth={true}
                                        margin="normal"
                                        onChange={(e) => this.handleChange(index, e)}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={4}> 
                                      <TextField
                                        required
                                        name="Keywords"
                                        label="Keywords"
                                        variant="outlined"
                                        value={val.Keywords}
                                        fullWidth={true}
                                        margin="normal"
                                        onChange={(e) => this.handleChange(index, e)}
                                      />
                                    </Grid>                                
                                    <Grid item xs={12} sm={4}> 
                                      <TextField
                                        required
                                        name="Descr"
                                        label="Description"
                                        variant="outlined"
                                        value={val.Descr}
                                        fullWidth={true}
                                        margin="normal"
                                        onChange={(e) => this.handleChange(index, e)}
                                      />
                                    </Grid>                                 
                                    <Grid item xs={12}>
                                    {  content.ContentType === 1 ? <TextField name="Annonce" multiline rows={3} label="Annonce" variant="outlined" value={(val.Annonce ? val.Annonce : "")} fullWidth margin="normal" onChange={(e) => this.handleChange(index, e)} /> : null }                                                                                                      
                                    </Grid>
                                  </Grid>

                                </AccordionDetails>
                                </Accordion>

                                <Grid container spacing={1} direction="row" justify="center">
                                  <Grid item xs={12}>
                                    {/* <div dangerouslySetInnerHTML={{__html: val.Value.replace(/(<? *script)/gi, "illegalscript")}} ></div> */}
                                    <SunEditor
                                      ref={this.ids[index]}
                                      // id={`editor_${val.TwoLetterISOLanguageName}`}
                                      // name={`editor_${val.TwoLetterISOLanguageName}`}
                                      // defaultValue = {val.Value}
                                      setContents={ val.Value }
                                      lang={val.TwoLetterISOLanguageName === "uk" ? "ua" : val.TwoLetterISOLanguageName  }
                                      height={700} 
                                      setDefaultStyle="font-family: Roboto; font-size: 1rem; ine-height: 1.5;letter-spacing: 0.00938em;"
                                      setOptions={{
                                        font: [ "Roboto", "Arial", "tahoma", "Courier New,Courier", "Comic Sans MS", "Impact", "Georgia", "Trebuchet MS", "Verdana" ],                                    
                                        buttonList: buttonList.complex,
                                        codeMirror: CodeMirror,
                                        addTagsWhitelist: "br|p|div|pre|blockquote|h1|h2|h3|h4|h5|h6|ol|ul|li|hr|figure|figcaption|img|picture|source|iframe|audio|video|table|thead|tbody|tr|th|td|a|b|strong|var|i|em|u|ins|s|span|strike|del|sub|sup|code|svg|path",
                                        attributesWhitelist: {
                                          "all": "contenteditable|id|style|colspan|rowspan|target|href|download|rel|src|alt|class|type|controls|media",
                                        },
                                        callBackSave: this.callBackSave,
                                        templates: [
                                          {
                                              name: "Template-1",
                                              html: "<p>HTML source1</p>"
                                          },
                                          {
                                              name: "Template-2",
                                              html: "<p>HTML source2</p>"
                                          }
                                        ],
                                      }}
                                      
                                      onChange={this.handleEditorChange}
                                      // onPaste={ this.handlePaste} 
                                      
                                      />
                                      <Typography component="div" align="right" variant="caption" display="block" gutterBottom>
                                        <pre>
                                          <code className="language-html">
                                          Help: &#60;div class=&quot;__se__tag your_class&quot;&#62;Other a tags&#60;/div&#62;
                                          </code>
                                        </pre>
                                      </Typography>

                                      {/* <div dangerouslySetInnerHTML={{__html: val.Value }}></div> */}
                                  </Grid>
                              </Grid>

                              </TabContainer>
                            )
                          );
                        })
                      }
                  </React.Fragment>
                  }
                  <div>{this.state.contenttype}</div>            
                </div>
            
              </div>
            </AccordionDetails>
            </Accordion>



            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={showDlg}
              onClose={this.handleClose}>
              <SnackbarContent
                className={classes.error}
                aria-describedby="client-snackbar"
                message={
                  <span id="client-snackbar" className={classes.message}>
                    <Icon
                      className={classNames(classes.icon, classes.iconVariant)}>
                      {" "}
                      error_outline
                    </Icon>
                    {dlgMessage}
                  </span>
                }
                action={[
                  <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classes.close}
                    onClick={this.handleClose}>
                    <CloseIcon />
                  </IconButton>,
                ]}
              />
            </Snackbar>

          </Paper>
        </Grid>          
      </Grid>
    
    
    );
  }
}

ContentEditor.propTypes = {
  classes: PropTypes.object.isRequired,
};

// export default withStyles(styles)(ContentEditor);

export default withTranslation()(withStyles(styles)(
  connect(
    // state => state.appLayout,
    (state) => state.contentEditor,
    (dispatch) => bindActionCreators(actionCreators, dispatch)
  )(ContentEditor)
));
