
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { actionRegister } from "../../store/Register";

import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import withWidth from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";
import { withCookies } from "react-cookie";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import lightBlue  from "@material-ui/core/colors/lightBlue";
import deepPurple from "@material-ui/core/colors/deepPurple";
import Avatar from "@material-ui/core/Avatar";
// import Link from "@material-ui/core/Link";
// import PhoneForwardedIcon from "@material-ui/icons/PhoneForwarded";

import { TelegramIcon, ViberIcon } from "../icons/jerelia-icons";
import LinearProgress from "@material-ui/core/LinearProgress";
import AuthService from "../../helpers/authService";

// eslint-disable-next-line no-undef
const host = process.env.NODE_ENV === "development"? "https://jerelia.com": "";

const getUserName = (userName) => {
  if(!userName){
    return "";
  }
  const words = userName.split(" ");
  const res = (`${words[1].charAt(0)}${words[0].charAt(0)}`).toUpperCase();
  return res;
};

// const formatPhoneNumber = (str) => {
//   //Filter only numbers from the input
//   let cleaned = ("" + str).replace(/\D/g, "");
  
//   //Check if the input is of correct
//   let match = cleaned.match(/^(1|)?(\d{3})(\d{2})(\d{3})(\d{4})$/);
  
//   if (match) {
//     //Remove the matched extension code
//     //Change this to format for any country code.
//     let intlCode = (match[1] ? "380 " : "");    
//     return [intlCode, match[2], " (", match[3], ") ", match[4], "-", match[5]].join("")
//   }
//   return null;
// }

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {        
      padding: theme.spacing(2,8),
    },
    background: "linear-gradient(0.00deg, #ffffff 97.00%, #54d1e621 99.00%)"
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",    
    minHeight: theme.spacing(8),
  },
  paperHeader: {
    padding: theme.spacing(1),
    textAlign: "center",        
  },
  btn: {
    borderRadius: theme.spacing(3)
  },

  line1: {
    fontSize: "18px"
  },
  line2: {
    fontSize: "14px"
  },
  line3: {
    fontSize: "18px"
  },

  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),    
    fontSize: "3em",
    lineHeight: "3em",
    color: theme.palette.getContrastText(lightBlue[700]),
    backgroundColor: theme.palette.primary.main,
    letterSpacing: "-0.1em",
  }, 
  selmentor: {
    textTransform: "capitalize",
  },
  btnViber: {
    margin: theme.spacing(1),
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
    "&:hover": {
      backgroundColor: deepPurple[400],
    },
  },
  btnTelegram: {
    margin: theme.spacing(1),
    color: theme.palette.getContrastText(lightBlue[600]),
    backgroundColor: lightBlue[600],
    "&:hover": {
      backgroundColor: lightBlue[500],
    },
  },
  
});

class RegisterClientSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {        
        isLoading: false,                
    };
    this.authService = new AuthService();
  }

  registerUser = () => {
    this.props.registerUser();
  };

  componentDidMount() {
    const { mentor } = this.props;
    window.scrollTo({top: 0, behavior: "smooth"});    
    
    if(mentor){
      this.registerUser();
    }
  }

  handleLogin = () => {
    this.authService.signinRedirect();
  };

  render(){
    // eslint-disable-next-line no-unused-vars
    const { classes, t,
            mentor, newUser, appError
          } = this.props;
    // const {  } = this.state;
    const shortName = mentor ?  getUserName(mentor.Name) : ""; 
    return (
      <div className={classes.root}>
        { newUser ?
        <> 
          <Typography variant="h5" gutterBottom align="center" className={classes.line1}>
          {t("register.ThankRegister", {name: newUser.FirstName})}             
          </Typography>
          <Typography variant="subtitle2" gutterBottom align="center" className={classes.line2}>{t("register.YourRegNumber")}</Typography>
          <Typography variant="h5" gutterBottom align="center">{newUser.RegistrationCode}</Typography>        
          <Typography variant="body2" gutterBottom align="center" style={{marginBottom: 24}} >{t("register.RememberRegNumber")}</Typography>        
          
        { mentor && 
        <Grid container direction="row" justify="center" alignItems="center" spacing={4} style={{marginBottom: 16}}>
          <Grid item>          
          {
            mentor.Avatar && mentor.Avatar.length > 0 ?
            <Avatar alt={shortName} src={`${host}${mentor.Avatar}`} className={classes.large} /> : 
            <Avatar className={classes.large}>
                { shortName }
            </Avatar>
          }
          </Grid>          
          {/* <Grid item >
            <Typography variant="h5" className={classes.line1} gutterBottom >{t("register.YourConsultant")}</Typography>
            <Typography className={classes.selmentor} gutterBottom>{mentor.FirstName.toLowerCase()} {mentor.LastName.toLowerCase()}</Typography>            
            <Typography variant="body1" gutterBottom>{mentor.RegistrationCode}</Typography>
            <Typography >
              <Button href={`tel:${mentor.Phone}`} color="inherit" startIcon={<PhoneForwardedIcon />}>
                +{formatPhoneNumber(mentor.Phone.startsWith("380") ? mentor.Phone : `38${mentor.Phone}`)}
              </Button>              
            </Typography>             
          </Grid> */}
        </Grid> 
        }

        <Grid container direction="row" justify="center" alignItems="center" spacing={2} style={{marginBottom: 16}}>
          <Grid item xs={11} sm={3}>
            <Button fullWidth className={classes.btn}
              variant="contained" color="primary"
              onClick={this.handleLogin}
              >
              {t("buttons.Login") }
            </Button>
            
          </Grid>
        </Grid>
                
        <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
          <Grid item>
            <IconButton className={classes.btnViber} aria-label="viber" target="_about" href="viber://pa?chatURI=supportjerelia">
              <ViberIcon fontSize="small" width={24} height={24} />
            </IconButton>
            <IconButton className={classes.btnTelegram} aria-label="telegram" target="_about" href="https://t.me/JR_support_Bot">
              <TelegramIcon fontSize="small" width={24} height={24} />
            </IconButton>
          </Grid>
          <Grid item xs={7} sm={4} md={3}>
            <Typography variant="body2" >{t("register.ContactSupport")}</Typography>
          </Grid>
        </Grid>
        </> 
        : <div style={{margin: "64px 32px", padding: 16}}>
          <LinearProgress />
          <Typography color="textSecondary" variant="caption" align="center" gutterBottom display="block">
            {t("register.loadingRegister")}
          </Typography>
          
        </div>
        }
        {!newUser && appError &&
          <Typography variant="body2" color="secondary" align="center" display="block">
            {appError}
          </Typography>
        }
      </div>
    );
  }
}


export default withTranslation()(withWidth()(
  withRouter(
    withStyles(styles, { withTheme: true })(
      connect(
          state => state.register,
          dispatch => bindActionCreators(actionRegister, dispatch)
      )(withCookies(RegisterClientSuccess))
    )
  )
));