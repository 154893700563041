import React from "react";
import axios from "axios";
import matchSorter from "match-sorter";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { OidcUserManager } from "../../helpers/OidcSettings";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import { actionOrderCreators } from "../../store/Order";
import withWidth from "@material-ui/core/withWidth";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import Input from "@material-ui/core/Input";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import AddShoppingCard from "@material-ui/icons/AddShoppingCart";
import Button from "@material-ui/core/Button";
import Clear from "@material-ui/icons/Clear";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import CardMembershipIcon from "@material-ui/icons/CardMembership";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Slide from "@material-ui/core/Slide";
import { Alert } from "@material-ui/lab";
import PromotionsThumb from "./PromotionsThumb";
import Collapse from "@material-ui/core/Collapse";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckIcon from "@material-ui/icons/Check";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import DlgBuyProducts from "./DlgBuyProducts";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import ThumbUpAltOutlinedIcon from "@material-ui/icons/ThumbUpAltOutlined";
import SvgIcon from "@material-ui/core/SvgIcon";
// import HistoryIcon from "@material-ui/icons/History";
import Badge from "@material-ui/core/Badge";
import {ReactComponent as ChestImg}  from  "./chest.svg";
import gray from "@material-ui/core/colors/grey";
import red from "@material-ui/core/colors/red";
import blue from "@material-ui/core/colors/blue";
// import lime from "@material-ui/core/colors/lime";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { OrderRowType } from "../../definitions/order";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import InputLabel from "@material-ui/core/InputLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { withTranslation } from "react-i18next";
import AppBar from "@material-ui/core/AppBar";
import Fab from "@material-ui/core/Fab";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import Toolbar from "@material-ui/core/Toolbar";
import Filter1Icon from "@material-ui/icons/Filter1";
import Filter2Icon from "@material-ui/icons/Filter2";
import Skeleton from "@material-ui/lab/Skeleton";
import DetailPromotion from "../Promo/DetailPromotion";
import "../../styles/index.css";
import { Divider } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";
import Link from "@material-ui/core/Link";
// import Backdrop from "@material-ui/core/Backdrop";

const styles = theme => ({
  root: {
    flexGrow: 1,        
    // marginTop: theme.spacing(1),
    padding: theme.spacing(1),
  },
  dialogTitle:{
    margin: 0,
    padding: theme.spacing(2),
  },
  dialogActions: {
    // marginBottom: theme.spacing(1*0.5),
  },
  extPanel: {
    [theme.breakpoints.down("xs")]: {
      paddingRight: 4
    },
  },
  button: {
    marginRight: theme.spacing(1),
  }, 
  btnSuccess: {
    // marginRight: theme.spacing(1),
    color: "#fff",
    backgroundColor: theme.palette.success.main,
    "&:hover":{
      backgroundColor: theme.palette.success.dark,
    },
  },

  chipconfirm: {
    padding: theme.spacing(3, 1),
    borderRadius: 24,
    fontSize: "1.2em",
    textTransform: "uppercase",        
    backgroundColor: theme.palette.success.main,
    "&:hover":{
      backgroundColor: theme.palette.success.dark,
    },
    color: "#fff",
  },

  pusmin: {
    marginRight: theme.spacing(1),    
  },

  colorSuccess: {
    color: theme.palette.success.main,
  },

  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },

  appBarBtn: {
    padding: theme.spacing(1, 0),   
    // boxShadow: "none",
    top: "auto",
    bottom: 0,    
    backgroundColor: "#fff",
  },

  fab: {
    position: "absolute",
    zIndex: 10000,
    bottom: 41,
    left: 0,
    right: 0,
    margin: "0 auto",
    backgroundColor: "#fff",
    boxShadow: "none",    
  },

  flex: {
    flex: 1,
    marginLeft: theme.spacing(1) * 2,
  },
  paper:{
    padding: theme.spacing(1),
  },
  payment: {
    padding: theme.spacing(1),
    height: "100%"
    // backgroundColor: theme.palette.type === "light" ? theme.palette.grey[100] : theme.palette.grey[800],
  },
  

  card: {
    marginTop:theme.spacing(1),
    marginBottom:theme.spacing(1),
  },
  cardheader: {
    fontSize: "12px;"
  },    
  actions: {
    display: "flex",
  },
  row: {
    borderTop: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.up("xs")]: {
      marginTop: theme.spacing(1),
    },
  },
  itemR: {
    textAlign: "right",
    display: "block",
    width: "100%"        
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    height: "72px",
  },
  imgXs: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    height: "34px",
  },
  imgcell: {        
  },
  inpR: {
    textAlign: "right",
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  suggestion: {
    display: "block",
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
  },
  divider: {
    height: theme.spacing(1) * 2,
  }, 
  progress: {
    height: 2,
    position: "absolute",
    top: 0,
    left: 0,
    right:0,
  },  
  linearColorPrimary: {
    backgroundColor: "#abdcda",
  },
  linearBarColorPrimary: {
    backgroundColor: "#d20000",
  },
  promotions: {
    padding: theme.spacing(1),
    margionTop: theme.spacing(2)
  },
  heading: {
    // fontSize: theme.typography.pxToRem(15),
    flexBasis: "66.66%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  blabel: {        
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  colorOrange: {
    color: "#ff9800"
  },
  colorGray:{
    color: gray[500],
  },
  colorRed: {
    color: red[500],
  },
  colorBlue:{
    color: blue[500],
  },
  rowScript: {
    marginBottom: theme.spacing(2),
    borderBottom: "dotted 1px #ccc"
  },
  rowDetail: {
    width: "100%",
    display: "block",
    [theme.breakpoints.up("sm")]: {
      // paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(4),
    },
    [theme.breakpoints.down("xs")]: {
      // paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(4),
    },

  },
  canRefuse: {
    // textAlign: "right",    
    color: theme.palette.warning.dark,
    textTransform: "uppercase",    
  },
  avatarPay: {
    backgroundColor: blue[600],
  },
  avatarDelivery: {
    backgroundColor: blue[600],
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  labelControl: {
    marginTop:theme.spacing(2),
  },
  
  toolbarBtn: {
    // minHeight: "38px",
  },
  grow: {
    flexGrow: 1,
  },
  qtyrow: {
    "&  input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    } 
  },

  fabButton: {
    width: 176,
    // height: 64,
    boxShadow: "none",
    border: "solid 3px white",
    color: "#fff",
    backgroundColor: theme.palette.success.main,
    "&:hover":{
      backgroundColor: theme.palette.success.dark,
    },      
    // backgroundColor: "#3f51b5",
    // borderWidth: 50,    
    position: "absolute",
    zIndex: 1,
    top: -24,
    left: 0,
    right: 0,
    margin: "0 auto",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  step: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  currency: {    
    fontSize: ".6em"
  },
  promoTab: {
    padding: (theme.spacing(2), theme.spacing(1)),
  },
  circularProgress: {
    marginLeft: 0,
    marginRight: theme.spacing(1),
    color: "#fff",
  },
  qtySripts: {
    "& .MuiTextField-root": {      
      width: "15ch",      
    },
    "& .MuiInputBase-adornedStart": {      
      width: "15ch",
      marginRight: theme.spacing(1),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,    
  },

});

function ChestIcon(props){
  return(
    <SvgIcon  >
      <ChestImg {...props} />
    </SvgIcon>
  );
}

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.dialogTitle} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

// function renderInputComponent(inputProps) {
//   const { classes, inputRef = () => {}, ref, ...other } = inputProps;
  
//   return (
//       <TextField        
//         fullWidth
//         InputProps={{          
//           inputRef: node => {
//             ref(node);
//             inputRef(node);
//           },
//           classes: {
//             input: classes.input,
//           },
//         }}
//         {...other}
//       />
//   );
// }

// function renderSuggestion(suggestion, { query, isHighlighted }) {

//   const matches = match(`${suggestion.Code}  ${suggestion.LocalName}`, query);
//   const parts = parse(`${suggestion.Code}  ${suggestion.LocalName}`, matches);    
    
//   return (
//       <MenuItem selected={isHighlighted} component="div">        
//         <ListItemIcon style={{minWidth: 42}} >
//           <img height="34" alt={suggestion.Code} src={ `${"https://cdn1.jerelia.com/Pictures/"}${"76x76"}/${suggestion.Image}`} />
//         </ListItemIcon>
//         <ListItemText style={{ paddingLeft: "0px" }}>
//           <span className={"menu-price"}>
//             {parseFloat(suggestion.CatalogPrice).toFixed(2)}</span>
//           {parts.map((part, index) =>
//             part.highlight ? (
//               <span key={String(index)} style={{ fontWeight: 500 }}>
//                 {part.text}
//               </span>
//             ) : (
//               <strong key={String(index)} style={{ fontWeight: 300 }}>
//                 {part.text}
//               </strong>
//             ),
//           )}
//         </ListItemText>
//       </MenuItem>
//   );
// }

function rowPrice(row, useBusinessPack){

  let qty = row.Qty;
  if(row.Qty !== row.OldQty){
      qty = row.OldQty;
    // return <Badge color="primary" variant="dot">            
    //         <UpdateIcon fontSize="small" />
    //       </Badge>
  }

  if(row.OrderRowDetails.length > 1) {
    return(
    <Badge color="primary" variant="dot">
      {parseFloat((row.Amount - (!useBusinessPack ? row.DiscountCdc : 0)) / qty).toFixed(2)}
    </Badge>
    )
  } else if(row.OrderRowDetails.length === 1){  
    if(row.OrderRowDetails[0].OrderRowPromotions.length > 1){
      return(
        <Badge color="primary" variant="dot">
          {parseFloat((row.Amount - (!useBusinessPack ? row.DiscountCdc : 0)) / qty).toFixed(2)}            
        </Badge>
        )
    }        
    return(      
      parseFloat((row.Amount - (!useBusinessPack ? row.DiscountCdc : 0)) / qty).toFixed(2)
    );
  }
  return(
    parseFloat((row.Amount - (!useBusinessPack ? row.DiscountCdc : 0)) / qty).toFixed(2)    
  );
}

// function rowPricePv(row){  
//   let qty = row.Qty;
//   if(row.Qty !== row.OldQty){
//       qty = row.OldQty;   
//   }
//   return (parseFloat((row.AmountPv -  (!Order.UseBusinessPack ? row.DiscountCdcPv : 0)) / qty).toFixed(2))
// }

function rowAmount(row){
  // if(row.Qty !== row.OldQty){
  //   return <UpdateIcon fontSize="small" />
  // }
  return (parseFloat(row.Amount - (!Order.UseBusinessPack ? row.DiscountCdc : 0)).toFixed(2))
}

// function rowAmountPv(row){  
//   return (parseFloat(row.AmountPv - (!Order.UseBusinessPack ? row.DiscountCdcPv : 0)).toFixed(2))
// }

function rowPersonalVolumePv(row){
  // if(row.Qty !== row.OldQty){
  //   return <UpdateIcon fontSize="small" />;
  // }
  return (parseFloat(row.PersonalVolumePv).toFixed(2))
}

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const GetOrderRowTypeName = function (val) {
  switch (val) {
    case 0:      
      return "Default";
    case 1:
      return "Promotion";
    case 2:
      return "Chest";
    case 3:
      return "Script";
    default:
      return "Default";
  }
}

// const ITEM_WIDTH = 400;
// const ITEM_HEIGHT = 360;


class Order extends React.Component {
  constructor(props) {
    super(props);
    const { cookies } = props;
    const refId = cookies.get("ref");
    
    // const days = 31;
    const id = this.props.match.params.id;
    
    // if(id){
    //   cookies.set("oderId", id, { path: "/", maxAge: days * 24 * 60 * 60 });
    // }else{
    //   id=cookies.get("oderId");
    // }

    // console.log("Order ID:", id);

    this.state = {
      open: false,
      backdrop: false,
      imgCDN: "https://cdn1.jerelia.com/Pictures/",
      imgLgSize: "392x392",
      imgMdSize: "183x183",
      imgXsSize: "76x76",
      expanded: false,
      expandedPayment: true,
      expandedDelivery: true,
      Transition: null,
      suggestion: null,            
      qty: 1,          
      popper: "",
      postoffice: "",
      Comment: "",
      isLoading: false,
      id: id,
      openPromo: false,
      openPromotions: false,
      openScripts: false,
      selectedOrderRowDetail: null,
      selectedOrderRowDetailCaptions: "",
      selectedPromotions: null,
      expandedPromotions: "panel1",
      expandedRecipient: false,
      openListProducts: false,
      titleDlgPromotions: "",
      titleDlgListProducts: "",
      listproducts: [],
      promotions:[],
      rowsSelected:[],
      optionsPostOffices: [],
      selectedPostOffice: null,
      searchAddr: "",
      selectedPlace: [],
      columns: this.getActionsColums(),      
      isAnonym : false,
      refId: refId,
      payBlock: React.createRef(),
      recipientBlock: React.createRef(),      
      disabledConfirm: false,
      indexPromoTab: "1",
      asOriflame: false,
      
      errorRecipientFirstName: false,            
      errorRecipientLastName: false,            
      errorRecipientMiddleName: false,            
      errorRecipientPhone: false,            
      errorRecipientMail: false    
    };

    this.infiniteLoaderRef = React.createRef();    
    this.timeout =  null;
    const {t} = this.props;
    this.t = t;    
  }

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  setBackdrop = async () => {
    const {backdrop} = this.state;
    this.setState({backdrop: !backdrop});
  }

  getUser = async () => {      
  const userManager = await OidcUserManager();
      userManager.getUser().then(async (user) => {

        if (!user || !user.access_token) {
          this.setState({expandedRecipient: true, isAnonym: true});
        }

      });

  }

  componentDidMount() {
    window.scrollTo({top: 0, behavior: "smooth"});            
    
    let id = this.props.match.params.id;          
    this.setState({id: id});
    // console.log("componentDidMount ID:", id);
    this.props.actions.requestOrder(id, id); 
    this.props.actions.requestRecommended();
    this.props.actions.requestPromotions();    
    this.props.actions.requestChest();    
    this.props.actions.requestDeliveries();
    this.getUser();
  }

  componentDidUpdate(prevProps, prevState) {    
    // console.log("1. componentDidUpdate");
    if(prevState.id !== this.state.id) {
      // console.log("2. componentDidUpdate from state ID", this.state.id);        
      this.props.actions.requestOrder(this.state.id, this.state.id);
      this.props.actions.requestRecommended();
      this.props.actions.requestPromotions();
      this.props.actions.requestChest();
      this.props.actions.requestDeliveries();     
    }    
  }

  
  
  static getDerivedStateFromProps(nextProps, prevState){    
    if(nextProps.match.params.id !== prevState.id){        
      return { id: nextProps.match.params.id, neworder: nextProps.neworder};
    }
    else return null;
  }         

  // shouldComponentUpdate(nextProps){
  //   if(nextProps.redirect && nextProps.Order.ExtOrderId !== "00000000-0000-0000-0000-000000000000" ){                  
  //     this.props.actions.requestNewOrder(this.state.refId);

  //     const payment = this.selectedPaymentType();
                  
  //     if(payment && (payment.Name === "Предоплата" || payment.Name === "Передплата") ){                
  //       this.props.history.push(`/pay/${nextProps.Order.ExtOrderId}`);        
  //     }else  {
  //       this.props.history.push(`/order/thanks/${nextProps.Order.ExtOrderId}`);        
  //     }      
  //   }
  //   return true;
  // }

  handelChangeAsOrifleme = () => {
    const {asOriflame} = this.state;

    this.setState({asOriflame: !asOriflame});
  };

  getActionsColums = () => {
      const { classes } = this.props;  
      const columns =  [         
        { 
          name: "Product.Code",
          label: "Code"
        }, 
        { 
          name: "Product.Name",
          label: "Product name"
        },
        {
          name: "Product.CatalogPrice",
          label: "Price",
          options: {
            customBodyRender: (value) => (
              <span className="price--line-through">{parseFloat(value).toFixed(2)}</span>
            )
          }
        },        
        {
          name: "Prices",
          label: "My Price",
          filter: false,
          sort: false,
          options: {
            filter: false,
            sort: false,
            customBodyRender: (value) => (              
              <span className={classes.colorRed}>{parseFloat(value[0]).toFixed(2)}</span>
            )
          } 
        },
        {           
          name: "Qty",
          label: "Qty",
          filter: false,
          options: {
            customBodyRender: (value, tableMeta, updateValue) => (            
              <TextField                  
                  type="number" margin="normal"
                  inputProps={{ min: 1, max: 1000  }}
                  value={value === 0 ? "" : value}
                  onChange={event => { 
                    updateValue(event.target.value);

                    const ActionByCondition = {...this.state.selectedPromotions.ActionByCondition };
                    const products = ActionByCondition.Products.slice();                    
                    products[tableMeta.rowIndex].Qty = parseInt(event.target.value);                    
                    ActionByCondition.Products = products;
                    this.setState({ActionByCondition: ActionByCondition});
                  }}
                />              
            )
          }
        },
        {
          name: "Buy",
          options: {
            filter: false,
            sort: false,
            empty: true,
            // customBodyRender: (value, tableMeta, updateValue) => {
            customBodyRender: (value, tableMeta) => {
              return (
                <Button                                                        
                    color="primary" variant="outlined" size="small"                   
                    onClick={ () =>  {
                      // console.log(tableMeta.rowData);
                      this.AddSigleToOrder(this.state.selectedPromotions.ActionByCondition.Products[tableMeta.rowIndex], tableMeta.rowData[4] );
                      // window.alert(`Clicked "Buy" for row ${tableMeta.rowIndex}`); 
                    }}
                    >
                    <AddShoppingCard />
                </Button>
              );
            }
          }
        },
      ];
      return columns;
  };

  createOrderPos = (data, promo) => {    
    let price = data.Product.CatalogPrice;
    let promotionType = promo.PromotionType === 1 ? 2 : 0;
    
    switch (promo.PromotionType) {
      case 0:
        price = data.Product.CatalogPrice;
        break;
      case 1:
        price = data.Prices[0];        
        break;
      case 2:
        price = data.Prices[0];        
        break;
      default:
        price = data.Product.CatalogPrice;
        break;
    }

    const position = {
      ParentIdrref: data.Product.Idrref,
      ProductId: data.Product.Id,
      orderrowtype: promotionType,
      Name: data.Product.Name,
      LocalName: data.Product.Name,
      Price: price,
      CatalogPrice: data.Product.CatalogPrice,
      CatalogPricePv: data.Product.CatalogPricePv,
      Rate: data.Rate === 0 ? parseFloat(data.Product.CatalogPrice / data.Product.CatalogPricePv).toFixed(2) : data.Rate,
      Code: data.Product.Code,
      Image: data.Product.Image,
      Source: { 
        Id: promo.Id,
        Idrref: promo.Idrref,
        Name: promo.Name,
        Description: promo.Description
      }
    };
    return position;     
  }  

  callBackFn = (id) => {
    if(id > 0){
      // const { cookies } = this.props;
      // const days = 10;
      // cookies.set("oderId", id, { path: "/", maxAge: days * 24 * 60 * 60 });
      this.setState({id: id});
      this.props.history.push(`/order/${id}`);
    }
  };

  handleAddSelectedProducts = () => {
      if(this.state.rowsSelected.length === 0 ){
        return;
      }      
      const positions = [];
      for (let i = 0; i < this.state.rowsSelected.length; i++) {
        
        const element = this.state.rowsSelected[i];
        const data = this.state.selectedPromotions.ActionByCondition.Products[element.dataIndex]
        if(parseInt(data.Qty) <= 0 ){
          continue;
        }
        
        const pos = this.createOrderPos(data, this.state.selectedPromotions);  
        positions.push({ pos: pos, qty: parseInt(data.Qty), orderrowtype: 3 } );      
      }
      
      if(positions.length > 0){
        this.props.actions.addToOrder(positions, this.callBackFn);
      }      
  };

  AddSigleToOrder = (data, qty) => {      
      if(parseInt(qty) === 0){
        return;
      }      
      const pos = this.createOrderPos(data, this.state.selectedPromotions);              
      this.props.actions.addToOrder( [{ pos: pos, qty: parseInt(qty), orderrowtype: pos.orderrowtype }], this.callBackFn);  
    };
    
  handleScriptQtyChange = (e, si, ri) => {      
    this.props.actions.updFromScripts(si, ri, parseInt(e.target.value));
  };

    /**
     * e - event
     * ri - row index
     * di - row detail index
     */
    handleQtyChange = (e, ri, di) => {
      // console.log("handleQtyChange");
      const qty = parseInt(e.target.value) > 0 ? e.target.value : 1;            
      this.props.actions.updRow(ri, di, parseInt(qty));
      // actions.updToOrder(ri, di, parseInt(qty));
    };
    
    handleUpdRow = (e, ri, di) => {
      const { Order } = this.props;
      const row = Order.Rows[ri];
      if(row.Qty === row.OldQty)
        return;
        this.props.actions.updToOrder(ri, di, parseInt(row.Qty));
      // console.log(`PDATE! handleUpdRow: ${row.Qty}`);
    };

    handleUpdRowIncDec = (e, ri, di, qty) => {
      e.stopPropagation();
      const { Order } = this.props;
      const row = Order.Rows[ri];
      if(row.Qty + qty >= 1 ){
        this.props.actions.updToOrder(ri, di, parseInt(row.Qty + qty));
      }
    };


    triggerChange = (index, qty) => {
      const { actions } = this.props;
      actions.updToOrder(index, qty);
    }

    /** Add from search */
    handleClickAddToOrder = () => {
      const {disabled} = this.props;
      if(disabled === true)
        return;
        
      const { suggestion } = this.state;
      
      if(!suggestion) {
        return;
      }
                  
      this.props.actions.addToOrder( 
        [
          { pos: suggestion, qty: parseInt(this.state.qty), orderrowtype: 0 }
        ],this.callBackFn
      );
      
    }
    
    handleSuggestionsFetchRequested = ({ value }) => {
      this.setState({ suggestion: null });              
      const inputValue =  value.trim().toLowerCase(); 
      // deburr(value.trim()).toLowerCase();
      this.props.actions.getProducts(inputValue);
    };
    
    handleSuggestionsClearRequested = () => {        
      this.props.actions.clearProducts();
      // this.setState({ suggestion: null });  
    };
    
    handleChange = (event) => {              
      this.setState({ [event.target.name]: event.target.value  });
    }

    handleChangePopper = name => (event, { newValue }) => {            
      if(newValue.length === 0){
        this.setState({ [name]: newValue, suggestion: null});
      } else {
        this.setState({ [name]: newValue });
      }      
    };

    getSuggestionValue = (suggestion) => {
      // console.log(suggestion);      
      this.setState({
        suggestion: suggestion,
      });
      return suggestion.LocalName;
    };
    
    ShowPromotionsClick = () => {      
      this.setState({ 
        open: true, 
        titleDlgPromotions: `${this.t("order.Promotions")}: ${this.props.promotions.length}`,
        promotions: [...this.props.promotions] });
    };

    ShowPromoDetail = (e, rIdx, dIdx) => {
      e.stopPropagation();
      const { Order } = this.props; 
      const row = Order.Rows[rIdx];
      const detail = row.OrderRowDetails[dIdx];
      // console.log("Details", detail);
      this.setState({ openPromo: true, selectedOrderRowDetailCaptions: `${row.Code} - ${row.Name}`,  selectedOrderRowDetail: detail });
    };
 
    HidePromoDetail = () => {
      this.setState({ openPromo: false, selectedOrderRowDetail: null });
    };

    ShowScriptsDetail = () => {
      this.setState({ openScripts: true, disabledConfirm: false });
    }

    HideScriptsDetail = () => {
      this.setState({ openScripts: false, disabledConfirm: false });
    }

    handleShowChestProducts = () => {
      this.setState({ 
        open: true, 
        titleDlgPromotions: `${this.t("order.Chest")}. ${this.t("order.YouHavePromo", {qty: this.props.chests.length})} `,
        promotions: [...this.props.chests] });
      // const {t} = this.props;
      // this.setState({openListProducts: true, titleDlgListProducts: `${t("order.CreditedChest")} ${this.props.Order.QtyChest}`, listproducts: [...this.props.chests] });
    };

    handleShowRecommendedProducts = () => {
      const {t} = this.props;
      this.setState({openListProducts: true, titleDlgListProducts: t("order.Recommended"), listproducts: [...this.props.recommended] });
    };

    handelHedeDlgProducts = () =>{
      this.setState({openListProducts: false, titleDlgListProducts: ""});
    }; 

    ShowPromotions = () => {
      this.setState({openPromotions: true});  
    };

    HidePromotions = () => {
      this.setState({openPromotions: false});  
    };

    handleClose = () => {
      this.setState({ open: false });
    };
    
    handleClickPromotions = (item) => {
      this.setState({selectedPromotions: item, openPromotions: true});
      // console.log("selectedPromotions: ", item);
    };

    SetExpandedPromotions = (flag) => {
      this.setState({expandedPromotions: flag});
    };

    handleExpandedPromotions = panel => (event, isExpanded) => {
      this.SetExpandedPromotions(isExpanded ? panel : false);
    };

    HideAnonymDlg = () => {
      this.setState({isAnonym: false});
    };
    
    // checkAnonym = async () => {
    //   this.continueConfirmOrder();
    //   // const userManager = await OidcUserManager();
    //   // userManager.getUser().then(async (user) => {        
    //   //   if (!user || !user.access_token) {
    //   //     this.setState({isAnonym: true});
    //   //   }
    //   //   else{
    //   //     this.continueConfirmOrder();
    //   //   }
    //   // });
    // };

    continueConfirmOrder = () => {
      const { Order, actions, t } = this.props;                 
      const selectedDeliveryProvider = this.SelectedDeliveryProvider();
      // console.log(selectedDeliveryProvider);

      if(!selectedDeliveryProvider){        
          actions.showMessage(t("order.Errors.NotDeliveryProvider"), "error");
          // window.scrollTo(0, this.state.payBlock);
          this.state.payBlock.current.scrollIntoView({ behavior: "smooth", block: "start" });
          return;
      }

      const emptyGuild = "00000000-0000-0000-0000-000000000000";

      if(Order.DeliveryProviderId === emptyGuild ){
        actions.showMessage(t("order.Errors.NotDeliveryProvider"), "error");
        return;
      }

       // console.log(Order.DeliveryProviderId);

      // TODO: Hard code! Need how to algorithm implantation
      if(Order.DeliveryProviderId === "B7351862-EDA0-11E4-80C8-00505696333E" || Order.DeliveryProviderId === "614F09AD-2CE7-11E8-80BD-8206460542B5" ){        
        
        if(Order.RecipientPostCode.length === 0){
          actions.showMessage(t("order.Errors.NotSelectedZipCode"), "error");
          this.setState({expandedRecipient: true });
          return;
        }

        if(Order.RecipientRegion.length === 0){
          actions.showMessage(t("order.Errors.NotSelectedRegion"), "error");
          this.setState({expandedRecipient: true });
          return;
        }

        if(Order.RecipientRaion.length === 0){
          actions.showMessage(t("order.Errors.NotSelectedRaion"), "error");
          this.setState({expandedRecipient: true });
          return;
        }

        if(Order.RecipientVillageCityTown.length === 0){
          actions.showMessage(t("order.Errors.NotSelectedCity"), "error");
          this.setState({expandedRecipient: true });
          return;
        }

        if(Order.RecipientStreetname.length === 0){
          actions.showMessage(t("order.Errors.NotSelectedStreetname"), "error");
          this.setState({expandedRecipient: true });
          return;
        }

        if(Order.RecipientStreetnameNumber.length === 0){
          actions.showMessage(t("order.Errors.NotSelectedStreetnameNumber"), "error");
          this.setState({expandedRecipient: true });
          return;
        }
      }
      else{
        if(Order.PostOffice === null || Order.PostOfficeId === ""){
          actions.showMessage(`${selectedDeliveryProvider.Name.toUpperCase()}! ${t("order.Errors.NotSelectePostOffice")}`, "error");
          return;
        }        
      }
                        
      if(Order.RecipientFirstName.length === 0){
        actions.showMessage(t("order.Errors.NotRecipientFirstName"), "error");
        this.setState({expandedRecipient: true, errorRecipientFirstName: true });
        this.state.recipientBlock.current.scrollIntoView({ behavior: "smooth", block: "start" });
        return;
      }
      
      if(Order.RecipientLastName.length === 0){
        actions.showMessage(t("order.Errors.NotRecipientLastName"), "error");
        this.setState({expandedRecipient: true, errorRecipientLastName: true });
        this.state.recipientBlock.current.scrollIntoView({ behavior: "smooth", block: "start" });
        return;
      }

      if(Order.RecipientMiddleName.length === 0){
        actions.showMessage(t("order.Errors.NotRecipientMiddleName"), "error");
        this.setState({expandedRecipient: true, errorRecipientMiddleName: true });
        this.state.recipientBlock.current.scrollIntoView({ behavior: "smooth", block: "start" });
        return;
      }

      if(Order.RecipientPhone.length === 0){
        actions.showMessage(t("order.Errors.NotRecipientPhone"), "error");
        this.setState({expandedRecipient: true, errorRecipientPhone: true });
        this.state.recipientBlock.current.scrollIntoView({ behavior: "smooth", block: "start" });
        return;
      }
            
      actions.requestScripts(this.props.Order.Id, this.showDlgScripts);
      // actions.requestScripts(this.props.Order.Id);

      // this.setState({openScripts: true});
    };

    handleConfirmOrder = () => {      
      this.continueConfirmOrder();
      //this.props.actions.requestNewOrder();
      // this.checkAnonym();            
    };

    showDlgScripts = (show) => {      
      this.setState({openScripts: show});
      
      if(show === false){
        this.setBackdrop();
        this.props.actions.confirmOrder(this.handleConfirmedOrder);        
        //this.props.actions.requestNewOrder();
      }
      

      // this.setState({openScripts: true});
      // if(this.props.scripts.length > 0){
      //   this.setState({openScripts: true});
      // }
    };

    handelExpandedPanelsClick = (name) => {
      const flag = this.state[name];
      if(name === "expandedDelivery")
        this.setState({ expandedDelivery: !flag });
      else
        this.setState({ expandedPayment: !flag });
    };

    handelDelFromOrder = (e, index ) => {
      this.props.actions.delFromOrder(e, index, this.props.t("order.DeletedRow"));      
    };

    handelChangeSelectedProducts = (e, val) => {
      this.setState({suggestion: val});
      // console.log("handelChangeSelectedProduct", val);      
    };

    handelChangeSearchProducts = (e, val) => {      
      if (val === "") {
        this.setState({suggestion: null});                
        return false;
      }

      if(val.length < 3){
        return false;
      }

      // console.log("Search products by value", val);
      this.props.actions.getProducts(val);
      
    };

    handelChangeSearchAddr = (e, val) => {
      
      if (val === "") {
        this.setState({
          optionsPostOffices: [],          
          selectedPlace: null,
        });
        // setOptions(value ? [value] : []);
        return false;
      }

      if(val.length < 3){
        return false;
      }


      // console.log("Search by value", val);

      const delivery = this.SelectedDeliveryProvider();
      const url = `/api/v1.0/PostOffice/${val}/${delivery.Name}`;


      const self = this;
      axios.get(url)
      .then(function(response){
        // console.log("PostOffices", response.data);
        self.setState({optionsPostOffices: response.data });
      })
      .catch((error) => {
        this.setState({optionsPostOffices: [] });
        console.log(error);
      });      
    };

    handelChangeSelectedAddr = (e, val) => {
      const { actions } = this.props;
      // console.log("handelChangeSelectedAddr", val);
      // this.setState({ selectedPostOffice: val });
      actions.selectPostOffice(val);

      // setOptions(newValue ? [newValue, ...options] : options);
                      // setValue(newValue);
    };

    defultDelivery = () => {
      const { deliveries } = this.props;      
      if(deliveries.length === 0) {
        return null;        
      }
      for (let i = 0; i < deliveries.length; i++) {
        const el = deliveries[i];
        if(el.Name === "Новая Почта"){
          return el.Id;
        }        
      }
      return deliveries[0].Id;
    };

    SelectedDeliveryProvider = () =>{
      const { deliveries, Order } = this.props;
      
      for (let i = 0; i < deliveries.length; i++) {
        const delivery = deliveries[i];
        if(delivery.Id === Order.DeliveryProviderId){
          return delivery;          
        }
      }    
      return null;
    };

    getPaymentTypeById = (paymentId) => {
      const { deliveries } = this.props;      
      for (let i = 0; i < deliveries.length; i++) {
        const delivery = deliveries[i];
        for (let y = 0; y < delivery.PaymentTypes.length; y++) {
          const payment = delivery.PaymentTypes[y];
          if(payment && payment.Id === paymentId){
            return payment;
          } 
        }
      }                      
      return null;
    };

    selectedPaymentType = () => {
      const delivery = this.SelectedDeliveryProvider();
      const { Order } = this.props;
      if(delivery){
        for (let i = 0; i < delivery.PaymentTypes.length; i++) {
          const payment = delivery.PaymentTypes[i];
          if(payment && payment.Id === Order.PaymentTypeId){
            return payment;
          }
        }
      }
      return null;
    };
    
    amountPaymentType = () => {
      const { t } = this.props;
      const payment = this.selectedPaymentType();
      if(payment && parseFloat(payment.AmountCOD) > 0){
        return (
          !this.state.isAnonym ? 
          <Alert variant="outlined" severity="info">            
            {t("order.PostpaidCost")}: <b>{parseFloat(payment.AmountCOD).toFixed(2)}</b> грн
          </Alert> : null
        );
      }
      return null;
    };

    descrPaymentType = () => {
      const { t } = this.props;
      const payment = this.selectedPaymentType();
      if(payment && payment.Comment.length > 0){
        return (
          !this.state.isAnonym ?  
          <Alert  severity="warning">
            <b>{t("common.Warning")}!</b> {payment.Comment}
          </Alert> : null
        );
      }
      return null;
    };

    descrDeliveryProvider = () => {
      const { t } = this.props;
      const delivery = this.SelectedDeliveryProvider();
      if(delivery && delivery.Comment.length > 0){
        return (
          !this.state.isAnonym ?
          <Alert variant="outlined" severity="info" >
            <Typography variant="caption" display="block" gutterBottom>
              {t("common.Note")}: {delivery.Comment}
            </Typography>            
          </Alert>: null
        );
      }
      return null;
    };

    paymentsDeliveryProvider = () => {
      const { Order, actions, t } = this.props;
      const delivery = this.SelectedDeliveryProvider();
      if(delivery){
        return(                    
            <RadioGroup row aria-label="PaymentTypeId" name="PaymentTypeId" value={Order.PaymentTypeId ? Order.PaymentTypeId : null } defaultValue={null} 
              onChange={(e) => actions.handleChangeFieldValue(e)}>
            { delivery.PaymentTypes.map((payment) => (                      
                <FormControlLabel key={payment.Id} 
                  value={payment.Id} control={<Radio color="primary" />}  label={t(`order.payments.${payment.Name}`)} />
            ))}                            
            </RadioGroup>           
        );  
      }
      return null;
    }

    handleSuggestionsPostOfficeRequested = ({ value }) => {
      // this.setState({ suggestion: null });       
      const val =  value.trim(); //.toLowerCase();

      if (val === "") {
        this.setState({
          optionsPostOffices: [],          
          selectedPlace: null,
        });
        // setOptions(value ? [value] : []);
        return false;
      }

      if(val.length < 3){
        return false;
      }
    
      const delivery = this.SelectedDeliveryProvider();      
      
      const url = `/api/v1.0/PostOffice/${val}/${delivery.Name}`;
      
      const self = this;
      axios.get(url)
      .then(function(response){
        console.log(response.data);

        self.setState({optionsPostOffices: response.data });          
      })
      .catch((error) => {
        this.setState({optionsPostOffices: [] });  
        console.log(error);          
      });
    };
    
    getSuggestionPostOfficeValue = (suggestion) => {
      this.setState({
        selectedPostOffice: suggestion,
      });
      return suggestion.LongName;
    };

    handleSuggestionsClearPostOfficeRequested = () => {        
      // this.props.actions.clearProducts();
      this.setState({ selectedPostOffice: null });
    };

    deliveryMethods = () => {
      const { Order, actions, t } = this.props;
      const {optionsPostOffices} = this.state;
            

      const delivery = this.SelectedDeliveryProvider();
              
      const filterOptions = (options, { inputValue }) => matchSorter(options, inputValue, { keys: ["LongName"] });
          
      if(delivery){
        return(                    
            <div>            
            { delivery.DeliveryMethods.map((method) => (
              <div key={method.RowId}>
                {method.Required ?
                  <div>
                  <Autocomplete
                    id={`searchDeliveryPlace${method.RowId}`}                    
                    filterOptions={filterOptions}
                    // filterOptions={(x) => x}
                    options={optionsPostOffices}
                    // autoComplete
                    // filterSelectedOptions                                        
                    getOptionLabel={(option) => (typeof option === "string" ? option : option.LongName)}
                    
                    getOptionSelected={(option, value) => option.LongName === value.LongName}                                                            
                    
                    onInputChange={(event, newInputValue) => {
                      this.handelChangeSearchAddr(event, newInputValue);
                    }}
                    
                    onChange={(event, newValue) => {
                      this.handelChangeSelectedAddr(event, newValue);                      
                    }}
                    
                    renderInput={(params) => <TextField {...params} label={`${t("common.Search")}: ${method.DeliveryName}`} variant="outlined" />}                    
                    
                    // renderOption={(option) => <Typography variant="body2" display="block">{option.LongName}</Typography>}

                    renderOption={(option, { inputValue }) => {

                      const matches = match(option.LongName, inputValue);
                      const parts = parse(option.LongName, matches);
                      

                      return (
                      
                          <Typography component="div" variant="body2" display="block" >
                          {parts.map((part, index) => (
                            <span key={index} style={{ fontWeight: part.highlight ? 500 : 300 }}>
                              {part.text}
                            </span>
                          ))}
                          
                          <Link color="inherit" title="open on the map" target="blank" href={`https://www.google.com/maps/place/${option.Latitude},${option.Longitude}`} variant="body2">
                            <RoomOutlinedIcon fontSize="small" />
                          </Link>
                          {/* <a title="open on the map" target="blank" href={`https://www.google.com/maps/place/${option.Latitude},${option.Longitude}`}>
                            <RoomOutlinedIcon fontSize="small" />
                          </a>  */}
                          
                          </Typography>                                                                                
                      );
                    }}

                  />

                  <Typography variant="caption" display="block" gutterBottom>
                    {t("order.SearchHelpPostOffice")}
                  </Typography>

                  { Order.PostOffice &&                     
                      <Alert icon={false} onClose={() => { actions.selectPostOffice(null);}}> <b>{t("order.PostOffice")}</b>: { `${ t(`carriers.${Order.PostOffice.Carrier}`) }, ${Order.PostOffice.LongName}` }</Alert>                    
                  }  

                  </div> :
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="RecipientPostCode">{t("order.RecipientPostCode")}</InputLabel>
                          <OutlinedInput
                            type="number"
                            id="RecipientPostCode"
                            name="RecipientPostCode"                      
                            value={Order.RecipientPostCode}
                            required
                            onChange={(e) => actions.handleChangeFieldValue(e)}
                            labelWidth={100}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="RecipientRegion">{t("order.RecipientRegion")}</InputLabel>
                          <OutlinedInput
                            id="RecipientRegion"
                            name="RecipientRegion"
                            value={Order.RecipientRegion}
                            required
                            onChange={(e) => actions.handleChangeFieldValue(e)}
                            labelWidth={100}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="RecipientRaion">{t("order.RecipientRaion")}</InputLabel>
                          <OutlinedInput                      
                            id="RecipientRaion"
                            name="RecipientRaion"                      
                            value={Order.RecipientRaion}
                            required
                            onChange={(e) => actions.handleChangeFieldValue(e)}                      
                            labelWidth={100}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="RecipientVillageCityTown">{t("order.RecipientVillageCityTown")}</InputLabel>
                          <OutlinedInput                      
                            id="RecipientVillageCityTown"
                            name="RecipientVillageCityTown"                      
                            value={Order.RecipientVillageCityTown}
                            required
                            onChange={(e) => actions.handleChangeFieldValue(e)}                      
                            labelWidth={100}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="RecipientStreetname">{t("order.RecipientStreetname")}</InputLabel>
                          <OutlinedInput                      
                            id="RecipientStreetname"
                            name="RecipientStreetname"                      
                            value={Order.RecipientStreetname}
                            required
                            onChange={(e) => actions.handleChangeFieldValue(e)}                      
                            labelWidth={100}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="RecipientStreetnameNumber">{t("order.RecipientStreetnameNumber")}</InputLabel>
                          <OutlinedInput                      
                            id="RecipientStreetnameNumber"
                            name="RecipientStreetnameNumber"                      
                            value={Order.RecipientStreetnameNumber}
                            required
                            onChange={(e) => actions.handleChangeFieldValue(e)}                      
                            labelWidth={100}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth  variant="outlined">
                        <InputLabel htmlFor="RecipientApartment">{t("order.RecipientApartment")}</InputLabel>
                          <OutlinedInput                      
                            id="RecipientApartment"
                            name="RecipientApartment"                      
                            value={Order.RecipientApartment}
                            required
                            onChange={(e) => actions.handleChangeFieldValue(e)}                      
                            labelWidth={100}
                        />
                      </FormControl>
                    </Grid>
                    </Grid>
                   }
                  <br />
                  <Alert variant="outlined" severity="info">                  
                    {/* <b>{t("common.Warning")}!</b>  */}
                    <b>{parseFloat(method.CostDelivery).toFixed(2)}</b> <small>грн</small>, {t("order.AmountDelivery")}
                    {/* <br />
                    <small>{method.DeliveryName} : {method.Name}</small><br />
                    <small> {method.AmountDevivery} + {method.AmountFixedRate} = {method.CostDelivery}</small> */}
                  </Alert>
              </div>                
            ))}
            </div>
        );  
      }
      return null;
    }; 
    
    handleConfirmedOrder = ( Order ) => {      
      // console.log("EVENT handleConfirmedOrder after confirm ORDER");            
      // PaymentTypeId: "F6D8A40E-12EA-4392-BF3A-6DE1E685F191"
      console.log(Order);
      
      if(Order.ExtOrderId !== "00000000-0000-0000-0000-000000000000" ){
        // this.props.actions.requestNewOrder(this.state.refId);
        const payment = this.getPaymentTypeById(Order.PaymentTypeId);
        let auth = "noreg";
        
        if(Order.UserName !== 9999999){
          auth = "reg";
        }

        if(payment && (payment.Name === "Предоплата" || payment.Name === "Передплата") ){                
          this.props.history.push(`/pay/${Order.ExtOrderId}`);        
        }else  {
          
          this.props.history.push(`/orderfin/${auth}/cod/${Order.ExtOrderId}`);
          // this.props.history.push(`/order/thanks/${Order.ExtOrderId}`);        
        }      
      }      
    }

    handleDlgBtnConfirm = () => {
      this.setState({disabledConfirm: true});      
      this.props.actions.confirmOrder(this.handleConfirmedOrder);
    };

    handleChangeIndexPromoTab = (event, newValue) => {
      this.setState({ indexPromoTab: newValue});
    };    

    render(){
      const { classes, Order, actions, suggestions, chests, deliveries, scripts, isLoading, t, 
              width, accessBusinessAttr, accessUsePaymentOBAttr, disabled } = this.props; 

      const { suggestion, promotions, selectedOrderRowDetail, selectedOrderRowDetailCaptions, 
              selectedPromotions, Comment, expandedRecipient, 
              errorRecipientFirstName, errorRecipientLastName, errorRecipientMiddleName, errorRecipientPhone, errorRecipientMail
            } = this.state;
                         
      const filterOptions = (options, { inputValue }) =>
              matchSorter(options, inputValue, {
                keys: ["Code", "Name"],
              });
            
      const RightPanel = (
        <React.Fragment>
          {/* Delivery */}
          <Accordion defaultExpanded TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header">

              <FormControlLabel
                aria-label="DeliveryInfo"
                control={<Filter1Icon className={classes.step} />}
                label={ <Typography color="primary">{t("order.SelectDeliveryService")}</Typography>}
              />
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1} direction="row" justify="center" >
                {/* <Grid item xs={12}>
                  <Button variant="contained" fullWidth startIcon={<HistoryIcon />}>
                    {t("order.DeliveryHistory")}...
                  </Button>
                </Grid> */}
                <Grid item xs={12}>
                  <Divider />
                  <FormControl component="fieldset">
                    { deliveries.length > 0 &&
                    <RadioGroup row aria-label="DeliveryProviderId" name="DeliveryProviderId" value={Order.DeliveryProviderId } defaultValue={Order.DeliveryProviderId } 
                      onChange={(e) => actions.handleChangeFieldValue(e)}>
                    { deliveries.map((delivery) => (                      
                        <FormControlLabel  key={delivery.Id} 
                          value={delivery.Id} control={<Radio color="primary" />} 
                          label={t(`carriers.${delivery.Name}`)} />
                    ))}                            
                    </RadioGroup>
                    }
                  </FormControl>
                </Grid>                
                <Grid item xs={12}>
                  {this.deliveryMethods()}
                </Grid>              
                <Grid item xs={12}>
                  {this.descrDeliveryProvider()}
                </Grid>
                <Grid ref={this.state.recipientBlock} item xs={12}>
                  <Button variant="contained" fullWidth startIcon={<HowToRegIcon />} color="primary"
                    onClick={ () => { this.setState({expandedRecipient: !expandedRecipient })}}
                  >
                    {t("order.RecipientInfo")}...
                  </Button>
                  
                  <Collapse in={expandedRecipient} timeout="auto" unmountOnExit>
                    {Order && 
                    <Grid container spacing={1} direction="row" justify="center" style={{paddingTop: "16px"}} >                      
                      <Grid item xs={12}>

                        <FormControl fullWidth className={classes.labelControl} variant="outlined">
                          <InputLabel htmlFor="RecipientLastName">{t("order.RecipientLastName")} *</InputLabel>
                            <OutlinedInput                      
                              id="RecipientLastName"
                              name="RecipientLastName"
                              required
                              error={errorRecipientLastName}
                              value={Order.RecipientLastName ? Order.RecipientLastName : ""}
                              onChange={(e) => actions.handleChangeFieldValue(e)}
                              labelWidth={90}                              
                          />
                        </FormControl>
                        <FormControl fullWidth className={classes.labelControl} variant="outlined">
                          <InputLabel htmlFor="RecipientFirstName">{t("order.RecipientFirstName")} *</InputLabel>
                            <OutlinedInput                      
                              id="RecipientFirstName"
                              name="RecipientFirstName"                      
                              value={Order.RecipientFirstName}
                              required
                              error={errorRecipientFirstName}
                              onChange={(e) => actions.handleChangeFieldValue(e)}
                              labelWidth={90}                              
                          />
                        </FormControl>
                        <FormControl fullWidth className={classes.labelControl} variant="outlined">
                          <InputLabel htmlFor="RecipientMiddleName">{t("order.RecipientMiddleName")} *</InputLabel>
                            <OutlinedInput                      
                              id="RecipientMiddleName"
                              name="RecipientMiddleName"
                              required
                              error={errorRecipientMiddleName}
                              value={Order.RecipientMiddleName}
                              onChange={(e) => actions.handleChangeFieldValue(e)}
                              labelWidth={90}                              
                          />
                        </FormControl>
                        <FormControl fullWidth className={classes.labelControl} variant="outlined">
                          <InputLabel htmlFor="RecipientPhone">{t("order.RecipientPhone")} *</InputLabel>
                            <OutlinedInput                      
                              id="RecipientPhone"
                              name="RecipientPhone"
                              required
                              error={errorRecipientPhone}
                              value={Order.RecipientPhone}
                              onChange={(e) => actions.handleChangeFieldValue(e)}
                              labelWidth={90}
                          />
                        </FormControl>
                        
                        <FormControl fullWidth className={classes.labelControl} variant="outlined">                          
                          <InputLabel htmlFor="RecipientMail">{t("order.RecipientMail")} *</InputLabel>
                            <OutlinedInput                      
                              id="RecipientMail"
                              name="RecipientMail"
                              type="email"
                              required
                              error={errorRecipientMail}
                              value={Order.RecipientMail ? Order.RecipientMail: "" }
                              onChange={(e) => actions.handleChangeFieldValue(e)}
                              labelWidth={90}
                          />
                        </FormControl>                        
                      </Grid>
                    </Grid>
                  }
                  </Collapse> 
                </Grid>
              </Grid>                                                         
            </AccordionDetails>
          </Accordion>
          {/* Delivery */}

          {/* Payment */}
          <Accordion defaultExpanded TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header">

              <FormControlLabel
                aria-label="PaymentInfo"
                control={<Filter2Icon className={classes.step} />}                
                label={ <Typography color="primary">{t("order.SelectPaymentMethod")}</Typography>}
              />                
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1} direction="row" justify="center" >
                { (accessBusinessAttr || accessUsePaymentOBAttr)  && 
                <React.Fragment>                   
                <Grid item xs={12}>                  
                  <FormControlLabel
                      control={
                        <Checkbox checked={Order.UsePaymentOB ? Order.UsePaymentOB: false }
                          onChange={(e) => actions.handleChangeFieldValue(e)}
                          name="UsePaymentOB"
                          color="primary"
                      />
                    }
                    label={t("order.UsePaymentOB")}
                  />     
                </Grid>
                
                <Grid item xs={12}>
                  <FormControl fullWidth style={{marginTop: "8px"}} variant="outlined">
                    <InputLabel htmlFor="AmountOB">{t("order.AmountOB")}</InputLabel>
                      <OutlinedInput                                      
                      type="number"
                      id="AmountOB"
                      name="AmountOB"
                      inputProps={{ min: 0 }}
                      value={Order.AmountOB ? Order.AmountOB : 0}
                      onChange={(e) => actions.handleChangeFieldValue(e)}
                      startAdornment={<InputAdornment position="start">грн</InputAdornment>}
                      labelWidth={230}                                
                      readOnly = {!Order.UsePaymentOB}                                
                    />
                  </FormControl>
                </Grid>
                </React.Fragment>
                }
                
                <Grid item xs={12}>                
                  {this.paymentsDeliveryProvider()}
                </Grid>
                  {this.descrPaymentType()}
                <Grid item xs={12}>
                  {this.amountPaymentType()}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          {/* Payment */}
        </React.Fragment>
      );

      return (
        <div className={classes.root}>

        {/* <Backdrop className={classes.backdrop} open={this.state.backdrop} onClick={this.setBackdrop}></Backdrop> */}
          { isLoading && 
              <LinearProgress className={classes.progress} 
              classes={{
                colorPrimary: classes.linearColorPrimary,
                barColorPrimary: classes.linearBarColorPrimary,
              }}
          />
          }
      
          {/* Body section */}
          {Order && 
          <Grid container spacing={1} direction="row" justify="center" >
            {/* Order section  */}
            <Grid item xs={12} lg={9}>              
              {/* Order header - buttons with promotions */}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper className={classes.paper} elevation={1}>
                      <Grid container spacing={1} direction="row" justify="flex-end" alignItems="center" >
                        <Grid item xs={12} lg={3}>                          
                          {
                            Order.Id === 0 ? 
                              <Typography variant="h5" align={ width === "xs" ? "center": "left" }>{t("order.State.New")}</Typography> : 
                              <Typography variant="h5" align="center">
                                <small><small>№</small> {Order.DocNumber}</small> - 
                                {parseFloat(Order.Amount - Order.DiscountCdc).toFixed(2)} <small><small>({Order.Rows.length})</small></small>
                              </Typography>                              
                          }
                          
                          {/* <div>                            
                            <small>[{this.props.qtyrows}]</small>
                          </div> */}
                        </Grid>
                        <Grid item xs={12} sm={9} lg={7} className={classes.itemR}>
                          <Grid container spacing={2} direction="row" justify={width === "xs" ? "center" : "flex-end" } alignItems="center" >                            
                          { chests.length > 0  && 
                            <Grid item xs={4}>                              
                              <Button fullWidth variant="outlined"
                                onClick={this.handleShowChestProducts}                                
                                // startIcon={<ArchiveIcon className={classes.colorOrange} />}
                                startIcon={<ChestIcon width={24} height={24} />} 
                              >
                                <span className={classes.blabel}>{ t("buttons.Chest") }:</span>&nbsp;{chests.length}
                              </Button>                              
                            </Grid>
                            }

                            { this.props.promotions.length > 0 && 
                            <Grid item xs={4}>                              
                              <Button fullWidth  color="secondary"
                                onClick={this.ShowPromotionsClick}
                                variant="contained"
                                startIcon={<CardMembershipIcon />}                            
                              >
                                <span className={classes.blabel}>{ t("buttons.Promotions") }:</span>&nbsp;{ this.props.promotions ? this.props.promotions.length : "" }
                              </Button>                              
                            </Grid>
                            }

                            {this.props.recommended && this.props.recommended.length > 0 &&  
                            <Grid item xs={4}>
                              
                              <Button fullWidth
                                onClick={this.handleShowRecommendedProducts}
                                variant="outlined" color="default"                              
                                startIcon={<ThumbUpAltOutlinedIcon />}
                              >
                                <span className={classes.blabel}>{ t("buttons.Recommended") }:</span>&nbsp;{this.props.recommended.length}
                              </Button>                              
                            </Grid>
                            }

                          </Grid>                                                  
                        </Grid>
                        
                        { width !== "xs" && 
                        <Grid item xs={12} sm={3} lg={2} className={classes.itemR}>
                          <Button fullWidth 
                              onClick={this.handleConfirmOrder}
                              variant="contained"
                              disabled={Order.Rows.length === 0}
                              // color={theme.palette.success}
                              className={classes.btnSuccess}
                              startIcon={<CheckIcon />}
                            >
                              { t("buttons.ConfirmOrder") }
                            </Button>                            
                        </Grid>
                        }

                        { accessBusinessAttr && 
                        <Grid item xs={12} className={classes.itemR}>
                          <FormControlLabel
                                control={
                                  <Checkbox checked={Order.UseBusinessPack}
                                    onChange={(e) => actions.handleChangeFieldValue(e)}
                                    name="UseBusinessPack"
                                    color="primary"
                                />
                              }
                              label={t("order.BusinessPack")}
                            />
                        </Grid>
                        }
                      </Grid>
                    </Paper> 
                </Grid>
              </Grid>    
            
              {/* Search products controls */}
              <Grid container spacing={2}>
                  <Grid item xs={12}>
                      <Paper className={classes.paper} elevation={1}>
                          <Grid container spacing={2} direction="row" justify="flex-start" alignItems="center" >
                              <Grid item xs={12} sm={8} lg={8}>

                              <Autocomplete
                                id={"searchProduct"}        
                                getOptionLabel={(option) => (typeof option === "string" ? option : option.LocalName)}
                                getOptionSelected={(option, value) => option.LocalName === value.LocalName}
                                options={suggestions.CatalogItems}
                                // multiple
                                // freeSolo
                                autoComplete
                                includeInputInList
                                // filterSelectedOptions
                    
                              filterOptions={filterOptions} 
                              onInputChange={(event, newInputValue) => {
                                this.handelChangeSearchProducts(event, newInputValue);
                              }}
                              onChange={(event, newValue) => {
                                this.handelChangeSelectedProducts(event, newValue);                      
                              }}
                              renderInput={(params) => <TextField {...params} label={`${t("order.SearchProducts")}`} variant="outlined" />}
                              renderOption={(option, { inputValue }) => {
                                  const matches = match(`${option.Code} ${option.LocalName}`, inputValue);
                                  const parts = parse(`${option.Code} ${option.LocalName}`, matches);                                          
                                return (
                                  <Grid container spacing={2}>
                                    {/* <Grid item xs={1} sm={1}>
                                    <img height="34" alt={option.Code} src={ `${"https://cdn1.jerelia.com/Pictures/"}${"76x76"}/${option.Image}`} />
                                    </Grid>   */}
                                    <Grid item xs={3} sm={2}>
                                      <Typography variant="body2" display="block" align="center" >
                                        {parseFloat(option.CatalogPrice).toFixed(2)}
                                      </Typography>                                      
                                    </Grid>
                                    <Grid item xs={9} sm={10} >
                                      <Typography variant="body2" display="block">
                                        {parts.map((part, index) =>
                                          part.highlight ? (
                                            <span key={String(index)} style={{ fontWeight: 500 }}>
                                              {part.text}
                                            </span>
                                          ) : (
                                            <strong key={String(index)} style={{ fontWeight: 300 }}>
                                              {part.text}
                                            </strong>
                                          ),
                                        )}                            
                                      </Typography>
                                    </Grid>                                                                        
                                  </Grid>
                                );
                              }}
                            />
                              </Grid>
                              <Grid item xs={7} sm={2} md={2} >
                                  <FormControl fullWidth  >
                                    <TextField                                      
                                      type="number" name="qty" variant="outlined" inputProps={{ min: 1, max: 100000 }}
                                      label={`${t("order.Qty")}`}
                                      value={this.state.qty}
                                      onChange={e => this.handleChange(e)}
                                      InputProps={{
                                        startAdornment: <InputAdornment position="start">шт</InputAdornment>,
                                      }}                                      
                                    />
                                  </FormControl>
                              </Grid>
                              <Grid item xs={5} sm={2} lg={2} >
                                  <Button variant="contained" color="primary"
                                      fullWidth style={{ paddingTop: "16px", paddingBottom: "16px"}}
                                      onClick = {() => this.handleClickAddToOrder()} 
                                  >                                        
                                      { disabled ? <CircularProgress  className={classes.circularProgress} size={24}/> : <AddShoppingCard /> } { t("buttons.Buy") }
                                  </Button>
                              </Grid>
                              <Grid item xs={12}>
                              {
                              suggestion && 
                              <Grid container spacing={2} direction="row" justify="flex-end" alignItems="center" >
                                <Grid item xs={2} lg={1}>
                                  <img className={classes.imgXs} alt={suggestion.Name} src={ `${this.state.imgCDN}${this.state.imgXsSize}/${suggestion.Image}`} />
                                </Grid>
                                <Grid item xs={10} lg={11} >                                
                                  {`${suggestion.Code} ${suggestion.LocalName} : ${parseFloat(suggestion.Price).toFixed(2)}`}                                
                                </Grid>
                              </Grid>                                                                                      
                              }
                              </Grid>                        
                          </Grid>
                      </Paper>                        
                  </Grid>                
              </Grid>                

              {/* Order body header */}
              <Grid container spacing={2}>
                <Grid item xs={12}>                  
                    <Paper className={classes.paper} elevation={1}>
                    <div className={classes.rowDetail}>
                      <Grid container direction="row" spacing={2}>
                        <Grid item xs={4} sm={2}  md={1} lg={1}><Typography variant="subtitle2" align="center" gutterBottom>&nbsp;</Typography></Grid>
                            <Grid item xs={8} sm={10} md={4} lg={6}><Typography variant="subtitle2" gutterBottom>{t("order.columns.Product")}</Typography></Grid>
                        <Grid item xs={3} sm={3}  md={1} lg={1}><Typography variant="subtitle2" gutterBottom>{t("order.Qty")}</Typography></Grid>
                        <Grid item xs={3} sm={3}  md={2} lg={1}><Typography variant="subtitle2" align="right" gutterBottom>{t("order.Price")}</Typography></Grid>
                        <Grid item xs={3} sm={3}  md={2} lg={1}><Typography variant="subtitle2" align="right" gutterBottom>{t("order.Amount")}</Typography></Grid>
                        { accessBusinessAttr && 
                        <Grid item xs={3} sm={3}  md={1} lg={1}><Typography variant="subtitle2" align="right" gutterBottom>{t("order.columns.LO")}</Typography></Grid>
                        }
                      </Grid>
                      </div>
                    </Paper>                    
                </Grid>
              </Grid>
              
              {/* Order body rows */}
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12}>
                {
                  Order.Rows.map((row, index) => (                    
                      <Accordion key={index}>
                        <AccordionSummary className={classes.extPanel}  expandIcon={<ExpandMoreIcon />} aria-controls={`row${row.Id}-content`} id={`row${row.Id}-content`} >
                          <Grid container spacing={2} direction="row" justify="flex-start" alignItems="center" >
                            <Grid item xs={4} sm={2}  md={1} lg={1}>
                                <img className={classes.img} alt={row.Name} src={ `${this.state.imgCDN}${this.state.imgMdSize}/${row.Image}`} />
                            </Grid>
                            <Grid item xs={8} sm={10} md={4} lg={6}>
                              <Typography variant="body1" display="block">
                                <small>{row.Code}</small><br />
                                {row.Name}
                              </Typography>
                              {/* {
                                row.OrderRowDetails.map((detail, idx) => (
                                  <Typography key={idx} variant="caption" className={classes.colorGray} >
                                    {idx > 0 ? ", ": null } {t(`order.${GetOrderRowTypeName(detail.OrderRowType)}`)}
                                  </Typography>
                                ))
                              } */}
                            </Grid>
                            <Grid item xs={3} sm={3}  md={1} lg={1}>
                              { row.OrderRowDetails.length === 1 ? 
                                <FormControl fullWidth >
                                  <Input type="number" className={classes.qtyrow}
                                    inputProps={{ min: 1, max: 1000  }}
                                    readOnly = {row.OrderRowDetails[0].OrderRowType === OrderRowType.Script} 
                                    disabled ={ row.OrderRowDetails[0].OrderRowType === OrderRowType.Script}
                                    value={row.OrderRowDetails[0].Qty}
                                    title={t(`order.${GetOrderRowTypeName(row.OrderRowDetails[0].OrderRowType)}`)}
                                    onKeyPress={e => {
                                      if (e.key === "Enter") {
                                        this.handleUpdRow(e, index, 0)                                        
                                      }
                                    }}
                                    onBlur = {e => this.handleUpdRow(e, index, 0)}

                                    onClick={(event) => event.stopPropagation()}
                                    onFocus={(event) => event.stopPropagation()}                                    
                                    onChange={e => this.handleQtyChange(e, index, 0)}
                                    startAdornment={<InputAdornment position="start">шт</InputAdornment>}
                                  />
                                </FormControl>
                                : 
                                <FormControl fullWidth >
                                  <Input type="number" readOnly disabled value={row.Qty}                                      
                                    startAdornment={<InputAdornment position="start">шт</InputAdornment>}
                                  />  
                                </FormControl>                                  
                              }
                            </Grid>
                            <Grid item xs={3} sm={3}  md={2} lg={1}>
                              <Typography align="right" display="block">
                              { rowPrice(row, Order.UseBusinessPack) }
                              </Typography>
                              
                              {/* {accessBusinessAttr && 
                              <Typography className={classes.colorBlue} align="right" variant="body2" >
                                { rowPricePv(row) }
                              </Typography>
                              } */}
                            </Grid>
                            <Grid item xs={3} sm={3}  md={2} lg={1}>
                              <Typography align="right">
                                { rowAmount(row) }
                              </Typography>
                              
                              {/* {accessBusinessAttr && 
                              <Typography className={classes.colorBlue} align="right" variant="body2" >
                                { rowAmountPv(row) }                                
                              </Typography>
                              } */}
                            </Grid>
                            <Grid item xs={3} sm={3}  md={1} lg={1} className={classes.itemR}>
                              { row.PersonalVolumePv > 0  && 
                                <Typography className={classes.colorBlue} align="right">
                                  {rowPersonalVolumePv(row)}                                  
                                </Typography>
                              }
                              
                              {/* {                               
                                process.env.NODE_ENV === "development" &&
                                <React.Fragment>
                                  
                                  <Typography color="secondary" align="right" variant="caption" display="block">
                                  
                                    {parseFloat(row.FastUpBonusPv).toFixed(2)}
                                  </Typography>
                                </React.Fragment>
                               }
                               */}
                            </Grid>

                            <Grid item xs={12} sm={12} md={1} lg={1}>
                               <Grid container spacing={2} direction="row">
                                { width === "xs" &&
                                <Grid item xs={6}>
                                  <IconButton size="small" className={classes.pusmin} aria-label="minus" disabled={disabled}
                                    onClick={(e) => this.handleUpdRowIncDec(e, index, 0, -1)}
                                  >
                                    <RemoveCircleOutlineIcon />
                                  </IconButton>
                                  
                                  <IconButton size="small" className={classes.pusmin} aria-label="plus" disabled={disabled}
                                    onClick={(e) => this.handleUpdRowIncDec(e, index, 0, 1)}
                                  >
                                    <AddCircleOutlineIcon />
                                    </IconButton>
                                </Grid>
                                }
                                <Grid item xs={6}>
                                  <div className={classes.itemR} >
                                    <IconButton size="small" color="default" aria-label="Del" className={classes.button} onClick={(e) => this.handelDelFromOrder(e, index)}>
                                      <Clear />
                                    </IconButton>
                                     {
                                      (row.OrderRowDetails.length === 1 && row.OrderRowDetails[0].OrderRowPromotions.length > 0) ?
                                      <IconButton size="small" color="primary" aria-label="Promo" className={classes.button} 
                                        onClick={(e) => this.ShowPromoDetail(e, index, 0)}
                                        >
                                        <InfoOutlinedIcon />
                                      </IconButton> 
                                      : null
                                      // <span style={{padding: 3, display: "flex"}}>&nbsp;</span>
                                    } 
                                  </div>    
                                </Grid>
                               </Grid>
                              

                            </Grid>                                                         
                          </Grid>                          
                        </AccordionSummary>                      
                        {/* Details of order row */}                      
                        <AccordionDetails >
                        {
                          row.OrderRowDetails.length > 1 ?
                          <div className={classes.rowDetail}>
                            <Grid container spacing={2} direction="row" justify="flex-start" alignItems="center" >
                              <Grid item xs={12} >                                                                                  
                                {                        
                                  row.OrderRowDetails.map((detail, idx) => (
                                  <Grid key={idx} spacing={2} container direction="row" className={classes.row}>
                                    <Grid item xs={4} sm={2} lg={1}>&nbsp;</Grid>                                
                                    <Grid item xs={8} sm={10} md={5} lg={6}>
                                      {t(`order.${GetOrderRowTypeName(detail.OrderRowType)}`)}
                                    </Grid>
                                    <Grid item xs={3} sm={3} lg={1} >
                                        <FormControl fullWidth >
                                            <Input type="number"
                                              inputProps={{min: 1, max: 1000}}
                                              value={detail.Qty}
                                              readOnly={detail.OrderRowType !== OrderRowType.Default} 
                                              disabled={detail.OrderRowType !== OrderRowType.Default}                                                  
                                              onClick={(e) => e.stopPropagation()}
                                              onFocus={(e) => e.stopPropagation()}                                              
                                              onChange={e => this.handleQtyChange(e, index, idx)}
                                              startAdornment={<InputAdornment position="start">шт</InputAdornment>}
                                            />
                                            </FormControl>
                                    </Grid>
                                    <Grid item xs={3} sm={3} lg={1}>                                      
                                      <div className={classes.itemR}>
                                        { detail.Price !== detail.CatalogPrice || detail.DiscountCdc > 0 ? 
                                        <Badge color="primary" variant="dot">
                                          <Typography>
                                            {parseFloat((detail.Amount - (!Order.UseBusinessPack ? detail.DiscountCdc : 0)) / detail.Qty).toFixed(2)}
                                            {/* {parseFloat(detail.Price).toFixed(2)} */}
                                          </Typography>
                                        </Badge> : 
                                        <Typography>
                                          {parseFloat((detail.Amount -  (!Order.UseBusinessPack ? detail.DiscountCdc : 0)) / detail.Qty).toFixed(2)}
                                          {/* {parseFloat(detail.Price).toFixed(2)} */}
                                        </Typography>
                                        }
                                      </div>
                                    </Grid>
                                    <Grid item xs={3} sm={3} lg={1} >                                      
                                        <Typography align="right">
                                          { detail.AmountCdc !== 0 ? parseFloat(detail.AmountCdc - detail.DiscountCdc).toFixed(2) : parseFloat(detail.Amount).toFixed(2) }
                                          {/* { detail.AmountCdcPv > 0 ? <small className={classes.colorGray}><br />{parseFloat(detail.AmountCdcPv - detail.DiscountCdcPv).toFixed(2)}</small> : null} */}
                                        </Typography>
                                    </Grid>                                
                                    <Grid item xs={3} sm={3} md={1} className={classes.itemR}>                                  
                                      <IconButton size="small" color="default" aria-label="Del" className={classes.button}
                                        onClick={() => actions.delFromDetail(row.Id, detail.Id )} >
                                          <Clear />
                                      </IconButton> 
                                      {
                                        (detail.OrderRowPromotions.length > 0) ?
                                        <IconButton size="small" color="primary" aria-label="Promo" className={classes.button} 
                                          onClick={(e) => this.ShowPromoDetail(e, index, idx)}
                                        >
                                          <InfoOutlinedIcon />
                                        </IconButton> 
                                        :
                                        null
                                      }
                                    </Grid>
                                  </Grid>                                        
                                  ))                                                                          
                                }                            
                              </Grid>
                            </Grid>
                          </div>
                          : null 
                        }
                        </AccordionDetails>                      
                      </Accordion>
                  ))}
                </Grid>
              </Grid>
              
              {/* Order footer */}
              <Grid container spacing={2}>
                <Grid item xs={12}>                                  
                    <Paper className={classes.paper} elevation={1} >
                        { Order.Rows.length > 0 ?
                        <Grid container spacing={2}>
                          {/* <Grid item xs={12} lg={6}>
                            <Alert severity="warning"><b>WARNING!</b> Prices and discounts may be recalculated the best value after order confirmation</Alert>
                          </Grid> */}
                          <Grid item xs={12} lg={4}>
                            <Typography variant="h6" align="center">{ t("order.Summary") }</Typography>
                            {/* <Typography>
                              DiscCons = {parseFloat(Order.AmountCdcPv).toFixed(2)} * {parseFloat(Order.PercentDiscountCdc).toFixed(2)}% = {parseFloat(Order.AmountCdcPv * Order.PercentDiscountCdc / 100 ).toFixed(2)} Pv
                            </Typography> */}
                          </Grid>
                          <Grid item xs={8} lg={5}>
                            <Typography noWrap align="right">
                              { t("order.WithoutDiscount") }, грн :
                            </Typography>

                            <Typography noWrap gutterBottom align="right" className={classes.colorRed}>
                              {t("order.Discount")}, <small>грн</small> :
                            </Typography>

                            <Typography noWrap gutterBottom align="right">
                              { t("order.Total") }, <small>грн</small> :
                            </Typography>                            
                                                        
                            {accessBusinessAttr && 
                            <React.Fragment>
                              <Typography noWrap gutterBottom align="right">
                                { t("order.ConsultantDiscount") } % :
                              </Typography>

                            {/* <Typography gutterBottom align="right">
                              {  !Order.UseBusinessPack ? t("order.ConsultantDiscount") : t("order.BonusBusinessPack") } :
                            </Typography> */}

                            <Typography noWrap className={classes.colorBlue} gutterBottom align="right">
                                  { t("order.AmountLO") } :
                            </Typography>                                                        
                            </React.Fragment>
                            }

                            {
                              Order.AmountCOD !== 0 ?
                              <Typography  noWrap gutterBottom align="right">
                              { t("order.AmountCOD") }, грн :
                              </Typography> : null
                            }
                            {
                              Order.AmountDelivery !== 0 ?
                              <Typography gutterBottom align="right">
                              { t("order.AmountDelivery") }, грн :
                              </Typography> : null
                            }
                            {
                              Order.AmountService !== 0 ?
                              <Typography noWrap gutterBottom align="right">
                              { t("order.AmountService") }, грн :
                              </Typography> : null
                            }
                            <Typography noWrap variant="h6" align="right">
                              { t("order.AmountPaid") }, <small>грн</small> :
                            </Typography>
                            {/* <Typography className={classes.colorSuccess} gutterBottom align="right">
                              <small> Debug info FastUpBonus, grn / pv / % </small> :
                            </Typography> */}
                          </Grid>

                          <Grid item xs={4} lg={3}>
                            { accessBusinessAttr &&  
                             <Typography align="right">
                              <span className="text-gray price--line-through">{parseFloat(Order.AmountCatalogPrice).toFixed(2)}</span>
                             </Typography>
                            }
                            <Typography variant="h6"  align="right" className={classes.colorRed}>                             
                              {/* {parseFloat(Order.AmountCatalogPrice - Order.Amount + (Order.AmountCdc * Order.PercentDiscountCdc / 100 ) ).toFixed(2)} -  */}
                              {parseFloat(Order.AmountCatalogPrice - Order.Amount + (!Order.UseBusinessPack ?  Order.DiscountCdc : 0)).toFixed(2)} 
                            </Typography>

                            <Typography gutterBottom variant="h6" align="right">                              
                              {parseFloat(Order.Amount - (!Order.UseBusinessPack ? Order.DiscountCdc : 0)).toFixed(2)} 
                            </Typography>
                            

                            { accessBusinessAttr && 
                            <React.Fragment>
                              <Typography gutterBottom align="right">
                                {parseFloat(Order.PercentDiscountCdc).toFixed(2)}
                              </Typography>
                            {/* <Typography gutterBottom align="right">
                              {parseFloat(Order.DiscountCdc).toFixed(2)}
                            </Typography> */}
                            <Typography gutterBottom className={classes.colorBlue} align="right">                                
                                {parseFloat(Order.PersonalVolumePv).toFixed(2)}
                            </Typography>
                            </React.Fragment>
                            }
                            
                            {
                              Order.AmountCOD !== 0 ?
                              <Typography gutterBottom align="right">
                              {parseFloat(Order.AmountCOD).toFixed(2)}
                            </Typography> : null
                            }
                            {
                              Order.AmountDelivery !== 0 ?
                              <Typography gutterBottom align="right">
                              {parseFloat(Order.AmountDelivery).toFixed(2)}
                            </Typography> : null
                            }
                            {
                              Order.AmountService !== 0 ?
                              <Typography gutterBottom align="right">
                              {parseFloat(Order.AmountService).toFixed(2)}
                            </Typography> : null
                            }

                            <Typography variant="h6" align="right">
                              {parseFloat(Order.Amount + Order.AmountService + Order.AmountCOD + Order.AmountDelivery  - (!Order.UseBusinessPack ? Order.DiscountCdc : 0)).toFixed(2)} 
                            </Typography>
                            
                          </Grid>
                        </Grid> : null
                        }
                        <Grid container justify="flex-end" spacing={2}>
                          {/* Comments to order */}
                          <Grid item xs={12} sm={9} lg={9}>
                            <TextField fullWidth
                              name="Comment" label={t("order.CommentToOrder")} multiline variant="outlined"
                              rows="2" defaultValue={Comment}
                              onChange={(e) => actions.handleChangeFieldValue(e)}
                            />
                          </Grid>
                          { width !== "xs" &&
                          <Grid item xs={12} sm={3} lg={3}>
                            <Button fullWidth size="large" style={{paddingTop: 10, paddingBottom: 10}}
                                disabled={Order.Rows.length === 0}
                                onClick={this.handleConfirmOrder}
                                variant="contained"
                                // color={theme.palette.success}
                                className={classes.btnSuccess}
                                startIcon={<CheckIcon />}
                              >
                                { t("buttons.ConfirmOrder") } { t("order.Order") }
                              </Button>
                        </Grid>
                        }
                        </Grid>                                                  
                    </Paper>                    
                </Grid>                
              </Grid>                                            
            </Grid>
          
            {/* Payment - delivery section */}
            <Grid ref={this.state.payBlock}  item xs={12} lg={3}>
              {RightPanel}            
            </Grid>
            </Grid>
          }
           
          {/* Current list active promotions */}
          <Dialog
            fullScreen={width === "xs"}
            fullWidth={true}
            maxWidth={"xl"}
            open={this.state.open}
            onClose={this.handleClose}
            TransitionComponent={Transition}
          > 
            <DialogTitle id="active-promotions-dialog-title" onClose={this.handleClose}>                 
              <div>
                { this.state.titleDlgPromotions }
              </div>                
            </DialogTitle>
            <DialogContent dividers>
              <Grid container spacing={4} className={classes.promotions} > 
                  <Grid item xs={12}>
                    {promotions && 
                    <Grid container spacing={2} >
                      {
                          promotions.map((item, index) => (                  
                            <Grid item key={index} xs={12} sm={12} md={6}>
                              <PromotionsThumb item={item} clickByPromotions={this.handleClickPromotions} ></PromotionsThumb>
                            </Grid>                        
                          ))
                        }
                    </Grid> 
                    }
                  </Grid>
              </Grid>                               
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Button color="default" onClick={this.handleClose}
              >
                {t("buttons.Cancel") }
              </Button>
            </DialogActions>
          </Dialog>
          
          {/* Detais of selected promotions */}
          <Dialog
            fullScreen={width === "xs"}
            fullWidth={true}
            maxWidth={"lg"}
            open={this.state.openPromotions}
            onClose={this.HidePromotions}
            TransitionComponent={Transition}
          >              
            <DialogTitle id="promotions-dialog-title" onClose={this.HidePromotions}>
              { selectedPromotions && 
                <div>
                  {selectedPromotions.Name}
                </div>
              }
            </DialogTitle>
            <DialogContent dividers={true}>

              { selectedPromotions && 
                <DetailPromotion promotion={selectedPromotions} />
              }                
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Button color="default" onClick={this.HidePromotions}
              >
                {t("buttons.Cancel") }
              </Button>
            </DialogActions>
          </Dialog>
          
          {/* List of products */}
          <DlgBuyProducts 
            open={this.state.openListProducts} title={this.state.titleDlgListProducts} items={this.state.listproducts}
            onClose={this.handelHedeDlgProducts}
          >
          </DlgBuyProducts>
          
          {/* Detais of applied promotions */}
          <Dialog
            fullScreen={width === "xs"}
            fullWidth={true}
            maxWidth={"md"}
            open={this.state.openPromo}
            onClose={this.HidePromoDetail}
            TransitionComponent={Transition}
          >              
            <DialogTitle id="details-promo-dialog-title" onClose={this.HidePromoDetail}>
              { selectedOrderRowDetail && 
                <div>
                  {t("order.Details")}
                  {/* {t(`order.${GetOrderRowTypeName(selectedOrderRowDetail.OrderRowType)}`)}  */}
                </div>
              }
            </DialogTitle>
            <DialogContent dividers>
              {
                selectedOrderRowDetail && 
                <div>
                  <Alert severity="info" className={classes.card}>                        
                    {selectedOrderRowDetailCaptions}
                  </Alert>
            
                  <Grid spacing={2} container direction="row" >
                    <Grid item xs={2} className={classes.itemR}><b>{t("order.Qty")}</b></Grid>
                    <Grid item xs={3} className={classes.itemR}><b>{t("order.Price")}</b></Grid>                    
                    <Grid item xs={3} className={classes.itemR}><b>{t("order.Amount")}</b></Grid>
                    <Grid item xs={3} className={classes.itemR}><b>{t("order.Discount")}</b></Grid>
                  </Grid>

                  {selectedOrderRowDetail.OrderRowPromotions.map((item, index) => (
                    <Grid key={index} spacing={2} container direction="row" className={classes.row}>
                      <Grid item xs={2} className={classes.itemR}  >                       
                          {item.Qty}                        
                      </Grid>
                      <Grid item xs={3} className={classes.itemR}>                        
                          <div className={ item.CatalogPrice !== item.Price ? classes.colorRed : null}>{parseFloat(item.Price).toFixed(2)}</div>
                          {
                            item.CatalogPrice !== item.Price ? 
                              <div><small className="text-gray price--line-through">{parseFloat(item.CatalogPrice).toFixed(2)}</small></div> : null
                          }
                          
                      </Grid>
                      <Grid item xs={3} className={classes.itemR}>                          
                        {parseFloat(item.Qty  * item.Price).toFixed(2)}                          
                      </Grid>                      
                      <Grid item xs={3} className={classes.itemR}>
                        {
                          item.Price < item.CatalogPrice ? 
                            <span className={classes.colorRed}>{parseFloat( ( item.Qty * (item.CatalogPrice - item.Price)) ).toFixed(2)}</span> : null
                        }                        
                      </Grid>
                      {
                        item.CatalogPrice !== item.Price ?                      
                          <Grid item xs={12}>
                            <Typography variant="body2">
                              <small>{item.PromotionsName}. {item.PromotionsDescr}</small>
                            </Typography>
                          </Grid> : null
                      }

                    </Grid>                                                   
                  ))}                  
                </div>
              }                
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Button color="default" onClick={this.HidePromoDetail}
              >
                {t("buttons.Cancel") }
              </Button>
            </DialogActions>
          </Dialog>
          
          {/* Dlg confirm order with product list from scripts */}
          <Dialog 
            fullScreen={width === "xs"}
            fullWidth={true}        
            maxWidth={"lg"}
            open={this.state.openScripts}
            onClose={this.HideScriptsDetail}
            TransitionComponent={Transition}
          >
            <DialogTitle id="details-scripts-dialog-title" onClose={this.HideScriptsDetail}>
            {
              `${t("order.AdditionalPositions")}`
            }
            </DialogTitle>            
            <DialogContent dividers style={{padding:"16px"}}>              
              <Typography variant="h6" gutterBottom>
                {t("order.Order")}: {this.props.Order.DocNumber}
              </Typography>
                {/* You can delete posions */}
                <Grid container spacing={2}>
                  {/* <Grid item xs={12}  >
                    <Button variant="outlined"
                      onClick={() => actions.delAllFromScripts()} >
                      <Clear className={classes.button} /> {t("order.RefuseGifts")}
                    </Button>
                  </Grid> */}
                  <Grid item xs={12}  >
                  
                  {scripts.map((item, sIdx) => (
                    
                    item.ActionByCondition.Products.length > 0 &&
                    <Grid key={item.Id} spacing={2} container direction="row" className={classes.rowScript} >
                      <Grid item xs={12} >
                        <Typography variant="h6" gutterBottom > 
                          {t("order.Programm")} : <small>{item.Name}</small>
                        </Typography> 
                        <Typography variant="caption" display="block" gutterBottom>
                          {item.Description}
                        </Typography>  
                      </Grid>                        
                      <Grid item xs={12}>
                        <Grid key={item.Id} spacing={2} container direction="row">                            
                          {item.ActionByCondition.Products.map((prod, pIdx) => (                              
                            <Grid key={prod.Product.Id} item xs={12} sm={6} >
                              <Grid container direction="row">
                                <Grid item xs={3} sm={3} >
                                  {
                                    prod.Product.Image && prod.Product.Image.length > 0 
                                      ? <img className={classes.img} alt={prod.Product.Name} src={ `${this.state.imgCDN}${this.state.imgXsSize}/${prod.Product.Image}`} /> 
                                      : <Skeleton animation={false} variant="rect" width={width === "xs" ? 72 : 142} height={width === "xs" ? 48 : 72} /> 
                                  }                                    
                                </Grid>
                                <Grid item xs={6} sm={7}>                                        
                                  <Typography variant="body2" gutterBottom >
                                    {prod.Product.Code} - {prod.Product.Name}
                                  </Typography>
                                  {
                                    item.ActionByCondition.AllowEditQty 
                                    ?                                     
                                      <div className={classes.qtySripts}>
                                      <Input                                      
                                        type="number"                                        
                                        inputProps={{ min: 1, max: 100000 }}
                                        value= {prod.Qty}
                                        onChange={e => this.handleScriptQtyChange(e, sIdx, pIdx)}
                                        startAdornment={<InputAdornment position="start">шт</InputAdornment>}
                                      />
                                      <Input                                         
                                        value={parseFloat(prod.Prices[item.SelectedPriceCol]).toFixed(2) }
                                        startAdornment={<InputAdornment position="start">грн</InputAdornment>}
                                      />
                                    </div>                                                                          
                                    :
                                    <Typography variant="button" gutterBottom>                                      
                                      {prod.Qty} <small>x</small> {prod.Prices[item.SelectedPriceCol]} <small>=</small> {parseFloat(prod.Qty * prod.Prices[item.SelectedPriceCol]).toFixed(2)} <small className={classes.currency}>грн</small>
                                    </Typography>
                                  }
                                  {
                                    prod.ForceAdd ? null :
                                    <div className={classes.canRefuse}>
                                      <Typography variant="caption" gutterBottom>! {t("order.CanRefuse")}</Typography>
                                    </div> 
                                  }
                                  </Grid>
                                <Grid item xs={2} sm={2} >
                                    <IconButton disabled={prod.ForceAdd} color="default" aria-label="Del" className={classes.button}
                                        onClick={() => actions.delFromScripts(sIdx, pIdx)}
                                    >
                                      <HighlightOffIcon />
                                    </IconButton>
                                </Grid>
                              </Grid>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}                  
                  </Grid>                
              </Grid>

            </DialogContent>
           
            <DialogActions className={classes.dialogActions}>
              <Grid container spacing={2}>                
                <Grid item xs={7} sm={3}>
                  <Button autoFocus className={classes.btnSuccess} disabled={disabled} fullWidth={true}
                    ref={ confirmBtn  => { this.confirmBtn = confirmBtn; }}
                    onClick={this.handleDlgBtnConfirm} >
                    <DoneAllIcon className={classes.button} /> {t("buttons.ConfirmOrder")}
                  </Button>
                </Grid>
                <Grid item xs={5} sm={3}>
                  <Button fullWidth={true} variant="outlined"
                    onClick={this.HideScriptsDetail}
                  >
                    {t("buttons.Cancel") }
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button variant="outlined" fullWidth={true} 
                      onClick={() => actions.delAllFromScripts()} >
                      <Clear className={classes.button} /> {t("order.RefuseGifts")}
                  </Button>
                </Grid>
              </Grid>
              

              

          </DialogActions>

          </Dialog>                        
        
        {/* Dialog warning anonym */}
        {/* <Dialog fullWidth={true} maxWidth={"sm"} open={this.state.isAnonym} onClose={this.HideAnonymDlg} TransitionComponent={Transition} >
            <DialogContent dividers style={{padding:"16px"}}>         

            <Alert severity="error">
              <AlertTitle>{t("common.Warning")}</AlertTitle>
                <Typography variant="h6">
                  <div dangerouslySetInnerHTML={{__html: t("order.IsAnonym") }} />
                  
                </Typography>                
              </Alert>          
            </DialogContent>
           
            <DialogActions className={classes.dialogActions}>
              <Button className={classes.btnSuccess}                   
                onClick={() =>  this.continueConfirmOrder()} >
                  <DoneAllIcon className={classes.button} /> {t("buttons.ConfirmOrder")}
              </Button>
              <Button autoFocus variant="contained" color="secondary"
                onClick={this.HideAnonymDlg}
              >
                {t("buttons.Cancel") }
              </Button>
          </DialogActions>
          </Dialog>  */}


          {
            width === "xs" &&
              <>
               
              <AppBar position="fixed" color="default" className={classes.appBarBtn}>
                <Toolbar className={classes.toolbarBtn}> 
                  <Fab className={classes.fab} disableRipple size="small" aria-label="lable-payment" color="inherit" onClick={this.handelChangeAsOrifleme}>
                    { this.state.asOriflame ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                  </Fab>
                  
                  { Order.Rows.length > 0 && 
                    <Typography component="span">
                        <Typography variant="overline" display="block">{t("order.Amount")}</Typography>
                        <Typography variant="subtitle1"><b>{parseFloat(Order.Amount + Order.AmountService + Order.AmountCOD + Order.AmountDelivery  - (!Order.UseBusinessPack ? Order.DiscountCdc : 0)).toFixed(2)}</b> <small>грн.</small> </Typography>
                    </Typography>
                  }

                  <div className={classes.grow} ></div>
                    <Chip className={classes.chipconfirm}                                                                                            
                        label= { t("buttons.ConfirmOrder") }
                        clickable
                        disabled={Order.Rows.length === 0}
                        // color={item.FreeRest <= 0 ? "default": "primary"}
                        onClick={this.handleConfirmOrder}
                    />
                  {/* <Button
                        disabled={Order.Rows.length === 0}
                        onClick={this.handleConfirmOrder}
                        variant="contained"                                
                        className={classes.btnSuccess}
                        startIcon={<CheckIcon />}
                      >
                        { t("buttons.ConfirmOrder") }
                  </Button> */}
                  
                  {/* <IconButton color="inherit">
                    <SearchIcon />
                  </IconButton> */}
                  {/* <IconButton edge="end" color="inherit">
                    <MoreIcon />
                  </IconButton> */}

                </Toolbar>
                
                {this.state.asOriflame && 
                  <>
                    <Divider style={{margin: "8px 0"}} />
                  <Grid container direction="row" justify="center" alignItems="center" spacing={4}>                  
                    <Grid item xs={7}>

                      {accessBusinessAttr && 
                      <Typography variant="body2" align="right" noWrap>
                        { t("order.WithoutDiscount") }, грн :
                      </Typography>
                      }

                      <Typography variant="body2" align="right" className={classes.colorRed}>
                        {t("order.Discount")}, <small>грн</small> :
                      </Typography>


                      <Typography variant="body2" align="right">
                        { t("order.Total") }, <small>грн</small> :
                      </Typography>                            
                      
                      
                      {accessBusinessAttr && 
                        <React.Fragment>
                        <Typography variant="body2" align="right">
                          { t("order.ConsultantDiscount") } % :
                        </Typography>
                        {/* <Typography variant="body2" align="right">
                          {  !Order.UseBusinessPack ? t("order.ConsultantDiscount") : t("order.BonusBusinessPack") } :
                        </Typography> */}

                        <Typography variant="body2" className={classes.colorBlue}  align="right">
                              { t("order.AmountLO") } :
                        </Typography>
                                                
                        </React.Fragment>
                      }

                      {
                        Order.AmountCOD !== 0 ?
                        <Typography variant="body2" align="right">
                        { t("order.AmountCOD") }, грн :
                        </Typography> : null
                      }
                      {
                        Order.AmountDelivery !== 0 ?
                        <Typography variant="body2" align="right">
                        { t("order.AmountDelivery") }, грн :
                        </Typography> : null
                      }
                      {
                        Order.AmountService !== 0 ?
                        <Typography variant="body2" align="right">
                        { t("order.AmountService") }, грн :
                        </Typography> : null
                      }
                      <Typography variant="subtitle2" align="right">
                        { t("order.AmountPaid") }, <small>грн</small> :
                      </Typography>
                      
                    </Grid>

                    <Grid item xs={3}>
                      { accessBusinessAttr &&  
                      <Typography variant="body2" align="right">
                        <span className="text-gray price--line-through">{parseFloat(Order.AmountCatalogPrice).toFixed(2)}</span>
                      </Typography>
                      }

                      <Typography variant="body2" align="right" className={classes.colorRed}>                      
                        {parseFloat(Order.AmountCatalogPrice - Order.Amount + (!Order.UseBusinessPack ?  Order.DiscountCdc : 0)).toFixed(2)} 
                      </Typography>

                      <Typography variant="body2" align="right">                              
                        {parseFloat(Order.Amount - (!Order.UseBusinessPack ? Order.DiscountCdc : 0)).toFixed(2)} 
                      </Typography>


                      
                      { accessBusinessAttr && 
                      <React.Fragment>
                        <Typography  variant="body2" align="right">
                          {parseFloat(Order.PercentDiscountCdc).toFixed(2)}
                        </Typography>
                        {/* <Typography variant="body2" align="right">
                          {parseFloat(Order.DiscountCdc).toFixed(2)}
                        </Typography> */}
                      <Typography  variant="body2" className={classes.colorBlue} align="right">                                
                          {parseFloat(Order.PersonalVolumePv).toFixed(2)}
                      </Typography>
                      </React.Fragment>
                      }

                      
                      {
                        Order.AmountCOD !== 0 ?
                        <Typography variant="body2" align="right">
                        {parseFloat(Order.AmountCOD).toFixed(2)}
                      </Typography> : null
                      }
                      {
                        Order.AmountDelivery !== 0 ?
                        <Typography variant="body2" align="right">
                        {parseFloat(Order.AmountDelivery).toFixed(2)}
                      </Typography> : null
                      }
                      {
                        Order.AmountService !== 0 ?
                        <Typography variant="body2" align="right">
                        {parseFloat(Order.AmountService).toFixed(2)}
                      </Typography> : null
                      }

                      <Typography variant="h5" align="right">
                        {parseFloat(Order.Amount + Order.AmountService + Order.AmountCOD + Order.AmountDelivery  - (!Order.UseBusinessPack ? Order.DiscountCdc : 0)).toFixed(2)} 
                      </Typography>
                      
                    </Grid>
                  </Grid>
                  </>
                }
              </AppBar>
              </>              
          }

        </div> 
      );
    }  
}

export default  withTranslation()(withWidth() (withStyles(styles)(
  connect(    
    state => state.orderRequest,
    dispatch => {
      return {
        actions: bindActionCreators(Object.assign({}, actionOrderCreators ), dispatch)
      }    }
  )(withCookies(Order)))
));
