import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actionOrderCreators } from "../../store/Order";
import { Link } from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import ShoppingCard from "@material-ui/icons/ShoppingCartOutlined";

const styles = theme => ({
    root: {
        flexGrow: 1,        
        // padding: theme.spacing(1),
    },
    buttons: {        
        marginRight: theme.spacing(1),
    },
})

class Basket extends React.Component {  
    constructor(props) {        
        super(props);
        this.state = {};
        // this.props.requestOrder();
    }
    
    componentDidMount() {
        this.props.requestOrder();
    }

    render(){
        const {qtyrows, Order, classes } = this.props;                        
        return(       
            <IconButton className={classes.buttons}  color="inherit" component={Link} to= { Order.Id > 0 ? `/order/${Order.Id}` : "/order" }>
                <Badge badgeContent={qtyrows} color="secondary">
                    <ShoppingCard />
                </Badge>
            </IconButton> 
        );
    }
}

export default withStyles(styles)(
    connect(    
        state => state.orderRequest,
        dispatch => bindActionCreators(actionOrderCreators, dispatch)
    )(Basket)
);