import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";
// import ReactCrop from "react-image-crop";
import Button from "@material-ui/core/Button";
// import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
// import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import LinearProgress from "@material-ui/core/LinearProgress";
import GetAppIcon from "@material-ui/icons/GetApp";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from "@material-ui/pickers";
// import { OidcUserManager } from "../../helpers/OidcSettings";
// import Tabs from "@material-ui/core/Tabs";
// import Tab from "@material-ui/core/Tab";
import AddAPhotoOutlinedIcon from "@material-ui/icons/AddAPhotoOutlined";
// import AuthService from "../../helpers/authService";
import lightBlue  from "@material-ui/core/colors/lightBlue";
import gray from "@material-ui/core/colors/grey";

import { AuthContext } from "../../providers/authProvider";

import RefreshIcon from "@material-ui/icons/Refresh";
import ReplayIcon from "@material-ui/icons/Replay";

import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File

import CodeMirror from "codemirror";
import "codemirror/mode/htmlembedded/htmlembedded";
import "codemirror/lib/codemirror.css";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneForwardedIcon from "@material-ui/icons/PhoneForwarded";
import SaveIcon from "@material-ui/icons/Save";


import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "../../styles/style.css";

import DateFnsUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/uk";

import axios from "axios";
import FileCopyIcon from "@material-ui/icons/FileCopy";

const formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ("" + str).replace(/\D/g, "");
    
    //Check if the input is of correct
    let match = cleaned.match(/^(1|)?(\d{3})(\d{2})(\d{3})(\d{4})$/);
    
    if (match) {
      //Remove the matched extension code
      //Change this to format for any country code.
      let intlCode = (match[1] ? "380 " : "");    
      return [intlCode, match[2], " (", match[3], ") ", match[4], "-", match[5]].join("")
    }
    return null;
}

const Capitalize = (s) => {
    if (typeof s !== "string") return ""
    return s.charAt(0).toUpperCase() + s.slice(1)
  }
  

const styles = theme => ({
    root: {
        width: "100%",
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    box: {
        display: "inline-block",
        padding: theme.spacing(2),
        boxSizing: "border-box",
        width:theme.spacing(40),
        [theme.breakpoints.down("xs")]: {
            width:theme.spacing(30),
            paddingLeft: theme.spacing(10)
        },
    },
    fieldset: {
        border: "solid 1px #c4c4c4",
        borderRadius: 4,
        paddingTop: 6,
        paddingBottom: 6, 
    },
    legend : {
        backgroundColor: "#fff",        
        fontSize: "1rem",
        position: "absolute",
        top: -8,
        left: -6,
        paddingLeft: 8,
        paddingRight: 8,
        transform: "translate(-8px, 0px) scale(0.75)",        
        
    },
    progress: {
        height: 2,
        position: "absolute",
        top: 0,
        left: 0,
        right:0,
    },  
    
    linearColorPrimary: {
        backgroundColor: "#abdcda",
    },

    linearBarColorPrimary: {
        backgroundColor: "#d20000",
    },
    
    paper: {
        minHeight: 128,
        padding: theme.spacing(2),
        boxShadow: "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
        borderRadius: theme.spacing(1),
    },    
    divider: {        
        marginBottom:theme.spacing(2),
    },
    previewbox: {
        backgroundColor: gray[100]
    },
    
    media: {
        height: 240,
    },
    
    dowloadlink:{
        overflowWrap: "anywhere",
    },

    profile: {
        background: "linear-gradient( 338deg, rgba(116,251,253,1) 0%, rgba(85,209,231,1) 24%, rgba(50,162,206,1) 100%)",
            
        // transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",        
        overflow: "hidden",
        // boxShadow: "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
        boxShadow: "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
        borderRadius: "8px",
        zIndex: 0,
        marginBottom: theme.spacing(2),
        height: 250,
        position: "relative",
    },

    hdprofile: {
        marginBottom: theme.spacing(2),
        padding: 0,        
    },
    tabsProfile: {
        fontSize: ".9em"
    },
    userbox:{
        color: "#fff",
        textShadow: "-1px -1px 1px rgb(255 255 255 / 10%), 0px 1px 3px rgb(0 0 0 / 50%)",
        right: "auto",
        display: "flex",
        // alignItems: "center",        
        left: 24,
        bottom: 64,
        position: "absolute",
        // marginTop: 30,
        zIndex: 3,
        [theme.breakpoints.down("xs")]: {            
            left: 0,
            bottom: 24,
        },
    },
    large: {
        width: 132,
        height: 132,
        border: "2px solid #fff",
        fontSize: "4em",
        lineHeight: "4em",
        color: theme.palette.getContrastText(lightBlue[500]),
        backgroundColor: lightBlue[300],
        letterSpacing: "-0.1em",        
        // [theme.breakpoints.down("xs")]: {        
        //     display: "none",           
        // },
    },
    middle: {
        width: 96,
        height: 96,
        border: "2px solid #48bfde",
        fontSize: "4em",
        lineHeight: "4em",
        color: theme.palette.getContrastText(lightBlue[700]),
        backgroundColor: lightBlue[700],
        letterSpacing: "-0.1em",
        // [theme.breakpoints.down("xs")]: {        
        //     display: "none",           
        // },
    },

    imgpreview: {
        overflow: "hidden"
    },
    preview: {
        // marginTop: theme.spacing(4),
        width: 132,
        height: 132,
        border: "4px solid #48bfde",
        [theme.breakpoints.down("xs")]: {
            // display: "none",           
        },
    },

    imgcover: {
        zIndex: 8,
        width: "100%",
        height: 280,
        // height: "100%",
        objectFit: "cover",
        // position: "absolute"
    },
    username: {
        fontWeight: 300,
        marginTop: theme.spacing(4),
        textTransform: "capitalize",
        [theme.breakpoints.down("xs")]: {
            marginTop: 0,
            fontSize: "1.8em",
        },                 
    },
    chooseFile:{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "row",
        width: "100%",
        padding: theme.spacing(1,2),
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: theme.palette.primary.main,
        borderRadius: 5,
        color: theme.palette.primary.main,
        cursor: "pointer",
    },
    button: {
        marginRight: theme.spacing(1),
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: "33.33%",
        flexShrink: 0,
      },
      secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
      },
});

function getUserName(userName){
    const words = userName.split(" ");
    const res = (`${words[1].charAt(0)}${words[0].charAt(0)}`).toUpperCase();
    // console.log("USER NAME", res);
    return res;
}
class Profile extends Component {
    constructor(props) {
        super(props);
        let date = new Date();
        this.state = {
            data: null,
            firstName: "",
            lastName: "",
            middleName: "",
            birthday: new Date(date.getFullYear(), date.getMonth()-1, 1),
            gender: "0",
            readOnly: false,            
            firstnameErrorTxt: "",
            lastnameErrorTxt: "",
            middlenameErrorTxt: "",
            user: undefined,
            keys: [],
            isLoading: false,
            tabVal: 0,
            src: undefined, // "https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg",
            cropper: undefined,
            cropData: undefined,
            comment: "",
            expanded: "panelInfo",
            showSnack: false,
            variantSanck: "info",
            message: ""
        };
        // this.authService = new AuthService();
        this.cropper = React.createRef();
        this.refId = React.createRef();
        moment.locale("uk");
    }
    
    // static
    static contextType = AuthContext;
    validate = () => {

        const { firstName, lastName, middleName } = this.state;
        const {t} = this.props;

        let errors = false;
        
        if(firstName.length === 0 ){
            this.setState({ firstnameError: true, firstnameErrorTxt: t("register.EmptyFirstName")});
            errors = true;
        }

        if(lastName.length === 0 ){
            this.setState({ lastnameError: true, lastnameErrorTxt: t("register.EmptyLastName")});
            errors = true;
        }

        if(middleName.length === 0 ){
            this.setState({ middlenameError: true, middlenameErrorTxt: t("register.EmptyMiddleName")});
            errors = true;
        }
        return !errors;
    };
    
    copyCodeToClipboard = (txt, message) => {        
        const self = this;
        navigator.clipboard.writeText(txt.toLowerCase()).then(function() {
            /* clipboard successfully set */
            self.setState({ showSnack: true, message: message });
            // console.log("copy", txt);
            }, function() {
            /* clipboard write failed */
            });
    
        // const el = document.getElementById(id);
        // if(el){
        //     navigator.clipboard.writeText(el.innerText.toLowerCase()).then(function() {
        //         /* clipboard successfully set */
        //       }, function() {
        //         /* clipboard write failed */
        //       });
        //     // navigator.clipboard.readText().then(text => el.innerText = text);
        // }
        // el.select();
        // document.execCommand("copy");
    }

    handleChangeAccordion = (panel) => (event, isExpanded) => {
        // console.log(isExpanded, panel);          
        // this.setState({ expanded:  isExpanded ? panel : false});

        this.setState(() => ({
            expanded:  isExpanded ? panel : false
          }));
        if(isExpanded){
            document.getElementById(`${panel}-header`).scrollIntoView();
        }
        
        // window.scrollTo({top: 0, behavior: "smooth"});
    };

    setCropper = (instance) => {
        this.setState({cropper: instance})
    };

    getImage (canvas, fileName) {
        // eslint-disable-next-line no-unused-vars
        return new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
            if (!blob) {
                //reject(new Error('Canvas is empty'));
                console.error("Canvas is empty");
                return;
            }
            blob.name = fileName;
            // blob.lastModified = ;
            resolve(blob);
            // window.URL.revokeObjectURL(this.fileUrl);
            // this.fileUrl = window.URL.createObjectURL(blob);
            // resolve(this.fileUrl);

            }, "image/jpeg");
        });
    }

    loadUser = async () => {
        if(this.authService){      
            if(this.authService.isAuthenticated()){
              this.authService.getUser()
                .then( user => {          
                  if(user != null && !user.expired){                    
                    this.setState({ user: user, reviewUserEmail: user.profile.email, reviewUserName: user.profile.name  });  
                  }
                  else{
                    this.setState({ user: null });
                  }
                });
              }
            } else {
              this.setState({ user: null });
            }


        // userManager.getUser().then(user => {
        //     this.setState({user: user});
        //     console.log("USER", user);
        // });
    };

    componentDidMount() {
        window.scrollTo({top: 0, behavior: "smooth"});
        this.loadData();        
        this.loadUser();
    }

    componentWillUnmount() {        
    }

    handleSetTabVal = (event, value) => {
        this.setState({ tabVal: value});        
    };

    loadData = async () => {
        const self = this;
        const context = this.context;
        const userManager = context.UserManager;
        userManager.getUser().then(user => {
            if (user != null) {            
                this.setState({isLoading: true});
                const URL =  "/api/v1.0/adviser/profile";                
                fetch(URL, {
                    method: "GET",
                    headers: {"Authorization": `${user.token_type} ${user.access_token}`}
                }
                )
                .then(response => response.json())
                    .then(data => {

                        // eslint-disable-next-line no-undef
                        if(process.env.NODE_ENV === "development")
                            console.log(data.Adviser);
                        
                            self.setState({ 
                                data: data,
                                firstName: Capitalize(data.Adviser.FirstName.toLowerCase()),
                                lastName: Capitalize(data.Adviser.LastName.toLowerCase()),
                                middleName: Capitalize(data.Adviser.MiddleName.toLowerCase()),
                                birthday : moment(data.Adviser.Birthday).format("YYYY-MM-DDTHH:mm:ss.SSSSZ"),
                                gender: data.Adviser.GenderOrder+"",
                                comment: data.Adviser.Comment,
                                readOnly: data.Adviser.ReadOnly,
                                isLoading: false, keys: [] 
                            });
                })
                .catch( error => {
                    this.setState({isLoading: false});
                    console.log(error)
                });
            }
        });
    };
    
    fileCatalog = () => {
        const date = new Date();
        const ds = date.getFullYear()
          + "-" + ("0" + (date.getMonth() + 1)).slice(-2)
          + "-" + ("0" + date.getDate()).slice(-2)
          + " " + ("0" + date.getHours()).slice(-2)
          + ":" + ("0" + date.getMinutes()).slice(-2)
    
        return `jerelia_${ds}.xml`;
    };

    downloadFile = () => {
        const url = "/api/v1.0/catalog/export/yml"; 
        const method = "GET";
        axios
      .request({
        url,
        method,        
        responseType: "blob", //important
      })
      .then(({ data }) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        const filename = this.fileCatalog();
        link.href = downloadUrl;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.remove();        
      });
    };
    
    onSelectFile = (e) => {
        if (e.target.files && e.target.files[0]) {
            // Maximum allowed size in bytes 5MB
            const maxAllowedSize = 5 * 1024 * 1024;

            if (e.target.files[0].size > maxAllowedSize) {
                // Here you can ask your users to load correct file
                e.target.value = "";
                return;
            }

            const reader = new FileReader();
            reader.addEventListener("load", () =>
                this.setState({ src: reader.result })
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    onImageLoaded = (image) => {
        this.imageRef = image;
    };
    
    // eslint-disable-next-line no-unused-vars
    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
    };
    
    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");
    
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );
    
        // eslint-disable-next-line no-unused-vars
        return new Promise((resolve, reject) => {
          canvas.toBlob((blob) => {
            if (!blob) {
              //reject(new Error('Canvas is empty'));
              console.error("Canvas is empty");
              return;
            }
            blob.name = fileName;
            // blob.lastModified = ;
            resolve(blob);
            // window.URL.revokeObjectURL(this.fileUrl);
            // this.fileUrl = window.URL.createObjectURL(blob);
            // resolve(this.fileUrl);

          }, "image/jpeg");
        });
    }

    getCroppedImgUrl(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");
    
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );
    
        // eslint-disable-next-line no-unused-vars
        return new Promise((resolve, reject) => {
          canvas.toBlob((blob) => {
            if (!blob) {
              //reject(new Error('Canvas is empty'));
              console.error("Canvas is empty");
              return;
            }

            blob.name = fileName;
            window.URL.revokeObjectURL(this.fileUrl);
            this.fileUrl = window.URL.createObjectURL(blob);
            resolve(this.fileUrl);
          }, "image/jpeg");
        });
    }

    uploadFile = (file) => {
        const {data} = this.state;
        const self = this;

        if(!file)
            return;

        const context = this.context;
        const userManager = context.UserManager;
        userManager.getUser().then(user => {
        
            const authorization = user ? `${user.token_type} ${user.access_token}`: ""; 

            var formData = new FormData();
            formData.append("avatar", file);
            formData.append("thumb", false);
            formData.append("resize", false);

            fetch("/api/v1/avatar/upload", {
            // content-type header should not be specified!
            method: "POST",
            headers: {                        
                "Authorization": authorization
            },
            body: formData,
            })
            .then(response => response.json())
            .then(success => {
                if(success){


                    data.Adviser.Avatar = success.avatar;
                    console.log(success);
                    console.log(data);
                    self.setState({data: {...data}});
                }            
            })
            .catch(error => console.log(error)
            );
        });
    }

    handleSaveAvatar = async () => {
        const {cropper, data} = this.state;
        if (typeof cropper !== "undefined") {
            const canvas =  cropper.getCroppedCanvas();
            const avatar = await this.getImage(                
                canvas,
                `${data.Adviser.RegistrationCode}.jpeg`
              );
            
            const cropData = canvas.toDataURL();
            
            this.setState({cropData: cropData});
            this.uploadFile(avatar);
            
        }
        // const {crop, data } = this.state;

        // if (this.imageRef && crop.width && crop.height) {
        //     const avatar = await this.getCroppedImg(
        //       this.imageRef,
        //       crop,
        //       `${data.Adviser.RegistrationCode}.jpeg`
        //     );
            
        //     if(!avatar)
        //         return;                
            
        //     if(avatar.size > 257000)
        //         return;

        //     // console.log(avatar);
        //     this.uploadFile(avatar);

            
        //   }
        
    };

    handleRotate = (val) => {
        this.cropper.current.cropper.rotate(val);        
    };
    
    handleEditorChange = (content) => {
        this.setState({comment: content});        
    };

    callBackSave = () => {
        if(!this.validate()){ 
            this.setState({expanded: "panelInfo"});
            document.getElementById("panelInfo-header").scrollIntoView();
            return;
          }
        
        const { comment, firstName, lastName, middleName, birthday, gender } = this.state;        
        const context = this.context;
        const userManager = context.UserManager;
        userManager.getUser().then(user => {
            if (user != null) {            
                this.setState({isLoading: true});
                const URL =  "/api/v1.0/adviser/saveprofile";                
                fetch(URL, {
                    method: "PUT",
                    headers: {
                        "Authorization": `${user.token_type} ${user.access_token}`,
                        "Accept": "application/json",
                        "Content-Type": "application/json;charset=UTF-8",
                    },
                    body: JSON.stringify({
                        "Idrref": this.state.data.Adviser.Idrref,
                        "Id": this.state.data.Adviser.Id,
                        "FirstName": firstName,
                        "LastName": lastName,
                        "MiddleName": middleName,
                        "Birthday": birthday,
                        "Gender": parseInt(gender),
                        "Comment": comment
                    })
                }
                )
                .then(response => response.json())
                    .then(data => {
                        this.setState({isLoading: false});
                        console.log(data);                        
                })
                .catch( error => {
                    this.setState({isLoading: false});
                    console.log(error)
                });
            }
        });

    };

    handelCloseSnack = () => {
        this.setState({ showSnack: false});
    };
    
    handleChangeGender = (e) => {
        this.setState({gender: e.target.value});
    };
    
    render() {
        const { classes, t, width } = this.props; 
        const { data, isLoading, comment, src, expanded, firstName, lastName, middleName, birthday, 
            gender, showSnack, variantSanck, message, readOnly } = this.state;
        const locale= "uk";
        
        const refUrl = `${window.location.protocol}//${window.location.host}/register/${data ? data.Adviser.RegistrationCode: ""}`;
        const publicUrl = `${window.location.protocol}//${window.location.host}/${data ? data.Adviser.RegistrationCode: ""}/introduce`;
        
        const refUrlYml = `${window.location.protocol}//${window.location.host}/api/v1.0/catalog/export/yml`;
        
        const shortName = data ?  getUserName(data.Adviser.Name) : "";

        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
            <div className={classes.root}>
                { isLoading && 
                    <LinearProgress className={classes.progress} 
                    classes={{
                        colorPrimary: classes.linearColorPrimary,
                        barColorPrimary: classes.linearBarColorPrimary,
                    }}
                />
                }

                {
                data && 
                    <>
                        <div className={classes.profile}>
                            <div className={classes.userbox}>
                                <Grid container direction="row" justify={ width === "xs" ? "center" : "flex-start"} spacing={2}>
                                    <Grid item>
                                    {
                                        data.Adviser.Avatar && data.Adviser.Avatar.length > 0 ?
                                        <>                                      
                                            <Avatar alt={shortName} src={data.Adviser.Avatar} className={classes.large} onClick={ () => this.setState({ expanded: "panelPhoto"})} /> 
                                        </>: 
                                        <>
                                        <Avatar className={classes.large} onClick={()=> this.setState({ expanded: "panelPhoto"})}>
                                            { shortName }
                                        </Avatar>
                                        </>
                                    }                                    
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.username} noWrap align={width === "xs" ? "center": "left"}   variant="h4" component="h2">
                                        {lastName} {firstName} {middleName} <br />
                                        <small>{data.Adviser.RegistrationCode}</small>
                                    </Typography>
                                    {/* <Typography variant="h6" align={width === "xs" ? "center": "left"} >
                                        {data.Adviser.RegistrationCode}
                                    </Typography> */}

                                </Grid>
                            </Grid>
                            </div>
                            {/* <img className={classes.imgcover} alt="profile cover" src="/assets/files/images/bg/people-network.jpg" ></img> */}
                        </div>
                                                                                       
                        <Accordion expanded={expanded === "panelInfo"} onChange={this.handleChangeAccordion("panelInfo")}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panelInfo-content"
                                id="panelInfo-header"
                            >
                            <Typography>{t("profile.panelInfo")}</Typography>
                            {/* <Typography className={classes.secondaryHeading}>{t("profile.panelInfoSec")}</Typography>  */}
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container direction="row" spacing={1} alignItems="stretch">
                                    <Grid item xs={12}>
                                        <Paper className={classes.paper}>
                                            <Grid container direction="row" spacing={2}>
                                                <Grid item xs={12} sm={4} md={3}>
                                                    <FormControl className={classes.fieldset} fullWidth variant="outlined" component="fieldset">
                                                        <FormLabel className={classes.legend} component="legend">{t("profile.GenderLabel")}</FormLabel>
                                                            <RadioGroup row aria-label="position" name="position"
                                                                value={`${gender}`} onChange={this.handleChangeGender}
                                                            >
                                                                <FormControlLabel
                                                                    value="2"
                                                                    control={<Radio color="primary" />}
                                                                    label={t("profile.Female")}
                                                                    labelPlacement="start"
                                                                    disabled={readOnly}
                                                                />
                                                                <FormControlLabel
                                                                    value="1"
                                                                    control={<Radio color="primary" />}
                                                                    label={t("profile.Male")}
                                                                    labelPlacement="start"
                                                                    disabled={readOnly}
                                                                />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={4} md={3}>
                                                    <TextField                                            
                                                        label={t("profile.FirstName")}
                                                        name="firstName"
                                                        placeholder={t("profile.FirstName")}
                                                        required                                                        
                                                        fullWidth
                                                        margin="none"
                                                        error={this.state.firstnameError}
                                                        helperText={this.state.firstnameErrorTxt}
                                                        InputLabelProps={{ shrink: true }}
                                                        InputProps={{ readOnly: readOnly }}
                                                        variant="outlined"
                                                        value={firstName}
                                                        onChange={e => this.setState({ [e.target.name]: e.target.value })}
                                                    />
                                                </Grid>                                                                                            
                                                <Grid item xs={12} sm={4} md={3}>
                                                    <TextField                                            
                                                        label={t("profile.MiddleName")}
                                                        name="middleName"                                                        
                                                        placeholder={t("profile.MiddleName")}
                                                        required                                                        
                                                        fullWidth
                                                        margin="none"
                                                        error={this.state.middlenameError}
                                                        helperText={this.state.middlenameErrorTxt}
                                                        InputLabelProps={{ shrink: true }}
                                                        InputProps={{ readOnly: readOnly }}                                                        
                                                        variant="outlined"
                                                        value={middleName}
                                                        onChange={e => this.setState({ [e.target.name]: e.target.value })}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4} md={3}>
                                                    <TextField
                                                        label={t("profile.LastName")}
                                                        name="lastName"
                                                        placeholder={t("profile.LastName")}
                                                        required                                                        
                                                        fullWidth
                                                        margin="none"
                                                        error={this.state.lastnameError}
                                                        helperText={this.state.lastnameErrorTxt}
                                                        InputLabelProps={{ shrink: true }}
                                                        InputProps={{ readOnly: readOnly, }}                                                        
                                                        variant="outlined"
                                                        value={lastName}
                                                        onChange={e => this.setState({ [e.target.name]: e.target.value })}
                                                    />
                                                </Grid>                                                                                        
                                                <Grid item xs={12} sm={4} md={3}>
                                                    <KeyboardDatePicker
                                                        // disableToolbar
                                                        inputVariant="outlined"
                                                        fullWidth={true}
                                                        name="birthday"
                                                        // variant="inline"
                                                        format="DD.MM.YYYY"
                                                        margin="none"
                                                        id="birthday"
                                                        label={t("register.Birthday")}
                                                        value={birthday}
                                                        readOnly={readOnly}
                                                        InputProps={{ readOnly: readOnly }}                                                        
                                                        onChange={date => this.setState({["birthday"] : moment(date).format("YYYY-MM-DDTHH:mm:ss.SSSSZ") }) }
                                                        KeyboardButtonProps={{
                                                            "aria-label": "change date",
                                                        }}
                                                    />                
                                                </Grid>                                                
                                                <Grid item xs={12} sm={4} md={3}>
                                                    <Button size="large" 
                                                        startIcon={<SaveIcon />} onClick={this.callBackSave} 
                                                        fullWidth variant="outlined" color="primary" disabled={readOnly}
                                                        style={{paddingTop: 13, paddingBottom: 14}}
                                                    >
                                                        {t("buttons.Save")}
                                                    </Button>
                                                </Grid>                                            
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Paper className={classes.paper}>
                                            <Typography variant="body1" gutterBottom>
                                                <small>{t("profile.RegNum")}</small>: <b>{data.Adviser.RegistrationCode}</b>
                                            </Typography>
                                            {/* <Typography gutterBottom display="block">
                                                {t("profile.ReferalLink")}
                                            </Typography> */}
                                            
                                            <div>
                                                {/* <Button id="refUrl" color="primary" href={refUrl} target="_blank">{refUrl}</Button> */}
                                                <Button size="small" 
                                                    color="primary" 
                                                    startIcon={<FileCopyIcon />} 
                                                    onClick={() => this.copyCodeToClipboard(refUrl, t("profile.CopyReferalLink"))} 
                                                    style={{marginRight: 8}}
                                                    >
                                                {t("profile.ReferalLink")}
                                                </Button> 
                                                
                                                <Button size="small" color="primary" startIcon={<FileCopyIcon />} onClick={() => this.copyCodeToClipboard(publicUrl, t("profile.CopyPublicPage"))}>
                                                {t("profile.PublicPage")}
                                                </Button> 
                                                
                                                
                                            </div>
                                                                                                                                    
                                            <Button size="small" color="primary" startIcon={<PhoneForwardedIcon />}>
                                                {formatPhoneNumber(data.Adviser.Phone.startsWith("380") ? data.Adviser.Phone : `38${data.Adviser.Phone}`)}
                                            </Button>
                                                
                                            
                                        </Paper>
                                    </Grid>
                                    
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === "panelPhoto"} onChange={this.handleChangeAccordion("panelPhoto")}>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panelPhoto-content"
                            id="panelPhoto-header"
                            >
                            <Typography>{t("profile.panelPhoto")}</Typography>
                            {/* <Typography className={classes.secondaryHeading}>{t("profile.panelPhotoSec")}</Typography>  */}
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container direction="row" spacing={2}>
                                    <Grid item xs={12}>
                                        <Paper className={classes.paper}>
                                            <Grid container direction="row" justify="flex-start" spacing={2} style={{paddingBottom: 24}}>
                                                <Grid item >
                                                    <label  htmlFor={"file"}>                                                        
                                                        <div className={classes.chooseFile}>
                                                            <AddAPhotoOutlinedIcon style={{padding: 2, marginRight: 6}}/>  {t("buttons.UploadYourAvatar")}
                                                        </div>
                                                            <input type="file" id="file" accept="image/*" style={{display: "none"}} onChange={this.onSelectFile} aria-label="File browser"  />
                                                    </label>
                                                </Grid>

                                                <Grid item >
                                                    {src && (
                                                        <Button variant="contained" color="primary"  fullWidth size="large"
                                                                onClick={this.handleSaveAvatar} startIcon={<SaveOutlinedIcon />} >
                                                        { width === "xs"? null : t("buttons.Save")}
                                                        </Button>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            
                                            <Grid container direction="row" justify="center" alignItems="center" spacing={4} className={classes.previewbox}>
                                                <Grid item  xs={12} sm={3} >
                                                    <div className={classes.box} >
                                                        <div className="img-preview" style={{ width: "100%", height: "256px" }} />
                                                    </div>                                                
                                                </Grid>
                                                <Grid item xs={12} sm={9}>
                                                    <Cropper
                                                        ref={this.cropper}
                                                        style={{ height: 400, width: "100%" }}
                                                        zoomTo={2}
                                                        initialAspectRatio={1}
                                                        preview=".img-preview"
                                                        src={src}
                                                        viewMode={1}
                                                        guides={false}
                                                        minCropBoxHeight={128}
                                                        minCropBoxWidth={128}
                                                        background={false}
                                                        responsive={true}
                                                        autoCropArea={1}
                                                        checkOrientation={true} // https://github.com/fengyuanchen/cropperjs/issues/671
                                                        onInitialized={(instance) => {
                                                            this.setCropper(instance);
                                                        }}
                                                    />
                                                    {src &&
                                                    <Typography align="center">
                                                        <Button 
                                                            className={classes.button} 
                                                            color="primary"
                                                            variant="contained" 
                                                            startIcon={<RefreshIcon />}
                                                            onClick={()=> this.handleRotate(90)}>
                                                            +90
                                                        </Button>
                                                        <Button variant="contained"
                                                            className={classes.button} 
                                                            color="primary"
                                                            startIcon={<ReplayIcon />}
                                                            onClick={()=> this.handleRotate(-90)}>
                                                            -90
                                                        </Button>
                                                    </Typography>
                                                    }
                                                </Grid>                                            
                                            </Grid>                                                                                                                                                                    
                                    </Paper>
                                </Grid>
                            </Grid>
                            </AccordionDetails>
                        </Accordion>                        
                        <Accordion expanded={expanded === "panelMentor"} onChange={this.handleChangeAccordion("panelMentor")}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panelMentor-content"
                                id="panelMentor-header"
                            >
                            <Typography>{t("profile.panelMentor")}</Typography>
                            {/* <Typography className={classes.secondaryHeading}>{t("profile.panelMentorSec")}</Typography>  */}
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container direction="row" spacing={1} alignItems="stretch">
                                    <Grid item xs={12}>
                                        <Paper className={classes.paper}>
                                            <Grid container direction="row" justify={ width === "xs" ? "center" : "flex-start"} spacing={ width === "xs" ? 1 : 8}>
                                                <Grid item>                                                    
                                                    <Avatar alt={data.Adviser.Mentor.Name} src={data.Adviser.Mentor.Avatar} className={classes.middle} />
                                                </Grid>
                                                <Grid item>
                                                    <Typography gutterBottom display="block" variant="body2">
                                                        {`${t("reports.dashboard.Mentor")}: ${data.Adviser.Mentor.Name}` }
                                                    </Typography>
                                                    <Typography variant="body2" gutterBottom>
                                                        {t("profile.RegNum")}: <b>{data.Adviser.Mentor.RegistrationCode}</b>
                                                    </Typography>
                                                    <Typography variant="body2" gutterBottom>
                                                    {t("register.Birthday")}: <b>
                                                        {/* {moment(data.Adviser.Mentor.Birthday).format("DD.MM.● ● ● ●")} */}
                                                        {moment(data.Adviser.Mentor.Birthday).format("DD")} {moment(data.Adviser.Mentor.Birthday).format("MMMM")}
                                                    </b>
                                                    </Typography>
                                                    <div>
                                                        <Button size="small" color="primary" href={`mailto:${data.Adviser.Mentor.Email}`} startIcon={<MailOutlineIcon />} >
                                                            {data.Adviser.Mentor.Email}
                                                        </Button>
                                                    </div>                                                                                                                                       
                                                    <Button size="small" startIcon={<PhoneForwardedIcon />} href={`tel: ${(data.Adviser.Mentor.Phone.startsWith("380") ? data.Adviser.Mentor.Phone : `38${data.Adviser.Mentor.Phone}`)}`} color="primary">
                                                        {formatPhoneNumber(data.Adviser.Mentor.Phone.startsWith("380") ? data.Adviser.Mentor.Phone : `38${data.Adviser.Mentor.Phone}`)}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === "panelAbout"} onChange={this.handleChangeAccordion("panelAbout")}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panelAbout-content"
                                id="panelAbout-header"
                            >
                            <Typography>{t("profile.panelAbout")}</Typography>
                            {/* <Typography className={classes.secondaryHeading}>Let me introduce myself</Typography> */}
                            </AccordionSummary>
                            <AccordionDetails>                                        
                                
                                    <SunEditor
                                        ref={this.refId}                
                                        lang={"ua"}
                                        height={ width === "xs" ? 500 : 700} 
                                        setDefaultStyle="font-family: Roboto; font-size: 1rem; ine-height: 1.5;letter-spacing: 0.00938em;"
                                        setOptions={{
                                            font: [ "Roboto", "Arial", "tahoma", "Courier New,Courier", "Comic Sans MS", "Impact", "Georgia", "Trebuchet MS", "Verdana" ],                                    
                                            // buttonList: buttonList.formatting,
                                            buttonList: [
                                                // default                                                
                                                ["save", "formatBlock", "bold", "underline", "italic"],
                                            ],
                                            codeMirror: CodeMirror,
                                            addTagsWhitelist: "br|p|div|pre|blockquote|h1|h2|h3|h4|h5|h6|ol|ul|li|hr|figure|figcaption|img|picture|source|audio|video|table|thead|tbody|tr|th|td|a|b|strong|var|i|em|u|ins|s|span|strike|del|sub|sup|code|svg|path",
                                            attributesWhitelist: {
                                                "all": "contenteditable|id|style|colspan|rowspan|target|href|download|rel|src|alt|class|type|controls|media",
                                            },
                                            callBackSave: this.callBackSave,
                                        }}                
                                        onChange={this.handleEditorChange}
                                        setContents={ comment }
                                    />
                            </AccordionDetails>
                        </Accordion>                        
                        {/* <Accordion expanded={expanded === "panelAddress"} onChange={this.handleChangeAccordion("panelAddress")}>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panelAddress-content"
                            id="panelAddress-header"
                            >
                            <Typography className={classes.heading} color="secondary">{t("profile.address")} !!!</Typography>                            
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container direction="row" spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Paper className={classes.paper}>
                                            Address 1
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Paper className={classes.paper}>
                                            Address 2
                                        </Paper>
                                    </Grid>                                    
                                </Grid>
                            </AccordionDetails>
                        </Accordion> */}
                        <Accordion expanded={expanded === "panelProducts"} onChange={this.handleChangeAccordion("panelProducts")}>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panelProducts-content"
                            id="panelProducts-header"
                            >
                            <Typography className={classes.heading}>{t("profile.products")}</Typography>
                            {/* <Typography className={classes.secondaryHeading}>{t("profile.ExportYml")}</Typography> */}
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container direction="row" spacing={2}>
                                    <Grid item xs={12} >                                                                                    
                                        <Button variant="outlined" className={classes.button}  color="primary" startIcon={<GetAppIcon />}  onClick={this.downloadFile} >
                                            {t("buttons.Download")}
                                        </Button>
                                        {/* <Link className={classes.dowloadlink} href={refUrlYml} target="_blank">{refUrlYml}</Link> */}
                                        <Button variant="outlined" className={classes.button} color="primary" startIcon={<FileCopyIcon />} onClick={() => this.copyCodeToClipboard(refUrlYml, t("profile.CopyLink"))}>
                                            {t("profile.Link")}
                                        </Button>                                                                                                                                                                
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>                        
                    </>
                }
                <Snackbar
                    anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                    }}
                    open={showSnack}
                    autoHideDuration={3000}
                    onClose={this.handelCloseSnack}        
                >
                <Alert onClose={this.handelCloseSnack} severity={variantSanck}>
                    {message}
                </Alert>
                </Snackbar>
            </div>            
            </MuiPickersUtilsProvider>
        );
    }
}

export default  withTranslation()(withWidth() (withStyles(styles)((Profile))));