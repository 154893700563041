import axios from "axios";
// import userManager from "../helpers/userManager";
import { OidcUserManager } from "../helpers/OidcSettings";

const requestCatalogItemsType = "REQUEST_CATALOG_ITEMS";
const receiveCatalogItemsType = "RECEIVE_CATALOG_ITEMS";
const initialState = { 
  response: { 
    ProductGroup: { LocalName: "" }, 
    CatalogItems: null, 
  },
  lang: "",
  grp: "",
  isLoading: false,
  activeNavItem: {title: "", key: ""},
};

const getActiveLang = (langs) => {
  for (let i = 0; i <langs.length; i++) {
    const element = langs[i];
    if(element.Active){
      return element.Name;          
    }
  }
  return "";
}

const getActiveNavItem = (items, key) => {
  if( typeof(items) === "undefined" || items === null){
    return null;
  }

  if(items.length === 0){
    return null;
  }

  for (let i = 0; i < items.length; i++) {
    const item = items[i];                    
    if(decodeURI(item.href).indexOf(key) > 0){
      return {title: item.title, key: key};      
    }    
    const find = getActiveNavItem(item.children, key);
    if(find !== null){
      return find;
    }    
  }
  return null;
}


export const actionCreators = {
  requestCatalogItems: (grp, pi) => async (dispatch, getState) => {    
    // Access to to anothe reducer    
    // getState().otherReducer;
    // const lang  = getActiveLang( getState().appLayout.app.Langs);

    if(grp.length === 0){      
      return;
    }

    const lang = getActiveLang(getState().appLayout.Langs); 

    if (grp === getState().catalogRequest.grp && getState().catalogRequest.lang === lang) {
      // Don't issue a duplicate request (we already have or are loading the requested data)
      return;
    }    
    
    const activeNavItem = getActiveNavItem(getState().appLayout.GroupNavItems, grp);

    dispatch({ type: requestCatalogItemsType, grp, pi, lang, activeNavItem  });

    let token = null;
    const userManager = await OidcUserManager();
        
    userManager.getUser().then(function (user) {
      if(user != null){      
        token =  `Bearer ${user.access_token}`;        
      }  
      // const url = `/api/v1.0/product/load?gid=${grp}`; 
      const url = `/api/v1.0/CatalogItem/Get?key=${grp}`; 
      axios.get(url,         
        { 
          headers: { Authorization: token }        
        }
      )
      .then(function(response){         
        dispatch({ type: receiveCatalogItemsType, grp, pi, lang, result: response.data });    
      })
      .catch((error) => {        
        console.log(error);  
        dispatch({ type: receiveCatalogItemsType, grp, pi, lang, initialState  });      
      });
    });
    
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case requestCatalogItemsType:
      return {
        ...state,
        pi: action.pi,
        grp: action.grp,
        lang: action.lang,
        activeNavItem: action.activeNavItem !== null ? action.activeNavItem :{title: "", key: ""},
        isLoading: true
      };
    case receiveCatalogItemsType:
      return {
        ...state,
        pi: action.pi,
        grp: action.grp,
        lang: action.lang,
        response: action.result,      
        isLoading: false
      };
    default:
      return state;
  }


};
