import React, { Suspense } from "react";
import NavMenu from "../NavMenu/NavMenu";
import Notification from "../Notification/Notification";
import Footer from "../Footer/Footer";
import { withStyles } from "@material-ui/core/styles";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { ukUA } from "@material-ui/core/locale";
import AppThemeOptions from "../Themes/Default/theme";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Helmet } from "react-helmet";

const styles = theme => ({
  main: {
    display: "flex",    
    marginBottom: theme.spacing(6),
    // marginTop: 170,

    [theme.breakpoints.down("xs")]: {
      minHeight: 300,
      // marginTop: theme.spacing(17),      
    },

    [theme.breakpoints.up("sm")]: {
      minHeight: 597,
      // marginTop: theme.spacing(17),
    },    
  },
  
  progress: {
    height: 2,
    position: "absolute",
    top: 0,
    left: 0,
    right:0,
  },  
  linearColorPrimary: {
    backgroundColor: "#abdcda",
  },
  linearBarColorPrimary: {
    backgroundColor: "#d20000",
  },
});


class Layout extends React.Component {
  // constructor(props) {        
  //   super(props);
  // }

  render() {
    const { classes, theme, toggleTheme } = this.props;    
    const muiTheme = createMuiTheme(AppThemeOptions[theme], ukUA);  
    return (
      <Suspense fallback={<LinearProgress className={classes.progress} 
        classes={{ colorPrimary: classes.linearColorPrimary, barColorPrimary: classes.linearBarColorPrimary }}
      />}>      
        <ThemeProvider theme={muiTheme}>          
          <Helmet>
          <title>J’erelia - джерело здоров&apos;я, краси, фінансового благополуччя.</title>
          <meta property="og:title" content="The Rock" />
          <meta property="og:description" content="Натуральна косметика по догляду за шкірою тіла, обличчя, рук та волоссям, ексклюзивні парфуми, інноваційні еко-засоби для чистоти в домі, аксесуари, вітаміни, комплексні програми очищення організму, фітопрепарати для здоров'я, продукти функціонального харчування від української компанії 'Джерелія'."/>
          <meta property="og:url" content="https://jerelia.com/" />
          <meta property="og:site_name" content="J’erelia" />
          <meta property="og:image" content="https://jerelia.com/assets/files/images/images/jerelia.jpg" />
          </Helmet>

            <NavMenu onThemeTypeSwitch={toggleTheme} />
            <main className={classes.main}>
              {this.props.children}
              <Notification />    
            </main>
            <Footer />
        </ThemeProvider>
      </Suspense>
    );

  }
}

export default withStyles(styles)(Layout);