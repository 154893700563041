import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { OidcUserManager } from "../../helpers/OidcSettings";
import gray from "@material-ui/core/colors/grey";
// import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

import moment from "moment";
import "moment/locale/ru";
import "moment/locale/uk";
import TreeView from "@material-ui/lab/TreeView";
import IconButton from "@material-ui/core/IconButton";
// import TreeItem, { TreeItemProps } from "@material-ui/lab/TreeItem";
import TreeItem from "@material-ui/lab/TreeItem";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import Phone from "@material-ui/icons/Phone";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

import axios from "axios";

const styles = theme => ({
    root: {
        flexGrow: 1,        
        padding: theme.spacing(1),        
    },
    rootTree: {        
        minHeight: 264,
        height: "auto",        
        "&.MuiTreeView-root > .MuiTreeItem-root > .MuiTreeItem-group": {            
            marginLeft: 0,
          }
    },
    progress: {
        height: 2,
        position: "absolute",
        top: 0,
        left: 0,
        right:0,
      },  
      linearColorPrimary: {
        backgroundColor: "#abdcda",
      },
      linearBarColorPrimary: {
        backgroundColor: "#d20000",
      },
    
    paper: {
        padding: theme.spacing(2),
    },
    btn: {
        marginRight: 5
    },
    info: {
        padding: theme.spacing(1),
        height: "auto",
        [theme.breakpoints.down("sm")]: {
            height: 100,
          },
    },
    divider: {        
        marginBottom:theme.spacing(2),
    },
    customer: {
        textTransform: "capitalize",
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    media: {
        height: 183,
        // backgroundSize: "contain",
    },
    value: {
        fontWeight: "bold",
    },
    table: {
        minWidth: 500,
    },
    bold: {
        fontWeight: "500",
    },
    total: {
        fontWeight: "500",
        backgroundColor: gray[200],
    },
    step: {
        marginRight: theme.spacing(1),        
      },  
    fixcell: {
        backgroundColor: gray[100],
    },
    treerow: {
        marginBottom: theme.spacing(1),
        borderBottom: `1px solid ${theme.palette.divider}`,        
    },
    consultant: {        
        // [theme.breakpoints.down("sm")]: {            
        //     whiteSpace: "initial",
        //     width: "15rem",
        //     overflowX: "auto",
        //   },

    }
});


class NetworkStructure extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            keys: [],
            isLoading: false,

        };    
    }

    componentDidMount() {
        this.loadData();        
    }

    componentWillUnmount() {        
    }

    loadData = async () => {
        const self = this;
        const userManager = await OidcUserManager();

        userManager.getUser().then(user => {
            let token = null;

            if (user != null) {
                token =  `Bearer ${user.access_token}`;                
                this.setState({isLoading: true});
                const URL =  "/api/v1.0/reports/networkstructure";
                axios.get(URL,  
                    { headers: { Authorization: token } }
                )                
                .then(function(response){
                    const keys = [];
                                        
                    self.setState({ data: response.data, isLoading: false, keys: keys });
                    
                    // console.log("NetworkStructure data", response.data); 
                })
                .catch((error) => {        
                    this.setState({isLoading: false});
                    console.log(error);
                });                
            }
        });
    };

    renderTree = (consultant) => (  
        
        <TreeItem key={consultant.НомерКонсультанта} nodeId={consultant.НомерКонсультанта.toString()} 
            label={
                <Grid className={this.props.classes.treerow} container direction="row" alignItems="center" spacing={1}>
                    <Grid item xs={3} sm={1} >
                        <Typography variant="body2" display="block">
                             {consultant.НомерКонсультанта}
                        </Typography>
                    </Grid>
                    <Grid item xs={9} sm={3}>
                        <Typography className={this.props.classes.consultant} variant="body2" display="block" >
                             {consultant.Client}
                        </Typography>                        
                        <Link className={this.props.classes.btn} href={`tel:${consultant.Телефон}`} variant="body2">
                            {consultant.Телефон}
                        </Link>
                        <Link className={this.props.classes.btn} href={`mailto:${consultant.ЭлАдрес}`} variant="body2">
                            {consultant.ЭлАдрес}
                        </Link>                        
                    </Grid>
                    <Grid item xs={6} sm={1} >
                       <IconButton size="small"  aria-label="Level">
                            {consultant.Level}
                         </IconButton>
                         <IconButton size="small" color="primary" href={`tel:${consultant.Телефон}`} aria-label="Phone">
                             <Phone />
                         </IconButton>
                         <IconButton size="small" color="primary" href={`mailto:${consultant.ЭлАдрес}`} aria-label="Email">
                             <MailOutlineIcon />
                         </IconButton>
                     </Grid>
                     <Grid item xs={6} sm={1}>
                        <Typography variant="body2" noWrap align="center">
                             {consultant.ВидКлиента}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} sm={1}>
                        <Typography variant="body2" display="block" noWrap align="center">
                            {consultant.ЛОПоНеоплаченнымЗаказам !==0 ? consultant.ЛОПоНеоплаченнымЗаказам : "-"}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} sm={1}>
                        <Typography variant="body2" display="block" noWrap align="center">
                            { consultant.ЛичныйОбъем !== 0 ? consultant.ЛичныйОбъем : "-"}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} sm={1}>
                        <Typography variant="body2" display="block" noWrap align="center">
                            { consultant.ГрупповойОбъем !== 0 ? consultant.ГрупповойОбъем : "-"}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} sm={1}>
                        <Typography variant="caption" display="block" align="center" >
                            {consultant.СтруктурныйСтатус}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} sm={1}>
                        <Typography variant="body2" display="block" noWrap >
                            {moment(consultant.ДатаРегистрации).format("DD-MM-YYYY")}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} sm={1}>
                        <Typography variant="body2" display="block" noWrap >
                            {moment(consultant.ДатаРождения).format("DD-MM-YYYY")}
                        </Typography>
                    </Grid>

                </Grid>                                 
            }>
          {Array.isArray(consultant.Consultants) ? consultant.Consultants.map((node) => this.renderTree(node)) : null}
        </TreeItem>
    );


    render() {
        const { classes, t } = this.props; 
        const { data, isLoading } = this.state;

        return (
            <div className={classes.root}>
                { isLoading && 
                    <LinearProgress className={classes.progress} 
                    classes={{
                        colorPrimary: classes.linearColorPrimary,
                        barColorPrimary: classes.linearBarColorPrimary,
                    }}
                />
                }

                <Grid container direction="row" spacing={2} justify="flex-start" alignItems="center" >
                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={1}>
                            <Typography variant="h6" align="center">
                                {t("reports.networkstructure.title")}
                            </Typography>
                            { data && 
                            <Typography align="center">
                                { data ? `${data.Adviser.RegistrationCode} ${data.Adviser.Name}` : null }
                            </Typography>
                            }
                        </Paper>
                    </Grid>
                </Grid>
                { 
                
                data ?
                <Grid container spacing={2} direction="row">
                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={1} style={{minHeight: "347px"}}>

                            <Grid className={this.props.classes.treerow} container direction="row" spacing={1}>
                                <Grid item xs={3} sm={1} >
                                    <Typography variant="subtitle2" display="block" align="center" >
                                        {t("reports.networkstructure.regnumber")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={9} sm={3}>
                                    <Typography variant="subtitle2" display="block" align="center" >
                                    {t("reports.networkstructure.Consultant")}
                                    </Typography>                                                
                                </Grid>

                                <Grid item xs={6} sm={1}>
                                    
                                </Grid>                    
                                <Grid item xs={6} sm={1}>
                                    <Typography variant="subtitle2" display="block" align="center" >
                                        {t("reports.networkstructure.ViewClient")}
                                    </Typography>                        
                                </Grid>

                                <Grid item xs={4} sm={1}>
                                    <Typography variant="subtitle2" display="block" align="center" >
                                        {t("reports.networkstructure.ЛОПоНеоплаченнымЗаказам")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} sm={1}>
                                    <Typography variant="subtitle2" display="block" align="center" >
                                        {t("reports.networkstructure.ЛичныйОбъем")}
                                    </Typography>                        
                                </Grid>                    
                                <Grid item xs={4} sm={1}>
                                    <Typography variant="subtitle2" display="block" align="center" >
                                        {t("reports.networkstructure.ГрупповойОбъем")}
                                    </Typography>                        
                                </Grid>
                        
                                <Grid item xs={4} sm={1}>
                                    <Typography variant="subtitle2" display="block" align="center" >
                                        {t("reports.networkstructure.СтруктурныйСтатус")}
                                    </Typography>                        
                                </Grid>
                                <Grid item xs={4} sm={1}>
                                    <Typography variant="subtitle2" display="block" align="center" >
                                        {t("reports.networkstructure.ДатаРегистрации")}
                                    </Typography>                                
                                </Grid>
                                <Grid item xs={4} sm={1}>
                                    <Typography variant="subtitle2" display="block" align="center" >
                                    {t("reports.networkstructure.ДатаРождения")}
                                    </Typography>                                    
                                </Grid>
                            </Grid>

                            <TreeView
                            className={classes.rootTree}
                            defaultExpanded={[data.Adviser.RegistrationCode.toString()]}
                            defaultCollapseIcon={<ArrowDropDownIcon />}
                            defaultExpandIcon={<ArrowRightIcon />}
                            defaultEndIcon={<div style={{ width: 24 }} />}
                            >
                            {this.renderTree(data.Consultant)}
                            </TreeView>
                        </Paper>
                    </Grid>
                </Grid> 
                :
                <Typography style={{paddingTop: "48px"}} color="textSecondary" variant="h5" align="center" gutterBottom>
                    {t("common.Loading")}...
                </Typography>
            }
            </div>            
        );
    }
}


export default  withTranslation()(withWidth() (withStyles(styles)((NetworkStructure))));

