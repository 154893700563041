const SW_INIT = "SW_INIT";
const SW_UPDATE = "SW_UPDATE";
const initialState = { 
    serviceWorkerInitialized: false,
    serviceWorkerUpdated: false,
    serviceWorkerRegistration: null,
};

export const actionCreators = {
  sw_init: () => ({ type: SW_INIT }),
  sw_updata: () => ({ type: SW_UPDATE })
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case SW_INIT:
      return {
        ...state,
        serviceWorkerInitialized: !state.serviceWorkerInitialized,
      };
    case SW_UPDATE:
      return {
        ...state,
        serviceWorkerUpdated: !state.serviceWorkerUpdated,
        serviceWorkerRegistration: action.payload,
      };
    default:
      return state;
  }
};
