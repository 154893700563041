import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import {ReactComponent as CatalogImg}  from "./catalog.svg";
// import {ReactComponent as ChildImg}  from "./icons/child.svg";
import {ReactComponent as FaceImg}  from "./face.svg";
import {ReactComponent as ForBodyImg}  from "./woman-body.svg";
import {ReactComponent as ForHomeImg}  from "./home.svg";
import {ReactComponent as HairImg}  from "./woman-hair.svg";
import {ReactComponent as HealthImg}  from "./heart.svg";
import {ReactComponent as JewerlyImg}  from "./diamond.svg";
import {ReactComponent as MakeupImg}  from "./make-up.svg";
import {ReactComponent as ManImg}  from "./manager.svg";
import {ReactComponent as ParfumsImg}  from "./perfume.svg";
import {ReactComponent as BabyImg}  from "./baby.svg";
import {ReactComponent as WomanHeadImg}  from "./woman-head.svg";
import {ReactComponent as FacebookImg}  from "./facebook.svg";
import {ReactComponent as TelegramImg}  from "./telegram.svg";
import {ReactComponent as ViberImg}  from "./viber.svg";

/* Jerelia catalog icon set */

function CatalogIcon(props){
    return(
      <SvgIcon  >
        <CatalogImg {...props} />
      </SvgIcon>
    );
}
function ChildIcon(props){    
    return (
        <SvgIcon >
            <BabyImg {...props} />
        </SvgIcon>
        );
    //   <SvgIcon  >
    //     <ChildImg {...props} />
    //   </SvgIcon>
    
}
function WomanHeadIcon(props){    
    return (
        <SvgIcon >
            <WomanHeadImg {...props} />
        </SvgIcon>
    );  
}

function FacebookIcon(props){    
  return (
      <SvgIcon >
          <FacebookImg {...props} />
      </SvgIcon>
  );  
}

function TelegramIcon(props){    
  return (
      <SvgIcon >
          <TelegramImg {...props} />
      </SvgIcon>
  );  
}

function ViberIcon(props){    
  return (
      <SvgIcon >
          <ViberImg {...props} />
      </SvgIcon>
  );  
}

function FaceIcon(props){
    return(
      <SvgIcon >
        <FaceImg {...props} />
      </SvgIcon>
    );
}
function ForBodyIcon(props){
    return(
      <SvgIcon  >
        <ForBodyImg {...props} />
      </SvgIcon>
    );
}
function ForHomeIcon(props){
    return(
      <SvgIcon  >
        <ForHomeImg {...props} />
      </SvgIcon>
    );
}
function HairIcon(props){
return(
    <SvgIcon  >
        <HairImg {...props} />
    </SvgIcon>
);
}
function HealthIcon(props){
    return(
    <SvgIcon style={{textAlign: "center"}} >
        <HealthImg {...props} />
    </SvgIcon>
    );
}
function JewerlyIcon(props){
    return(
        <SvgIcon  >
            <JewerlyImg {...props} />
        </SvgIcon>
    );
}
function MakeupIcon(props){
    return(
        <SvgIcon  >
            <MakeupImg {...props} />
        </SvgIcon>
    );
}
function ManIcon(props){
    return(
        <SvgIcon  >
            <ManImg {...props} />
        </SvgIcon>
    );
}
function ParfumsIcon(props){
    return(
        <SvgIcon  >
            <ParfumsImg {...props} />
        </SvgIcon>
    );
}
function HeartPulseIcon(props) {
    return (
      <SvgIcon {...props}>
        <path d="M7.5,4A5.5,5.5 0 0,0 2,9.5C2,10 2.09,10.5 2.22,11H6.3L7.57,7.63C7.87,6.83 9.05,6.75 9.43,7.63L11.5,13L12.09,11.58C12.22,11.25 12.57,11 13,11H21.78C21.91,10.5 22,10 22,9.5A5.5,5.5 0 0,0 16.5,4C14.64,4 13,4.93 12,6.34C11,4.93 9.36,4 7.5,4V4M3,12.5A1,1 0 0,0 2,13.5A1,1 0 0,0 3,14.5H5.44L11,20C12,20.9 12,20.9 13,20L18.56,14.5H21A1,1 0 0,0 22,13.5A1,1 0 0,0 21,12.5H13.4L12.47,14.8C12.07,15.81 10.92,15.67 10.55,14.83L8.5,9.5L7.54,11.83C7.39,12.21 7.05,12.5 6.6,12.5H3Z"/>
      </SvgIcon>
    );
}
function HomeLightbulbIcon(props) {
    return (
      <SvgIcon {...props}>
        <path d="M12 3L2 12H5V20H19V12H22M13 18H11V17H13M13.5 14.58V16H10.5V14.58A3 3 0 1 1 13.5 14.58Z"/>
      </SvgIcon>
    );
}
function FaceOutlineIcon(props) {
    return (
      <SvgIcon {...props}>
        <path d="M10.25,13A1.25,1.25 0 0,1 9,14.25A1.25,1.25 0 0,1 7.75,13A1.25,1.25 0 0,1 9,11.75A1.25,1.25 0 0,1 10.25,13M15,11.75A1.25,1.25 0 0,0 13.75,13A1.25,1.25 0 0,0 15,14.25A1.25,1.25 0 0,0 16.25,13A1.25,1.25 0 0,0 15,11.75M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M10.66,4.12C12.06,6.44 14.6,8 17.5,8C17.96,8 18.41,7.95 18.84,7.88C17.44,5.56 14.9,4 12,4C11.54,4 11.09,4.05 10.66,4.12M4.42,9.47C6.13,8.5 7.45,6.92 8.08,5.03C6.37,6 5.05,7.58 4.42,9.47M20,12C20,11.22 19.88,10.47 19.67,9.76C18.97,9.91 18.25,10 17.5,10C14.37,10 11.58,8.56 9.74,6.31C8.69,8.87 6.6,10.88 4,11.86C4,11.9 4,11.95 4,12C4,16.41 7.59,20 12,20C16.41,20 20,16.41 20,12Z"/>  
      </SvgIcon>
    );
}
function AccountTieOutline(props) {
    return (
      <SvgIcon {...props}>
        <path d="M16.36 12.76C18.31 13.42 20 14.5 20 16V21H4V16C4 14.5 5.69 13.42 7.65 12.76L8.27 14L8.5 14.5C7 14.96 5.9 15.62 5.9 16V19.1H10.12L11 14.03L10.06 12.15C10.68 12.08 11.33 12.03 12 12.03C12.67 12.03 13.32 12.08 13.94 12.15L13 14.03L13.88 19.1H18.1V16C18.1 15.62 17 14.96 15.5 14.5L15.73 14L16.36 12.76M12 5C10.9 5 10 5.9 10 7C10 8.1 10.9 9 12 9C13.1 9 14 8.1 14 7C14 5.9 13.1 5 12 5M12 11C9.79 11 8 9.21 8 7C8 4.79 9.79 3 12 3C14.21 3 16 4.79 16 7C16 9.21 14.21 11 12 11Z"/>
      </SvgIcon>
    );
}



export { FacebookIcon, TelegramIcon, ViberIcon, CatalogIcon, ChildIcon, WomanHeadIcon, FaceIcon, ForBodyIcon, ForHomeIcon, HairIcon, HealthIcon, JewerlyIcon, MakeupIcon, ManIcon, 
         ParfumsIcon, HeartPulseIcon, HomeLightbulbIcon, FaceOutlineIcon, AccountTieOutline };