import React from "react";
import {withRouter} from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionOrderCreators } from "../../store/Order";
import { connect } from "react-redux";
import {withStyles} from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import ProductSlider from "../Slider/ProductSlider";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Avatar from "@material-ui/core/Avatar";
import AccessTime from "@material-ui/icons/AccessTime";
import ShoppingBasketOutlinedIcon from "@material-ui/icons/ShoppingBasketOutlined";
import { withTranslation } from "react-i18next";
import red from "@material-ui/core/colors/red";
import blue from "@material-ui/core/colors/blue";
import pink from "@material-ui/core/colors/pink";
import gray from "@material-ui/core/colors/grey";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import BrightnessAutoIcon from "@material-ui/icons/BrightnessAuto";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import Rating from "@material-ui/lab/Rating";
import Brightness1Icon from "@material-ui/icons/Brightness1";
import ListItemIcon from "@material-ui/core/ListItemIcon";
// import InputAdornment from "@material-ui/core/InputAdornment";

import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
// import ShareIcon from "@material-ui/icons/Share";
import Collapse from "@material-ui/core/Collapse";

import Link from "@material-ui/core/Link";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import Youtube from "../Video/youtube";
import moment from "moment";
import "moment/locale/uk";
import TablePagination from "@material-ui/core/TablePagination";
// import { OidcUserManager } from "../../helpers/OidcSettings";
// import { AuthConsumer } from "../../providers/authProvider";
import AuthService from "../../helpers/authService";
// import { getThemeProps } from "@material-ui/styles";
// import { withThemeCreator } from "@material-ui/styles";

const EGUID = "00000000-0000-0000-0000-000000000000";

const styles = theme => ({
    card: {                
        // transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",        
        // boxShadow: "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px"    
    },
    header: {
        fontSize: "1.9rem",
        fontWeight: 300,        
        [theme.breakpoints.down("sm")]: {
            textAlign: "center",
            fontSize: "1.6rem",
        },
    },
    appBarBtn: {   
        padding: theme.spacing(1, 0, 1, 0),        
        boxShadow: "none",
        top: "auto",
        bottom: 0,
        marginBottom: "-4px",
        backgroundColor: "#ffffffbf",
     },
    toolbarBtn: {
        // minHeight: "38px",
    },
    grow: {
        flexGrow: 1,
    },
    breadcrumbs: {
        marginBottom: theme.spacing(1),
    },
    link: {
        display: "flex",
        // whiteSpace: "nowrap",
        // overflow: "hidden",
    },
    chipBtn: {        
        marginRight: theme.spacing(2)
    },
      
    chipBuyBtn: { 
        pading: theme.spacing(2),
        // marginRight: theme.spacing(2),
        // height: theme.spacing(6),
        // borderRadius: 32,
        // fontSize: "1rem"
    },
    attr: {
        fontSize: "1rem",
        padding: theme.spacing(1, 2),
    },
    block: {
        padding: theme.spacing(2),        
    },
    priceblock: {
        marginTop: theme.spacing(3),
        // marginBottom: theme.spacing(3)        
    },
    tableAttr: {
        color: gray[600],
        // textTransform: "uppercase",
        // marginBottom: theme.spacing(2),

        // "& tr" : {
        //     "& td": {
        //         padding: theme.spacing(0, 2)
        //     }             
        // }
    },
    tdAttr: {
        paddingLeft: theme.spacing(3)
    },
    tabReview: {
        paddingTop: theme.spacing(0),
        // paddinBottom: theme.spacing(2),
    },
    averageReview: {
        color: gray[500],
        padding: theme.spacing(0, 3),
    },
    images: {
        padding: theme.spacing(0, 2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(0),
        }
    },
    SubstanceComposition:{
        [theme.breakpoints.down("sm")]: {
          whiteSpace: "initial",
          width: "19rem",
          overflowX: "auto",
        },
        [theme.breakpoints.down("md")]: {       
          width: "100%",
        },
        [theme.breakpoints.up("lg")]: {
          width: "100%",
        },
    },
    rating: {
        marginBottom: theme.spacing(3)
    },

    reviewbox: {
        padding: theme.spacing(2),
        backgroundColor: gray[50],
        borderRadius: "16px"
    },

    reviewAuth: {
        marginLeft: theme.spacing(2),
        fontSize: ".9rem",
        textTransform: "capitalize"
    },

    buttons: {
        marginRight: theme.spacing(1),
    },
    
    specialprice: {        
        position: "relative",
        width: 48,
        top: 1,
        left: -16,
        fontSize: "0.8rem",
        color: theme.palette.secondary.main,
        padding: theme.spacing(1* 0.2, 1),
        borderRadius: "0 16px 16px 0",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor:theme.palette.secondary.main,
        zIndex: 100,
        // border: "1px solid #f50057",
        
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",        
        boxShadow: "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
        // right: theme.spacing(1),
    },

    priceBB: {        
        position: "absolute",
        left: "35%",
        [theme.breakpoints.down("xs")]: {
            left: "84%",            
        },        
        // height: "30px",
        width: "60px",
        fontSize: "0.8rem",
        // lineHeight: "30px",
        padding: theme.spacing(1* 0.2, 1),
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: gray[300],
        borderRadius: "16px 0 0 16px", 
        textAlign: "center",
        zIndex: 100,
    },

    burst: {
        width: 60,
        padding: theme.spacing(1),
        position: "absolute",
        top: 45,
        right: 35,  
    },
    specialcomment: {
        padding: theme.spacing(1),
        position: "absolute",
        top: 10,
        left: 5,
        fontWeight: "bold",
        fontSize: "1.3rem",
        color: "#fff"
    },
    select: {
        borderRadius: "24px",
        lineHeight: 24,
    },
    // actcover:{
    //     height: 308,
    //   },

    cardcontent: {        
        marginBottom: theme.spacing(1),
        padding: theme.spacing(0, 1),
    },
    
    name: {        
        fontWeight: 400,
        fontSize: "1.1rem",
        // marginBottom: theme.spacing(1),
        height: 60,         
        textOverflow: "clip",        
        overflow: "hidden",        

        [theme.breakpoints.down("sm")]: {
            fontSize: "1rem",
            height: 80,
          },        
    },
    actions: {
        // position: "absolute",
        // left: 0,
        // right: 0,
        bottom: 0,
      },
    action4:{
        backgroundColor: theme.palette.secondary.main,
    },
    
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
          duration: theme.transitions.duration.shortest,
        }),
    },
    buy: {
        padding: theme.spacing(4),
        // marginLeft: "auto",            
        // margin: theme.spacing(1),
    },
    expandOpen: {
        transform: "rotate(180deg)",
    },
    redprice: {       
        fontWeight: 300,
        marginRight: theme.spacing(1),
    },
    priceOld: {
        fontWeight: 300,
        color: gray[400]
        // textDecoration: "line-through",        
        // color: "#8a8a8a",        
    },
    

    specialtag: {        
        position: "absolute",
        left: 0,
        // marginTop: theme.spacing(-1 *1 ),
        width: "41.666667%",       
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        }, 
        textAlign: "center",
        zIndex: 2,
    },

    btnright: {
        textAlign: "right",
    },
    blabel: {        
        [theme.breakpoints.down("xs")]: {
          display: "none",
        },
      },    
    price: {        
        marginRight: theme.spacing(2),
    },
    colorRed: {
        color: red[500],
        fontSize: "1.7rem"
    },
    colorBlue: {
        color: blue[500],
    }, 
    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(1),        
    },
    adv:{
        height: 150,
        border: "solid 1px red"
    },
    blockquote: {        
        // lineHeight: "1rem",
        fontSize: "1rem",
        margin: "0px auto",
        position: "relative",
        // fontFamily: "Georgia, serif",
        padding: theme.spacing(3, 3),
        borderRadius: theme.spacing(2),
        backgroundColor: "rgb(244, 246, 248)",
        color: "rgb(99, 115, 129) !important",
        "&::before": {
            fontFamily: "Georgia, serif",
            left: theme.spacing(2),
            top: 0,
            content: "\"“\"",
            fontSize: "2em",
            display: "block",            
            position: "absolute",
            color: "rgb(145, 158, 171)"
        },
        "&::after": {
            fontFamily: "Georgia, serif",
            // left: theme.spacing(2),
            bottom: theme.spacing(-2),
            right: theme.spacing(2),
            content: "\"”\"",
            fontSize: "2em",
            display: "block",            
            position: "absolute",
            color: "rgb(145, 158, 171)"
        },
        [theme.breakpoints.down("sm")]: {
            marginBottom: theme.spacing(3),
        }
      },
      tabprod: {
        [theme.breakpoints.down("sm")]: {
            marginTop: theme.spacing(3),
        }
      },
      btnsreview: {
        [theme.breakpoints.down("sm")]: {
            paddingTop: theme.spacing(3),
        } 
      },
      reviewDate: {
          textAlign: "right",
          paddingRight: theme.spacing(3),
          fontSize: ".8rem"
      },

    
});

class Review {
    constructor(Id, Rating, Advantage, Limitations, Text, UserName, UserEmail, ManagerChangeState, 
            ProductCode, ProductName,
            Useful, Reviewtype, Reviewstate) {
        this.Id = Id;
        this.Rating = Rating;
        this.Advantage = Advantage;
        this.Limitations = Limitations;
        this.Text = Text;
        this.UserName = UserName;
        this.UserEmail = UserEmail;
        this.ManagerChangeState = ManagerChangeState;
        this.ProductCode = ProductCode;
        this.ProductName = ProductName;
        this.Useful = Useful;
        this.Reviewtype = Reviewtype;
        this.Reviewstate = Reviewstate;        
        // this.CreatedOn = CreatedOn;
        // this.UpdatedOn = UpdatedOn;
    }
}

function getCleanHtml(html){
    return html.replace(/(<? *script)/gi, "illegalscript");
}

function getUserName(regNumber, userName){
    if(regNumber.length > 0){
        const words = userName.split(" ");
                
        let str = "";
        let count = 0;
        if(words.length > 0){
            for (let i = 0; i < words.length; i++) {                
                const word = words[i];
                if(word.length === 0)
                    continue;
                
                if(word.length > 1){        
                    if(count === 0){
                        str =`${word[0].charAt(0)}.`;
                    } else {
                        str = word +" "+ str;
                    }        
                    count++;                
                }
                if( count>1 )
                    break;
            }
        }
        return str.toLowerCase();
        // return (`${words[1]} ${words[0].charAt(0)}.`).toLowerCase();
    }

    return userName.toLowerCase();
}

function getRating(number){
    let rating = Number("0." + String(number).split(".")[1]);    
    let _num = parseInt(number) + (rating > 0 ? 0.5 : 0);
    return _num;
}

function getUserAvatar(regNumber, userName){
    if(regNumber.length > 0){
        const words = userName.split(" ");
        return `${words[1].charAt(0)}${words[0].charAt(0)}`;
    }
    return "U";
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-prevent-tabpanel-${index}`}
        aria-labelledby={`scrollable-prevent-tab-${index}`}
        {...other}
      >
        {value === index && <div style={{paddingTop: 15, minHeight: 200}}>{children}</div>}
      </Typography>
    );
}

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    labelL: PropTypes.string,
    value: PropTypes.number.isRequired,
    labelR: PropTypes.string,

};

function LinearProgressWithLabel(props) {
    return (
      <Box display="flex" alignItems="center" mb={1}>
        <Box minWidth={55}>
          <Typography variant="body2" color="textSecondary">{props.labelL}</Typography>
        </Box>

        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" value={props.value}/>
        </Box>
        
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">{props.labelR}</Typography>
        </Box>

      </Box>
    );
}

class CatalogItemLg extends React.Component {  
    constructor(props) {
        super(props);                
        this.state = {
            // eslint-disable-next-line no-undef
            cdn: `${process.env.REACT_APP_CDN}Pictures/`,
            imgLgSize: "392x392",
            imgMdSize: "183x183",
            qty: 1,
            user: undefined,
            expanded: false,            
            selChildId: undefined,
            child: undefined,            
            tabVal: 0,
            tabReviewTab: 0,
            code: undefined,            
            showAddRating: false,            
            
            reviews: undefined, 
            page: 1,            
            totalCount: 0,
            pageSize: 5,

            ratingVal: 0,
            advantage: "",
            limitations: "",
            reviewText: "",
            reviewUserName: "",
            reviewUserEmail: "",
            rating: {
                average: 1.8,
                from: 5,
                total: 75.24,
                star5: 69.21,
                star4: 39.81,
                star3: 42.81,
                star2: 66.88,
                star1: 1.62,
            }
        };
        // eslint-disable-next-line no-undef
        this.cdn = `${process.env.REACT_APP_CDN}Pictures/`;
        this.imgXsSize = "76x76";
        this.myRef = React.createRef();
        this.authService = new AuthService();
        moment.locale("uk");
    }

    loadUser = async () => {        
        if(this.authService){      
            if(this.authService.isAuthenticated()){
              this.authService.getUser()
                .then( user => {          
                  if(user != null && !user.expired){                    
                    this.setState({ user: user, reviewUserEmail: user.profile.email, reviewUserName: user.profile.name  });  
                  }
                  else{
                    this.setState({ user: null });
                  }
                });
              }
            } else {
              this.setState({ user: null });
            }


        // userManager.getUser().then(user => {
        //     this.setState({user: user});
        //     console.log("USER", user);
        // });
    };


    loadReviews = (code, page, pageSize) => {
        const URL = `/api/v1.0/review/${code}/${page}/${pageSize}`;         
        fetch(URL)
            .then(response => response.json())
                .then(data => {                    
                    this.setState({ reviews: data });
                })
            .catch(error => console.log(error));
    }

    componentDidMount() {     
        const {page, pageSize } = this.state;  
        const { item } = this.props;
        this.loadUser();        
        this.loadReviews(item.GroupProductId !== EGUID ? item.GroupProductCode : item.Code, page, pageSize);
    }

    // static getDerivedStateFromProps(props, state) {
    //     const { item } = props;
        
    //     console.log("getDerivedStateFromProps", item);
        
    //     return item;
    // }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState)  {
        const {page, pageSize } = this.state;  
        const { item } = this.props;

        if(item.Code !== prevProps.item.Code){
            this.loadReviews(item.GroupProductId !== EGUID ? item.GroupProductCode : item.Code, page, pageSize);
        }
    }

    handleSetTabVal = (event, value) => {
        this.setState({ tabVal: value});        
    };

    handleSetTabReviewVal = (event, value) => {
        this.setState({ tabReviewTab: value});        
    };

    handleShowRating = () => {
        const { showAddRating } = this.state;
        this.setState({showAddRating : !showAddRating});
        
        this.myRef.current.scrollIntoView();
        
        // const timeout = setTimeout(() => {
        //     // console.log(document.body.scrollHeight);
            
        //     // const scrollToMyRef = () => window.scrollTo(0, this.reviewTxtRef.current.offsetTop);
        //     // window["scrollTo"]({ top: document.body.scrollHeight, behavior: "smooth" });
        //     // window["scrollTo"]({ top: (document.body.scrollHeight), behavior: "smooth" });
        //     // this.reviewTxtRef.current.focus();
        //   }, 100);
      
        //   return () => {
        //     clearTimeout(timeout);
        //   };
    };

    handleCancelRaview = () => {
        this.setState({showAddRating : false});
    };

    validateReview = () => {
        const { reviewText, reviewUserName, reviewUserEmail} = this.state;
        let errorReviewText = false;
        let errorTxtReviewText = "";

        let errorReviewUserName = false;
        let errorTxtReviewUserName = "";

        let errorReviewUserEmail = false; 
        let errorTxtReviewUserEmail = "";
        let valid = true;
        
        if(reviewText.length === 0){
            valid = false;        
            errorReviewText = true;
            errorTxtReviewText = "Fill review";
        }

        if(reviewUserName.length === 0 ){
            valid = false;        
            errorReviewUserName = true;
            errorTxtReviewUserName = "Fill user name";
        }

        if(reviewUserEmail.length === 0 ){
            valid = false;        
            errorReviewUserEmail = true; 
            errorTxtReviewUserEmail = "Fill email";
        }else{
            const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(reviewUserEmail)) {
                valid = false;        
                errorReviewUserEmail = "Not valid pattern email address"; 
                errorTxtReviewUserEmail = "Fill valid email address";
            }
        }

        this.setState({
            errorReviewText: errorReviewText, 
            errorTxtReviewText: errorTxtReviewText,
            errorReviewUserName: errorReviewUserName,
            errorTxtReviewUserName: errorTxtReviewUserName,
            errorReviewUserEmail: errorReviewUserEmail,
            errorTxtReviewUserEmail: errorTxtReviewUserEmail
        });

        return valid;
    };

    handleSaveRaview = async () => {
        if(!this.validateReview()){
            return;
        }
        const {item} = this.props;
        const { ratingVal, advantage, limitations, user, 
                reviewText, reviewUserName, reviewUserEmail} = this.state;
        
        // const child = this.getChild( code ? code:item.Code);
        const article = item.GroupProductId !== EGUID ? item.GroupProductCode : item.Code; // child ? child.Code : item.Code;
        
        const productName = item.GroupProductId !== EGUID ? item.LocalName : item.GroupProductName; // child ? child.LocalName : item.LocalName;
        const review = new Review( 0, ratingVal, advantage, limitations, reviewText, reviewUserName, reviewUserEmail, "", 
                                   article, productName, true, 0, 0 );
        
        console.log(JSON.stringify(review));
        const url = "/api/v1.0/Review";
        
        const authorization = user ? `${user.token_type} ${user.access_token}`: ""; 
        // headers: {"Authorization": `${user.token_type} ${user.access_token}` }

        await fetch(url, {
            method: ("POST"),
            headers: {
              "Accept": "application/json",
              "Content-Type": "application/json;charset=UTF-8",
              "Authorization": authorization
            },
            body: JSON.stringify(review)
          })
            .then((response) => response.json())
            .then((data) => {
                this.setState({showAddRating : false});
                console.log(data);
            })
            .catch((error) => {
              console.log("Error", error);
            });        
        
    };

    handleSetRatingVal = (event, value) => {
        this.setState({ ratingVal: value});      
    };

    // eslint-disable-next-line no-unused-vars
    handleChangePage = (event, newPage) => {
        console.log("newPage" ,newPage);

        const { item } = this.props;
        const { pageSize } = this.state;
        let page = newPage + 1;

        this.setState({page: page });

        this.loadReviews(item.GroupProductId !== EGUID ? item.GroupProductCode : item.Code, page, pageSize);
        
        // throw new Error("handleChangePage: Not implementation");
    };

    handleChangeRowsPerPage = (event) => {        
        const { item } = this.props;
        let page = 1;
        let pageSize = event.target.value;        
        this.setState({page: page, pageSize: pageSize});
        this.loadReviews(item.GroupProductId !== EGUID ? item.GroupProductCode : item.Code, page, pageSize);
    };

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleQtyChange = (event) => {
        if(event.target.name === "qty"){          
          this.setState({ [event.target.name]:  parseInt(event.target.value) === 0 ? 1 :  event.target.value });
        }      
    }
    
    AvailableCSS = (value) =>{
        const { classes} = this.props;
        if(value){
            return "";
        }
        else{
           return classes.grayscale;
        }
    }

    handleAddToOrder = (e, pos) => {

        if(pos.FreeRest <= 0){
            window.location.href = "https://direct.smartsender.com/redirect?continue=viber%3A%2F%2Fpa%3Fcontext%3DZGw6MjQ4OTg%26chatURI%3Djerelia_bot";
            return;
        }

        const {disabled} = this.props;
        const { item } = this.props;
        const { qty, code } = this.state;
        const child = this.getChild( code ? code:item.Code);
        
        if(disabled === true)
            return;

        if (item.Children.length > 0 && child){
            console.log("Add child to order:", child);
            // this.setState({ ProductId: child.ProductId });         
            this.props.actions.addToOrder([ { pos: child, qty: qty, orderrowtype: 0}] )
        } else {
            console.log("Add master to order:", pos);
            // this.setState({ProductId: pos.ProductId});         
            this.props.actions.addToOrder([ { pos: pos, qty: qty, orderrowtype: 0}] )
        }
    }

    handleChangeChild = (event) => {
        const { item } = this.props;
        let child;
        if(item.Children.length > 0){
            child = item.Children.find( (child) => {
                if(child.ProductId === event.target.value)
                    return child;
                return null;
                });            
            this.setState({ code: child ? child.Code : undefined });
        }
    };

    getChild = (code) => {
        const { item } = this.props;
        let child = undefined;
        
        if(item.Children.length > 0){
            child = item.Children.find( (el ) => {
                if(el.Code === code)
                    return el;
                return null;
                });                        
        }        
        return child;
    };

    SpecialPriceLabel = (value) => {
        const { classes } = this.props;        
        switch (value) {
            case 0:
                return  <div className={classes.specialprice} title="Wow ціна" style={{backgroundColor: pink[500] }} aria-label="recipe"><NewReleasesIcon /></div>  // "Wow ціна";
            case 1:
                return <div className={classes.specialprice}  title="Акція" style={{backgroundColor: blue[500] }} aria-label="recipe"><BrightnessAutoIcon /></div>  //"Акція";    
            default:
                return null;    
        }
    }
    
    handleLinkClick =(event, url) => {
        event.preventDefault();
        this.props.history.push(url);
        // console.info("You clicked a breadcrumb.");
      }

    render(){
        const { item, classes, width, IsAuthenticated, disabled, t } = this.props;
        const { code, page, pageSize, reviews, user, tabVal,tabReviewTab, showAddRating, 
                ratingVal, advantage, limitations, reviewText, reviewUserName, reviewUserEmail, 
                errorReviewText, errorTxtReviewText, errorReviewUserName, errorTxtReviewUserName, 
                errorReviewUserEmail, errorTxtReviewUserEmail} = this.state;
        
        const child = this.getChild( code ? code:item.Code);
        
        return(
            <>
                <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
                    <Link color="inherit" href="/" onClick={this.handleLinkClick} className={classes.link}>
                        <HomeOutlinedIcon className={classes.icon} />                    
                    </Link>
                    <Link
                        color="inherit"
                        href={`/catalog/${ child ? child.Series : item.Series}`}
                        onClick={e => this.handleLinkClick(e,`/catalog/${ child ? child.Series : item.Series}`)}
                        className={classes.link}
                    >
                        { child ? child.Series : item.Series}
                    </Link>
                    
                    {/* <Typography color="textPrimary" className={classes.link}>
                        <span >{child ? child.Code : item.Code}</span>                        
                    </Typography> */}
                </Breadcrumbs>
                <Paper className={classes.card} elevation={0} >
                    <Box className={classes.cardcontent}>
                        {/* <Grid container direction="row" justify="flex-end" alignItems="flex-start"> 
                            <Grid item xs={12} sm={6} md={7}>
                                <Typography  className={classes.header} component="h1" variant="h4">
                                    {child ? child.LocalName : item.LocalName}
                                </Typography>
                            </Grid>
                        </Grid> */}
                        
                        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2} > 
                            {/* Left column */}
                            <Grid item xs={12} sm={6} md={5}>                                
                                {
                                    width === "xs" && 
                                    <Typography  className={classes.header} component="h1" variant="h4" align="center">
                                        {child ? child.LocalName : item.LocalName}
                                    </Typography>
                                }
                                { IsAuthenticated && 
                                    <span className={classes.priceBB}>{( child ? (child.CatalogPricePv > 0.01 ? <span>{parseFloat(child.CatalogPricePv).toFixed(1)} <small>ББ</small></span>: null) : (item.CatalogPricePv > 0.01 ? <span>{parseFloat(item.CatalogPricePv).toFixed(1)} <small>ББ</small></span>: null)   )}</span>
                                }

                                {
                                    child ?
                                        (child.Comment.length > 0 && <span className={classes.specialprice} aria-label="recipe">{child.Comment}</span>):
                                        (item.Comment.length > 0 && <span className={classes.specialprice} aria-label="recipe">{item.Comment}</span>)
                                }

                                { 
                                    child ? 
                                        (child.Tag.length > 0 &&
                                        <Typography className={classes.specialtag}  align="center"  component="span" >                                         
                                            <Chip size="small" color="secondary"
                                                label={<Typography variant="body2">{child.Tag}</Typography> } 
                                            />
                                        </Typography>) 
                                    :
                                    (item.Tag.length > 0 &&
                                    <Typography className={classes.specialtag} component="span" >                         
                                        <Chip size="small" color="secondary"
                                            label={<Typography variant="body2">{item.Tag}</Typography> } 
                                        />
                                    </Typography>) 
                                }

                                {/* {this.SpecialPriceLabel(item.SpecialPriceType)} */}
                                                                
                                <div className={classes.images}>                            
                                    <ProductSlider available={ child ? child.Available : item.Available}  items={ child ? child.Photos : item.Photos}></ProductSlider>                                
                                </div>

                                {width === "xs" && child && item.Children.length > 0 &&
                                <Grid container direction="row" justify="center" alignItems="center" >
                                    <Grid item xs={12} sm={10} md={8} style={{height: "64px"}}>                                                                        
                                        <TextField 
                                            select
                                            size="small"
                                            fullWidth
                                            margin="dense"
                                            value={child.ProductId}
                                            variant="outlined"
                                            onChange={this.handleChangeChild}
                                            InputProps={{
                                                classes: { notchedOutline: classes.select },
                                            }}
                                            >
                                            {item.Children.map((option) => (
                                            <MenuItem key={option.Code} value={option.ProductId}>
                                                <ListItemIcon>                                
                                                    <Brightness1Icon fontSize="small" style={{color: (option.Color.length> 0 ? `#${option.Color}`: "#fff")}} />
                                                </ListItemIcon>
                                                {option.CharName}
                                            </MenuItem>
                                            ))}
                                        </TextField>
                                    
                                    </Grid>
                                </Grid>
                                }
                                
                                
                            </Grid>
                            
                            {/* Right column */}
                            <Grid item xs={12} sm={6} md={7}>
                                {
                                    width !== "xs" &&                                
                                    <Typography  className={classes.header} component="h1" variant="h4">
                                        {child ? child.LocalName : item.LocalName}
                                    </Typography>
                                }
                                
                                {/* Price row */}
                                <Grid className={classes.priceblock} container direction="row" justify={width === "xs" ? "center": "flex-start" } alignItems="center" >
                                    <Grid item >                                         
                                    { IsAuthenticated ?
                                    <>
                                        <Typography component="div" display="block">                                            
                                            <Typography  color="primary" variant="h5" component="span" style={{marginRight: 8}}>
                                                {parseFloat(child ? child.SellingPrice : item.SellingPrice).toFixed(2)} <small style={{fontSize: ".6em"}}>₴</small>
                                            </Typography>
                                            
                                            <Typography className={classes.priceOld} variant="h6" component="span">                                                    
                                                    <span className="price--line-through">
                                                      {item.OldPrice === 0 ? parseFloat(item.Price).toFixed(2): parseFloat(item.OldPrice).toFixed(2)}
                                                    </span> 
                                                    <small style={{fontSize: ".6em"}}>₴</small>                                                       
                                                {/* {item.CatalogPricePv > 0.01 ? <small style={{fontSize: ".9rem"}} className={classes.colorBlue}>({parseFloat(item.CatalogPricePv).toFixed(1)})</small> : null} */}
                                            </Typography> 
                                            <Typography component="p" variant="caption" color="textSecondary">
                                                {t("product.ProductPriceComment")}
                                            </Typography>      
                                        </Typography>
                                    </>
                                    :        
                                    <Typography component="div" display="block"  >                                        
                                            <Typography color="primary" component="span" style={{fontSize: "1.2em", marginRight: 8}}>
                                                {parseFloat(child ? child.Price : item.Price).toFixed(2)} <small style={{fontSize: ".7em"}}>₴</small>
                                            </Typography>                                        
                                        { item.OldPrice > 0 &&                                        
                                            <Typography component="span" className="price--line-through text-gray"  >
                                                {parseFloat(child ? child.OldPrice : item.OldPrice).toFixed(2)} <small style={{fontSize: ".7em"}}>₴</small>
                                            </Typography>
                                        }
                                    </Typography>
                                    }                                    
                                    </Grid>

                                    
                                </Grid>
                                
                                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={4} >
                                    <Grid item xs={12} sm={6} md={4}  >
                                    {width !== "xs" && child && item.Children.length > 0 &&
                                        <TextField 
                                            select
                                            size="small"
                                            fullWidth
                                            margin="dense"
                                            value={child.ProductId}
                                            variant="outlined"
                                            onChange={this.handleChangeChild}
                                            InputProps={{
                                                classes: { notchedOutline: classes.select },
                                            }}
                                            >
                                            {item.Children.map((option) => (
                                            <MenuItem key={option.Code} value={option.ProductId}>
                                                { option.Color.length > 0 &&
                                                    <ListItemIcon>
                                                    {/* <img width={24} height={24} 
                                                    src = {`${this.cdn}properties/${option.MiniFile}`}
                                                    /> */}                                                    
                                                        <Brightness1Icon fontSize="small" style={{color: (option.Color.length > 0 ? `#${option.Color}`: "#fff")}} />                                                                                                            
                                                    </ListItemIcon>
                                                }
                                                { option.CharName.length > 0 ? option.CharName : <span>&nbsp;</span>}
                                            </MenuItem>
                                            ))}
                                        </TextField>
                                    }
                                    </Grid>
                                </Grid>                                                                
                                <Grid container direction="row" justify={width === "xs" ? "center": "flex-start"} spacing={4} alignItems="center" >
                                    {/* <Grid item xs={6} sm={6} md={3} >
                                        <TextField 
                                            name="qty"
                                            className={classes.textField}
                                            label={t("product.AddToOrderQty")}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">шт</InputAdornment>,                                                
                                            }}
                                            fullWidth     
                                            margin="normal"
                                            variant="outlined"
                                            type="number"
                                            min="1"
                                            value={this.state.qty}
                                            onChange={e => this.handleQtyChange(e)}
                                        />
                                    </Grid> */}
                                    { width !== "xs" ? 
                                    <Grid item sm={6} md={5}  >
                                        <Chip  style={{width: "100%"}} 
                                            icon={item.FreeRest <= 0 ? <AccessTime /> : 
                                            (disabled ? <CircularProgress  className={classes.circularProgress} /> :  <ShoppingBasketOutlinedIcon />)}
                                            // label={ t("buttons.AddToCart") }
                                            label= {item.FreeRest <= 0 ? t("buttons.NotifyArrival") : t("buttons.Buy")}
                                            clickable
                                            // disabled={item.FreeRest <= 0 }
                                            color={item.FreeRest <= 0 ? "default": "primary"}
                                            onClick={(e) => this.handleAddToOrder(e, item)}
                                        />
                                    </Grid> : null }

                                    <Grid  item xs={12} sm={7}>
                                        <Grid container justify={width === "xs" ? "center": "flex-start"} spacing={2} >
                                            <Grid item >
                                                <Rating readOnly name="half-rating" value={getRating(item.ProductEvaluation.AvgRating)} precision={0.5} />                                                                                                
                                            </Grid>
                                            <Grid item>
                                                <Typography>                                                    
                                                    {t("product.ProductReview", {count: item.ProductEvaluation.Qty})}                                                
                                                </Typography>                         
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <table className={classes.tableAttr}>
                                            <tbody>
                                                <tr className={classes.attr}>
                                                    <td>{t("product.Article")}:</td>
                                                    <td className={classes.tdAttr}>{ child ? child.Code : item.Code }</td>
                                                </tr>
                                                <tr className={classes.attr}>
                                                    <td>{t("product.VolumeSubstances")}:</td>
                                                    <td className={classes.tdAttr}>{ child ? child.VolumeSubstances : item.VolumeSubstances}<small>{ child ? child.Measure : item.Measure}</small> </td>
                                                </tr>
                                                <tr className={classes.attr}>
                                                    <td>{t("product.Seria")}:</td>
                                                    <td className={classes.tdAttr}>{ child ? child.Series : item.Series}</td>
                                                </tr>                                                
                                            </tbody>
                                        </table>                                        
                                    </Grid>                                    
                                    
                                    {
                                    item.BestReview && 
                                        <Grid item xs={12}>
                                        <blockquote className={classes.blockquote}>
                                            <Typography gutterBottom>
                                                {item.BestReview.Text}
                                            </Typography>
                                            <Typography align="right" style={{textTransform: "capitalize"}} variant="caption" display="block" gutterBottom>
                                                { getUserName(item.BestReview.RegNumber, item.BestReview.UserName) }
                                            </Typography>
                                        </blockquote>
                                    </Grid>
                                    }

                                </Grid>                                                                
                            </Grid>                        
                        </Grid>

                        
                        
                        <Tabs className={classes.tabprod} value={tabVal}  onChange={this.handleSetTabVal}
                            indicatorColor="primary"
                            textColor="primary"
                        >
                            <Tab label={t("product.About")} />
                            <Tab label={t("product.HowUse")} />
                            <Tab label={t("product.Ingredients")} />
                            {/* <Tab style={{width: "48px", minWidth: "48px"}} icon={<ShareIcon />}  /> */}
                            
                        </Tabs>

                        <TabPanel value={tabVal} index={0}>
                            <div className={classes.SubstanceComposition}>
                                <div dangerouslySetInnerHTML={{__html: (getCleanHtml( child ? child.FullDescription : item.FullDescription) ) }}></div>
                            </div>
                        </TabPanel>
                        <TabPanel value={tabVal} index={1}>
                            <Grid container direction="row" spacing={2}>
                                <Grid item xs={12}>
                                    <Typography component="div" className={classes.SubstanceComposition}>
                                        <div dangerouslySetInnerHTML={{__html: (getCleanHtml( child ? child.UseSubstance : item.UseSubstance) ) }}></div>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value={tabVal} index={2}>
                            <div className={classes.SubstanceComposition}>
                                <div dangerouslySetInnerHTML={{__html: (getCleanHtml( child ? child.SubstanceComposition : item.SubstanceComposition) ) }}></div>
                            </div>
                        </TabPanel>
                        <TabPanel value={tabVal} index={3}>
                            Social 
                        </TabPanel>
                        
                        { item.ExternalLinks && item.ExternalLinks.length > 0 && 
                        <Grid container direction="row" justify="center" alignItems="stretch" style={{ marginBottom: 24}} > 
                            {item.ExternalLinks.map((external) => (
                                <Grid key={external.LineNumber} item xs={12} sm={6} >
                                <Youtube youtubeId={external.LinkToProduct} />
                            </Grid>            
                            ))}

                            {/* {item.ExternalLinks.map((link) => (
                                <Grid item xs={12} sm={6} >
                                    <Youtube youtubeId={item.YoutubeId} />
                                </Grid>
                            ))} */}
                        </Grid>
                        }

                        <Grid container direction="row" justify="flex-start" >
                            <Grid item xs={12} sm={6} >
                                <Grid container direction="row" justify="flex-start" >
                                    <Grid item>
                                        <Tabs value={tabReviewTab}  onChange={this.handleSetTabReviewVal} indicatorColor="primary" textColor="primary" >
                                            {/* <Tab className={classes.tabReview} label={ `${t("product.Reviews")} (${reviews})`} />
                                            <Tab className={classes.tabReview} label={ `${t("product.Questions")} (${reviews})`} /> */}
                                            <Tab className={classes.tabReview} label={t("product.Reviews")} />
                                            {/* <Tab disabled className={classes.tabReview} label={t("product.Questions")} /> */}
                                        </Tabs>
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="row" justify="flex-start" alignItems="center">
                                            <Grid item >
                                                <Typography variant="h4" className={classes.averageReview}>
                                                    { parseFloat(item.ProductEvaluation.AvgRating).toFixed(1) }                                                    
                                                </Typography>
                                            </Grid>
                                            <Grid item >
                                                <Rating style={{fontSize: "11px"}} readOnly name="half-rating" size="small" value={getRating(item.ProductEvaluation.AvgRating)} precision={0.5} />
                                                <Typography style={{fontSize: "11px"}}>
                                                    {t("product.ProductReview", {count: item.ProductEvaluation.Qty})}
                                                </Typography>
                                            </Grid>                                                                                
                                        </Grid>
                                    </Grid>
                                </Grid>
                                
                            </Grid>
                            <Grid item xs={12} sm={6} >
                                <Typography className={classes.btnsreview} component="div" align="right">        
                                    <Chip className={classes.chipBtn}                                        
                                        label={t("product.WriteReview")}
                                        clickable
                                        color="primary"
                                        variant="outlined"
                                        onClick={this.handleShowRating}
                                    />
                                    {/* <Chip className={classes.chipBtn}                                        
                                        label={t("product.AskUs")}
                                        clickable
                                        disabled                                        
                                        variant="outlined"
                                        onClick={this.handleShowRating}
                                    /> */}

                                    {/* <Button 
                                        startIcon={<BorderColorOutlinedIcon />} 
                                        variant="outlined" 
                                        color="primary" 
                                        size="large" 
                                        fullWidth={width === "xs"} 
                                        onClick={this.handleShowRating}
                                    >
                                        {t("product.WriteReview")}
                                    </Button> */}
                                </Typography>
                            </Grid>                            
                        </Grid>
                        
                        <TabPanel value={tabReviewTab} index={0}>
                            {reviews && 
                            <div>
                                {reviews.Data.map((review) => (
                                    <div style={{marginBottom: "16px"}} key={review.Id}>
                                        <Grid container direction="row">
                                            <Grid item xs={9} sm={10}>

                                                <Grid container direction="row">
                                                    <Grid item>
                                                        <Avatar>{getUserAvatar(review.RegNumber, review.UserName)}</Avatar>
                                                    </Grid>
                                                    <Grid item>
                                                        <div className={classes.reviewAuth}>
                                                            <Typography variant="caption" component="div"  color="textSecondary">                                                        
                                                                    {getUserName(review.RegNumber, review.UserName)}
                                                            </Typography>
                                                            <Rating style={{fontSize: "14px"}} readOnly name="half-rating" size="small" value={review.Rating} precision={0.5} />
                                                        </div>
                                                    </Grid>                                            
                                                </Grid>                                                                                                                            
                                            </Grid>
                                            <Grid item xs={3} sm={2}>
                                                <Typography className={classes.reviewDate} color="textSecondary" gutterBottom>
                                                    { moment(review.CreatedOn).format("DD-MM-YY") }
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        
                                        <div style={{paddingTop: "16px"}} >
                                        {
                                            review.Advantage.length > 0 &&
                                            <Typography variant="body2" color="textSecondary" gutterBottom>
                                                <b>{t("reviews.Advantage")}</b>: {review.Advantage}
                                            </Typography>
                                        }

                                        {
                                            review.Limitations.length > 0 &&
                                            <Typography variant="body2" color="textSecondary" gutterBottom>
                                                <b>{t("reviews.Limitations")}</b>: {review.Limitations}
                                            </Typography>
                                        }

                                            <Typography variant="body2" color="textSecondary" gutterBottom>
                                                {/* <b>{t("reviews.Comment")}</b>:  */}
                                                {review.Text}
                                            </Typography>
                                        </div>
                                    </div>
                                ))}

                                <TablePagination
                                    component="div"
                                    rowsPerPageOptions={[5, 10, 25]}
                                    labelRowsPerPage={""}
                                    count={reviews.TotalCount}
                                    page={page-1}
                                    onChangePage={this.handleChangePage}
                                    rowsPerPage={pageSize}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    />

                            </div>}

                            {/* <Grid container direction="row" justify="center" alignItems="stretch" spacing={4}>
                                <Grid item xs={12} sm={3}>
                                    <Typography variant="h6" display="block" align="center">
                                        Average rating
                                    </Typography>
                                    <Typography variant="h2" display="block" align="center" color="error">
                                        {rating.average} / {rating.from}
                                    </Typography>
                                    
                                    <Typography component="div" align="center">
                                        <Rating name="half-rating" defaultValue={rating.average} precision={0.5} />
                                    </Typography>

                                    <Typography variant="body2" display="block" align="center"  gutterBottom>
                                        ( {rating.total}k reviews  )
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={3}>                                        
                                    <LinearProgressWithLabel value={rating.star5} labelL="5 Star" labelR={`${Math.round(rating.star5,)}k`} />
                                    <LinearProgressWithLabel value={rating.star4} labelL="4 Star" labelR={`${Math.round(rating.star4,)}k`}/>
                                    <LinearProgressWithLabel value={rating.star3} labelL="3 Star" labelR={`${Math.round(rating.star3,)}k`}/>
                                    <LinearProgressWithLabel value={rating.star2} labelL="2 Star" labelR={`${Math.round(rating.star2,)}k`}/>
                                    <LinearProgressWithLabel value={rating.star1} labelL="1 Star" labelR={`${Math.round(rating.star1,)}k`}/>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Box pt={width === "xs" ? 0 : 6} alignContent="center">                                        
                                    <Button 
                                        startIcon={<BorderColorOutlinedIcon />} 
                                        variant="outlined" 
                                        color="primary" 
                                        size="large" 
                                        fullWidth={width === "xs"} 
                                        onClick={this.handleShowRating}
                                    >
                                        Write your review
                                    </Button> 
                                    </Box>                                                                                                                                                                   
                                </Grid>
                            </Grid> */}
                            <Collapse in={showAddRating} timeout="auto" >
                                <Grid container direction="row">
                                    <Grid item xs={12}>
                                        <Box mb={2} className={classes.reviewbox}>
                                            <Typography variant="h6" display="block">
                                                {t("reviews.AddReview")}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" gutterBottom>{t("reviews.YourReviewAboutProduct")}: {child ? child.LocalName : item.LocalName}</Typography>
                                            <Box display="flex" alignItems="center" mb={1}>                                                
                                                <Box width="100%" mr={1}>
                                                    <Rating 
                                                        precision={1}
                                                        name="rating-controlled"
                                                        value={ratingVal}
                                                        onChange={this.handleSetRatingVal}
                                                    />
                                                </Box>                                            
                                            </Box>
                                            
                                            <Grid container direction="row" spacing={2}>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        ref={this.myRef}
                                                        // inputRef={(input) => {
                                                        //     if(input != null) {
                                                        //        input.focus();
                                                        //     }
                                                        //   }}                                                  
                                                        fullWidth
                                                        id="advantage"
                                                        name="advantage"
                                                        label={t("reviews.Advantage")}
                                                        value={advantage}
                                                        multiline
                                                        rows={1}
                                                        variant="outlined"
                                                        onChange={this.handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField                                                        
                                                        fullWidth
                                                        id="limitations"
                                                        name="limitations"
                                                        label={t("reviews.Limitations")}
                                                        value={limitations}
                                                        multiline
                                                        rows={1}
                                                        variant="outlined"
                                                        onChange={this.handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField                                                        
                                                        id="reviewText"
                                                        name="reviewText"                                                        
                                                        required
                                                        fullWidth
                                                        label={t("reviews.Comment")}
                                                        value={reviewText}
                                                        multiline
                                                        rows={4}
                                                        error={errorReviewText}
                                                        helperText={errorTxtReviewText}
                                                        variant="outlined"
                                                        onChange={this.handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        required
                                                        disabled={user ? true: false}
                                                        fullWidth
                                                        id="reviewUserName"
                                                        name="reviewUserName"
                                                        label="User name"
                                                        value={reviewUserName}
                                                        error={errorReviewUserName}
                                                        helperText={errorTxtReviewUserName}
                                                        variant="outlined"
                                                        onChange={this.handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        required
                                                        disabled={user ? true : false}
                                                        type="email"                                                        
                                                        fullWidth
                                                        id="reviewUserEmail"
                                                        name="reviewUserEmail"                                                        
                                                        label="User email"
                                                        value={reviewUserEmail}
                                                        error={errorReviewUserEmail}
                                                        helperText={errorTxtReviewUserEmail}
                                                        variant="outlined"
                                                        onChange={this.handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography component="div" align="right">
                                                        <Button variant="outlined" className={classes.buttons} onClick={this.handleCancelRaview} >{t("buttons.Cancel")}</Button>
                                                        <Button variant="contained" color="primary" onClick={this.handleSaveRaview} >{t("buttons.PostReviews")}</Button>
                                                    </Typography>
                                                </Grid>                                                
                                            </Grid>

                                            <Grid  container direction="row" justify="flex-end" alignItems="center" >
                                                <Grid item >
                                                    
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Divider className={classes.divider} />
                            </Collapse>
                        </TabPanel>                    
                        <TabPanel value={tabReviewTab} index={1}>
                        </TabPanel>
                </Box>
            </Paper>            
            
                {
                width === "xs" ?               
                <AppBar position="fixed" color="default" className={classes.appBarBtn}>
                    <Toolbar className={classes.toolbarBtn}>                    

                        {/* <Chip className={classes.chipBuyBtn}  style={{width: "100%"}}
                                            icon={item.FreeRest <= 0 ? <AccessTime /> : 
                                            (disabled ? <CircularProgress  className={classes.circularProgress} /> :  <ShoppingBasketOutlinedIcon />)}
                                            label="Додати до кошика"
                                            clickable
                                            disabled={item.FreeRest <= 0 }
                                            color="primary"
                                            onClick={(e) => this.handleAddToOrder(e, item)}
                                        /> */}
                        <Button color={ item.FreeRest <= 0 ? "default" : "primary"} fullWidth size="large"
                            // disabled={item.FreeRest <= 0 }
                            onClick={(e) => this.handleAddToOrder(e, item)}
                            variant="contained"                            
                            startIcon={item.FreeRest <= 0 ? <AccessTime /> : (disabled ? <CircularProgress  className={classes.circularProgress} /> :  <ShoppingBasketOutlinedIcon />)}
                        >
                            { item.FreeRest <= 0 ? t("buttons.NotifyArrival") : t("buttons.Buy")} 
                        </Button>
                    </Toolbar>
                </AppBar>
                : null
                }

            </>
        );
    }
}

export default withTranslation()(withWidth()(withRouter( withStyles(styles)(
    connect(    
        state => state.orderRequest,
        dispatch => {
            return {
               actions: bindActionCreators(Object.assign({}, actionOrderCreators), dispatch)
        }}
    )(CatalogItemLg)
))));
