import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
// import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import uuidv4 from "uuid/v4";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import Edit from "@material-ui/icons/Edit";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import ArrowBack from "@material-ui/icons/ArrowBack";
import ArrowForward from "@material-ui/icons/ArrowForward";
import SaveOutlined from "@material-ui/icons/SaveOutlined";
import Add from "@material-ui/icons/Add";
import Clear from "@material-ui/icons/Clear";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../store/MenuBuilder";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { Icon } from "@material-ui/core";
import SortableTree, { toggleExpandedForAll, removeNodeAtPath, changeNodeAtPath, getNodeAtPath, addNodeUnderParent } from "react-sortable-tree";

import "react-sortable-tree/style.css";

const styles = theme => ({
    root: {
        flexGrow: 1,        
        margin: theme.spacing(1),
    },
    input: {
        marginRight: theme.spacing(2), 
      },
    iconButton: {
        padding: 10,
      },
    menuname: {
        paddingRight: theme.spacing(2)
    },
    treeWrapper: {
        height: 800,
    },
});

const maxDepth = 3;
const newItemMenu = "New menu item";

class MenuBuilder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            file: this.props.match.params.file || "new",
            searchString: "",
            searchFocusIndex: 0,
            searchFoundCount: null,
            openDlgNode: false,
            openDlgDelNode: false,
            fullWidth: true,
            maxWidth: "md",
            temptxt: "",
            node: {},
            path: [],
            treeIndex: -1
        };
        this.props.requestMenu(this.state.file); 
    }
            
    // This method runs when incoming props (e.g., route params) change
    // UNSAFE_componentWillReceiveProps(nextProps) {        
    //     this.setState(
    //         {filename: nextProps.match.params.file || "new"}
    //     );
    //     this.props.requestMenu(nextProps.match.params.file); 
    // };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.file !== this.state.file) {
            this.props.requestMenu(this.state.file); 
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.match.params.file !== prevState.file){
          return { file: nextProps.match.params.file};
       }
       else return null;
    }
   
    handleOpenDlgNode = opened => {     
        this.setState({openDlgNode: opened});
    };

    handleOpenDlgDelNode = opened => {     
        this.setState({openDlgDelNode: opened});
    };

    handleSearchOnChange = e => {
        this.setState({ searchString: e.target.value });
    };
    
    selectPrevMatch = () => {
        const { searchFocusIndex, searchFoundCount } = this.state;    
        this.setState({
          searchFocusIndex:
            searchFocusIndex !== null
              ? (searchFoundCount + searchFocusIndex - 1) % searchFoundCount
              : searchFoundCount - 1,
        });
    };
    
    selectNextMatch = () => {
        const { searchFocusIndex, searchFoundCount } = this.state;    
        this.setState({
          searchFocusIndex:
            searchFocusIndex !== null
              ? (searchFocusIndex + 1) % searchFoundCount
              : 0
        });
    };
    
    toggleNodeExpansion = expanded => {
        this.props.changeMenu(
            toggleExpandedForAll({
                treeData: this.props.treeData,
                expanded
            }));
    };

    handleDelItem = (rowInfo) => {
        // let {node, treeIndex, path} = rowInfo;
        let {path} = rowInfo;
        let treeData = removeNodeAtPath({
            treeData: this.props.treeData,
            path: path,
            getNodeKey: ({treeIndex: number}) => {                  
                return number;
            },
            ignoreCollapsed: true,
        });

        this.props.changeMenu(treeData);
    };

    handleSaveItem = () => {        
        this.props.changeMenu(
            changeNodeAtPath({
                treeData: this.props.treeData,
                newNode: this.state.node,                
                path: this.state.path,
                ignoreCollapsed: true,
                getNodeKey: ({ treeIndex }) =>  treeIndex                
            })
        );
        this.setState({openDlgNode: false});
    };

    addNode(rowInfo){
        let {treeIndex, path} = rowInfo;
        const NEW_NODE = {
            children: null,
            classname: "",
            href: "/",
            icon: "",
            id: uuidv4(),
            isauthenticated: false,
            visitor: 0,
            open: false,
            roles: "",
            subtitle: "",
            target: "",
            title: newItemMenu};

        path.pop();

        let parentNode = getNodeAtPath({
            treeData: this.props.treeData,
            path : path,
            getNodeKey: ({ treeIndex }) =>  treeIndex,
            ignoreCollapsed : true
        });

        let getNodeKey = ({ node: object, treeIndex: number }) => {
            return number;
        };

        let parentKey = getNodeKey(parentNode);
        
        if(parentKey === -1) {
            parentKey = null;
        }        

        let newTree = addNodeUnderParent({
            treeData: this.props.treeData,
            newNode: NEW_NODE,
            expandParent: true,
            parentKey: treeIndex,
            getNodeKey: ({ treeIndex }) =>  treeIndex
     });
     this.props.changeMenu(newTree.treeData);
    }

    handleNewItem = () => {        
            this.props.changeMenu(            
                addNodeUnderParent({
                    treeData: this.props.treeData,
                    newNode: {
                        children: null,
                        classname: "",
                        href: "/",
                        icon: "",
                        id: uuidv4(),
                        isauthenticated: false,
                        visitor: 0,
                        open: false,
                        roles: "",
                        subtitle: "",
                        target: "",
                        title: newItemMenu,
                        },
                    expandParent: true,
                    parentKey: null,
                    getNodeKey: ({ treeIndex }) =>  treeIndex
                }).treeData);        
    };

    handleEditItem = ({ node, path, treeIndex }) => {        
        this.setState({ node: {...node}, path: path, treeIndex: treeIndex, openDlgNode: true });                
    };

    handleChange = (e) => {
        const node = {...this.state.node};

        if(e.target.type === "checkbox") {
            node[e.target.name] = e.target.checked;
        } else {
            node[e.target.name] = e.target.value;
        }                
        this.setState({node: node});
    };

    handleSaveTree = () => {
        this.props.saveMenu(this.props.treeData);
    };

    render(){
        const { classes } = this.props;        
        const { searchString, searchFocusIndex, searchFoundCount } = this.state;                
        return(                                    
            <Paper className={classes.root}>
                <IconButton className={classes.iconButton} onClick={this.handleSaveTree} >
                    <SaveOutlined />
                </IconButton>                
                <IconButton className={classes.iconButton} onClick={this.handleNewItem} >
                    <Add />
                </IconButton>
                <IconButton className={classes.iconButton} onClick={this.toggleNodeExpansion.bind(this, true)}>
                    <KeyboardArrowDown />
                </IconButton>
                <IconButton className={classes.iconButton} onClick={this.toggleNodeExpansion.bind(this, false)}>
                    <KeyboardArrowUp />
                </IconButton>
                <InputBase className={classes.input} placeholder="Search menu item" onChange={this.handleSearchOnChange}/>                
                <IconButton className={classes.iconButton} onClick={this.selectPrevMatch}>
                    <ArrowBack />
                </IconButton>                
                <IconButton  className={classes.iconButton} onClick={this.selectPrevMatch}>
                    <ArrowForward />
                </IconButton>
                <label>
                    {searchFocusIndex} / {searchFoundCount}
                </label>
            
                <hr />

                <div className={classes.treeWrapper}>
                    <SortableTree
                        treeData={this.props.treeData}
                        maxDepth={maxDepth}
                        searchQuery={searchString}
                        searchFocusOffset={searchFocusIndex}
                        isVirtualized={true}
                        searchFinishCallback={matches =>
                            this.setState({
                              searchFoundCount: matches.length,
                              searchFocusIndex:
                                matches.length > 0 ? searchFocusIndex % matches.length : 0,
                            })
                          }
                        onChange={treeData => this.props.changeMenu(treeData)}
                        generateNodeProps={rowInfo => ({
                            buttons: [
                            <IconButton key={0} size="small" onClick={() => this.handleEditItem(rowInfo)}>
                                <Edit />
                            </IconButton>,
                            <IconButton key={1} size="small" onClick={() => this.handleDelItem(rowInfo)}>
                                <Clear />
                            </IconButton>,                            
                            <IconButton key={2} size="small" onClick={() => this.addNode(rowInfo)}>
                                <Add />
                            </IconButton>,                        
                            ],
                          })}

                        // onChange={treeData => this.setState({ treeData })}                    
                    />
                </div>
                
                <Typography className={classes.menuname} variant="overline" display="block" align="right" gutterBottom >
                    Menu : {this.state.file}
                </Typography>            
                
                <Dialog 
                    fullWidth={this.state.fullWidth}
                    maxWidth={this.state.maxWidth}
                    open={this.state.openDlgNode} onClose={this.handleOpenDlgNode.bind(this, false)} 
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Menu item</DialogTitle>
                    <DialogContent>
                    {/* <DialogContentText> 
                        {this.state.temptxt}
                    </DialogContentText> */}
                                        
                    <TextField
                        variant="outlined"
                        fullWidth
                        autoFocus
                        margin="normal"                        
                        name="title"
                        label="Title"
                        type="text"
                        
                        value={this.state.node.title}
                        onChange={e => this.handleChange(e)}
                    />
                    <TextField
                        variant="outlined"                        
                        margin="normal"                        
                        name="subtitle"
                        label="Subtitle"
                        type="text"
                        fullWidth
                        value={this.state.node.subtitle || ""}
                        onChange={e => this.handleChange(e)}
                    />
                    <TextField 
                        variant="outlined"                       
                        margin="normal"
                        name="href"
                        label="HREF"
                        type="text"
                        fullWidth
                        value={this.state.node.href || "/"}
                        onChange={e => this.handleChange(e)}
                    />

                    
                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}  >
                        <Grid item xs={2} sm={1}>
                            <Typography component="div" align="center">                
                                <Icon className={classes.icon}>{ this.state.node.icon ? this.state.node.icon : "block"}</Icon>        
                            </Typography>
                        </Grid>
                        <Grid item xs={10} sm={4}>
                            <TextField 
                                variant="outlined"
                                fullWidth
                                className={classes.input}
                                margin="normal"
                                name="icon"
                                label="Icon"
                                type="text"                    
                                value={this.state.node.icon || ""}
                                onChange={e => this.handleChange(e)}
                            />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                margin="normal" 
                                className={classes.input}
                                name="classname"
                                label="Classname"                        
                                type="text"
                                value={this.state.node.classname || ""}
                                onChange={e => this.handleChange(e)}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TextField
                                variant="outlined"
                                fullWidth 
                                margin="normal"
                                name="target"
                                label="Target"
                                type="text"
                                value={this.state.node.target || ""}
                                onChange={e => this.handleChange(e)}
                            />
                        </Grid>

                    </Grid>


                    

                    
                    
                    
                    
                    <FormControl component="fieldset" fullWidth margin="normal">
                        <FormLabel component="legend">Visitor</FormLabel>
                        <RadioGroup row
                            aria-label="visitor" name="visitor"  
                            value={`${this.state.node.visitor}`}
                            onChange={this.handleChange} 
                        >
                            <FormControlLabel value="0" control={<Radio />} label="All"  />
                            <FormControlLabel value="1" control={<Radio />} label="Anonymous" />
                            <FormControlLabel value="2" control={<Radio />} label="Authorized" />                        
                        </RadioGroup>
                    </FormControl>
                    
                    
                    {/* <FormControlLabel  
                        margin="dense"                       
                        control={<Checkbox name="isauthenticated"  
                                    checked={this.state.node.isauthenticated}
                                    onChange={this.handleChange} 
                                    margin="dense" />} label="Is Authenticated" 
                    /> */}

                    <TextField 
                        name="roles"
                        label="Roles"
                        type="text"
                        fullWidth
                        value={this.state.node.roles || ""}
                        onChange={e => this.handleChange(e)}
                    />
                    <br /><br />

                    </DialogContent>
                    <DialogActions>
                    <Button onClick={this.handleOpenDlgNode.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.handleSaveItem.bind()} color="primary">
                        Save
                    </Button>
                    </DialogActions>
                </Dialog>
            </Paper>            
        );
    }
}

MenuBuilder.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(connect(    
    state => state.menuBuilder,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(MenuBuilder));