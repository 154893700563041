/* /src/components/auth/callback.jsx */
import React from "react";
import { AuthConsumer } from "../../providers/authProvider";

export const Callback = () => (
    <AuthConsumer>
        {({ signinRedirectCallback }) => {
            signinRedirectCallback();
            return (
                <p style={{padding: 32, textAlign: "center", display: "block", width: "100%"}} variant="h5" >Завантаження...</p>
            )
        }}
    </AuthConsumer>
);