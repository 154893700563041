import React, { Component } from "react";
import axios from "axios";
import withWidth from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Typography } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/moment";
// import Toolbar from "@material-ui/core/Toolbar";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import MUIDataTable, {ExpandButton} from "mui-datatables";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Rating from "@material-ui/lab/Rating";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import EditIcon from "@material-ui/icons/Edit";
import DoneIcon from "@material-ui/icons/Done";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import "moment/locale/uk";
import CachedIcon from "@material-ui/icons/Cached";
import Box from "@material-ui/core/Box";
import { OidcUserManager } from "../../helpers/OidcSettings";
import Badge from "@material-ui/core/Badge";
import { withTranslation } from "react-i18next";



import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import DialogTitle from "@material-ui/core/DialogTitle";
// import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
// import TextField from "@material-ui/core/TextField";

import EditReview from "./EditReview";
// import Button from "@material-ui/core/Button";
// import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";


const styles = theme => ({
    root: {            
        flexGrow: 1,        
    },
    title: {
        flexGrow: 1,
        display: "none",
        [theme.breakpoints.up("sm")]: {
            display: "block",
        },
    },
    button: {
        margin: theme.spacing(1),
    },
    iconButton: {
        margin: theme.spacing(1),
    },
    page: {
        padding: theme.spacing(2,2),
        marginTop: theme.spacing(1),
    },    
    ratingstar: {        
        fontSize: "11px",        
      },
    pandding: {
        color: theme.palette.warning.main
      },
    approved: {
        color: theme.palette.success.main
    },
    approvedAct: {
        minWidth: "138px",
        textAlign: "left"
    },
    deleted: {
        color: theme.palette.text.secondary
    },
    table: {
        width: "100%",
        maxWidth: "100%",
        overflowX: "auto"
    }

});

// const extractFilename = (path) => {
//     const pathArray = path.split("/");
//     const lastIndex = pathArray.length - 1;
//     return pathArray[lastIndex];
//  };

function TabLabel(props) {
    const { text, icon, ...other } = props;
  
    return (
      <div 
        {...other}
      >
        <Grid container alignItems="center" spacing={2}>
            { icon && 
            <Grid item>
                {icon}
            </Grid>
            }
            <Grid item>
                <Typography>
                    {text}
                </Typography>
            </Grid>                                        
        </Grid>                        
      </div>
    );
  }

  TabLabel.propTypes = {
    text: PropTypes.string.isRequired,
    icon: PropTypes.node,
    // value: PropTypes.any.isRequired,
  };

class Reviews extends Component {
    constructor(props){
        super(props);

        moment.locale(props.i18n.language);        
        // this.locale = "uk";

        this.state = {
            search: " ",
            page: 1,
            pageSize: 10,
            reviewState: 0,
            all: undefined,
            pending: undefined,
            approved: undefined,
            deleted: undefined,
            tabValue: "1",
            user: undefined,
            editedRow: undefined,
            editDlg: false,
            ProductCode: "", 
            ProductName: "",
            RatingValue: 0,
            Advantage: "", 
            Limitations: "",
            Text: "",
            Best: false,
            
            openPeriod: false,
            startPeriod: moment().format("YYYY-MM-01"),
            finishPeriod: moment().format("YYYY-MM-") + moment().daysInMonth()
        };
        
        
    }
    
    loadUser = async () => {         
        const userManager = await OidcUserManager();
        userManager.getUser().then(user => {
            this.setState({user: user});      
        });
    };

    componentDidMount() {
        const {search, page, pageSize } = this.state;
        this.loadContent(search, page, pageSize, 0);
        this.loadContent(search, page, pageSize, 1);
        this.loadContent(search, page, pageSize, 2);
        this.loadUser();
    }

    handleChangeTab = (event, newValue) => {
        this.setState({ tabValue: newValue });
    };

    loadContent = (search, page, pageSize, reviewState) => {
        const URL = `/api/v1.0/review/${search.length > 0 ? search : " " }/${page}/${pageSize}/${reviewState}`; 
        // console.log("loadContent URL: ", URL);
        fetch(URL)
        .then(response => response.json())
            .then(data => {        
                // console.log(data);
                switch (reviewState) {
                    case 0:
                        this.setState({pending: {...data}});
                        break;
                    case 1:
                        this.setState({approved: {...data}});
                        break;
                    case 2:
                        this.setState({deleted: {...data}});
                        break;                            
                    default:
                        this.setState({all: data});
                        break;
                }
                // this.setState({ content: content, file: content.TopBanner.length > 0 ? extractFilename(content.TopBanner): "" });
                // window.scrollTo({top: -200, behavior: "smooth"});
            })
        .catch(error => console.log(error));

        // console.log("Load content by key: " + key);
    };
 
    sort = (page, sortOrder) => {
        console.log( "sort", page, sortOrder);        
    };

    // eslint-disable-next-line no-unused-vars
    changePage = (page, searchText, sortOrder) => {
        const { tabValue, pageSize } = this.state;
        
        let reviewState =  parseInt(tabValue)-1;        
        // console.log( "changePage", page+1, sortOrder);

        this.loadContent((searchText ? searchText : ""), parseInt(page)+1, pageSize, reviewState);
    };

    changeRowsPerPage = (rows) => {
        // console.log( "changeRowsPerPage", rows);
        const {tabValue, search } = this.state;       
        this.setState({pageSize: rows});
        this.loadContent(search, 1, rows, (parseInt(tabValue)-1));        
    };

    getCount = () => {
        const {tabValue, pending, approved, deleted } = this.state;
        switch (tabValue) {
            case "1":
                return pending ? pending.TotalCount: 0;
            case "2":
                return pending ? approved.TotalCount: 0;                
            case "3":                
                return pending ? pending.TotalCount: 0;
            default:                
                return pending ? deleted.TotalCount: 0;
        }
    }

    handleRefresh = () => {
        const {tabValue, search, page, pageSize } = this.state;       
        this.loadContent(search, page, pageSize, (parseInt(tabValue)-1));        
    };

    setPeriod = (flag) => {
        this.setState({ openPeriod: flag});
    };
    
    handleOpenPeriod = () => {
        this.setPeriod(true);
    };

    handleClosePeriod = () => {
        this.setPeriod(false);
    };

    getDataByIdx = (idx) => {
        const {tabValue, pending, approved, deleted } = this.state;
        switch (tabValue) {
            case "1":
                return pending ? pending.Data[idx] : undefined;
            case "2":
                return pending ? approved.Data[idx] : undefined;                
            case "3":                
                return pending ? deleted.Data[idx] : undefined;
            default:                
                return undefined;
        }
    };

    changeReviewState = (id, state) => {
        const { tabValue, search, page, pageSize, user } = this.state;
        const self = this;        
        if(!user)
            return;                
                
        const url = `/api/v1.0/review/${id}/${state}`;                        
        fetch(url, 
            {    method: "PUT",  
                headers: {"Authorization": `${user.token_type} ${user.access_token}` }
            }
        )
        .then((response) => response.json())
        .then( (data) => {
            console.log("Change state:", data);
            if(tabValue === "1"){
                self.loadContent(search, page, pageSize, 0);
                self.loadContent(search, page, pageSize, state);
            } else if(tabValue === "2"){
                self.loadContent(search, page, pageSize, 1);
                self.loadContent(search, page, pageSize, 2);
            } else if(tabValue === "3"){
                self.loadContent(search, page, pageSize, 2);
                self.loadContent(search, page, pageSize, state);
            }
            
        })
        .catch((error) => {
          console.log(error);
        });  
    };
    
    handleClose = (flag) => {
        this.setState({ editDlg: flag });
    }

    handleUpdReview = (review) => {
        console.log("Update review:", review);        
        const { tabValue, search, page, pageSize, user } = this.state;
        const self = this;        
        if(!user)
            return;                
                
        const url = `/api/v1.0/review/${review.Id}`;                        
        fetch(url, 
            {   method: "PUT",  
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `${user.token_type} ${user.access_token}` 
                },
                body: JSON.stringify(review)
            }
        )
        .then((response) => response.json())
        .then( (data) => {
            console.log("Change state:", data);            
            self.loadContent(search, page, pageSize, parseInt(tabValue)-1);            
        })
        .catch((error) => {
          console.log(error);
        });  

        this.setState({editDlg: false});
    };
        
    handleEdit = async (dataIndex) => {        
        const row = this.getDataByIdx(dataIndex);        
        if(row){
            this.setState({editedRow : row, editDlg: true});
        }
    };

    handleApprove = async (dataIndex) => {                        
        const row = this.getDataByIdx(dataIndex);        
        if(row){
            this.changeReviewState(row.Id, 1);
        }
    };

    handleDelete = (dataIndex) => {
        const row = this.getDataByIdx(dataIndex);
        if(row){
            this.changeReviewState(row.Id, 2);
        }
    };

    handlePanding = (dataIndex) => {        
        const row = this.getDataByIdx(dataIndex);
        console.log("handlePanding", row);
        if(row){
            this.changeReviewState(row.Id, 0);
        }
    };

    renderRowAct = (dataIndex, tabValue, classes) => {        
        switch (tabValue) {
            case "1":
                return (
                    <div className={classes.approvedAct}>
                        <IconButton size="small" className={classes.iconButton} onClick={() => this.handleEdit(dataIndex)}>
                            <EditIcon />
                        </IconButton>
                        <IconButton size="small" className={classes.iconButton} onClick={() => this.handleApprove(dataIndex)}>
                            <DoneIcon />
                        </IconButton>
                        <IconButton size="small" className={classes.iconButton} onClick={( )=> this.handleDelete(dataIndex)}>
                            <DeleteOutlineIcon />
                        </IconButton>
                    </div>
                );
            case "2":
                return (
                    <div className={classes.approvedAct}>
                        <IconButton size="small" className={classes.iconButton} onClick={() => this.handleEdit(dataIndex)}>
                            <EditIcon />
                        </IconButton>
                        <IconButton size="small" className={classes.iconButton} onClick={()=> this.handleDelete(dataIndex)}>
                            <DeleteOutlineIcon />
                        </IconButton>
                    </div>
                );
            case "3":                
            return (
                <div className={classes.approvedAct}>
                    <IconButton size="small" className={classes.iconButton} onClick={() => this.handlePanding(dataIndex)}>
                        <AccessTimeIcon />
                    </IconButton>
                    <IconButton size="small" className={classes.iconButton} onClick={()=> this.handleApprove(dataIndex)}>
                        <DoneIcon />
                    </IconButton>                    
                </div>
            );
            default:                
                return null;
        }        
    };

    handleChangeDate = (value, name) => { 
        this.setState({ [name]: moment(value).format("YYYY-MM-DDTHH:mm:ss.SSSSZ") });
    }

    downloadFile = () => {
        const self = this;
        const {startPeriod, finishPeriod, tabValue} = this.state;
        
        const state = parseInt(tabValue)-1;
        let filename = "";

        switch (state) {
            case 0:
                filename = "reviewsPending.csv";
                break;
            case 1:
                filename = "reviewsApproved.csv";
                break;
            case 2:
                filename = "reviewsDeleted.csv";
                break;
            default:
                break;
        }

        // if(state === 0){
        //     filename += "_pending, Approved, Deleted"
        // } else if


        
        const url = `/api/v1.0/review/${startPeriod}/${finishPeriod}/${state}`; 
        const method = "GET";
        axios
      .request({
        url,
        method,        
        responseType: "blob", //important
      })
      .then(({ data }) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        // const filename = "reviews.csv";
        link.href = downloadUrl;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.remove(); 
        self.setPeriod(false);
      });
    };
    
    render() {
        const {classes, t} = this.props;
        const {tabValue, pending, approved, deleted, editDlg, editedRow, openPeriod, startPeriod, finishPeriod} = this.state;
        
        const columns = [
            {
                name: "Rating",
                label: t("reviews.Rating"),
                options: {
                    filter: true,                
                    // eslint-disable-next-line no-unused-vars
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <Typography component="div" align="center">                                
                                <Rating name="half-rating" size="small"  className={classes.ratingstar} value={value} precision={0.5} readOnly />                                
                                <Typography variant="subtitle2">{value}</Typography>
                                {/* <Typography variant="body2">{tableMeta.rowData[13]}</Typography> */}
                            </Typography>
                        )
                    }
                },
            },
            {
                name: "Advantage",
                label: "Advantage",
                options: {
                    filter: false,
                    display: false,
                    viewColumns: false,
                }
            },
            {
                name: "Limitations",
                label: "Limitations",
                options: {
                    filter: false,
                    display: false,
                    viewColumns: false,
                }
            },
            {
                name: "ProductCode",
                label: t("reviews.ProductCode"),
                options: {
                    filter: true,
                    // eslint-disable-next-line no-unused-vars
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (                                                    
                            tableMeta.rowData[11] ?
                            <Badge color="secondary" variant="dot">
                                <Typography variant="body2"> {value}</Typography>
                            </Badge> :
                            <Typography variant="body2">{value}</Typography>
                        )
                    }
                }
            },
            {
                name: "ProductName",
                label: t("reviews.ProductName"),
                options: {
                  filter: true,
                }
            },
            {
                name: "RegNumber",
                label: t("reviews.RegNumber"),
                options: {
                  filter: true,
                }
            },
            {
                name: "UserName",
                label: t("reviews.UserName"),
                options: {
                  filter: true,
                }
            },
            {
                name: "UserEmail",
                label: t("reviews.UserEmail"),
                options: {
                  filter: true,
                }
            },
            {
                name: "CreatedOn",
                label: t("reviews.Date"),
                options: {
                  filter: true,
                  customBodyRender: (value) => {
                    return (
                        <>
                            <div><small>{moment(value).format("DD-MM-YY")}</small></div>
                            <div><small>{moment(value).format("HH:mm:ss")}</small></div>
                        </>                        
                        
                    )
                }
                }
            },
            {
                name: "UpdatedOn",
                label: t("reviews.Date"),
                options: {
                  filter: true,
                  customBodyRender: (value) => {
                    return (
                        <>
                            <div><small>{moment(value).format("DD-MM-YY")}</small></div>
                            <div><small>{moment(value).format("HH:mm:ss")}</small></div>
                        </>                        
                        
                    )
                }
                }
            },
            {
                name: "ManagerChangeState",
                label:  t("reviews.Manager"),
                options: {
                  filter: true,
                }
            },
            {
                name: "Text",
                label: "Text",
                options: {
                    filter: false,
                    display: false,
                    viewColumns: false,
                }
            },
            {
                name: "Best",
                label: "Best",
                options: {
                    filter: false,
                    display: false,
                    viewColumns: false,
            }
            },
            {
                name: "Useful",
                label: "Useful",
                options: {
                    filter: false,
                    display: false,
                    viewColumns: false,
            }
            },
            {
                name: "Useless",
                label: "Useless",
                options: {
                  filter: false,
                  display: false,
                  viewColumns: false,
                }
            },
            {
                name: "Id",
                label: "Id",
                options: {
                  filter: false,
                  display: true,
                  viewColumns: true,
                }
            },
            {
                name: "Act",
                label: " ",
                options: {
                  filter: false,
                  sort: false,
                  empty: true,
                  customBodyRenderLite: (dataIndex) => {
                    return (
                        this.renderRowAct(dataIndex, tabValue, classes)
                    );
                  }
                }
            },               
        ];

        const options = {
            filter: true,
            filterType: "dropdown",
            responsive: "standard",
            selectableRows: "none",
            serverSide: true,
            expandableRows: true,
            expandableRowsHeader: false,
            expandableRowsOnClick: false,
            count: this.getCount(),
            onTableChange: (action, tableState) => {
                // console.log(action, tableState);
                switch (action) {
                    case "changePage":
                      this.changePage(tableState.page, tableState.searchText, tableState.sortOrder);
                      break;
                    case "sort":
                      // this.sort(tableState.page, tableState.sortOrder);
                      break;
                    case "search":
                        this.changePage(0, tableState.searchText, tableState.sortOrder);
                        break;
                    case "changeRowsPerPage":
                        this.changeRowsPerPage(tableState.rowsPerPage);                                                
                        break;
                    default:
                        // console.log("action not handled.");
                        break;                      
                  }
            },
            // eslint-disable-next-line no-unused-vars
            isRowExpandable: (dataIndex, expandedRows) => {
                
              // if (dataIndex === 3 || dataIndex === 4) return false;
      
              // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
              // if (expandedRows.data.length > 0 && expandedRows.data.filter(d => d.dataIndex === dataIndex).length === 0) return false;
              return true;
            },
            // rowsExpanded: [0, 1],
            // eslint-disable-next-line no-unused-vars
            renderExpandableRow: (rowData, rowMeta) => {
              const colSpan = rowData.length + 1;
              return (
                <TableRow>
                  <TableCell colSpan={colSpan}>
                    {/* Data:  */}
                    <Box p={1}>
                        <Typography variant="subtitle2"> 
                            Advantage:
                        </Typography>
                        <Typography variant="body2" gutterBottom> 
                            {rowData[1]}
                        </Typography>
                    </Box>
                    <Box p={1}>
                    <Typography variant="subtitle2"> 
                        Limitations:
                    </Typography>
                    <Typography variant="body2" gutterBottom> 
                        {rowData[2]}
                    </Typography>
                    </Box>
                    <Box p={1}>
                    <Typography variant="subtitle2"> 
                        Review:
                    </Typography>
                    <Typography variant="body2" gutterBottom> 
                        {rowData[11]}
                    </Typography>
                    </Box>

                  </TableCell>
                </TableRow>
              );
            },
            onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => console.log(curExpanded, allExpanded, rowsExpanded),
            customToolbar: () => {
                return (
                    <React.Fragment>                    
                    <Tooltip title={"Refresh"}>                      
                      <IconButton className={classes.iconButton} onClick={this.handleRefresh}>
                        <CachedIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title={"Download csv"}>                                            
                      <IconButton color="secondary" className={classes.iconButton} onClick={this.handleOpenPeriod}>
                        <CloudDownloadOutlinedIcon />
                      </IconButton>
                        
                    </Tooltip>
                  </React.Fragment>
                );
            }
        };

        const components = {
            ExpandButton: function(props) {
              // if (props.dataIndex === 3 || props.dataIndex === 4) return <div style={{width:"24px"}} />;
              return <ExpandButton {...props} />;
            }
        };
        
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={moment.locale()}>
        <div className={classes.root}>
            {/* <AppBar position="static" color="inherit" elevation={1}>
                <Toolbar variant="dense">
                    <Typography className={classes.title} variant="h6" noWrap>
                        Reviews
                    </Typography>
                </Toolbar>
            </AppBar> */}
                                    
            <Paper className={classes.page} elevation={2} >
                <Typography variant="h5" align="center" gutterBottom>{t("reviews.title")}</Typography>
                <TabContext value={tabValue}>
                    <Grid container direction="row" justify="flex-start" alignItems="center">
                        <Grid item xs={12} sm={8}>
                            <AppBar position="static" color="inherit" elevation={0}>
                                <TabList onChange={this.handleChangeTab} aria-label="reviews-lists">
                                    <Tab label={<TabLabel icon={<AccessTimeIcon className={classes.pandding} />} text={t("reviews.pending")} />}  value="1" />
                                    <Tab label={<TabLabel icon={<DoneAllIcon className={classes.approved} />}  text= {t("reviews.approved")} />} value="2" />
                                    <Tab label={<TabLabel icon={<DeleteOutlineIcon className={classes.deleted } />} text={t("reviews.deleted")} />} value="3" />
                                </TabList>
                            </AppBar>
                        </Grid>
                        <Grid item xs={12} sm={4}>                            
                        </Grid>                                     
                    </Grid>
                                        
                    <TabPanel value="1">                        
                        <MUIDataTable 
                            responsive="standard"
                            title={t("reviews.tableTitlePending")}
                            data={pending? pending.Data : []}                            
                            columns={columns} 
                            options={options} 
                            components={components} 
                        />
                    </TabPanel>
                    <TabPanel value="2">                        
                        <MUIDataTable 
                            responsive="standard"
                            title={t("reviews.tableTitleApproved")}
                            data={approved? approved.Data : []}
                            columns={columns} 
                            options={options} 
                            components={components} 
                        />
                    </TabPanel>
                    <TabPanel value="3">                        
                        <MUIDataTable 
                            responsive="standard"
                            title={t("reviews.tableTitleDeleted")}
                            data={deleted? deleted.Data : []}
                            columns={columns} 
                            options={options} 
                            components={components} 
                        />                        
                    </TabPanel>
                </TabContext>
            </Paper>                
            
            {editedRow && 
                <EditReview review={editedRow} open={editDlg} onClose={this.handleClose} onSave={this.handleUpdReview} />
            }

            <Dialog open={openPeriod} fullWidth maxWidth={"sm"} onClose={this.handleClosePeriod} aria-labelledby="form-dialog-review">
                <DialogTitle id="form-dialog-download">Download csv by period </DialogTitle> 
                
                <DialogContent>                
                    <Grid container direction="row" spacing={2} justify="center" alignItems="center">
                        <Grid item>
                            <KeyboardDatePicker
                            // disableToolbar
                                inputVariant="outlined"
                                fullWidth={true}                            
                            format="DD.MM.YYYY"
                            margin="normal"
                            id="startPeriod"
                            name="startPeriod"
                            label={t("reviews.StartPeriod")}                                
                            value={startPeriod}
                            onChange={date =>
                                this.handleChangeDate(date, "startPeriod")
                            }
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                            }}
                            />
                        </Grid>
                        <Grid item>
                            <KeyboardDatePicker
                                // disableToolbar
                                inputVariant="outlined"
                                fullWidth={true}                            
                            format="DD.MM.YYYY"
                            margin="normal"
                            id="finishPeriod"
                            name="finishPeriod"
                            label={t("reviews.FinishPeriod")}                                
                            value={finishPeriod}
                            onChange={date =>
                                this.handleChangeDate(date, "finishPeriod")
                            }
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                            }}
                            />
                        </Grid>
                    </Grid>
                                        
                    
                </DialogContent>

                <DialogActions>
                <Button onClick={this.handleClosePeriod} >
                    Cancel
                </Button>
                <Button  color="primary" onClick={this.downloadFile}>
                    Download
                </Button>
                </DialogActions>
            </Dialog>


        </div>
        </MuiPickersUtilsProvider>
        );
    }
}

export default withTranslation()(withWidth()(withStyles(styles, { withTheme: true })(Reviews)));