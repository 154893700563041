/* /src/components/auth/callback.jsx */
import React from "react";
import { AuthConsumer } from "../../providers/authProvider";

export const SigninPopup = () => (
    <AuthConsumer>
        {({ signinPopup }) => {
            signinPopup();
            return <span>loading</span>;
        }}
    </AuthConsumer>
);