
import React, { Component } from "react";
import {withRouter} from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionOrderCreators } from "../../store/Order";
import {withStyles} from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { connect } from "react-redux";
import DetailPromotion from "../Promo/DetailPromotion";
import { withTranslation } from "react-i18next";
import { OidcUserManager } from "../../helpers/OidcSettings";
import axios from "axios";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
    root: {
        flexGrow: 1,        
        padding: theme.spacing(1),
    },
    progress: {
        height: 2,
        position: "absolute",
        top: 0,
        left: 0,
        right:0,
      },  
      linearColorPrimary: {
        backgroundColor: "#abdcda",
      },
      linearBarColorPrimary: {
        backgroundColor: "#d20000",
      },
});

class PromotionPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,             
            id: this.props.match.params.id || "",
            promotion: null,
            rowsSelected:[],
            ActionByCondition: null,
                        
            
        };
        // this.props.requestOrder();
        this.props.requestPromotions();
        
    }    
    
    
    componentDidUpdate(prevProps, prevState) {       
        if(this.state.id !==  prevState.id ){
            this.loadPromotion(this.state.id);
        }        
    }

    componentDidMount() {
        this.loadPromotion(this.state.id);
    }

    loadPromotion = async (id) => {        
        const self = this;
        const userManager = await OidcUserManager();

        userManager.getUser().then(user => {
            let token = null;

            if (user != null) {
                token =  `Bearer ${user.access_token}`;
            }

            this.setState({isLoading: true});
            const URL =  `/api/v1.0/Promotions/GetById/${id}/true`; 
            
            axios.get(URL,  
                { headers: { Authorization: token } }
            )                
            .then(function(response){
                self.setState({ promotion: response.data, isLoading: false });
                // console.log("Selected promotion", response.data);
            })
            .catch((error) => {        
                this.setState({isLoading: false});
                console.log(error);
            });                            
        });

    };

  
    render() {
        const { classes, t } = this.props;
        const { promotion, isLoading } = this.state;

        return (
            <div className={classes.root}>
                { isLoading && 
                    <LinearProgress className={classes.progress} 
                    classes={{
                        colorPrimary: classes.linearColorPrimary,
                        barColorPrimary: classes.linearBarColorPrimary,
                    }}
                />
                }
                { promotion ? <DetailPromotion promotion={promotion} /> : 
                    <Typography style={{paddingTop: "48px"}} color="textSecondary" variant="h5" align="center" gutterBottom>
                    {t("common.Loading")}...
                    </Typography>
                }
            </div>
        );
    }
}



export default withTranslation()(withWidth() (withRouter( withStyles(styles)(
    connect(    
        state => state.orderRequest,
        dispatch => bindActionCreators(actionOrderCreators, dispatch)
    )(PromotionPage)
))));