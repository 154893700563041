import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import List from "@material-ui/core/List";
import { Link } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
// import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";

import Grid from "@material-ui/core/Grid";
import withWidth from "@material-ui/core/withWidth";
import Box from "@material-ui/core/Box";
import { CatalogIcon, ChildIcon, FaceIcon, ForBodyIcon, ForHomeIcon, HairIcon, HealthIcon, JewerlyIcon, MakeupIcon, ManIcon, 
         ParfumsIcon, HeartPulseIcon, HomeLightbulbIcon, FaceOutlineIcon, AccountTieOutline, WomanHeadIcon } from "../icons/jerelia-icons";

import "../../styles/index.css";
import { Typography } from "@material-ui/core";
// import classNames from "classnames";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}


// function TabContainer(props) {
//   return (
//     <div>
//       {props.children}
//     </div>
//   );
// }
  
  const styles = theme => ({
    root: {
      flexGrow: 1,
      width: "100%",
      // [theme.breakpoints.down("xs")]: {
      //   marginTop: theme.spacing(7),
      // },
      // [theme.breakpoints.up("sm")]: {
      //   marginTop: theme.spacing(8),
      // },     
    },
    appbarsecond: {
      background: theme.palette.appbarsecond.background,
    },
    navbar: {
      paddingTop: 0,
      paddingBottom: 0,
      maxHeight: "100%",
    },
    menuarea:{
      paddingTop: theme.spacing(2),
      backgroundColor: theme.palette.background.paper, 
      height: "38em",
      [theme.breakpoints.up("sm")]: {
        height: "36em",
      },
      width: "100%",                  
      overflow: "auto",
      // minHeight: "23em",
    },
    menuitem: {
      paddingTop: 4,
      paddingBottom: 4,
    },
    bgimg: {      
      // maxHeight: "23em",
      // minHeight: "23em",
      overflow: "auto",
      // backgroundSize: "auto 180px",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "right bottom",
    },
    tabs: {
    },

    tabLabel: {
      fontSize: ".7rem",
      [theme.breakpoints.up("sm")]: {
        fontSize: ".8rem",
      },
    },
    indicator: {
      backgroundColor: theme.palette.primary.main
      // backgroundColor: "transparent",
    },
    labelIcon: {
      fontSize: ".7rem",
      [theme.breakpoints.up("sm")]: {
        fontSize: ".8rem",
      },
    },
    label: {
      color: "red"
    },
    wrapper: {
      
    },
    selected:{
      // border: "1px solid #eee",
      // backgroundColor: theme.palette.primary.main,
      // color: "#fff !important"
    }
    
  });

  function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
  }
  
class BigMenu extends React.Component{
    constructor(props) {
      super(props);        
      this.state = {
          show: false,
          showtabs: true,
          value: 0,
          imgpath: "/assets/files/images/groups",
      };       
    }

    handleChange = (event, value) => {
        if(this.state.value === value){
          this.setState(state => ({ show: !state.show }));  
        }
        else {
          this.setState({ show: true });  
        }
        this.setState({ value });
    };

    handleClickAway = () => {
      this.setState({ show: false });
    };

    getIcon = (icon) => {
      switch (icon) {
        case "catalog":          
          return (<CatalogIcon width={24} height={24} />);
        case "child":          
          return (<ChildIcon width={24} height={24} />);
          case "women-head":          
          return (<WomanHeadIcon width={24} height={24} />);  
        case "face":
          return (<FaceIcon width={24} height={24} />);
        case "face-outline":          
          return (<FaceOutlineIcon/>);  
        case "for-body":          
          return (<ForBodyIcon width={24} height={24} />);
        case "for-home":          
          return (<ForHomeIcon width={24} height={24} />);
        case "hair":          
          return (<HairIcon width={24} height={24} />);
        case "health":          
          return (<HealthIcon width={24} height={24}/>);
        case "jewerly":          
          return (<JewerlyIcon width={24} height={24} />);
        case "makeup":          
          return (<MakeupIcon width={24} height={24}/>);
        case "account-tie-outline":          
          return (<AccountTieOutline />);
        case "parfums":          
          return (<ParfumsIcon width={24} height={24} />);
        case "heart-pulse":          
          return (<HeartPulseIcon width={24} height={24} />);
          case "manager":          
          return (<ManIcon width={24} height={24} />);  
        case "home-lightbulb":          
          return (<HomeLightbulbIcon width={24} height={24} />);
        default:
          return (<Icon width={24} height={24}>{icon}</Icon>)          
      }
    };

    render() {
        const { classes } = this.props;
        const { value, show, showtabs } = this.state;                
        return (
            <div className={classes.root}>
            {showtabs ? (
            <ClickAwayListener onClickAway={this.handleClickAway}>
              <AppBar color="default"  className={classes.appbarsecond}  position="static" elevation={1}>
                <Tabs
                  className={classes.tabs}
                  value={value}
                  onChange={this.handleChange}
                  variant="scrollable"
                  scrollButtons="on"
                  // scrollButtons="auto"
                  classes={{                    
                    indicator: classes.indicator,
                    flexContainer: classes.flexContainer,
                  }}
                  // indicatorColor="primary"
                  textColor="primary"
                  aria-label="scrollable big menu"                  
                >
                  {
                    this.props.items.map((item) => {
                      return (                        
                        // <Tab color="inherit" key={item.id} 
                        //   icon={ isWidthUp("sm", this.props.width) ? this.getIcon(item.icon) : null } 
                        //   label={item.title} />
                          <Tab color="inherit" key={item.id} aria-label={item.title}
                          classes={{
                            root: classes.tabRoot,
                            wrapper: classes.wrapper,
                            labelIcon: classes.labelIcon,
                            selected: classes.selected,                            
                          }}
                            icon={this.getIcon(item.icon)} 
                            label={item.title}
                            // label={<span className={classes.tabLabel}>{item.title}</span>}
                          />  
                      );
                    })
                  }                  
                </Tabs>
                {show ? (
                <Box  boxShadow={1}>
                {
                  this.props.items.map((item, index) => {
                    return(                    
                      value === index && 
                      <TabPanel  className={classes.menuarea} key={item.id}>                                           
                          {(item.children.length > 0 ? (
                            <Grid container spacing={0} className={classes.bgimg}>
                            {
                              item.children.map((sitem) => {
                                return(
                                  <Grid key={sitem.id} item xs={12} sm={4} md={ item.children.length > 4 ? 2 : 3} >
                                    {
                                      (sitem.children !== null ? 
                                        (
                                        <List component="nav" className={classes.navbar}>
                                          <ListItemLink  key={sitem.id} component={Link} onClick={this.handleClickAway} to={sitem.href}>                                          
                                            <Typography color="textPrimary" variant="button">{sitem.title}</Typography>
                                          </ListItemLink>
                                          {
                                            sitem.children.map((titem) => (

                                              <ListItem button key={titem.id} component={Link} className={classes.menuitem} onClick={this.handleClickAway} to={titem.href}>                                                
                                                <Typography variant="body2" key={titem.id} color="inherit" gutterBottom >{titem.title}</Typography>
                                              </ListItem>
                                              )
                                            )
                                          }
                                        </List>                                                    
                                        ) : (<p>{sitem.title}</p>))
                                    }
                                </Grid>    
                                )
                              })
                            }
                            </Grid>                                                    
                          ) : null)}
                      </TabPanel>
                    ) 
                  })
                }                                               
              </Box>
              ) : null }
              </AppBar>
              
            </ClickAwayListener>
            ) : null }            
            </div>
          );
    }
}

BigMenu.propTypes = {
    classes: PropTypes.object.isRequired,
  };

  export default withWidth()(withStyles(styles)(BigMenu));