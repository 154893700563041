import React, { Component } from "react";
import {withRouter} from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionOrderCreators } from "../../store/Order";
import {withStyles} from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { connect } from "react-redux";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import AddShoppingCard from "@material-ui/icons/AddShoppingCart";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { OrderRowType } from "../../definitions/order";

const styles = theme => ({
    root: {
        display: "flex", 
        width: "100%",
        padding: theme.spacing(1,1)
      },
      details: {          
        display: "flex",
        flexDirection: "column",
        width: "100%",
      },
      content: {        
        flex: "1 0 auto",        
        height: 100,
        [theme.breakpoints.down("sm")]: {
            height: 100,
        },
      },
      cover: {
        padding: 0,
        width: 168,        
        display: "block",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        // [theme.breakpoints.down("sm")]: {
        //     height: 76,
        // },
      },
      controls: {        
        padding: theme.spacing(1),        
      },
      playIcon: {
        height: 28,
        width: 28,
      },
      price: {
        marginRight: theme.spacing(2),
      },
      qty: {
                                
        "&[type=number]": {
            "-moz-appearance": "textfield",        
        },
        "&::-webkit-outer-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },
        "&::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },
      },
});

class ProdPromoThumb extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // eslint-disable-next-line no-undef
            cdn: `${process.env.REACT_APP_CDN}Pictures/`,            
            imgLgSize: "392x392",
            imgMdSize: "183x183",
            imgXsSize: "76x76",
            Qty: this.props.qty,  
        };
        this.handleBuy = this.handleBuy.bind(this);        
    } 
    
    handleBuy() {
        const qty = isNaN(parseInt(this.state.Qty)) ? 1 : parseInt(this.state.Qty);  
        this.setState({Qty: qty});        
        const {item, promo} = this.props;        
        let price = item.Product.CatalogPrice;       
        let promotionType = OrderRowType.Default;
        let sourse = null;
        let useLO = true;

        if(promo){
            if(promo.PromotionType === OrderRowType.Promotion){
                promotionType = OrderRowType.Chest;
                if(item.Prices && item.Prices.length > 0) {
                    useLO =  promo.ActionByCondition.UseLO;
                    price = item.Prices[0];
                    sourse =  { 
                        Id: promo.Id,
                        Idrref: promo.Idrref,
                        Name: promo.Name,
                        Description: promo.Description
                        };
                }                
            }                
        }
                
        const pos = {
            ParentIdrref: item.Product.Idrref,
            ProductId: item.Product.Id,
            orderrowtype: promotionType, // OrderRowType.Chest.Default,
            Name: item.Product.Name,
            LocalName: item.Product.Name,
            Price: price,
            CatalogPrice: item.Product.CatalogPrice,
            CatalogPricePv: item.Product.CatalogPricePv,
            Rate: parseFloat(item.Product.CatalogPrice / item.Product.CatalogPricePv).toFixed(2),
            Code: item.Product.Code,
            Image: item.Product.Image,
            UseLO: useLO,
            Source: sourse,
        };
        
        // console.log({"item": item, "pos": pos, "qty": qty});        
        this.props.actions.addToOrder([{ pos: pos, qty: qty, orderrowtype: promotionType }]);
    }

    handleChangeQty = (e) => {
        // console.log("Change value", e.target.value);
        this.setState({ Qty: parseInt(e.target.value) })
    }

    render() {
        const {item, classes, promo, disabled } = this.props;
        const { cdn } = this.state;
        return (
            <Card className={classes.root} variant="outlined">
                <CardMedia
                    className={classes.cover}
                    component="div"
                    image={`${cdn}${this.state.imgMdSize}/${item.Product.Image}`}
                    title={item.Product.Name}
                />
                <div className={classes.details}>
                    <CardContent className={classes.content}>
                        <Typography variant="body2" gutterBottom>
                            {item.Product.Code} : {item.Product.Name}
                        </Typography>                        
                    </CardContent>                    
                    <Grid className={classes.controls} container  direction="row" justify="flex-end" alignItems="center" spacing={1}>
                        <Grid item xs={4} >
                            {
                                item.Prices && item.Prices.length > 0 ? 
                                <Typography variant="h6" display="block" className={classes.price}> 
                                    <span className={classes.colorRed}>{parseFloat(item.Prices[0]).toFixed(2)}</span> <small className="price--line-through text-gray">{parseFloat(item.Product.CatalogPrice).toFixed(2)}</small>
                                </Typography> : parseFloat(item.Product.CatalogPrice).toFixed(2)
                            }
                            {/* {parseFloat(item.Product.CatalogPrice).toFixed(2)} */}
                        </Grid>
                        <Grid item xs={4} >
                            <Input fullWidth                                    
                                type="number"
                                name="qty"
                                inputProps={{ 
                                    className: classes.qty, 
                                    step: 1, 
                                    min: 1, 
                                    max: (promo.PromotionType === 1 ? this.props.qty : 1000)
                                }}
                                value={this.state.Qty}                                                        
                                // onChange={e => this.setState({ Qty:  ( promo.PromotionType === 1 ? 1 : e.target.value ) })}  
                                onChange={this.handleChangeQty}  
                                startAdornment={<InputAdornment position="start">шт</InputAdornment>}
                            />
                        </Grid>
                        
                        <Grid item > 
                            <Button disabled={ disabled }
                            color="primary" 
                            className={classes.buy}
                            onClick={this.handleBuy}
                            >
                            <AddShoppingCard /> 
                        </Button>
                        </Grid>
                    </Grid>
                </div>
            </Card>
        );
    }
}


export default withTranslation()(withWidth()(withRouter( withStyles(styles)(
    connect(    
        state => state.orderRequest,
        dispatch => {
            return {
               actions: bindActionCreators(Object.assign({}, actionOrderCreators), dispatch)
        }}
    )(ProdPromoThumb)
))));