import React, { Component } from "react";
import axios from "axios";
import { OidcUserManager } from "../../helpers/OidcSettings";
import { withTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";
import OpenWithIcon from "@material-ui/icons/OpenWith";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import BurstModeOutlinedIcon from "@material-ui/icons/BurstModeOutlined";
import PageviewOutlinedIcon from "@material-ui/icons/PageviewOutlined";

import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";

import ClearIcon from "@material-ui/icons/Clear";
import Grid from "@material-ui/core/Grid";
import Collapse from "@material-ui/core/Collapse";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
// import DialogContentText from "@material-ui/core/DialogContentText";
// import DialogTitle from "@material-ui/core/DialogTitle";

import  {default as SliderPreview} from "../Slider/Slider";
import Typography from "@material-ui/core/Typography";

import {
    sortableContainer,
    sortableElement,
    sortableHandle,
  } from "react-sortable-hoc";
import arrayMove from "array-move";
import gray from "@material-ui/core/colors/grey";
import blue from "@material-ui/core/colors/blue";

const styles = theme => ({
    root: {
        flexGrow: 1,        
        padding: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(1),
    },
    margin: {
        margin: theme.spacing(1),        
    },
    avatar: {
        backgroundColor: gray[500],
        cursor: "move"
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    avatarB: {
        backgroundColor: blue[500],
    },
});

const DragHandle = withStyles(styles)(sortableHandle((props) => 
    {
        const { classes } = props;
        return (
            <Avatar aria-label="recipe" className={classes.avatar} title="Move">
                <OpenWithIcon fontSize="small" />
          </Avatar>
        // <IconButton aria-label="drag" className={classes.margin} >
        //     <OpenWithIcon fontSize="small" />
        // </IconButton>

        );
    }    
));

const SortableItem = sortableElement( ( { value, expanded, onEdit, onChange, onDelete}) => {            
        return(    
            <Card style={{marginBottom: "4px", width: "100%"}} >
                <CardHeader
                    avatar={
                        <DragHandle /> 
                    }
                    action={
                        <React.Fragment>                        
                            <IconButton aria-label="edit" onClick={ () => onEdit(value)} >
                            { expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}  
                            </IconButton>                            
                            <IconButton aria-label="delete" onClick={ () => onDelete(value)} >
                                <ClearIcon />
                            </IconButton>
                      </React.Fragment>
                      }
                    title={`${value.Caption}`}
                    subheader={ value.Img }
                /> 
                
                <Collapse in={expanded} timeout="auto" unmountOnExit>                                        
                    <CardContent>
                        <Grid container direction="row" spacing={2} justify="flex-start" alignItems="stretch" >
                            <Grid item xs={12} sm={6}>
                            <TextField
                                id="Caption"
                                name="Caption"
                                label="Caption"
                                style={{ margin: 8 }}
                                placeholder="Caption"
                                fullWidth
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}                                
                                variant="outlined"
                                value={value.Caption}
                                onChange = {(e) => onChange(e, value)}
                            />
                            <TextField
                                id="Text"
                                name="Text"
                                label="Text"
                                style={{ margin: 8 }}
                                placeholder="Text"
                                fullWidth
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}                                
                                variant="outlined"
                                value={value.Text}
                                onChange = {(e) => onChange(e, value)}
                                />
                            <TextField
                                id="FullPath"
                                name="FullPath"
                                label="Path to image"
                                style={{ margin: 8 }}
                                placeholder="Select path to image"
                                fullWidth
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}                                
                                variant="outlined"
                                value={value.FullPath}
                                onChange = {(e) => onChange(e, value)}
                            />
                            <TextField
                                id="Url"
                                name="Url"                                
                                label="Url to action"
                                style={{ margin: 8 }}
                                placeholder="Url to action"
                                fullWidth
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}                                
                                variant="outlined"
                                value={value.Url}
                                onChange = {(e) => onChange(e, value)}
                                />
                            <div>
                            <TextField
                                id="ShowFrom"
                                name="ShowFrom"
                                type="datetime-local"                                
                                label="Date show from, MM/DD/YY"
                                style={{ margin: 8 }}
                                placeholder="Show from"                            
                                margin="normal"
                                inputProps={{
                                    step: 1
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}                                
                                variant="outlined"
                                value={value.ShowFrom}
                                onChange = {(e) => onChange(e, value)}
                                />
                            <TextField
                                id="ShowTo"
                                name="ShowTo"
                                type="datetime-local"                                
                                label="Date show to, MM/DD/YY"
                                style={{ margin: 8 }}
                                placeholder="Show to"                            
                                margin="normal"
                                inputProps={{
                                    step: 1
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}                                
                                variant="outlined"
                                value={value.ShowTo}
                                onChange = {(e) => onChange(e, value)}
                                /> 
                            </div>
                            <div>
                            <FormControlLabel style={{ margin: 14 }} control={<Checkbox name="ShowText" checked={value.ShowText} onChange = {(e) => onChange(e, value)} />} label="Show text" />
                            <FormControlLabel style={{ margin: 14 }} control={<Checkbox name="Hide" checked={value.Hide} onChange = {(e) => onChange(e, value)} />} label="Hide" />
                            <FormControlLabel style={{ margin: 14 }} control={<Checkbox name="IsAuthenticated" checked={value.IsAuthenticated} onChange = {(e) => onChange(e, value)} />} label="IsAuthenticated" />
                            </div>
                            </Grid>
                            {/* Image preview */}
                            <Grid item xs={12} sm={6}>                                
                                <CardMedia style={{objectFit: "scale-down", lineHeight: "400px", textAlign: "center" }}
                                    component="img"
                                    alt={value.Caption}
                                    width="100%"
                                    image={value.FullPath}
                                    title={value.Caption}
                                />
                                <Typography variant="body2" display="block" align="center" gutterBottom >
                                    Image preview
                                </Typography>
                            </Grid>
                        </Grid>                                                                
                        
                        
                    </CardContent>
                </Collapse>               
                                                            
        </Card>    
        );
    }
  );
  
const SortableContainer = sortableContainer(({children}) => {
    return <div>{children}</div>;
  });

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

class Slider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.slider,
            openId: -1,
            preview: false,
        };    
    }
   
    setPreviw = (value) => {
        this.setState({preview: value});
    };

    handleOpenPreviw = () => {
        this.setPreviw(true);
      };
    
    handleClosePreviw = () => {
        this.setPreviw(false);
      };

    onSortEnd = ({oldIndex, newIndex}) => {                       
        this.setState(( {Items} ) => (
            {
            Items: arrayMove(Items, oldIndex, newIndex),        
            }
        ));        
    };

    onEdit = (value) => {        
        const Id = this.state.openId;
        if(Id === value.Id){
            this.setState({openId: -1});
        }else{
            this.setState({openId: value.Id});
        }                
        console.log("Edit value", value);
    };
    
    onChange = (e, value) => {
        console.log("onChange", e.target.name);    
        const items = [...this.state.Items];        
        // const idx = items.findIndex(item => item.Id === value.Id);
        // const item = items[idx];
        const item = items.find(item => item.Id === value.Id);
        if(e.target.type === "checkbox") {
            item[e.target.name] = e.target.checked;
        } else {
            item[e.target.name] = e.target.value;
            if(e.target.name === "FullPath"){
                item["Img"] = e.target.value.split("/").pop();
            }
        }
        this.setState({Items: items});
    }

    onDelete = (value) => { 
        console.log("Delete value", value);
        this.setState(( {Items} ) => ({
            Items: Items.filter(item => item.Id !== value.Id)
        }));
    };

    onAdd = () => {
        console.log("Add slide");
        const items = [...this.state.Items];        
        let maxId = 1;

        if(items.length > 0) {        
            const max = items.reduce(function(prev, current) {
                return (prev.Id > current.Id) ? prev : current
            })            
            maxId = max.Id + 1;
        }
                
        const date = new Date();
        const from = date.toISOString().slice(0,10);
        let _to = date;
        _to.setDate(date.getDate() + 21);
        const to = _to.toISOString().slice(0,10);

        items.push(
            {
                "Id": maxId,
                "Caption": `Slide # ${maxId}`,
                "Url": "/",
                "Text": "",
                "FullPath": "/assets/files/images/sliders/YOUR_IMG_FILENAME",
                "Path": "",
                "Img": "",
                "IsAuthenticated": false,
                "Hide": false,
                "ShowFrom": `${from}T00:00:00`,
                "ShowTo": `${to}T00:00:00`
             }
        );


        this.setState({Items: items});

    }

    onSave = async () =>  {
        console.log("Save slider");
        const userManager = await OidcUserManager();
        userManager.getUser().then(user => {
            let token = null;

            if (user != null) {
                token =  `Bearer ${user.access_token}`;                                                
                this.setState({isLoading: true});
                const slider = {
                    $type: this.state.$type,
                    Id: this.state.Id,
                    FileName: this.state.FileName,
                    Name: this.state.Name,
                    Items: this.state.Items,                    
                    // Json: JSON.stringify(this.state.Items)
                }

                console.log("Send to save slider", slider);

                const URL =  "/api/v1.0/slider";
                
                axios.post(URL, slider, { 
                    headers: { 
                        Authorization: token,
                        "Content-Type": "application/json"
                    } 
                })                
                .then(function(response){                                                                                                                                   
                    console.log("Saved slider", response.data); 
                })
                .catch((error) => {        
                    this.setState({isLoading: false});
                    console.log(error);
                });


            }
        });

    }

    render() {
        const { classes, t } = this.props; 
        const { Name, Items, openId, preview } = this.state;
        return (
            <React.Fragment>

            <Card className={classes.paper} >
                <CardHeader

                avatar={
                    <Avatar aria-label="slider" className={classes.avatarB}>
                        <BurstModeOutlinedIcon />
                    </Avatar>
                }
                action={
                    <React.Fragment>
                        <IconButton aria-label="preview" title="Slider preview" onClick={this.handleOpenPreviw} >
                            <PageviewOutlinedIcon />
                        </IconButton>
                        <IconButton aria-label="save" title="Save slider" onClick={this.onSave} >
                            <SaveIcon />
                        </IconButton>
                        <IconButton aria-label="add" title="Add slide" onClick={this.onAdd} >
                            <AddIcon />
                        </IconButton>
                  </React.Fragment>
                  }
                 title={ Name }
                 subheader="Slider"
                >

                </CardHeader>

                <CardContent>                                                
                    <SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
                        {Items.map((value, index) => (
                            <SortableItem key={`i-${index}`} index={index} value={value} expanded={openId === value.Id} 
                                onEdit={this.onEdit} onChange={this.onChange} onDelete={this.onDelete}
                            />
                        ))}
                    </SortableContainer>
                </CardContent>
                        
            </Card>
        
            <Dialog fullScreen open={preview} onClose={this.handleClosePreviw} aria-labelledby="form-dialog-title">
                <DialogTitle onClose={this.handleClosePreviw} id="dialog-preview-title"> {`Slider preview: ${Name}`}</DialogTitle>
                
                <DialogContent>
                    {/* <DialogContentText>
                        Slider preview
                    </DialogContentText> */}                
                <div>
                    <SliderPreview slider={ {Items: this.state.Items} }></SliderPreview>
                </div>
            
                </DialogContent>
                <DialogActions>
                <Button variant="contained" onClick={this.handleClosePreviw} color="primary">
                    {t("buttons.Close")}
                </Button>
                
                </DialogActions>
            </Dialog>

            </React.Fragment>
        );
    }
}


export default  withTranslation()(withWidth() (withStyles(styles)((Slider))));