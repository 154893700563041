
import React, { Component } from "react";
// import {withRouter} from "react-router-dom";
import { withCookies } from "react-cookie";
import { bindActionCreators } from "redux";
import { actionOrderCreators } from "../../store/Order";
import {withStyles} from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { connect } from "react-redux";
// import { InfiniteLoader, List, WindowScroller, AutoSizer } from "react-virtualized";

// import { InfiniteLoader, AutoSizer, CellMeasurer, CellMeasurerCache, createMasonryCellPositioner, Masonry }  from "react-virtualized";

import { FixedSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";


import Grid from "@material-ui/core/Grid";
// import { Alert } from "@material-ui/lab";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";


// import TabContext from "@material-ui/lab/TabContext";
// import TabList from "@material-ui/lab/TabList";
// import TabPanel from "@material-ui/lab/TabPanel";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
// import MUIDataTable from "mui-datatables";
import AddShoppingCard from "@material-ui/icons/AddShoppingCart";
// import Typography from "@material-ui/core/Typography";
import { withTranslation } from "react-i18next";
// import ProdPromoThumbXs from "../Order/ProdPromoThumbXs";
import ProdPromoThumb from "../Order/ProdPromoThumb";
import { Typography } from "@material-ui/core";

// import ProdPromoThumb from "../Order/ProdPromoThumb";

const ITEM_WIDTH = 200;
const ITEM_HEIGHT = 160;

const styles = theme => ({
    root: {
        flexGrow: 1,        
        padding: theme.spacing(1),
    },
    progress: {
        height: 2,
        position: "absolute",
        top: 0,
        left: 0,
        right:0,
      },  
      linearColorPrimary: {
        backgroundColor: "#abdcda",
      },
      linearBarColorPrimary: {
        backgroundColor: "#d20000",
      },
      promoTab: {
        padding: theme.spacing(1),
      },
      block: {
        padding: theme.spacing(1),
        margin: theme.spacing(1),
      },
      grid: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        justifyContent: "center",
      },
      gridItem: {
        // width: ITEM_WIDTH,
        padding: theme.spacing(1),
      },
      card: {
        height: "100%",
      },
      row: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
      }
});

function a11yProps(index) {
  return {
    id: `promo-tab-${index}`,
    "aria-controls": `promo-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabs-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (        
          <>
          {children}
          </>        
      )}
    </div>
  );
}
// function generateIndexesForRow(rowIndex, maxItemsPerRow, itemsAmount) {
//   const result = [];
//   const startIndex = rowIndex * maxItemsPerRow;

//   for (let i = startIndex; i < Math.min(startIndex + maxItemsPerRow, itemsAmount); i++) {
//     result.push(i);
//   }

//   return result;
// }

function getMaxItemsAmountPerRow(width) {
  return Math.max(Math.floor(width / ITEM_WIDTH), 1);
}

function getRowsAmount(width, itemsAmount, hasMore) {
  const maxItemsPerRow = getMaxItemsAmountPerRow(width);  
  const rows = Math.ceil(itemsAmount/ maxItemsPerRow) + (hasMore ? 1 : 0);  
  return rows;
}

const RowItem = React.memo(function RowItem({prodId, item, promo, classes}) {  
  return (
    <Grid item xs={12} sm={6} className={classes.gridItem} key={prodId}>
      <ProdPromoThumb promo={promo} item={item.Item} qty={1}></ProdPromoThumb> 
    </Grid>
  );
});



class DetailPromotion extends Component {
  constructor(props) {
      super(props);      
      const list = [];
      if(props.promotion){ 
          if(props.promotion.Conditions.length > 0){
            for (let i = 0; i < props.promotion.Conditions.length; i++) {
              const cond = props.promotion.Conditions[i];
              // list.push( {"type": "condition", "Name": cond.Name, "Description": cond.Description } );
              if(cond.Products.length > 0){
                for (let y = 0; y < cond.Products.length; y++) {
                  const item = cond.Products[y];
                  list.push( {"type": "item", "Item": item } );
                }
              }
            }            
          }                              
      } 
      
      
      this.state = {
        rowsSelected:[],
        ActionByCondition: null,
        indexPromoTab: 0,
        options: {
            filterType: "textField",            
            enableNestedDataAccess: ".",                        
            download: false,
            print: false,
            viewColumns: false,
            responsive: "vertical",            
            selectToolbarPlacement: "none",
            rowsSelected:[],
            onRowSelectionChange: (rowsSelected, allRows) => {              
              this.setState({ rowsSelected: allRows });          
            },
            customToolbar: () => {
              return (
                    <React.Fragment>                
                      <Tooltip title={"Add selected"}>
                        <IconButton onClick={this.handleAddSelectedProducts}>
                          <PlaylistAddIcon  />
                        </IconButton>
                      </Tooltip>
                      
                    </React.Fragment>
              );
            }
        },
        columns: this.getActionsColums(),
        list: list,
      };
      this.infiniteLoaderRef = React.createRef();
      this.infiniteLoaderRefOne = React.createRef();
  }

  createOrderPos = (data, promo) => {
      let price = data.Product.CatalogPrice;
      let promotionType = promo.PromotionType === 1 ? 2 : 0;
      
      switch (promo.PromotionType) {
        case 0:
          price = data.Product.CatalogPrice;
          break;
        case 1:
          price = data.Prices[0];        
          break;
        case 2:
          price = data.Prices[0];        
          break;
        default:
          price = data.Product.CatalogPrice;
          break;
      }
  
      const position = {
        ParentIdrref: data.Product.Idrref,
        ProductId: data.Product.Id,
        orderrowtype: promotionType,
        Name: data.Product.Name,
        LocalName: data.Product.Name,
        Price: price,
        CatalogPrice: data.Product.CatalogPrice,
        CatalogPricePv: data.Product.CatalogPricePv,
        Rate: data.Rate === 0 ? parseFloat(data.Product.CatalogPrice / data.Product.CatalogPricePv).toFixed(2) : data.Rate,
        Code: data.Product.Code,
        Image: data.Product.Image,
        Source: { 
          Id: promo.Id,
          Idrref: promo.Idrref,
          Name: promo.Name,
          Description: promo.Description
        }
      };
      return position;     
  }

  AddSigleToOrder = (data, qty) => {      
      if(parseInt(qty) === 0){
        return;
      }
      
      const pos = this.createOrderPos(data, this.props.promotion);              
      this.props.actions.addToOrder([ 
        { pos: pos, qty: parseInt(qty), orderrowtype: pos.orderrowtype } 
      ]);
  };

  getActionsColums = () => {
        const { classes } = this.props;  
        const columns =  [         
          { 
            name: "Product.Code",
            label: "Code"
          }, 
          { 
            name: "Product.Name",
            label: "Product name"
          },
          {
            name: "Product.CatalogPrice",
            label: "Price",
            options: {
              customBodyRender: (value) => (
                <span className="price--line-through">{parseFloat(value).toFixed(2)}</span>
              )
            }
          },        
          {
            name: "Prices",
            label: "My Price",
            filter: false,
            sort: false,
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => (              
                <span className={classes.colorRed}>{parseFloat(value[0]).toFixed(2)}</span>
              )
            } 
          },
          {           
            name: "Qty",
            label: "Qty",
            filter: false,
            options: {
              customBodyRender: (value, tableMeta, updateValue) => (            
                <TextField                  
                    type="number" margin="normal"
                    inputProps={{ min: 1, max: 1000  }}
                    value={value === 0 ? "" : value}
                    onChange={event => { 
                      updateValue(event.target.value);
  
                      const ActionByCondition = {...this.props.promotion.ActionByCondition };
                      const products = ActionByCondition.Products.slice();                    
                      products[tableMeta.rowIndex].Qty = parseInt(event.target.value);                    
                      ActionByCondition.Products = products;
                      this.setState({ActionByCondition: ActionByCondition});
                    }}
                  />              
              )
            }
          },
          {
            name: "Buy",
            options: {
              filter: false,
              sort: false,
              empty: true,
              // customBodyRender: (value, tableMeta, updateValue) => {
              customBodyRender: (value, tableMeta) => {
                return (
                  <Button                                                        
                      color="primary" variant="outlined" size="small"                   
                      onClick={ () =>  {
                        // console.log(tableMeta.rowData);
                        this.AddSigleToOrder(this.props.promotion.ActionByCondition.Products[tableMeta.rowIndex], tableMeta.rowData[4] );
                        // window.alert(`Clicked "Buy" for row ${tableMeta.rowIndex}`); 
                      }}
                      >
                      <AddShoppingCard />
                  </Button>
                );
              }
            }
          },
        ];
        return columns;
  };

  handleChangeIndexPromoTab = (event, newValue) => {
        this.setState({ indexPromoTab: newValue});
  };

loadMorePromoCondition = ( { startIndex, stopIndex } ) => {
    // const batchSize = stopIndex - startIndex;
    // const offset = stopIndex;
    // console.log("batchSize", batchSize, "offset", offset);

    return new Promise(resolve =>
      setTimeout(() => {
        // for (let index = startIndex; index <= stopIndex; index++) {
        //   // itemStatusMap[index] = LOADED;
        // }
        console.log(startIndex, stopIndex);
        resolve();
      }, 2500)
    );
};

isPromoConditionLoaded = ( { index } ) => {      
  return Boolean( this.state.list.length[ index ] );
};

    render() {
        const { classes, promotion, width, t } = this.props;
        const { indexPromoTab, list } = this.state;
        const hasMore = false;
        const _width =  width === "xs" ? (window.innerWidth - 8) : (0.08 * window.innerWidth);
        const _height = 0.8 * window.innerHeight;
        const rowCount = getRowsAmount(_width, list.length, hasMore);
        
        const rowRenderer = ({index, style}) => {
          const {classes} = this.props;
          const {list} = this.state;          
          const item = list[index];          
          return (
            <div style={style} className={classes.row}> 
            {/* <Grid spacing={2} container direction="row" justify={ width === "xs" ? "center": "flex-end"} > */}
              <RowItem promo={promotion} key={item.Item.Product.Id} item={item} prodId={item.Item.Product.Id} classes={classes}/>              
            {/* </Grid> */}                
          </div>
          )
        };

        return (
            <div>
                {
                    promotion && 
                    <Grid spacing={width === "xs" ? 0 : 2} container direction="row" >
                        <Grid item xs={12}>
                            <Typography variant="body2" >
                            {
                                promotion.PromotionType === 1 ? `[ ${promotion.AvailableQuantity} ] - ` : null
                            }
                                {promotion.Description}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} >
                        
                        <Tabs variant="fullWidth" value={indexPromoTab} onChange={this.handleChangeIndexPromoTab} aria-label="promotion conditions tabs">
                          { promotion.ActionByCondition.Products.length > 0 && 
                            <Tab wrapped label={` - ${t("order.PromotionalGoods")}`} icon={promotion.ActionByCondition.Products.length} {...a11yProps(0)} /> }
                          { (list.length > 0 &&  !promotion.ActionByCondition.ExcludeProducts) && 
                            <Tab wrapped label={` - ${t("order.TermsAction")}`} icon={list.length} {...a11yProps(1)} /> }
                        </Tabs>

                        { promotion.ActionByCondition.Products.length > 0 && 
                        <TabPanel id="act-tab-1" value={indexPromoTab} index={0} >
                          <Grid style={{paddingTop: "8px"}} container spacing={1}>
                          {
                            promotion.ActionByCondition.Products.map((item, index) => (
                              <Grid key={index} item xs={12} sm={6}>
                                <ProdPromoThumb item={item} promo={promotion} qty={promotion.ActionByCondition.ApplyToQty}></ProdPromoThumb>
                              </Grid>
                            ))
                          }

                          {/* { promotion.ActionByCondition.Products.length <= 12 ?
                              promotion.ActionByCondition.Products.map((item, index) => (
                                <Grid key={index} item xs={12} sm={6}>
                                  <ProdPromoThumb item={item} promo={promotion} qty={promotion.ActionByCondition.ApplyToQty}></ProdPromoThumb>
                                </Grid>
                            )) 
                            :
                            <div style={{display: "table", tableLayout:"fixed", width:"100%"}}>
                              <MUIDataTable
                                title=""
                                data={promotion.ActionByCondition.Products}
                                columns={columns}              
                                options={options}
                              />
                            </div>
                          } */}

                          </Grid>
                        </TabPanel>
                        }

                        { (list.length > 0 &&  !promotion.ActionByCondition.ExcludeProducts) &&
                        <TabPanel value={indexPromoTab} index={1}>
                          <InfiniteLoader ref={this.infiniteLoaderRef} itemCount={rowCount} isItemLoaded={this.isPromoConditionLoaded} loadMoreItems={this.loadMorePromoCondition} >
                            {({onItemsRendered, ref}) => (                              
                                  <List
                                    className={classes.grid}
                                    ref={ref}
                                    height={_height}
                                    width={"100%"}
                                    itemCount={rowCount}
                                    itemSize={ITEM_HEIGHT}
                                    onItemsRendered={onItemsRendered}
                                    noItemsRenderer={this.noItemsRenderer}
                                  >
                                  {rowRenderer}
                                  </List>
                            )}
                          </InfiniteLoader>
                        </TabPanel>
                        }

                        {/* <Tabs variant="fullWidth" value={indexPromoTab} onChange={this.handleChangeIndexPromoTab} aria-label="promotions prod tabs" >
                          { promotion.ActionByCondition.Products.length > 0 && <Tab wrapped label={` - ${t("order.PromotionalGoods")}`} icon={promotion.ActionByCondition.Products.length} value="0" /> } 
                          { list.length > 0 && <Tab wrapped label={` - ${t("order.TermsAction")}`} icon={list.length} value="1" />}
                        </Tabs>
                        
                        <TabPanel value={indexPromoTab} index={0}>
                          Item One
                        </TabPanel>
                        <TabPanel value={indexPromoTab} index={1}>
                          Item Two
                        </TabPanel> */}
                        
                        {/*                         
                        { promotion.ActionByCondition.Products.length > 0 && 
                          <TabPanel className={classes.promoTab} value="0" index={0}>
                              <Grid style={{paddingTop: "24px"}} container spacing={2}>
                                  { promotion.ActionByCondition.Products.length <= 12 ?
                                    promotion.ActionByCondition.Products.map((item, index) => (
                                        <Grid key={index} item xs={12} sm={6}>
                                          <ProdPromoThumb item={item} promo={promotion} qty={promotion.ActionByCondition.ApplyToQty}></ProdPromoThumb>
                                        </Grid>
                                    )) :
                                      <div style={{display: "table", tableLayout:"fixed", width:"100%"}}>
                                        <MUIDataTable
                                          title=""
                                          data={promotion.ActionByCondition.Products}
                                          columns={columns}              
                                          options={options}
                                        />
                                      </div>                            
                                  }
                              </Grid>
                          </TabPanel>
                        }                        
                        { list.length > 0 && 
                          <TabPanel className={classes.promoTab} value="1" index={1}> 
                                <InfiniteLoader
                                  ref={infiniteLoaderRef}
                                  itemCount={rowCount}
                                  isItemLoaded={this.isPromoConditionLoaded}
                                  loadMoreItems={this.loadMorePromoCondition}
                                >
                                  {({onItemsRendered, ref}) => (
                                    
                                        <List
                                          className={classes.grid}
                                          ref={ref}
                                          height={_height}
                                          width={"100%"}
                                          itemCount={rowCount}
                                          itemSize={ITEM_HEIGHT}
                                          onItemsRendered={onItemsRendered}
                                          noItemsRenderer={this.noItemsRenderer}
                                        >
                                        {rowRenderer}
                                        </List>
                                  )}
                                </InfiniteLoader>
                            </TabPanel>    
                        }
 */}

                          {/* <TabContext value={indexPromoTab}>
                            <TabList onChange={this.handleChangeIndexPromoTab} variant="fullWidth" aria-label="simple tabs example">
                                <Tab wrapped label={` - ${t("order.PromotionalGoods")}`} icon={promotion.ActionByCondition.Products.length} value="1" />
                                <Tab wrapped label={` - ${t("order.TermsAction")}`} icon={list.length} value="2" />
                          </TabList>
                            
                          </TabContext> */}
                        </Grid>
                    </Grid>    
                }
            </div>
        );
    }
}

export default  withTranslation()(withWidth() (withStyles(styles)(
  connect(    
    state => state.orderRequest,
    dispatch => {
      return {
        actions: bindActionCreators(Object.assign({}, actionOrderCreators ), dispatch)
      }    }
  )(withCookies(DetailPromotion)))
));
