import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import MoreVert from "@material-ui/icons/MoreVert";
import Paper from "@material-ui/core/Paper";
import CreateNewFolder from "@material-ui/icons/CreateNewFolderOutlined";
import CloudUpload from "@material-ui/icons/CloudUploadOutlined";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
// import InputBase from "@material-ui/core/InputBase";
import ListItemIcon from "@material-ui/core/ListItemIcon";
// import FileCopyIcon from "@material-ui/icons/FileCopy";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import ClearIcon from "@material-ui/icons/Clear";
import EditIcon from "@material-ui/icons/Edit";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import FolderOutlinedIcon from "@material-ui/icons/FolderOutlined";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import gray from "@material-ui/core/colors/grey";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";

import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import SyncIcon from "@material-ui/icons/Sync";

// import SortableTree, { toggleExpandedForAll } from 'react-sortable-tree';
// import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer'

import { bindActionCreators } from "redux";
import { actionCreators } from "../../store/FilesBuilder";
import { connect } from "react-redux";

// import { FilePond, registerPlugin} from 'react-filepond';
import { FilePond} from "react-filepond";
import "filepond/dist/filepond.min.css";

// import FilePondPluginFileMetadata from 'filepond-plugin-file-metadata';
// import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
// import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
// import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

// // Register the plugins
// registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
// registerPlugin(FilePondPluginFileMetadata);

const styles = theme => ({
  root: {        
    margin: theme.spacing(1),
    paddingTop: theme.spacing(1),
    minHeight: 600,
  },
  iconButton: {
    padding: 10,
  },
  preview: {
    width: "100%",
    height: "auto"
  },
  button: {
    margin: 0,
    padding: 0,
  },
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  input: {    
    backgroundColor: "#eee",
    padding: "2px 15px",
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },

  crumbs :{
    marginRight: theme.spacing(1)*2,
    fontSize: 24,    
  },

  colorGray: {
    backgroundColor: gray[200],
  },

});

const ITEM_HEIGHT = 38;

const extractFileExt = (path) => {
  const pathArray = path.split(".");
  const lastIndex = pathArray.length - 1;
  return pathArray[lastIndex];
};

const allowPreview = (row) => {
  if(row.isDirectory)
    return false;
  
  const ext = extractFileExt(row.title);
  if( ext === "jpg" || ext === "jpeg" || ext === "png" || ext === "gif" || ext ==="webp")
    return true;

  return false;
}

const DialogTitle = withStyles(theme => ({
  content: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(1) * 2,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.content}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class Files extends Component {
  constructor(props) {
    super(props);

    this.state = {
      path: this.props.match.params.path || "",
      searchString: "",
      searchFocusIndex: 0,
      searchFoundCount: null,
      anchorEl: null,
      openFolder: false,
      openDelFolder: false,
      openUpload: false,
      fullWidth: true,
      maxWidth: "sm", 
      folderName: "",
      isNewFolder: true,
      selectedPath: "",
      acceptedFileTypes: ["jpg", "jpeg", "png", "gif", "webp", "pdf", "doc", "docx", ".xls", ".xlsx", "txt"],// ["image/*", "audio/*", "video/*"],
      resize: true,
      thumb: true,
      openDlgPreview: false,
      selectedImg: undefined,
    };
    this.props.requestFiles(this.props.match.params.path || ""); 
}

// This method runs when incoming props (e.g., route params) change
  // componentWillReceiveProps(nextProps) {        
  //   this.setState(
  //       { path: nextProps.match.params.path || "" }
  //   );
  //   this.props.requestFiles(nextProps.match.params.path); 
  // };

  handleNewFolder = () =>{
    this.setState({folderName: "", openFolder: true, isNewFolder: true});
  };

  handleCopyPath = () => {
    this.setState({anchorEl : null});    
    navigator.clipboard.writeText(`/assets/files/${this.state.selectedPath}`);    
  };

  handleEdit = () => {
    this.setState({anchorEl : null});
    const folers = this.state.selectedPath.split("/");
    let folderName = "";
    if(folers.length > 0){
      folderName = folers[folers.length-1]; 
    }    
    this.setState({folderName: folderName, openFolder: true, isNewFolder: false});
  };

  handleDelete = () => {    
    const folers = this.state.selectedPath.split("/");
    let folderName = "";
    if(folers.length > 0){
      folderName = folers[folers.length-1]; 
    } 
    console.log(this.state.selectedPath, folderName); 
    this.setState({anchorEl : null, folderName: folderName, openDelFolder: true});
  };
  
  handleClickDelete = () => {
    this.setState({openDelFolder: false});
    const path = this.props.path.length === 0 ? this.state.folderName : `${this.props.path}/${this.state.folderName}`;      
    this.props.requestDelFolder(path);   
  };

  handleSaveFolder = () => {  
    this.setState({openFolder: false});

    if(this.state.isNewFolder){
      const path = this.props.path.length === 0 ? this.state.folderName : `${this.props.path}/${this.state.folderName}`;      
      this.props.requestAddFolder(path);   
    }
    else
    {      
      this.props.requestRename(this.state.selectedPath, this.state.folderName); 
    }    
  }

  handleOpenDlgPreview = opened => {     
    this.setState({openDlgPreview: opened});
  };

  handlePreview = (row) => {    
      this.setState({openDlgPreview: true, selectedImg: row});  
  };

  handleOpenDlgDelFolder = opened => {     
    this.setState({openDelFolder: opened});
  };

  handleOpenDlgFolder = opened => {     
    this.setState({openFolder: opened});
  };

  handleOpenDlgUpload = opened => {     
    this.setState({openUpload: opened});
  };

  handleOpenMenu = (event, path) => {    
    this.setState({ anchorEl: event.currentTarget, selectedPath: path });
  }

  handleCloseMenu = () => {
    this.setState({anchorEl : null});
  }

  handleChange = prop => event => {      
    this.setState({ [prop]: event.target.value });
  };
  
  loadFiles = path => {
    this.props.requestFiles(path); 
  };

  handleInit =() => {       
    this.pond.props.server.process.headers = {
      ...this.pond.props.server.process.headers,
      "Authorization":this.props.token,
    };        
    // console.log('FilePond instance has initialised',  this.pond);    
  }

  handleChangeResize = () => {
    const {resize} = this.state;
    this.setState({resize: !resize});
  };
  
  handelRefresh = () => {
    const { fullPath} = this.props;
    
    if(fullPath && fullPath.length > 0){
        const path = fullPath[fullPath.length-1].Path;
        console.log(path);
        
        this.loadFiles(path);
    }
  };

  render() {
    const { anchorEl, openDlgPreview, selectedImg, resize, thumb} = this.state;    
    const openMenu = Boolean(anchorEl);
    const { classes } = this.props;
    const rootImg = "/assets/files/";
    // console.log("token", this.props.token);

    return (
      <React.Fragment>
      <Grid container direction="row">
        <Grid item xs={12}>
          <Paper className={classes.root}>
            <Grid container direction="row" spacing={2}>          
              <Grid item>
                <form          
                  style={{ display: "inline-block" }}
                  onSubmit={event => { event.preventDefault();  }}
                >
                  <IconButton className={classes.iconButton} onClick={this.handleNewFolder}>
                    <CreateNewFolder />
                  </IconButton>
                  <IconButton className={classes.iconButton} onClick={this.handleOpenDlgUpload.bind(this, true)}>
                    <CloudUpload />
                  </IconButton>

                  <IconButton className={classes.iconButton} onClick={this.handelRefresh}>
                    <SyncIcon />
                  </IconButton>
                </form>                
              </Grid>              
              <Grid item >
                <Breadcrumbs aria-label="breadcrumb" style={{padding: "8px 8px"}}>
                  {this.props.fullPath.map((link) => (
                    <Link key={link.Path} color="inherit" onClick={() => {this.loadFiles(link.Path)}} >
                      {link.title}
                    </Link>
                  ))}
                </Breadcrumbs>        
              </Grid>
            </Grid>                        
          <TableContainer >
            <Table style={{width: "100%"}} >        
              <TableHead>
                <TableRow hover>
                  <TableCell style={{width:"28px"}}></TableCell> 
                  <TableCell style={{width:"28px"}}></TableCell>
                  <TableCell style={{width:"auto"}}>Name</TableCell>            
                  <TableCell style={{width:"100%"}}>Relative path</TableCell>
                  <TableCell></TableCell> 
                </TableRow>
              </TableHead>
          <TableBody>
            { this.props.treeFiles.map((row, index) => (
              <TableRow key={index} hover>
                <TableCell component="th">
                  <IconButton className={classes.button} onClick={(event) => this.handleOpenMenu(event, row.Path)} >
                    <MoreVert />
                  </IconButton>
                </TableCell>
                <TableCell component="th" >
                  {row.isDirectory ? <FolderOutlinedIcon /> : <InsertDriveFileOutlinedIcon />}
                </TableCell>
                <TableCell scope="row">
                  {
                    row.isDirectory ? 
                    <Link                     
                      variant="body1"
                      onClick={() => {this.loadFiles(row.Path)}}
                    >
                      <Typography noWrap variant="subtitle1">{row.title}</Typography>
                    </Link> : 
                    <Typography noWrap variant="subtitle1">{row.title}</Typography>
                  }
                </TableCell>
                <TableCell>
                  {
                    !row.isDirectory ?                     
                      <Typography>
                        {`/assets/files/${row.Path}`}
                        <IconButton className={classes.margin} size="small" aria-label="copy" onClick={() => {navigator.clipboard.writeText(`/assets/files/${row.Path}`)}}>
                          <FileCopyOutlinedIcon fontSize="inherit" />
                        </IconButton>                      
                          
                        </Typography>
                    : null
                  }                
                </TableCell>
                <TableCell>                  
                  { allowPreview(row) ? <IconButton aria-label="preview" onClick={this.handlePreview.bind(this, row)}><VisibilityOutlinedIcon  /> </IconButton> : null}
                </TableCell>
              
              </TableRow>
            ))}
          </TableBody>            
        </Table>
        </TableContainer>
        
        </Paper>
        </Grid>
      </Grid>

      <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={openMenu}
          onClose={this.handleCloseMenu}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: 200,
            },
          }}
        >
          <MenuItem onClick={this.handleEdit}>
          <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
              <Typography variant="inherit" noWrap>
                Rename
              </Typography>
          </MenuItem>
          <MenuItem onClick={this.handleDelete}>
            <ListItemIcon>
              <ClearIcon fontSize="small" />
            </ListItemIcon>
              <Typography variant="inherit" noWrap>
                Delete
              </Typography>
          </MenuItem>
          <Divider />
          <MenuItem onClick={this.handleCopyPath}>
            <ListItemIcon>
              <FileCopyOutlinedIcon fontSize="small" />
            </ListItemIcon>
              <Typography variant="inherit" noWrap>
                Copy path
              </Typography>
            </MenuItem>
        </Menu>    
      
      <Dialog
          fullWidth={this.state.fullWidth}
          maxWidth="md"
          open={openDlgPreview} onClose={this.handleOpenDlgPreview.bind(this, false)} 
          aria-labelledby="form-dialog-title">
        <DialogTitle id="dlg-preview" onClose={this.handleOpenDlgPreview.bind(this, false)}>
          Preview: { selectedImg ? `${rootImg}${selectedImg.Path}`: null }
        </DialogTitle>
        <DialogContent>
          { selectedImg && 
            <img alt={selectedImg.Path} className={classes.preview} src={`${rootImg}${selectedImg.Path}`} />
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleOpenDlgPreview.bind(this, false)} >
              Cancel
          </Button>                
        </DialogActions>
        </Dialog>      

      <Dialog
        fullWidth={this.state.fullWidth}
        maxWidth={this.state.maxWidth}
        open={this.state.openFolder} onClose={this.handleOpenDlgFolder.bind(this, false)} 
        aria-labelledby="form-dialog-title">
        <DialogTitle id="dlg-folder-title" onClose={this.handleOpenDlgFolder.bind(this, false)}>
          Folder
        </DialogTitle>
        <DialogContent>
        <DialogContentText> 
            {this.state.temptxt}
        </DialogContentText>        
        <TextField
            autoFocus
            margin="dense"                        
            name="folderName"
            label="Folder name"
            type="text"
            fullWidth
            value={this.state.folderName}
            onChange={this.handleChange("folderName")}
        />                                                                                                                                
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleOpenDlgFolder.bind(this, false)} >
              Cancel
          </Button>        
          <Button onClick={this.handleSaveFolder.bind(this)} >
              Save
          </Button>
        </DialogActions>
        </Dialog>      

      <Dialog
        fullWidth={this.state.fullWidth}
        maxWidth={this.state.maxWidth}
        open={this.state.openDelFolder} onClose={this.handleOpenDlgDelFolder.bind(this, false)} 
        aria-labelledby="form-dialog-title">
        <DialogTitle id="dlg-delfolder-title" onClose={this.handleOpenDlgDelFolder.bind(this, false)}>
          Delete folder: {this.state.folderName} ?
        </DialogTitle>
        <DialogContent>
        <DialogContentText> 
            {this.state.temptxt}
        </DialogContentText>        
        </DialogContent>
        <DialogActions>
        <Button onClick={this.handleOpenDlgDelFolder.bind(this, false)} >
            Cancel
        </Button>        
        <Button onClick={this.handleClickDelete}  >
            Yes
        </Button>

        </DialogActions>
      </Dialog>      

      <Dialog
        fullWidth={this.state.fullWidth}
        maxWidth={this.state.maxWidth}
        open={this.state.openUpload} onClose={this.handleOpenDlgUpload.bind(this, false)} 
        aria-labelledby="form-dialog-title">
        <DialogTitle id="dlg-upload-title" onClose={this.handleOpenDlgUpload.bind(this, false)}>
          Upload
        </DialogTitle>
        <DialogContent> 
          <FilePond ref={ref => this.pond = ref}
            files={this.state.files}
            allowMultiple={true}
            allowFileSizeValidation={true}
            // oninit={() => this.handleInit() } 
            acceptedFileTypes={this.state.acceptedFileTypes}
            server=
            {{                
                process: {
                  url: `/api/v1/files/upload?path=${this.props.path}`,
                  headers: {
                    "Authorization": this.props.token
                  },
                  ondata: (formData) => {
                    formData.append("thumb", thumb);
                    formData.append("resize", resize);
                    return formData;
                  },
                }
            }}                    
          >
          </FilePond>       
          <FormGroup row>
              <FormControlLabel
                control={<Checkbox checked={resize} onChange={this.handleChangeResize} name="resize" />}
                label="Create thumbnails for image files"
          /> 
            </FormGroup>
        </DialogContent>
        <DialogActions>
        <Button onClick={this.handleOpenDlgUpload.bind(this, false)} >
            Close
        </Button>        
        {/* <Button onClick={this.handleOpenDlgUpload}  >
            Upload
        </Button> */}

        </DialogActions>
      </Dialog>      
                  
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(connect(    
  state => state.filesBuilder,
  dispatch => bindActionCreators(actionCreators, dispatch)
)(Files));