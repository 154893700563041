import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { actionOrdersCreators } from "../../store/Orders";
import { actionOrderCreators } from "../../store/Order";
import LinearProgress from "@material-ui/core/LinearProgress";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/uk";
import grey from "@material-ui/core/colors/grey";
import red from "@material-ui/core/colors/red";
import MUIDataTable from "mui-datatables";
import CreateIcon from "@material-ui/icons/Create";
import SettingsEthernetIcon from "@material-ui/icons/SettingsEthernet";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import SearchIcon from "@material-ui/icons/Search";
import RefreshIcon from "@material-ui/icons/Refresh";
import { withTranslation } from "react-i18next";

const styles = theme => ({ 
    root: {
        flexGrow: 1,      
        padding: theme.spacing(1),
    },
    progress: {
        height: 2,
        position: "absolute",
        top: 0,
        left: 0,
        right:0,
      },  
    linearColorPrimary: {
        backgroundColor: "#abdcda",
      },
    linearBarColorPrimary: {
        backgroundColor: "#d20000",
      },
    paper:{
        padding: theme.spacing(1) * 2,
    },
    row: {
        fontSize: ".9em",
        borderBottom: `1px solid ${theme.palette.divider}`,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },    
    header: {
        color: grey[800],
        fontSize: "1.1em",   
        borderBottom: `1px solid ${theme.palette.divider}`,        
    },
    prices: {
        textAlign: "right",
    },
    center: {
        textAlign: "center",
    },
    payed: {
        color: theme.palette.success.main,
    },
    state: {
        paddingTop: 0,
        paddingBottom: 0,
        textTransform: "capitalize",                
    },
    st10: {
        paddingTop: 0,
        paddingBottom: 0,
        textTransform: "capitalize",        
    },
    st150: {
        color: theme.palette.success.main,
        paddingTop: 0,
        paddingBottom: 0,
        textTransform: "capitalize",        
    },
    discount: {
        color: red[800],
    },
    toolbar: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        // width: 400,
      },
      input: {
        marginLeft: theme.spacing(2),
        flex: 1,
      },
      start: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),        
      },
      end: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      iconButton: {
        padding: 10,
      },
      divider: {
        height: 28,
        margin: 4,
      },
});

// function GetIconState(state) {
//     switch (state) {
//         case 10:
//             return <CreateIcon />;
//         case 150:            
//             return <Checkbox />;
//         case 30:
//             return <DeleteOutlineIcon />;
//         default:
//             return <AccessTime />;
//     }
// };

// function GetColorState(state) {
//     switch (state) {
//         case 10:
//             return "primary";
//         case 150:
//             return "primary";
//         default:
//             return "default";
//     }
// };



class AllOrders extends React.Component { 
    constructor(props) {        
        super(props);
        
        this.state = {
            open: false,
            locale: "ru",
            sortDirection: "",
            sortColumm: "",
            tableStatePersist: { //Dynamic collection of props that are needed between table refreshes.
                searchText: "",
                filterList: [],
                columns: []
            },
        };

        moment.locale("ru");
        
        this.props.actions.requestClientsOrders({ 
            pi: parseInt(this.props.match.params.pi, 10) || 0, 
            ps: parseInt(this.props.match.params.ps, 10) || 10, 
            sortDirection: "", 
            sortColumn: ""});
    }

    editOrder = (id) => {
        console.info(`Edit order id=${id}`);
        // this.props.actions.requestOrder(id);
        this.props.history.push(`/order/${id}`);
    };

    handleChangeTabIndex = (event, value) => {
        this.setState({tabIndex: value});
    };

    GetClassState = (state) =>{    
        const { classes} = this.props;            
        switch (state) {
            case 10:
                return classes.st10;
            case 150:
                return classes.st150;
            default:
                return classes.state;
        }
    }
    
    getData = (query) => {                                
        this.setState({sortColumn: query.sortColumn, sortDirection: query.sortDirection });        
        console.log("Query", query);        
        this.props.actions.requestClientsOrders(query);            
    };

    //Return all columns, their props, and any current state-related changes
    getColumns = () => {
        const { classes, t } = this.props;
        //Define all of the alert table's columns and their default props and options as per the mui-datatables documentation
        let columns = [
            { 
                name: "StateName",
                label: "State",
                options: {
                    filter: false,
                    sort: false
                }    
            },
            {
                name: "CreatedOn",
                label: "Date",
                options: {
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <span>
                            {moment(value).format("DD-MM-YYYY")}<br />
                            <small>{moment(value).format("kk:mm:ss")}</small>
                        </span>                            
                    );                                            
                    }
                }
            },
            {
                name: "DocNumber",
                label: "DocNumber",
                options: {
                    sort: true
                }
            },
            {
                name: "RegNumber",
                label: "Reg",
                options: {
                    sort: true
                }
            },
            {
                name: "ClientName",
                label: "Customer",
                options: {
                    sort: true
                }
            },            
            {
                name: "Amount",
                label: "Amount",
                options: {
                    sort: true
                }
            },
            {
                name: "AmountOB",
                label: "AmountOB",
                options: {
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {                            
                      return (
                        value  > 0 ? parseFloat(value).toFixed(2) : null
                      );
                    }
                }
            },
            {
                name: "ConsultantAmmount",
                label: "Deferred",
                options: {
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {                            
                      return (
                        value  > 0 ? parseFloat(value).toFixed(2) : null
                      );
                    }
                }
            },
            {
                name: "Paid",
                label: "Payed",
                options: {
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {                            
                      return (
                      value === true ?  <span className={classes.payed}>{t("orders.Payed")}</span> : null
                      );
                    }
                }
            },
            {
                name: "BarCode",
                label: "TTN",
                options: {
                    sort: true
                }
            },
            {
              name: "Action",
              options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                  return (
                    <div className={classes.actWidth} >          
                      <IconButton                                                             
                          onClick={ () => {                                
                            window.alert(`Clicked "Del" for row ${tableMeta.rowIndex}`); 
                          }}
                      >
                        <CreateIcon />
                      </IconButton>
                    </div>
                  );
                }
              }
            },
        ]
          
        //Loop thru columns and assign all column-specific settings that need to persist thru a data refresh
        for(let i = 0; i < columns.length; i++) {     
        //     //Assign the filter list to persist
        //     columns[i].options.filterList = this.state.tableStatePersist.filterList[i];
            
            if(this.state.tableStatePersist.columns[i] !== undefined) {
            
                //If 'display' has a value in tableStatePersist, assign that, or else leave it alone            
                if(this.state.tableStatePersist.columns[i].hasOwnProperty("display"))
                    columns[i].options.display = this.state.tableStatePersist.columns[i].display;
                        
                //If 'sortDirection' has a value in tableStatePersist, assign that, or else leave it alone                                                
                if(this.state.tableStatePersist.columns[i].hasOwnProperty("sortDirection")) {
                //The sortDirection prop only permits sortDirection for one column at a time
                    if(this.state.tableStatePersist.columns[i].sortDirection !== "none"){
                        columns[i].options.sortDirection = this.state.tableStatePersist.columns[i].sortDirection;
                    }                    
                }
            }

        }
    
        return columns;
    }

    render() {
        const { classes, isLoading, OrdersClients, t, c_pi, c_count } = this.props;
        const { locale } = this.state;
        
        const options = {
            filterType: "textField",
            filter: false,
            search: false,
            download: false,
            print: false,
            viewColumns: false,
            responsive: "scroll",
            selectableRows:false,
            disableToolbarSelect: true,
            serverSide: true,
            count: c_count,
            page: c_pi,
            // onSearchChange: (searchText) => {
            //     console.log("searchText", searchText);
            //     if(searchText.length > 3){
            //         this.getData(1, this.props.c_ps, null, searchText);
            //     }
            // },
            onTableChange: (action, tableState) => {
                console.log("action",action);
                console.log("tableState", tableState);
                                
                if(action !== "propsUpdate") {
                    this.setState({
                      tableStatePersist: {
                        searchText: tableState.searchText,
                        filterList: tableState.filterList,
                        columns: tableState.columns 
                      },
                    });
                  }                                    
                  
                switch (action) {
                    case "changePage":
                        this.getData({ pi: tableState.page, ps: tableState.rowsPerPage, sortDirection: this.state.sortDirection, sortColumn: this.state.sortColumn});
                        break;
                    case "changeRowsPerPage":
                        this.getData({pi: 1, ps: tableState.rowsPerPage, sortDirection: this.state.sortDirection, sortColumn: this.state.sortColumn});
                        break;
                    case "sort":                        
                        this.getData( {pi: 1, ps: tableState.rowsPerPage, sortDirection: tableState.columns[tableState.activeColumn].sortDirection, sortColumn: tableState.columns[tableState.activeColumn].name});
                        break; 
                    default:
                        break;       
                    // case "search":
                    //     // console.log("search", tableState.searchText);
                    //     this.getData(1, tableState.rowsPerPage, null, tableState.searchText);                       
                    //     break;
                }

            }
        };

        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>            
            <div className={classes.root}>
                { isLoading && 
                    <LinearProgress className={classes.progress} 
                    classes={{
                        colorPrimary: classes.linearColorPrimary,
                        barColorPrimary: classes.linearBarColorPrimary,
                    }}
                />
                }
                
                <Grid container spacing={1}>
                    <Grid item xs={12} lg={3} >
                        <Paper component="form" className={classes.toolbar}>
                            <InputBase
                                className={classes.input}
                                placeholder="Search orders"
                                inputProps={{ "aria-label": "search orders" }}
                            />
                            <Divider className={classes.divider} orientation="vertical" />
                            <IconButton className={classes.iconButton} aria-label="search">
                                <SearchIcon />
                            </IconButton>
                            
                        </Paper>
                    </Grid>
                    <Grid item xs={12} lg={9} >
                        <Paper component="form" className={classes.toolbar}>                            
                            <KeyboardDatePicker className={classes.start}
                                name="Start"                                
                                KeyboardButtonProps={{ "aria-label": "start perion" }}
                            />
                                                        
                            <SettingsEthernetIcon />
                            
                            <KeyboardDatePicker className={classes.end}
                                name="End"                                
                                KeyboardButtonProps={{ "aria-label": "end perion" }}
                            />
                            <Divider className={classes.divider} orientation="vertical" />
                            <IconButton className={classes.iconButton} aria-label="refresh">
                                <RefreshIcon />
                            </IconButton>
                        </Paper>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>                 
                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={1}> 
                            <Typography className={classes.header} variant="button" display="block" align="center" gutterBottom>
                                Full: {t("orders.JournalOrders")}
                            </Typography>

                            <div style={{display: "table", tableLayout:"fixed", width:"100%"}}>
                                <MUIDataTable title="" data={OrdersClients} columns={this.getColumns()} options={options} />
                            </div>
                        </Paper>                          
                    </Grid>
                </Grid>
            </div>
            </MuiPickersUtilsProvider>
        );
    }
}

export default  withTranslation()(withWidth()(withStyles(styles)(
    connect(    
        state => state.ordersRequest,
        dispatch => {
            return {
               actions: bindActionCreators(Object.assign({}, actionOrdersCreators, actionOrderCreators ), dispatch)
        }    }
    )(AllOrders)
)));
