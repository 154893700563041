/* /src/components/auth/callback.jsx */
import React from "react";
import { AuthConsumer } from "../../providers/authProvider";

export const Signin = () => (
    <AuthConsumer>
        {({ signinRedirect }) => {
            signinRedirect();
            return <p>Завантаження...</p>;
        }}
    </AuthConsumer>
);