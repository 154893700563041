import React from "react";
import {withRouter} from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionOrderCreators } from "../../store/Order";
import { connect } from "react-redux";
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";
// import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import withWidth from "@material-ui/core/withWidth";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import AccessTime from "@material-ui/icons/AccessTime";
import Radio from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";

// import { fade } from "@material-ui/core/styles/colorManipulator";
import { withTranslation } from "react-i18next";

// import red from "@material-ui/core/colors/red";
import pink from "@material-ui/core/colors/pink";
import blue from "@material-ui/core/colors/blue";
import gray from "@material-ui/core/colors/grey";
import CircularProgress from "@material-ui/core/CircularProgress";
import LazyLoad from "react-lazyload";

// import TextField from "@material-ui/core/TextField";
// import MenuItem from "@material-ui/core/MenuItem";

import Chip from "@material-ui/core/Chip";
import Rating from "@material-ui/lab/Rating";

// import Brightness1Icon from "@material-ui/icons/Brightness1";
// import ListItemIcon from "@material-ui/core/ListItemIcon";

import LensIcon from "@material-ui/icons/Lens";
// import Fab from "@material-ui/core/Fab";
import ShoppingBasketOutlinedIcon from "@material-ui/icons/ShoppingBasketOutlined";
// import { getThemeProps } from "@material-ui/styles";
// import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";

const styles = theme => ({
    card: {
        height: 555,
        [theme.breakpoints.down("sm")]: {
            height: 430,
        },
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",        
        boxShadow: "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px"    
    },
    cardcontent: {
        padding: theme.spacing(1*0.5, 1),
        height: 300,
        [theme.breakpoints.down("sm")]: {
            height: 304,
        },
    },

    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    propsradio: {
        padding: theme.spacing(1),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1 / 4),
        },        
    },

    propsbutton: {        
        margin: 2,
        padding: (0, 2),
        // width: theme.spacing(3),
        minWidth: theme.spacing(3),
        height: theme.spacing(3),
        // maxWidth: theme.spacing(3),        
    },
    propslabel: {                
        fontSize: ".7em",
        width: theme.spacing(4),
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    },


    specialprice: {        
        position: "absolute",        
        top: 0,        
        color: theme.palette.secondary.main,
        fontSize: 12,
        backgroundColor: gray[50],
        height: "24px",
        lineHeight: "24px",
        padding: theme.spacing(0, 1),
        borderRadius: "6px 16px 16px 0",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor:theme.palette.secondary.main,
        // transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",        
        // boxShadow: "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
        zIndex: 1100
    },
    priceBB: {
        position: "absolute",   
        top: 0,                
        right: 0,
        fontSize: 12,        
        backgroundColor: gray[50],
        color: gray[700],
        padding: theme.spacing(0, 1),        
        height: "24px",
        lineHeight: "24px",        
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: gray[200],
        borderRadius: "16px 6px 0 16px", 
        // borderRadius: "50%",
        textAlign: "center",
        // transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",        
        // boxShadow: "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px", 
        zIndex: 1,
    },
    buyblock: {
        
    },
    specialTag: {
        position: "absolute",                
        bottom: theme.spacing(-1),                
        right: theme.spacing(1),        
        // height: "24px",
        // lineHeight: "24px",        
        zIndex: 1100,
    },
    options: {
        minHeight: theme.spacing(5),
        height: theme.spacing(5),
        [theme.breakpoints.down("sm")]: {
            height: "auto",           
        }, 

    },
    burst: {
        width: 60,
        padding: theme.spacing(1),
        position: "absolute",
        top: 45,
        right: 35,  
    },
    specialcomment: {
        padding: theme.spacing(1),
        position: "absolute",
        top: 10,
        left: 5,
        fontWeight: "bold",
        fontSize: "1.3rem",
        color: "#fff"
    },
    
    select: {
        borderRadius: "24px",
        padding: 0
    },
    selPadding: {
        
            paddingTop: 6,
            paddingBottom: 2
        
    },
    listIcon: {
        minWidth: "28px", 
        minHeight: "26px", 
        verticalAlign: "middle"
    }, 
        
    media: {
        height: 300,
        [theme.breakpoints.down("sm")]: {
            height: 183,
        },
        // backgroundSize: "contain",    
        // backgroundColor: fade(theme.palette.common.black, 0.03),    
        // "&:hover": {
        //   backgroundColor: fade(theme.palette.common.black, 0.04),
        // },
    },
    grayscale: {
        // // webkitFilter: "grayscale(100%)",
        // // filter: "grayscale(100%)",
        
        webkitFilter: "opacity(30%) grayscale(100%)",
        filter: "opacity(30%) grayscale(100%)",
        "&:hover": {
            webkitFilter: "none",
            filter: "none",
          },
    },
    seria: {
        color: gray[500],        
    },    
    name: {
        height: 66,         
        textOverflow: "clip",
        overflow: "hidden",        
        [theme.breakpoints.down("sm")]: {            
            height: 70,
            fontSize: "1em",
          },        
    },
    actions: {
        // position: "absolute",
        // left: 0,
        // right: 0,
        bottom: 0,
      },
    action4:{
        backgroundColor: theme.palette.secondary.main,
    },
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
          duration: theme.transitions.duration.shortest,
        }),
    },
    buy: {
        padding: theme.spacing(4),
        width: "40% !important",
        // marginLeft: "auto",            
        // margin: theme.spacing(1),
    },
    
    expandOpen: {
        transform: "rotate(180deg)",
    },
    redprice: {       
        fontWeight: 400,
        marginRight: theme.spacing(1),
    },
    priceOld: {
        textDecoration: "line-through",        
        color: "#8a8a8a",        
    },
    
    btnright: {
        textAlign: "right",
    },
    blabel: {        
        [theme.breakpoints.down("xs")]: {
          display: "none",
        },
      },    
    price: {        
        marginRight: theme.spacing(2),
    },
    colorRed: {
        color: pink[500],
        fontSize: "1.4rem"
    },
    colorBlue: {
        color: blue[500],
    }, 
    circularProgress: {        
        marginLeft: 0,
        marginRight: theme.spacing(1),        
      },
    ratingstar: {
        paddingTop: 4,
        fontSize: "1.3em",        
        [theme.breakpoints.down("xs")]: {
            fontSize: ".8em",
        },
      },
    ratingtxt: {
        paddingLeft: theme.spacing(2),
        fontSize: "1em",
        [theme.breakpoints.down("xs")]: {
            fontSize: ".8em",            
        },
    },
});

const getColor = (color) => {
    if(!color || color.length === 0)
        return blue[600];
        
        if(color.charAt(0) !== "#")
            color = "#"+color;    
    return color;
}

// const PropsRadio = withStyles({
//     // root: {
//     //   color: pink[400],
//     //   "&$checked": {
//     //     color: pink[600],
//     //   },
//     // },
//     checked: {},
//   })((props) => <Radio color={props.customColor} {...props} />);
  

class CatalogItemXs extends React.Component {  
    constructor(props) {
        super(props);
        let selChildId;
        let child;
        if(props.item.Children.length > 0){
            selChildId = props.item.Children[0].ProductId;
            child = props.item.Children[0];
        }

        this.state = {
            // eslint-disable-next-line no-undef
            cdn: `${process.env.REACT_APP_CDN}Pictures/`,
            imgLgSize: "392x392",
            imgMdSize: "183x183",
            expanded: false,
            ProductId: null,
            selChildId: selChildId,
            child: child
        };
    }

    SpecialPriceLabel = (value) => {
        // const { classes } = this.props;        
        switch (value) {
            case 0:
                return (
                    <Chip variant="outlined" color="secondary" label={<Typography variant="subtitle2">Wow ціна</Typography> } />  
                    // <div className={classes.specialprice} title="Wow ціна" style={{backgroundColor: pink[500] }} aria-label="recipe"><NewReleasesIcon /></div>  // "Wow ціна";
                )
            case 1:
                return <Chip color="primary" label={<Typography variant="subtitle2">Акція</Typography> } /> 
                // <div className={classes.specialprice}  title="Акція" style={{backgroundColor: blue[500] }} aria-label="recipe"><BrightnessAutoIcon /></div>  //"Акція";    
            default:
                // return <Chip color="primary" variant="outlined" label={<Typography variant="subtitle2">Розпродаж</Typography> } /> 
                return null;
        }
    }

    AvailableCSS = (value) =>{
        const { classes} = this.props;
        if(value <= 0){
            return classes.grayscale;            
        }
        else{
            return "";
        }
    }

    handleAddToOrder = (e, pos) => {
        const {disabled} = this.props;
        const { item } = this.props;
        const { child } = this.state;

        if(disabled === true)
            return;

        if (item.Children.length > 0 && child){
            console.log("Add child to order:", child);
            this.setState({ProductId: child.ProductId});         
            this.props.actions.addToOrder([ { pos: child, qty: 1, orderrowtype: 0}] )
        } else {
            console.log("Add master to order:", pos);
            this.setState({ProductId: pos.ProductId});         
            this.props.actions.addToOrder([ { pos: pos, qty: 1, orderrowtype: 0}] )
        }
        
        // this.setState({ProductId: pos.ProductId});         
        // this.props.actions.addToOrder([ { pos: pos, qty: 1, orderrowtype: 0}] )
    }

    handleChangeChild = (event) => {
        const { item } = this.props;
        if(item.Children.length > 0){
            const child = item.Children.find( (child) => {
                if(child.ProductId === event.target.value)
                    return child;
                return null;
                });
            this.setState({child: {...child}, selChildId: event.target.value}); 
        }        
    };
    
    handleChangeColor = (event) => {
        const { item } = this.props;
        if(item.Children.length > 0){
            const child = item.Children.find( (child) => {
                if(child.ProductId === event.target.value)
                    return child;
                return null;
                });
            this.setState({child: {...child}, selChildId: event.target.value}); 
        }        
    }; 

    handleChangeProps = (ProductId) => {
        const { item } = this.props;
        
        if(item.Children.length > 0){
            const child = item.Children.find( (child) => {
                if(child.ProductId === ProductId)
                    return child;
                return null;
                });
            this.setState({child: {...child}, selChildId: ProductId}); 
        }
    }; 

    render(){
        const {item, classes, width, IsAuthenticated, disabled, t } = this.props;
        const {child, ProductId, selChildId, cdn} = this.state;
        return(
            <>                        
            <Card className={classes.card} >                                   
                <CardActionArea onClick={() => this.props.history.push(`/product/${(child ? child.Code : item.Code)}`)} > 
                    { item.Comment.length > 0 &&
                        <span className={classes.specialprice} aria-label="recipe">{item.Comment}</span>
                    }
                    
                    { IsAuthenticated && 
                        <>
                            {( child ? (child.CatalogPricePv > 0.01 ? 
                                <span className={classes.priceBB}><span>{parseFloat(child.CatalogPricePv).toFixed(1)} <small>ББ</small></span></span>: null) : 
                                (item.CatalogPricePv > 0.01 ? <span className={classes.priceBB}><span>{parseFloat(item.CatalogPricePv).toFixed(1)} <small>ББ</small></span></span>: null))
                            }
                        </> 
                    }

                    { child ? 
                        <div className={classes.specialTag} >
                            { child.Tag.length > 0 &&
                                <Chip size="small"  color="secondary" 
                                    label={<Typography variant="body2">{child.Tag}</Typography> } 
                            />}                            
                        </div>
                    :
                        <div className={classes.specialTag} component="div" >                                
                            { item.Tag.length > 0 &&
                                <Chip size="small"  color="secondary" 
                                    label={<Typography variant="body2">{item.Tag}</Typography> } 
                            />}
                        </div>
                    }

                    <LazyLoad height={300}>
                        <CardMedia                                        
                            className={clsx(classes.media, this.AvailableCSS(item.FreeRest))}
                            image={`${cdn}${ width === "xs" ? this.state.imgMdSize : this.state.imgLgSize}/${(child ? child.Image : item.Image)}`}
                            title={item.LocalName}                        
                        />
                    </LazyLoad>                    
                </CardActionArea>

                <CardContent className={classes.cardcontent}>                                         
                        <Grid className={classes.options} container direction="row" justify="center" alignItems="center" spacing={0}>
                        { item.Children.length > 0 &&
                            <Grid item>
                                {item.Children.map((option) => (
                                    option.Color.length > 0 ?
                                    <Radio className={classes.propsradio} size= {width === "xs" ? "small": "medium"} key={option.ProductId}
                                        title={ option.CharName } 
                                        style={{ color: getColor(option.Color) }}
                                        icon={<LensIcon fontSize={ width === "xs" ? "small": "default" } style={{ color: getColor(option.Color) }} />}
                                        checked={selChildId === option.ProductId}
                                        onChange={this.handleChangeColor}
                                        value={option.ProductId}
                                        name="radio-button-props"                            
                                    />                                    
                                    : <Button 
                                        title={ option.CharName } 
                                        color={selChildId === option.ProductId ? "secondary": "default"} 
                                        className={classes.propsbutton} 
                                        key={option.ProductId} 
                                        size="small"  
                                        variant="outlined"
                                        value={option.ProductId}
                                        onClick={() => this.handleChangeProps(option.ProductId)}
                                      >
                                            <span className={classes.propslabel}>{option.CharName}</span>
                                    </Button>
                                    
                                ))}
                            </Grid>
                        }
                        </Grid>
                                                                                    
                    <Typography className={classes.name} align="center" gutterBottom display="block" variant="body1">
                        {child ? child.LocalName : item.LocalName}
                    </Typography>
                    <Typography noWrap className={classes.seria} align="center" display="block" variant="overline">  
                        { child ? <>{child.Code} / {child.Series} </> : <>{item.Code} / {item.Series}</>}                        
                    </Typography>                            
                                                
                    { IsAuthenticated ?
                        <Typography align="center" component="div" display="block" >
                            <Typography color="primary" >
                                {parseFloat(child ? child.SellingPrice : item.SellingPrice).toFixed(2)} <small style={{fontSize: ".7em", marginRight: 16}}>₴</small>
                                {child ? 
                                <span className="price--line-through text-gray">
                                    { child.OldPrice === 0 ? parseFloat(child.Price).toFixed(2): parseFloat(child.OldPrice).toFixed(2) } <small style={{fontSize: ".7em"}}>₴</small>
                                </span> :
                                <span className="price--line-through text-gray">
                                { item.OldPrice === 0 ? parseFloat(item.Price).toFixed(2): parseFloat(item.OldPrice).toFixed(2) } <small style={{fontSize: ".7em"}}>₴</small>
                            </span> 
                                }                              
                            </Typography>
                            <Typography >

                            </Typography>
                        </Typography> :
                        <Typography component="div" display="block" align={"center"} >                                                             
                            <Typography component="span" color="primary">
                                {parseFloat(child ? child.Price : item.Price).toFixed(2)}
                            </Typography>
                            { item.OldPrice > 0 &&
                                <Typography className="price--line-through text-gray" color="textSecondary" component="span" style={{marginLeft: 8}}>
                                    {parseFloat(child ? child.OldPrice : item.OldPrice).toFixed(2)} 
                                </Typography>
                            }
                        </Typography>
                    }     
                                            
                    
                    <Grid container justify="center" alignItems="center" spacing={0} >
                        <Grid item >
                                <Rating name="half-rating" size="small" readOnly className={classes.ratingstar} defaultValue={item.ProductEvaluation.AvgRating} precision={0.5} />                            
                        </Grid>
                        <Grid item>                            
                            <Typography className={classes.ratingtxt} align="center" variant="body2" >
                                { item.ProductEvaluation.Qty !== 0 ? `${item.ProductEvaluation.Qty} від.` : null } 
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid className={classes.buyblock} style={{marginTop: "8px"}} container direction="row" justify="flex-start" alignItems="center" >
                        <Grid item xs={12}  style={{textAlign: "center"}} >
                            <Chip
                                icon={ item.FreeRest <= 0 ? <AccessTime /> : 
                                    (disabled && item.ProductId === ProductId ? <CircularProgress  className={classes.circularProgress} /> :  <ShoppingBasketOutlinedIcon  /> )
                            }
                            disabled={item.FreeRest <= 0 }
                            label= {item.FreeRest <= 0 ? t("buttons.NotifyArrivalShort") : t("buttons.Buy")}
                            clickable
                            onClick={(e) => this.handleAddToOrder(e, item)}
                            color={ item.FreeRest <= 0 ? "default" : "primary"}
                            // onDelete={(e) => this.handleAddToOrder(e, item)}
                            // deleteIcon={<DoneIcon />}
                            
                        />
                            {/* <Chip style={{paddingLeft: "16px"}} variant="default" color="primary" disabled={item.FreeRest <= 0 }
                                icon={ item.FreeRest <= 0 ? <AccessTime className={classes.extendedIcon} /> : 
                                  (disabled && item.ProductId === ProductId ? <CircularProgress  className={classes.circularProgress} /> :  <ShoppingCartOutlinedIcon className={classes.extendedIcon} /> )
                            }
                            label={t("buttons.Buy")} 
                            onClick={(e) => this.handleAddToOrder(e, item)}
                            /> */}

                            {/* <Fab className={classes.buy} variant="extended" size="small" color="primary" aria-label="add" 
                                disabled={item.FreeRest <= 0 }
                                onClick={(e) => this.handleAddToOrder(e, item)}
                            >                                
                                 { item.FreeRest <= 0 ? <AccessTime className={classes.extendedIcon} /> : 
                                        (disabled && item.ProductId === ProductId ? <CircularProgress  className={classes.circularProgress} /> :  <ShoppingCartOutlinedIcon className={classes.extendedIcon} />   )
                                } +                                
                            </Fab> */}
                        </Grid>
                    </Grid>
                    
                    {/* { item.Children.length > 0 &&
                    <Grid container direction="row" justify="center" alignItems="center" style={{marginTop: "8px"}}>
                        <Grid item xs={12} sm={11} >                            
                            <TextField
                                select
                                size="small"
                                fullWidth
                                margin="dense"
                                value={selChildId}
                                variant="outlined"
                                onChange={this.handleChangeChild}
                                InputProps={{
                                    classes: { notchedOutline: classes.select, input: classes.selPadding },
                                                                        
                                }}
                                >
                                {item.Children.map((option) => (
                                <MenuItem key={option.Code} value={option.ProductId}>
                                    { option.Color.length > 0 &&
                                    <ListItemIcon className={classes.listIcon}>
                                        <Brightness1Icon fontSize="small" style={{color: (option.Color.length> 0 ? `#${option.Color}`: "#fff")}} />
                                    </ListItemIcon>
                                    }
                                    <Typography style={{lineHeight: "1.2rem"}} variant="inherit">{option.CharName}</Typography>                                    
                                </MenuItem>
                                ))}
                            </TextField>                            
                        </Grid>
                    </Grid>
                    } */}
                
                </CardContent>
            </Card>
            </>
        );
    }
}

export default withTranslation()(withWidth()(withRouter( withStyles(styles)(
    connect(    
        state => state.orderRequest,
        dispatch => {
            return {
               actions: bindActionCreators(Object.assign({}, actionOrderCreators), dispatch)
        }}
    )(CatalogItemXs)
))));
