import React, { useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
  
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import { Typography, Grid, Avatar, Button } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import lightBlue  from "@material-ui/core/colors/lightBlue";
import { useTranslation } from "react-i18next";
import LinkIcon from "@material-ui/icons/Link";
// import useMediaQuery from "../media-query";




const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
        width: "100%",
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    box: {
        display: "inline-block",
        padding: theme.spacing(2),
        boxSizing: "border-box",
        width:theme.spacing(40),
        [theme.breakpoints.down("xs")]: {
            width:theme.spacing(30),
            paddingLeft: theme.spacing(10)
        },
    },
    
    progress: {
        height: 2,
        position: "absolute",
        top: 0,
        left: 0,
        right:0,
    },  
    
    linearColorPrimary: {
        backgroundColor: "#abdcda",
    },

    linearBarColorPrimary: {
        backgroundColor: "#d20000",
    },        
    
    dowloadlink:{
        overflowWrap: "anywhere",
    },

    profile: {
        background: "linear-gradient( 338deg, rgba(116,251,253,1) 0%, rgba(85,209,231,1) 24%, rgba(50,162,206,1) 100%)",
            
        // transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",        
        overflow: "hidden",
        // boxShadow: "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
        boxShadow: "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
        borderRadius: "8px",
        zIndex: 0,
        marginBottom: theme.spacing(2),
        height: 250,
        position: "relative",
    },

    
    
    userbox:{
        color: "#fff",
        textShadow: "-1px -1px 1px rgb(255 255 255 / 10%), 0px 1px 3px rgb(0 0 0 / 50%)",
        right: "auto",
        display: "flex",
        // alignItems: "center",        
        left: 24,
        bottom: 64,
        position: "absolute",
        // marginTop: 30,
        zIndex: 3,
        [theme.breakpoints.down("xs")]: {            
            left: 0,
            bottom: 24,
        },
    },
    large: {
        width: 132,
        height: 132,
        border: "2px solid #fff",
        fontSize: "4em",
        lineHeight: "4em",
        color: theme.palette.getContrastText(lightBlue[500]),
        backgroundColor: lightBlue[300],
        letterSpacing: "-0.1em",        
        // [theme.breakpoints.down("xs")]: {        
        //     display: "none",           
        // },
    },
    middle: {
        width: 96,
        height: 96,
        border: "2px solid #48bfde",
        fontSize: "4em",
        lineHeight: "4em",
        color: theme.palette.getContrastText(lightBlue[700]),
        backgroundColor: lightBlue[700],
        letterSpacing: "-0.1em",
        // [theme.breakpoints.down("xs")]: {        
        //     display: "none",           
        // },
    },            
    username: {
        fontWeight: 300,
        marginTop: theme.spacing(4),
        textTransform: "capitalize",
        [theme.breakpoints.down("xs")]: {
            marginTop: 0,
            fontSize: "1.8em",
        },                 
    },
    
    button: {
        marginRight: theme.spacing(1),
    },

    comment: {
        padding: theme.spacing(1,2),
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(1,1),
        },                 
    }

}));

function getUserName(userName){
    const words = userName.split(" ");
    const res = (`${words[1].charAt(0)}${words[0].charAt(0)}`).toUpperCase();
    // console.log("USER NAME", res);
    return res;
}

const Introduce = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { ref } = useParams();
    const [adviser, setAdviser] = React.useState(null);
    const theme = useTheme();

    const fetchData = useCallback( async () => {
        const url = `/api/v1.0/adviser/PublicProfile/${ref}`;
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");
         
        fetch(url, { method: "GET", headers: headers })      
            .then(response => response.json())
                .then(data => {      
                    console.log(data);
                    if(data){
                        setAdviser(data.Adviser);
                    }
                    
                });
    
    }, [ref]);
    
    const width = "sm";
    // const matches = useMediaQuery(theme.breakpoints.up(width));
    const matches = useMediaQuery(theme.breakpoints.up(width));
    const refUrl = `${window.location.protocol}//${window.location.host}/register/${adviser ? adviser.RegistrationCode: ""}`;

    useEffect( () => {
        fetchData();
    }, [fetchData]);

    const shortName = adviser ? getUserName(adviser.Name) : "";
    // const width = useWidth();

    return (
        <div className={classes.root}>
            
                {/* <Typography>{`theme.breakpoints.up('${width}') matches: ${matches}`}</Typography>                
                <Typography>Public adviser profile: <b>{ref}</b></Typography> */}
                { adviser && 
                    <>
                        <div className={classes.profile}>
                            <div className={classes.userbox}>
                                <Grid container direction="row" justify={ !matches ? "center" : "flex-start"} spacing={2}>
                                    <Grid item>
                                    {
                                        adviser.Avatar && adviser.Avatar.length > 0 ?
                                        <>                                      
                                            <Avatar alt={shortName} src={adviser.Avatar} className={classes.large} onClick={ () => this.setState({ expanded: "panelPhoto"})} /> 
                                        </>: 
                                        <>
                                        <Avatar className={classes.large} onClick={()=> this.setState({ expanded: "panelPhoto"})}>
                                            { shortName }
                                        </Avatar>
                                        </>
                                    }                                    
                                    </Grid>
                                    <Grid item>
                                        <Typography className={classes.username} noWrap align={!matches ? "center": "left"}  variant="h4" component="h2">
                                            { adviser.LastName.toLowerCase() } { adviser.FirstName.toLowerCase() } { adviser.MiddleName.toLowerCase() } <br />
                                            <small>{adviser.RegistrationCode}</small>
                                        </Typography> 
                                    </Grid>
                                </Grid>
                            </div>                            
                        </div>

                        <Grid container direction="row" spacing={2}>
                            <Grid item xs={12}>                                                                                                                                    
                                <Button color="primary" startIcon={<LinkIcon />} href={refUrl} target="_blank" >
                                    {t("profile.JoinMyTeam")}
                                </Button> 
                            </Grid>
                        </Grid>

                        <Typography className={classes.comment} component="div">
                            <div dangerouslySetInnerHTML={{__html: adviser.Comment  }}></div>        
                        </Typography>

                    </>
                }
            
        </div>
    );
}

export default Introduce;