import React, { Component, Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import { OidcUserManager } from "../../helpers/OidcSettings";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(1),
      },
      paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
      },
      block: {
        marginTop: theme.spacing(3),
      }
  });
  
class BearerToken extends Component {
    constructor(props) {
        super(props);
        this.state = { user: null };
    }    
    
    componentDidMount() {     
        this.loadUser();
    }

    loadUser = async () => {         
        const userManager = await OidcUserManager();
        userManager.getUser().then(user => {
            this.setState({user: user});
            // console.log(user);
        });
    };

    render() {
        const { classes } = this.props;
        const { user } = this.state;

        return (
            <div className={classes.root}>    
                <Grid container spacing={1} direction="row">
                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={1} >
                        {
                            user ? 
                                <Fragment>
                                    <Typography variant="h5" gutterBottom>
                                        Bearer token user info  
                                    </Typography>
                                    <Grid container spacing={1} direction="row">
                                        <Grid item xs>
                                            <TextField label={"Name"}
                                                fullWidth
                                                margin="normal"
                                                readOnly                                       
                                                variant="outlined"
                                                value={user.profile.name}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <TextField label={"Email"}
                                                fullWidth
                                                margin="normal"
                                                readOnly                                            
                                                variant="outlined"
                                                value={user.profile.email}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <TextField label={"User name"}
                                                fullWidth
                                                margin="normal"
                                                readOnly                                            
                                                variant="outlined"
                                                value={user.profile.preferred_username}
                                            />
                                        </Grid>                                                                                
                                    </Grid>

                                    <Grid container spacing={1} direction="row">
                                        <Grid item xs>
                                            <TextField label={"Roles"}
                                                fullWidth
                                                margin="normal"
                                                readOnly                                            
                                                variant="outlined"
                                                value={user.profile.role}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <TextField label={"Auth time"}
                                                fullWidth
                                                margin="normal"
                                                readOnly                                            
                                                variant="outlined"
                                                value={new Date( user.profile.auth_time * 1000)}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <TextField label={"Expires"}
                                                fullWidth
                                                margin="normal"
                                                readOnly                                            
                                                variant="outlined"
                                                value={new Date( user.expires_at * 1000)}
                                            />
                                        </Grid>

                                    </Grid>

                                    <div>
                                        <TextField id="bearer_token_sw" label={`Copy all for swagger, token type: ${user.token_type}`}
                                            multiline fullWidth readOnly margin="normal"
                                            rowsMax={8} 
                                            variant="outlined"
                                            value={`Bearer ${user.access_token}`}
                                        />
                                    </div>
                                    <div >
                                        <TextField id="bearer_token_ps" label={`Copy all for postman, token type: ${user.token_type}`}
                                            multiline fullWidth readOnly margin="normal"
                                            rowsMax={8} 
                                            variant="outlined"
                                            value={user.access_token}
                                        />
                                    </div>
                                </Fragment>
                            : null
                        }  
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(BearerToken);