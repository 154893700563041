import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { OidcUserManager } from "../../helpers/OidcSettings";
import gray from "@material-ui/core/colors/grey";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import GridOnIcon from "@material-ui/icons/GridOn";

import axios from "axios";

const styles = theme => ({
    root: {
        flexGrow: 1,        
        padding: theme.spacing(1),
    },
    progress: {
        height: 2,
        position: "absolute",
        top: 0,
        left: 0,
        right:0,
      },  
      linearColorPrimary: {
        backgroundColor: "#abdcda",
      },
      linearBarColorPrimary: {
        backgroundColor: "#d20000",
      },
    
    paper: {
        padding: theme.spacing(2),
    },
    info: {
        padding: theme.spacing(1),
        height: "auto",
        [theme.breakpoints.down("sm")]: {
            height: 100,
          },
    },
    divider: {        
        marginBottom:theme.spacing(2),
    },
    customer: {
        textTransform: "capitalize",
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    media: {
        height: 183,
        // backgroundSize: "contain",
    },
    value: {
        fontWeight: "bold",
    },
    table: {
        minWidth: 500,
    },
    bold: {
        fontWeight: "500",
    },
    total: {
        fontWeight: "500",
        backgroundColor: gray[200],
    },
    step: {
        marginRight: theme.spacing(1), 
      },  
    fixcell: {
        backgroundColor: gray[100],
    },
    customtooltip: {
        backgroundColor: "#fff",
        padding: theme.spacing(1),
        border: `1px solid ${theme.palette.divider}`,        
    }
});


// const InfoPanel = withStyles(styles)( props => {
//     const { classes, title, value, ...other } = props;
//     return (
//       <Paper className={classes.info} elevation={1} {...other}>
//         <Typography gutterBottom align="center" variant="body2">{title}</Typography>
//         <Typography gutterBottom align="center" className={classes.value} >
//             { value } &nbsp;
//         </Typography>
//       </Paper>
//     );
//   });

const CustomizedLabel = withStyles(styles)( props => {    
    const {x, y, stroke, value } = props;          
    return <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle" >{value}</text>    
});

// const CustomTooltip = ({ active, payload, label }) => {
    const CustomTooltip = withStyles(styles)( props => {    
    const {active, payload, label, classes} = props;        
    if (active) {
        // console.log(payload);      
      return (
        <div className={classes.customtooltip}>
          <p className="label">{`Каталог ${label}, ${payload[0].name} = ${payload[0].value}`}</p>
          {/* <p className="intro">{getIntroOfPage(label)}</p> */}
          
        </div>
      );
    }
  
    return null;
  });

// const CustomizedLabelCatalog = withStyles(styles)( props => {    
//     const {x, y, stroke, value } = props;          
//     return <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle" >XXX {value}</text>    
// });

// const CustomizedAxisTick = withStyles(styles)( props => {    
//     const {x, y, payload} = props;    
//     return ( 
//         <g transform={`translate(${x},${y})`}>
//         <text x={0} y={0} dy={16}  fill="#666" >{ payload.value.replace(/\((.+?)\)/g, "") }</text>
//       </g>);
// });





class PersonalIndicators extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            keys: [],
            isLoading: false,
            width: 0, 
            height: 0,
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.loadDashBoard();
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
      }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      }

    loadDashBoard = async () => {
        const self = this;
        const userManager = await OidcUserManager();

        userManager.getUser().then(user => {
            let token = null;

            if (user != null) {
                token =  `Bearer ${user.access_token}`;                
                this.setState({isLoading: true});
                const URL =  "/api/v1.0/reports/personalindicators";
                axios.get(URL,  
                    { headers: { Authorization: token } }
                )                
                .then(function(response){
                    const keys = [];
                    
                    // for (let i = 0; i < response.data.ConsultantStatusHistory.length; i++) {
                    //     const element = response.data.ConsultantStatusHistory[i];                        
                    //     keys.push(element.МА);                        
                    // }
                    
                    self.setState({ data: response.data, isLoading: false, keys: keys });
                    
                    console.log("PersonalIndicators data", response.data); 
                })
                .catch((error) => {        
                    this.setState({isLoading: false});
                    console.log(error);
                });                
            }
        });
    };


    render() {
        const { classes, t } = this.props; 
        const { data, width, isLoading } = this.state;        
        let h = 300;
        // switch (width) {
        //     case "xs":
        //         w=330;
        //         break;
        //     case "sm":
        //         w=900;
        //         break;
        //     case "md":
        //         w=1000;
        //         break;                
        //     case "lg":
        //         w=1400;
        //         break;            
        //     case "xl":
        //         w=1500;
        //         break;            
        //     default:
        //         w=900;
        //         h=300;
        //         break;
        // }

        return (
            <div className={classes.root}>
                { isLoading && 
                    <LinearProgress className={classes.progress} 
                    classes={{
                        colorPrimary: classes.linearColorPrimary,
                        barColorPrimary: classes.linearBarColorPrimary,
                    }}
                />
                }

                <Grid container direction="row" spacing={2} justify="flex-start" alignItems="center" >
                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={1}>
                            <Typography variant="h6" align="center">
                                {t("reports.personalindicators.title")}
                            </Typography>
                            { data && 
                            <Typography align="center">
                                { data ? `${data.Adviser.RegistrationCode} ${data.Adviser.Name}` : null }
                            </Typography>
                            }
                        </Paper>
                    </Grid>
                </Grid>
                { 
                
                data && (
                <Grid container spacing={2} direction="row"  >                                             
                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={1} style={{minHeight: "347px"}}>
                            <Typography style={{color: "#649a78"}} align="center">
                                {t("reports.personalindicators.titleGO", {periods: data.ConsultantStatusHistory.length})}                                
                            </Typography>

                            {/* ЛО за 17 періодів ББ (враховуючи поточний) */}
                            <div>                                
                            <AreaChart
                                width={width-50}
                                height={h}
                                data={data.ConsultantStatusHistory}
                                margin={{
                                    top: 15, right: 10, left: 10, bottom: 15,
                                }}
                                >
                                <CartesianGrid strokeDasharray="3 3" />
                                {/* <XAxis dataKey="МА"  tick={<CustomizedAxisTick/>} /> */}
                                <XAxis dataKey="CatalogNum" />
                                 {/* <XAxis dataKey="ПорядокПериода" /> */}                                 
                                <YAxis />
                                <Tooltip content={<CustomTooltip />} />
                                                                
                                <Legend />
                                {/* <Legend width={100} wrapperStyle={{ top: 40, right: 20, backgroundColor: "#f5f5f5", border: "1px solid #d5d5d5", borderRadius: 3, lineHeight: "40px" }} /> */}
                                {/* <Line type="monotone" dataKey="ОО" stroke="#8884d8" activeDot={{ r: 8 }} /> */}                                                                
                                <Area type="monotone" dataKey="ГО" stroke="#82ca9d" fill="#adf3c7" strokeWidth={2} activeDot={{ r: 8 }} label={ this.props.width !== "xs" ? <CustomizedLabel /> : null} />
                                {/* <Line type="monotone" dataKey="ГО" stroke="#82ca9d" strokeWidth={2} activeDot={{ r: 8 }} label={ this.props.width !== "xs" ? <CustomizedLabel /> : null} /> */}
                            </AreaChart>
                            </div>
                            
                            {/* ГО за 17 періодів ББ (враховуючи поточний) */}
                            <div style={{marginTop: "16px"}}> 
                                <Typography style={{color: "#55519e"}} align="center">
                                    {t("reports.personalindicators.titleOO", {periods: data.ConsultantStatusHistory.length})}
                                </Typography>
                                <AreaChart
                                    width={width-50}
                                    height={h}
                                    data={data.ConsultantStatusHistory}
                                    margin={{
                                        top: 15, right: 10, left: 10, bottom: 15,
                                    }}
                                    >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    {/* <XAxis dataKey="ПорядокПериода" /> */}
                                    <XAxis dataKey="CatalogNum" />
                                    <YAxis />
                                    <Tooltip content={<CustomTooltip />} />
                                    <Legend />
                                    <Area type="monotone" dataKey="ОО" stroke="#8884d8" strokeWidth={2} activeDot={{ r: 8 }} fill='#8884d8' label={ this.props.width !== "xs" ? <CustomizedLabel /> : null} />
                                    {/* <Line type="monotone" dataKey="ОО" stroke="#8884d8" strokeWidth={2} activeDot={{ r: 8 }} />  */}
                                </AreaChart>
                            </div>
                            
                            {/* Винагорода за 17 периодов (закриті періоди) в грн */}
                            <div style={{marginTop: "16px"}}>
                                <Typography style={{color: "#E64A19"}} align="center">
                                    {t("reports.personalindicators.titleRemuneration", {periods: data.ConsultantGiftHistory.length})}                                    
                                </Typography>
                                <AreaChart
                                    width={width-50}
                                    height={h}
                                    data={data.ConsultantGiftHistory}
                                    margin={{
                                        top: 15, right: 10, left: 10, bottom: 15,
                                    }}
                                    >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    {/* <XAxis dataKey="ПорядокПериода" /> */}
                                    <XAxis dataKey="CatalogNum" />
                                    <YAxis />
                                    <Tooltip content={<CustomTooltip />} />
                                    <Legend />
                                    {/* <Line type="monotone" dataKey="Gift" stroke="#E64A19" strokeWidth={2} activeDot={{ r: 8 }} />  */}
                                    <Area name="OB" type="monotone" dataKey="Gift" stroke="#E64A19" fill="#f75c2c" strokeWidth={2} activeDot={{ r: 8 }}  label={ this.props.width !== "xs" ? <CustomizedLabel /> : null}/>
                                </AreaChart>

                            </div>


                            <div style={{marginTop: "16px", marginBottom: "16px"}}>                                                                                                                                 
                                <Accordion defaultExpanded TransitionProps={{ unmountOnExit: true }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="mystatuses"
                                        id="mystatuses-header">
                                            <FormControlLabel
                                            aria-label="MyStatuses"
                                            control={<StarBorderIcon className={classes.step} />}                
                                            label={ <Typography>{t("reports.personalindicators.titleMyStatuses")}</Typography>}
                                        />                
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {/* <div style={{width: "100%", display: "block"}}> 
                                            <Typography gutterBottom align="center">
                                                {t("reports.personalindicators.titleMyStatuses")}
                                            </Typography>
                                        </div> */}

                                        <div style={{overflowX: "auto", width: "100%", display: "grid"}}> 
                                            <TableContainer >
                                                <Table className={classes.table} size="small" aria-label="a dense table">
                                                    {/* <caption>{t("reports.personalindicators.titleMyStatuses")}</caption> */}
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Статус / Кат-ги</TableCell>
                                                            {
                                                                data.ConsultantStatusHistory.map((row) => (
                                                                    <TableCell align="center" key={row.ПорядокПериода}>{row.CatalogNum}</TableCell>
                                                                ))
                                                            }    
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell className={classes.fixcell}>{t("reports.personalindicators.status.Structure")}</TableCell>
                                                            {
                                                                data.ConsultantStatusHistory.map((row) => (
                                                                    <TableCell align="center" key={row.ПорядокПериода}><small>{row.СтруктурныйСтатус}</small></TableCell>
                                                                ))
                                                            }    
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell className={classes.fixcell}>{t("reports.personalindicators.status.Director")}</TableCell>
                                                            {
                                                                data.ConsultantStatusHistory.map((row) => (
                                                                    <TableCell align="center" key={row.ПорядокПериода}><small>{row.ДиректорскийСтатус}</small></TableCell>
                                                                ))
                                                            }    
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell className={classes.fixcell}>{t("reports.personalindicators.status.Global")}</TableCell>
                                                            {
                                                                data.ConsultantStatusHistory.map((row) => (
                                                                    <TableCell align="center" key={row.ПорядокПериода}><small>{row.ГлобалСтатус}</small></TableCell>
                                                                ))
                                                            }    
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>

                                                    </TableBody>
                                                </Table>
                                        </TableContainer>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            
                            <Accordion TransitionProps={{ unmountOnExit: true }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header">
                                    <FormControlLabel
                                        aria-label="DataTable"
                                        control={<GridOnIcon className={classes.step} />}                
                                        label={ <Typography>{t("reports.personalindicators.titleDataTable")}</Typography>}
                                />                
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div style={{overflowX: "auto", width: "100%", display: "grid"}}>
                                        <TableContainer >
                                            <Table className={classes.table} size="small" aria-label="a dense table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={{width: "64px"}}>{t("reports.personalindicators.fields.ПорядокПериода")}</TableCell>
                                                        <TableCell>{t("reports.personalindicators.fields.МА")}</TableCell>
                                                        <TableCell align="right">{t("reports.personalindicators.fields.ГО")}</TableCell>
                                                        <TableCell align="right">{t("reports.personalindicators.fields.ОО")}</TableCell> 
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                {
                                                    data.ConsultantStatusHistory.map((row) => (
                                                        <TableRow key={row.ПорядокПериода}>
                                                            <TableCell style={{width: "64px"}} component="th" scope="row">
                                                                {row.ПорядокПериода}
                                                            </TableCell>
                                                            <TableCell>{row.МА}</TableCell>
                                                            <TableCell align="right">{row.ГО}</TableCell>
                                                            <TableCell align="right">{row.ОО}</TableCell>
                                                        </TableRow>
                                                    ))
                                                }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </AccordionDetails>                                                    
                            </Accordion>
                            {/* <Typography>{width}/{height}  w:{w}; h:{h}</Typography> */}
                        </Paper>
                    </Grid>
                </Grid> 
                )
            }
            </div>            
        );
    }
}


export default  withTranslation()(withWidth() (withStyles(styles)((PersonalIndicators))));

