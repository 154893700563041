import React from "react";
import {withStyles} from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
// import axios from "axios";
// import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import MobileStepper from "@material-ui/core/MobileStepper";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

// import Grid from "@material-ui/core/Grid";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const styles = theme => (
{
    root: {
        position: "relative",
        marginBottom: theme.spacing(2),
    },
    
    header: {
        padding: theme.spacing(1),
        // align: "center",
        // paddingTop: theme.spacing(1),
        // backgroundColor: theme.palette.primary.main,
        background: "linear-gradient(338deg, rgba(116,251,253,1) 0%, rgba(85,209,231,1) 24%, rgba(50,162,206,1) 100%)",
        // height: 32,
        color: "#fff",
    },

    slide: {
        display: "block",
        overflow: "hidden",
        // backgroundSize: "cover",
        backgroundSize: "100% auto",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "50%",
        height: 500,
        [theme.breakpoints.down("sm")]: {
            height: 209,
        },
        [theme.breakpoints.up("sm")]: {
             height: 400,
        },
        [theme.breakpoints.up("md")]: {
            height: 500,
        },
        // [theme.breakpoints.up("lg")]: {
        //     height: 550,
        // },
        [theme.breakpoints.up("xl")]: {
            height: 650,
        },
    },
    img: {
        display: "block",
        overflow: "hidden",
        width: "100%",
    },
    headertxt: {
        fontWeight: 300,
    },
    tilebar: {
        minHeight: 80,
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 46,
        padding: theme.spacing(1),                
        background: "rgba(0, 0, 0, 0.4)",        
    },
});

class Slider extends React.Component {    
    constructor(props) {
        super(props);
        this.state = {            
            activeStep: 0,
            maxSteps: props.slider.Items.length,
            interval: 50000,
        }; 
        
        // console.log(props.slider);
    }
        
    setActiveStep = (step) =>{
        this.setState({ activeStep: step });            
    }

    handleNext = () => {        
        // this.setState(prevState => ({
        //     activeStep: prevState.activeStep - 1,
        //   }));
        if(this.state.maxSteps > this.state.activeStep + 1){
            this.setActiveStep(this.state.activeStep + 1);
        }
        // this.setActiveStep(prevActiveStep => prevActiveStep + 1);
      }
    
    handleBack = () => {
        // this.setState(prevState => ({
        //     activeStep: prevState.activeStep + 1,
        //   }));
        
        if(0 <= this.state.activeStep - 1){
            this.setActiveStep(this.state.activeStep - 1);
        }
        // this.setActiveStep(prevActiveStep => prevActiveStep - 1);
    }
    
    handleStepChange = (step) => {        
        this.setActiveStep(step);
    }
    
    render() {
        const { classes, slider, t, width } = this.props; 
        const {activeStep} = this.state; 
        let w  = "md/";
        switch (width) {
            case "xl":
                w = "";
                break;    
            case "lg":
                w = "";
                break;
            case "xs":        
                w = "sm/";
                break;
            default:
                w = "md/";
                break;
        }

        return (
            
            <div className={classes.root}>
                { slider.Items && slider.Items.length > 0 &&
                    <React.Fragment>   
                        <AutoPlaySwipeableViews
                            index={this.state.activeStep}
                            onChangeIndex={this.handleStepChange}
                            enableMouseEvents
                            interval={this.state.interval}
                        >
                        {slider.Items.map((step) => (                            
                            <Typography key={step.Id} component={Link} to={step.Url} 
                                        className={classes.slide} 
                                    style={{ backgroundImage:`url(${step.Path}/${w}${step.Img})` }}
                                    // style={{ backgroundImage:`url(${step.Path}/${step.Img})` }}
                                >
                            </Typography>                             
                        ))}
                        </AutoPlaySwipeableViews>                        
                        <MobileStepper
                            steps={this.state.maxSteps}
                            position="static"
                            activeStep={ activeStep }
                            className={classes.mobileStepper}
                            nextButton={
                            <Button size="small" onClick={() => this.handleNext()} disabled={this.state.activeStep === this.state.maxSteps - 1}>
                                {t("buttons.Next")}
                                <KeyboardArrowRight />
                            </Button>
                            }
                            backButton={
                            <Button size="small" onClick={() => this.handleBack()} disabled={this.state.activeStep === 0}>
                               <KeyboardArrowLeft /> 
                               {t("buttons.Back")}                                
                            </Button>
                            }
                        />

                    {  slider.Items[activeStep].ShowText && (slider.Items[activeStep].Caption.length > 0 || slider.Items[activeStep].Text.length > 0) ?
                    <div className={classes.tilebar}>
                        <Typography variant="h4" style={{color: "#fff"}} className={classes.headertxt} display="block" align="center" >
                            {slider.Items[activeStep].Caption}
                        </Typography>
                        <Typography variant="h6" style={{color: "#fff"}} className={classes.headertxt} display="block" align="center" >
                            {slider.Items[activeStep].Text}
                        </Typography>
                    </div> : null
                    }
                    </React.Fragment>                
                }                                                 
            </div>                                    
        );
    }
}

export default withTranslation() (withWidth()(withStyles(styles)(Slider)));