import React, { Component } from "react";
import withWidth from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Typography } from "@material-ui/core";

// import Toolbar from "@material-ui/core/Toolbar";

import MUIDataTable, {ExpandButton} from "mui-datatables";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

import EditIcon from "@material-ui/icons/Edit";
import DoneIcon from "@material-ui/icons/Done";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import "moment/locale/uk";
import CachedIcon from "@material-ui/icons/Cached";
import Box from "@material-ui/core/Box";
// import { OidcUserManager } from "../../helpers/OidcSettings";
import { AuthContext } from "../../providers/authProvider";

import { withTranslation } from "react-i18next";


const styles = theme => ({
    root: {            
        flexGrow: 1,        
    },
    title: {
        flexGrow: 1,
        display: "none",
        [theme.breakpoints.up("sm")]: {
            display: "block",
        },
    },
    button: {
        margin: theme.spacing(1),
    },
    iconButton: {
        margin: theme.spacing(1),
    },
    page: {
        padding: theme.spacing(2,2),
        marginTop: theme.spacing(1),
    },    
    ratingstar: {        
        fontSize: "11px",        
      },
    pandding: {
        color: theme.palette.warning.main
      },
    approved: {
        color: theme.palette.success.main
    },
    approvedAct: {
        minWidth: "138px",
        textAlign: "left"
    },
    deleted: {
        color: theme.palette.text.secondary
    },
    table: {
        width: "100%",
        maxWidth: "100%",
        overflowX: "auto"
    }

});

// const extractFilename = (path) => {
//     const pathArray = path.split("/");
//     const lastIndex = pathArray.length - 1;
//     return pathArray[lastIndex];
//  };

function TabLabel(props) {
    const { text, icon, ...other } = props;
  
    return (
      <div 
        {...other}
      >
        <Grid container alignItems="center" spacing={2}>
            { icon && 
            <Grid item>
                {icon}
            </Grid>
            }
            <Grid item>
                <Typography>
                    {text}
                </Typography>
            </Grid>                                        
        </Grid>                        
      </div>
    );
  }

  TabLabel.propTypes = {
    text: PropTypes.string.isRequired,
    icon: PropTypes.node,
    // value: PropTypes.any.isRequired,
  };

class Drafts extends Component {
    constructor(props){
        super(props);        
        this.state = {
            search: " ",
            page: 1,
            pageSize: 10,                        
            user: undefined,
            drafts: undefined,                                                                        
        };
        
        moment.locale("uk");
    }        

    // static
    static contextType = AuthContext;

    componentDidMount() {                
        const {search, page, pageSize } = this.state;          
        this.loadContent(search, page, pageSize);    
    }

    loadContent = async (search, page, pageSize) => {        
        // const userManager = await OidcUserManager();        
        const context = this.context;
        const userManager = context.UserManager;
                
        userManager.getUser()
        .then(user => {
            if(!user)
                return;

            console.log(context.parseJwt(user.access_token));
            
            const URL = `/api/v1.0/orders/alldrafts/${search.length > 0 ? search : " " }/${page}/${pageSize}`;            
            fetch(URL, {
                    method: "GET",
                    headers: {"Authorization": `${user.token_type} ${user.access_token}` }
                }
            )
            .then(response => response.json())
                .then(data => {                    
                    this.setState({drafts: {...data}});
            })
            .catch(error => console.log(error));

        });        

    };
 
    sort = (page, sortOrder) => {
        console.log( "sort", page, sortOrder);        
    };


    // eslint-disable-next-line no-unused-vars
    changePage = (page, searchText, sortOrder) => {
        // eslint-disable-next-line no-unused-vars
        const { tabValue, pageSize } = this.state;
        
                

        this.loadContent((searchText ? searchText : ""), parseInt(page)+1, pageSize);
    };

    changeRowsPerPage = (rows) => {
        // console.log( "changeRowsPerPage", rows);
        // eslint-disable-next-line no-unused-vars
        const {tabValue, search } = this.state;       
        this.setState({pageSize: rows});
        this.loadContent(search, 1, rows );
    };

    getCount = () => {
        // eslint-disable-next-line no-unused-vars
        const {tabValue, drafts } = this.state;        
        return drafts ? drafts.TotalCount: 0;
        
    }

    handleRefresh = () => {
        // eslint-disable-next-line no-unused-vars
        const {tabValue, search, page, pageSize } = this.state;       
        this.loadContent(search, page, pageSize, );
    };

    getDataByIdx = (idx) => {
        // eslint-disable-next-line no-unused-vars
        const {tabValue, drafts } = this.state;        
        return drafts ? drafts.Data[idx] : undefined;                    
    };

            
    renderRowAct = (dataIndex, tabValue, classes) => {        
        switch (tabValue) {
            case "1":
                return (
                    <div className={classes.approvedAct}>
                        <IconButton size="small" className={classes.iconButton} onClick={() => this.handleEdit(dataIndex)}>
                            <EditIcon />
                        </IconButton>
                        <IconButton size="small" className={classes.iconButton} onClick={() => this.handleApprove(dataIndex)}>
                            <DoneIcon />
                        </IconButton>
                        <IconButton size="small" className={classes.iconButton} onClick={( )=> this.handleDelete(dataIndex)}>
                            <DeleteOutlineIcon />
                        </IconButton>
                    </div>
                );
            case "2":
                return (
                    <div className={classes.approvedAct}>
                        <IconButton size="small" className={classes.iconButton} onClick={() => this.handleEdit(dataIndex)}>
                            <EditIcon />
                        </IconButton>
                        <IconButton size="small" className={classes.iconButton} onClick={()=> this.handleDelete(dataIndex)}>
                            <DeleteOutlineIcon />
                        </IconButton>
                    </div>
                );
            case "3":                
            return (
                <div className={classes.approvedAct}>
                    <IconButton size="small" className={classes.iconButton} onClick={() => this.handlePanding(dataIndex)}>
                        <AccessTimeIcon />
                    </IconButton>
                    <IconButton size="small" className={classes.iconButton} onClick={()=> this.handleApprove(dataIndex)}>
                        <DoneIcon />
                    </IconButton>                    
                </div>
            );
            default:                
                return null;
        }        
    };

    render() {
        const {classes, t} = this.props;
        const {drafts} = this.state;
        
        const columns = [
            // {
            //     name: "Drafts",
            //     label: t("Drafts"),
            //     options: {
            //         filter: true,                
            //         // eslint-disable-next-line no-unused-vars
            //         customBodyRender: (value, tableMeta, updateValue) => {
            //             return (
            //                 <Typography component="div" align="center">                                
            //                     <Rating name="half-rating" size="small"  className={classes.ratingstar} value={value} precision={0.5} readOnly />                                
            //                     <Typography variant="subtitle2">{value}</Typography>
            //                     {/* <Typography variant="body2">{tableMeta.rowData[13]}</Typography> */}
            //                 </Typography>
            //             )
            //         }
            //     },
            // },
            // {
            //     name: "Advantage",
            //     label: "Advantage",
            //     options: {
            //         filter: false,
            //         display: false,
            //         viewColumns: false,
            //     }
            // },
            
            // {
            //     name: "ProductCode",
            //     label: t("reviews.ProductCode"),
            //     options: {
            //         filter: true,
            //         // eslint-disable-next-line no-unused-vars
            //         customBodyRender: (value, tableMeta, updateValue) => {
            //             return (                                                    
            //                 tableMeta.rowData[11] ?
            //                 <Badge color="secondary" variant="dot">
            //                     <Typography variant="body2"> {value}</Typography>
            //                 </Badge> :
            //                 <Typography variant="body2">{value}</Typography>
            //             )
            //         }
            //     }
            // },
            // {
            //     name: "ProductName",
            //     label: t("reviews.ProductName"),
            //     options: {
            //       filter: true,
            //     }
            // },
                                    
            // {
            //     name: "CreatedOn",
            //     label: t("CreatedOn"),
            //     options: {
            //       filter: true,
            //       customBodyRender: (value) => {
            //         return (
            //             <>
            //                 <div><small>{moment(value).format("DD-MM-YY")}</small></div>
            //                 <div><small>{moment(value).format("HH:mm:ss")}</small></div>
            //             </>                        
                        
            //         )
            //     }
            //     }
            // },
            {
                name: "UpdatedOn",
                label: t("UpdatedOn"),
                options: {
                  filter: true,
                  customBodyRender: (value) => {
                    return (
                        <>
                            <div><small>{moment(value).format("DD-MM-YY")}</small></div>
                            <div><small>{moment(value).format("HH:mm:ss")}</small></div>
                        </>                        
                        
                    )
                }
                }
            },
            {
                name: "DocNumber",
                label: t("DocNumber"),
                options: {
                  filter: true,
                }
            },            
            {
                name: "UserName",
                label: "UserName",
                options: {
                    filter: true,                        
                }
            },
            {
                name: "Email",
                label: "Email",
                options: {
                    filter: true,                        
                }
            },
            {
                name: "RecipientLastName",
                label: "FirstName",
                options: {
                    filter: true,                        
                }
            },
            {
                name: "RecipientFirstName",
                label: "LastName",
                options: {
                    filter: true,                        
                }
            },
            {
                name: "RecipientMiddleName",
                label: "MiddleName",
                options: {
                    filter: true,                        
                }
            },
            {
                name: "Amount",
                label: "Amount",
                options: {
                    filter: false, 
                    customBodyRender: (value) => parseFloat(value).toFixed(2)
                }
            },
            {
                name: "DiscountCdc",
                label: "Discount",
                options: {
                    filter: false,
                    customBodyRender: (value) => parseFloat(value).toFixed(2)
                }
            },
            

            
            // {
            //     name: "Id",
            //     label: "Id",
            //     options: {
            //       filter: false,
            //       display: true,
            //       viewColumns: true,
            //     }
            // },
            // {
            //     name: "Act",
            //     label: " ",
            //     options: {
            //       filter: false,
            //       sort: false,
            //       empty: true,
            //       customBodyRenderLite: (dataIndex) => {
            //         return (
            //             this.renderRowAct(dataIndex, tabValue, classes)
            //         );
            //       }
            //     }
            // },               
        ];

        const options = {
            filter: true,
            filterType: "dropdown",
            responsive: "standard",
            selectableRows: "none",
            serverSide: true,
            expandableRows: true,
            expandableRowsHeader: false,
            expandableRowsOnClick: false,
            count: this.getCount(),
            onTableChange: (action, tableState) => {
                // console.log(action, tableState);
                switch (action) {
                    case "changePage":
                      this.changePage(tableState.page, tableState.searchText, tableState.sortOrder);
                      break;
                    case "sort":
                      // this.sort(tableState.page, tableState.sortOrder);
                      break;
                    case "search":
                        this.changePage(0, tableState.searchText, tableState.sortOrder);
                        break;
                    case "changeRowsPerPage":
                        this.changeRowsPerPage(tableState.rowsPerPage);                                                
                        break;
                    default:
                        // console.log("action not handled.");
                        break;                      
                  }
            },
            // eslint-disable-next-line no-unused-vars
            isRowExpandable: (dataIndex, expandedRows) => {
                
              // if (dataIndex === 3 || dataIndex === 4) return false;
      
              // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
              // if (expandedRows.data.length > 0 && expandedRows.data.filter(d => d.dataIndex === dataIndex).length === 0) return false;
              return true;
            },
            // rowsExpanded: [0, 1],
            // eslint-disable-next-line no-unused-vars
            renderExpandableRow: (rowData, rowMeta) => {
              const colSpan = rowData.length + 1;
              return (
                <TableRow>
                  
                  <TableCell colSpan={colSpan}>                    
                    <Box p={1}>
                        <Typography variant="subtitle2"> 
                            Draft details: {rowData[1]}
                        </Typography>                        
                    </Box>
                    {/* <Box p={1}>
                    <Typography variant="subtitle2"> 
                        Limitations:
                    </Typography>
                    <Typography variant="body2" gutterBottom> 
                        {rowData[2]}
                    </Typography>
                    </Box>
                    <Box p={1}>
                    <Typography variant="subtitle2"> 
                        Review:
                    </Typography>
                    <Typography variant="body2" gutterBottom> 
                        {rowData[9]}
                    </Typography>
                    </Box> */}

                  </TableCell>

                </TableRow>
              );
            },
            onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => console.log(curExpanded, allExpanded, rowsExpanded),
            customToolbar: () => {
                return (
                    <React.Fragment>
                    <Tooltip title={"Refresh"}>
                      <IconButton className={classes.iconButton} onClick={this.handleRefresh}>
                        <CachedIcon />
                      </IconButton>
                    </Tooltip>
                  </React.Fragment>
                );
            }
        };

        const components = {
            ExpandButton: function(props) {
              // if (props.dataIndex === 3 || props.dataIndex === 4) return <div style={{width:"24px"}} />;
              return <ExpandButton {...props} />;
            }
        };
        
        return (                    
            <div className={classes.root}>                                    
                <Paper className={classes.page} elevation={2} >                    
                    <MUIDataTable 
                        responsive="standard"
                        title={t("Drafts")}
                        data={drafts? drafts.Data : []}                            
                        columns={columns} 
                        options={options} 
                        components={components} 
                    />
                </Paper>                        
            </div>
        );
    }
}

export default withTranslation()(withWidth()(withStyles(styles, { withTheme: true })(Drafts)));