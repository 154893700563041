import React from "react";
import {withStyles} from "@material-ui/core/styles";
import {withRouter} from "react-router-dom";
import withWidth from "@material-ui/core/withWidth";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../store/Search";
import { actionOrderCreators } from "../../store/Order";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
// import CatalogItem from "../Catalog/CatalogItem";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import CatalogItemXs from "../Catalog/CatalogItemXs";
import { withTranslation } from "react-i18next";
import { OidcUserManager } from "../../helpers/OidcSettings";
import EmptyCard from "../Catalog/EmptyCard";
import gray from "@material-ui/core/colors/grey";

const styles = theme => (
    {
        root: {
            flexGrow: 1,
            padding: theme.spacing(2),
            marginBottom: 90,
            [theme.breakpoints.down("sm")]: {
                padding: theme.spacing(1 * 0.5),
                minHeight: 300,
            },
        },
        paper: {
            // padding: theme.spacing(1) * 2,
        },
        group: {
            padding: theme.spacing(2),            
            fontWeight: 400,
        },
        progress: {
            height: 2,
            position: "absolute",
            top: 0,
            left: 0,
            right:0,
          },  
          linearColorPrimary: {
            backgroundColor: "#abdcda",
          },
          linearBarColorPrimary: {
            backgroundColor: "#d20000",
          },
          catalogItem: {            
            height: "508px",
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: gray[200],
            borderRadius: "8px",
            // borderRight: "1px solid #f5f6fa",
            position: "relative",
            zIndex: 1,
            [theme.breakpoints.down("sm")]: {
                height: "390px",
            }, 
            "&:hover": {
                zIndex: 1000,
            },
        },
        wrapper: {        
            position: "absolute",        
            zIndex: 1,
            width: "100%",
            // minHeight: "503",
            height: "504px",
            overflow: "hidden",
            left: 0,
            top: 0,        
            // borderRadius: theme.spacing(1 / 2),
            transition: "box-shadow .5s ease-in-out",
            [theme.breakpoints.down("sm")]: {
                height: "376px",
                minHeight: "376px",
            }, 
            "&:hover": {
                height: "auto !important",
                borderRadius: "8px",
                transform: "scale(1.04)",
                boxShadow: "0 8px 10px rgb(54 74 178 / 16%), 0 6px 30px rgb(54 74 178 / 4%), 0 16px 24px rgb(54 74 178 / 8%)",
            }
        },
    }
);


class Search extends React.Component {  
    constructor(props) {        
        super(props);
        let N = 1;         
        // switch (this.props.width) {
        //     case "xs":                
        //         N = 1;
        //         break;
        //     case "sm":                
        //         N = 2;
        //         break;
        //     case "md":                
        //         N = 3;
        //         break;
        //     case "lg":                
        //         N = 4;
        //         break;
        //     case "xl":                
        //         N = 4;
        //         break;
        //     default:
        //         N = 1;
        //         break;
        // }            
        this.state = {
            splash: Array.apply(null, {length: N}).map(Number.call, Number),                               
            q: this.props.match.params.q,
            page: parseInt(this.props.match.params.page, 10) || 1,
            size: parseInt(this.props.match.params.page, 10) || 12,
            IsAuthenticated: false,
        };
        // this.props.actions.requestSearch(q, page, size);        
    }

    loadUser = async () => {         
        const userManager = await OidcUserManager();
        userManager.getUser().then(user => {
            if (user != null) {
              // const token = `Bearer ${user.access_token}`;
              // console.log("token", token);
              this.setState({IsAuthenticated: true});
            }
        });
    };

    static getDerivedStateFromProps(props, state) {
        const q = props.match.params.q;
        const page = parseInt(props.match.params.page, 10) || 1;
        const size = parseInt(props.match.params.page, 10) || 12;
        
        if(q !== state.q || page !== state.page || size !== state.size){
            return {
                q: q,
                page: page,
                size: size
            };    
        }         
        return null;       
    }
    
    componentDidMount() {
        window.scrollTo({top: 0, behavior: "smooth"});
        this.props.actions.requestSearch(this.state.q, this.state.page, this.state.size);
        this.loadUser();
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.state.q !==  prevState.q || this.state.page !== prevState.page || this.state.size !== prevState.size  ) {
            this.props.actions.requestSearch(this.state.q, this.state.page, this.state.size);            
        }    
    }    

    render(){
        const { classes, response, isLoading, t, width } = this.props;
        
        return (
            <div className={classes.root}>
                { isLoading && 
                <LinearProgress className={classes.progress} 
                    classes={{
                        colorPrimary: classes.linearColorPrimary,
                        barColorPrimary: classes.linearBarColorPrimary,
                    }}
                />
                }
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Paper  elevation={1}>
                            <Typography className={classes.group} variant="body1" component="h3">                                
                                <small> { t("common.SearchResult")}</small> : {this.props.q}
                            </Typography>                            
                        </Paper>                        
                    </Grid>                
                </Grid>
                { response.CatalogItems && 
                
                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={width === "xs" ? 1 : 3}>
                {                    
                    response.CatalogItems.length > 0 ?
                        response.CatalogItems.map((item) => (
                            <Grid item  key={item.Code} xs={6} sm={6} md={4} lg={3} xl={2}>
                                <div className={classes.catalogItem}>
                                    <div className={classes.wrapper}>
                                        <CatalogItemXs item={item} IsAuthenticated={this.state.IsAuthenticated}></CatalogItemXs>
                                    </div>
                               </div>
                            </Grid>
                            // <Grid item key={index} xs={12} sm={6} md={4} lg={3} xl={2} >
                            //     <CatalogItemXs item={item} IsAuthenticated={this.state.IsAuthenticated}></CatalogItemXs>
                            // </Grid>                        
                        )) : 
                        this.state.splash.map((item ,index) =>(
                            <Grid item key={index} xs={12} >
                                <EmptyCard></EmptyCard>
                            </Grid>                                        
                        ))                    
                }
                </Grid>
                }
            </div>
        );
    }
}

export default withTranslation()(withWidth()(withRouter(withStyles(styles)(
    connect(    
        state => state.searchRequest,
        dispatch => {
            return {
               actions: bindActionCreators(Object.assign({}, actionCreators, actionOrderCreators), dispatch)
        }}        
    )(Search)))
));